import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import AlertProvider from './components/Alert/AlertProvider';

import { MobileProvider } from './services/contexts/MobileContext';
import { CustomThemeProvider } from './services/contexts/Themes/ThemeContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <CustomThemeProvider>
    <AlertProvider>
      <MobileProvider>
        <Router>
          <CssBaseline />
          <App />
        </Router>
      </MobileProvider>
    </AlertProvider>
  </CustomThemeProvider>,
);
