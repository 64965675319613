import { Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import CommonButton from 'components/common/CommonButton';
import { useForm, useWatch } from 'react-hook-form';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ApplicationQuestionTypes,
  IApplicationForm,
  IApplicationSection,
} from 'utils/models';
import { FlexBox } from 'utils/styledComponents';
import usePostForm from 'hooks/Applications/usePostFrom';
import ApplicationInput from '../../common/ApplicationInput';
import ApplicationDropZone from '../../common/ApplicationDropZone';
import Loading from '../../common/Loading';
import ApplicationSelect from './ApplicationSelect';
import ApplicationRadio from './ApplicationRadio';
import { merge } from 'lodash';
import ApplicationFormModal from './ApplicationFormModal';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { getStoredAppFormById, setStoredAppFormById } from 'utils/storage';

export interface FormForSend extends IApplicationForm {
  files: FileWithId[] | null;
}
interface IFormWithHooks {
  form: IApplicationForm;
  formId: string | undefined;
  filledForm: FormForSend | null;
  setFilledForm: React.Dispatch<React.SetStateAction<FormForSend | null>>;
}

export interface FileWithId {
  questionId?: number;
  sectionId?: number;
  file: File;
}

const FormWithHook: FunctionComponent<IFormWithHooks> = (props) => {
  const { form, formId, filledForm, setFilledForm } = props;
  const { submitForm, isLoading, isSuccess, setIsSuccess } = usePostForm({
    formId,
  });

  const { addAlert } = useContext(AlertContext);

  const [isSuccessfulModalOpen, setIsSuccessfulModalOpen] =
    useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [isTriggerClearFiles, setIsTriggerClearFiles] =
    useState<boolean>(false);

  const {
    control,
    trigger,
    setValue,
    reset,
    getValues,
    clearErrors,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<FormForSend>({
    defaultValues: filledForm!,
  });

  const updatedForm = useWatch({ control });

  useEffect(() => {
    setFilledForm((prev) => merge({}, prev, updatedForm));
    setTimeout(() => {
      setStoredAppFormById(updatedForm as IApplicationForm, formId);
    }, 0);
  }, [updatedForm, setFilledForm]);

  useEffect(() => {
    if (isSuccess === true) {
      setIsSuccessfulModalOpen(true);
      setIsErrorModalOpen(false);
    } else if (isSuccess === false) {
      setIsErrorModalOpen(true);
      setIsSuccessfulModalOpen(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    const retrievedForm = getStoredAppFormById(formId);
    if (retrievedForm) {
      setFilledForm(retrievedForm as FormForSend);
      reset(retrievedForm);
      setIsTriggerClearFiles(true);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message:
          'We retrieved your progress and will further continue tracking your changes.',
      });
    } else {
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Your progress is automatically saved as you make changes.',
      });
    }
  }, []);

  const onSubmit = (data: FormForSend) => {
    const { files, ...dtoObject } = data;
    submitForm(dtoObject, files);
  };

  if (isSuccess) {
    return (
      <FlexBox
        className={'center'}
        sx={{ height: '90vh', flexDirection: 'column', gap: '1rem' }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/success.svg`}
          alt='success'
        />
        <Typography variant={'h4'}>Your application was received!</Typography>
      </FlexBox>
    );
  }

  function renderQuestionByType(
    type: ApplicationQuestionTypes,
    sectionIndex: number,
    questionIndex: number,
    sectionId: number,
    questionId: number,
  ) {
    const answerPath =
      `applicationTemplate.sections.${sectionIndex}.questions.${questionIndex}.answer` as const;
    const pathToQuestions =
      form.applicationTemplate.sections[sectionIndex].questions[questionIndex];

    if (
      type === ApplicationQuestionTypes.TEXT ||
      type === ApplicationQuestionTypes.TEXT_AREA ||
      type === ApplicationQuestionTypes.LINK
    ) {
      return (
        <ApplicationInput
          clearErrors={clearErrors}
          errors={errors}
          type={type}
          control={control}
          answerPath={answerPath}
        />
      );
    }
    if (type === ApplicationQuestionTypes.FILE) {
      return (
        <ApplicationDropZone
          setError={setError}
          clearErrors={clearErrors}
          answerPath={answerPath}
          sectionId={sectionId}
          questionId={questionId}
          control={control}
          setValue={setValue}
          isTriggerClearFiles={isTriggerClearFiles}
        />
      );
    }

    if (
      type === ApplicationQuestionTypes.TAGS ||
      type === ApplicationQuestionTypes.CHECKBOX
    ) {
      return (
        <ApplicationSelect
          filledForm={filledForm}
          type={type}
          pathToQuestions={pathToQuestions}
          control={control}
          answerPath={answerPath}
          clearErrors={clearErrors}
        />
      );
    }

    if (type === ApplicationQuestionTypes.RADIO) {
      return (
        <ApplicationRadio
          clearErrors={clearErrors}
          pathToQuestions={pathToQuestions}
          answerPath={answerPath}
          control={control}
        />
      );
    }
  }

  function renderQuestions() {
    if (!form) return;

    const sections = form.applicationTemplate.sections;

    function renderSections() {
      return (
        <>
          {sections.map(
            (section: IApplicationSection, sectionIndex: number) => (
              <Box key={sectionIndex} sx={{ width: '100%', marginTop: '5rem' }}>
                <Typography variant='h3'>
                  {sectionIndex + 1}. {section.name}
                </Typography>
                {renderQuestionInSection(section, sectionIndex)}
              </Box>
            ),
          )}
        </>
      );
    }

    return renderSections();

    function renderQuestionInSection(
      section: IApplicationSection,
      sectionIndex: number,
    ) {
      return section.questions.map((item, questionIndex) => {
        return (
          <Box key={questionIndex} sx={{ marginY: '2rem', marginLeft: '2rem' }}>
            <Typography variant='h4' sx={{ marginY: '1rem' }}>
              {questionIndex + 1}. {item.question}
            </Typography>
            <Typography
              variant='body1'
              sx={{ fontStyle: 'italic', color: 'grey', marginY: '1rem' }}>
              {item.description}
            </Typography>
            {renderQuestionByType(
              item.type,
              sectionIndex,
              questionIndex,
              section.id!,
              item.id!,
            )}
          </Box>
        );
      });
    }
  }

  function renderStaticQuestions() {
    return (
      <Box sx={{ width: '100%' }}>
        <Typography variant='h3'>Basic Information</Typography>
        <Box sx={{ marginY: '2rem', marginLeft: '2rem' }}>
          <Typography variant='h4' sx={{ marginY: '1rem' }}>
            1. Applicant name
          </Typography>
          <Box
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <ApplicationInput
              clearErrors={clearErrors}
              errors={errors}
              type={ApplicationQuestionTypes.TEXT}
              control={control}
              fixedAnswer={'applicantName'}
              isCounter={false}
            />
          </Box>
        </Box>

        <Box sx={{ marginY: '2rem', marginLeft: '2rem' }}>
          <Typography variant='h4' sx={{ marginY: '1rem' }}>
            2. Company name
          </Typography>
          <Box
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <ApplicationInput
              clearErrors={clearErrors}
              errors={errors}
              type={ApplicationQuestionTypes.TEXT}
              control={control}
              fixedAnswer={'companyName'}
              isCounter={false}
            />
          </Box>
        </Box>

        <Box sx={{ marginY: '2rem', marginLeft: '2rem' }}>
          <Typography variant='h4' sx={{ marginY: '1rem' }}>
            3. Email Address
          </Typography>
          <Box
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <ApplicationInput
              type={ApplicationQuestionTypes.TEXT}
              control={control}
              fixedAnswer={'email'}
              typoOfInput={'email'}
              isCounter={false}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <FlexBox
        className={'center'}
        sx={{
          height: '80vh',
        }}>
        <Loading />;
      </FlexBox>
    );
  }

  return (
    <Container
      sx={{
        width: '100%',
        maxWidth: '60rem',
        paddingBottom: '3rem',
      }}>
      <FlexBox
        sx={{
          gap: '2rem',
          flexDirection: 'column',
          alignItems: 'start',
        }}>
        <Typography variant='h2'>{form.name}</Typography>
        {form.description && (
          <Typography
            variant='body1'
            sx={{ fontStyle: 'italic', color: 'grey' }}>
            {form.description}
          </Typography>
        )}
        <form style={{ width: '100%' }}>
          {renderStaticQuestions()}
          {renderQuestions()}
          <ApplicationFormModal
            isOpen={isErrorModalOpen}
            onClose={() => setIsErrorModalOpen(false)}
            isError={true}
            onSubmitForm={() => {
              setIsSuccess(null);
              handleSubmit(() => onSubmit(filledForm!))();
            }}
            onBack={() => {
              setIsSuccess(null);
              setIsErrorModalOpen(false);
            }}
            message='It seems there was a problem with submitting your application. Please try again'
          />
          <ApplicationFormModal
            isOpen={isSuccessfulModalOpen}
            onClose={() => setIsSuccessfulModalOpen(false)}
            isError={false}
            onSubmitForm={() => {
              handleSubmit(() => onSubmit(filledForm!))();
            }}
            onBack={() => {
              setIsSuccessfulModalOpen(false);
            }}
            message={form.submissionMessage || ''}
            email={getValues().email}
          />
          <CommonButton
            text={'Finish'}
            onClick={async () => {
              const isValid = await trigger();
              if (isValid) {
                setIsSuccessfulModalOpen(true);
              }
            }}
            bgcolor={'black'}
            sx={{
              borderRadius: '0px',
              marginTop: '1rem',
              width: '12.5rem',
              height: '3rem',
              '&:hover': {
                backgroundColor: 'black',
                boxShadow: '0px 4px 4px 0px #00000040',
              },
            }}
          />
        </form>
      </FlexBox>
    </Container>
  );
};

export default FormWithHook;
