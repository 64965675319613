import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { JOIN_BY_INVITE_ROUTE, postFormData } from 'utils/requests';
import { AlertContext, ALERT_TYPES } from '../Alert/AlertContext';
import { useNavigate } from 'react-router';
import RegisterForm from './RegisterForm';
import { white } from 'theme';
import Loading from '../common/Loading';
import { getAccount } from 'utils/storage';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '50rem',
    marginTop: '2rem',
  },
  loginBox: {
    display: 'flex',
    backgroundColor: white,
    width: '35%',
    borderRadius: '5px',
    border: '#658288 1px solid',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '35px',
    paddingBottom: '1.875rem',
    rowGap: '1.875rem',
  },
}));

interface OwnProps {
  secretKey: string;
}

const JoinByInvite: FunctionComponent<OwnProps> = (props) => {
  const classes = useStyles();
  const { secretKey } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [email, setEmail] = useState<string | null>('');
  const { addAlert } = useContext(AlertContext);
  const userEmail = getAccount().email;
  const navigate = useNavigate();

  useEffect(() => {
    getInvite();
  }, []);

  async function getInvite() {
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('invite', secretKey);
      if (userEmail) formData.append('email', userEmail);
      const invite = await postFormData(JOIN_BY_INVITE_ROUTE, formData, true);
      setType(invite.type);
      setEmail(invite.email);

      if (!invite.type) {
        addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: invite,
        });
        navigate('/login');
      }
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
      navigate('/login');
    }

    setLoading(false);
  }

  function renderMessage() {
    if (loading) return <Loading />;
    if (!type) return null;
    if (type === 'noAccount') {
      return <RegisterForm emailToRegisterWith={email} secretKey={secretKey} />;
    }
    return null;
  }

  return renderMessage();
};

export default JoinByInvite;
