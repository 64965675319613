import { Box, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, ReactElement } from 'react';
import CloseModalButton from './CloseModalButton';
import { primary } from 'theme';
import { FlexBox } from '../../../utils/styledComponents';

interface IModalHeader {
  handleClose: () => void;
  text: string;
  textColor?: string;
  noCloseButton?: boolean;
  headerSize?: string | number;
  icon?: ReactElement;
}

const ModalHeader: FunctionComponent<IModalHeader> = (props) => {
  const { handleClose, text, textColor, noCloseButton, headerSize, icon } =
    props;

  const theme = useTheme();

  return (
    <Box display='flex' justifyContent='space-between'>
      <FlexBox
        sx={{
          gap: '0.75rem',
        }}>
        {icon}
        <Typography
          variant='h5'
          sx={{
            marginBottom: '1rem',
            paddingTop: 1,
            color: textColor ?? primary.pureWhite,
            fontSize: headerSize ?? '1.25rem',
          }}>
          {text}
        </Typography>
      </FlexBox>
      {!noCloseButton && (
        <CloseModalButton
          handleClose={handleClose}
          color={textColor ?? theme.palette.primary.dark}
          sx={{
            boxShadow: 'none',
          }}
        />
      )}
    </Box>
  );
};

export default ModalHeader;
