import React, { FunctionComponent } from 'react';
import { ArrowBackIos } from '@mui/icons-material';
import useBack from 'hooks/Common/useBack';
import { Box } from '@mui/system';
import { Button, Typography, useTheme } from '@mui/material';
import { naturalPallete } from 'theme';

export interface ISideMenuBackButton {
  text: string;
}

const SideMenuBackButton: FunctionComponent<ISideMenuBackButton> = (props) => {
  const { text } = props;
  const { navigateBack } = useBack();
  const theme = useTheme();

  return (
    <Box
      sx={{
        mt: '2rem',
        pl: '0.5rem',
        py: {
          xs: '0.5rem',
          md: '0.2rem',
        },
      }}>
      <Button
        sx={{
          display: 'flex',
          justifyContent: 'start',
          borderRadius: '0px',
          borderLeft: '2px solid transparent',
        }}
        fullWidth
        onClick={navigateBack}>
        <ArrowBackIos
          sx={{
            fontSize: '1.25rem',
            marginRight: '0.5rem',
            color: naturalPallete.natural2,
          }}
        />
        <Typography
          sx={{
            ml: '0.75rem',
            fontWeight: 500,
            color: naturalPallete.natural2,
          }}>
          {text}
        </Typography>
      </Button>
    </Box>
  );
};

export default SideMenuBackButton;
