import { Box } from '@mui/system';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Button, Typography, useTheme } from '@mui/material';
import { Close, Place, RemoveRedEye } from '@mui/icons-material';
import { primary } from 'theme';
import { IBookedRoom } from 'utils/models';
import RoomDetails from '../RoomBooking/RoomDetails';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { BOOKING_ROOM_UPCOMING, deleteData } from 'utils/requests';
import ConfirmationForm from 'components/common/ConfirmationForm';

interface RoomProps {
  room: IBookedRoom;
  setRooms: (rooms: any) => void;
}

const Room: FunctionComponent<RoomProps> = (props) => {
  const { room, setRooms } = props;
  const { ecosystemName } = useParams();
  const navigate = useNavigate();

  const theme = useTheme();

  const { addAlert } = useContext(AlertContext);

  const [showMeetingDetails, setShowMeetingDetails] = useState<boolean>(false);
  const [isShowConfirmForm, setIsShowConfirmForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function showDetails() {
    setShowMeetingDetails(true);
  }

  function cancelBooking() {
    setShowMeetingDetails(false);
    setIsShowConfirmForm(true);
  }

  async function requestCancelBooking() {
    setIsLoading(true);

    try {
      await deleteData(BOOKING_ROOM_UPCOMING, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'id', value: room.id },
      ]);

      setRooms((prevMeetings: IBookedRoom[]) =>
        prevMeetings.filter((m) => m.id !== room.id),
      );
      setShowMeetingDetails(false);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Booking canceled successfully',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderPhoto() {
    return (
      <Box
        sx={{
          height: '100%',
          width: '5rem',
          minWidth: 80,
          cursor: 'pointer',
          marginRight: 2,
        }}>
        <Box
          sx={{
            borderRadius: '6px',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
          onClick={showDetails}>
          <img
            src={room.picture}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
      </Box>
    );
  }

  function renderMeetingDetails() {
    return (
      <Box
        sx={{
          height: '100%',
          width: '59%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'left',
        }}>
        <Typography
          fontWeight='bold'
          fontSize={'1rem'}
          color={theme.palette.primary.dark}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {room.title}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              bgcolor: theme.palette.primary.light,
              paddingX: 1,
              paddingY: 0.2,
              borderRadius: 0.3,
              marginLeft: 0,
              marginRight: 1,
              maxHeight: 22,
            }}>
            <Typography
              fontSize={'0.75rem'}
              fontWeight='bold'
              color={
                theme.palette.primary.main
              }>{`${room.startTime} - ${room.endTime}`}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              marginTop: 0.2,
              maxWidth: 100,
              overflow: 'hidden',
            }}>
            <Place
              sx={{
                color: theme.palette.primary.main,
                fontSize: '0.75rem',
                marginRight: 0.5,
                marginTop: 0.2,
              }}
            />
            <Typography
              fontSize={'0.75rem'}
              fontWeight='bold'
              color={theme.palette.primary.main}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {room.location}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  function renderButtons() {
    return (
      <Box
        sx={{
          height: '100%',
          minWidth: 46,
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Button
          sx={{
            minWidth: 'unset',
            width: 30,
            height: 30,
            bgcolor: theme.palette.primary.dark,
            borderRadius: '6px',
            marginY: 0.4,
            '&:hover': { bgcolor: theme.palette.primary.contrastText },
          }}
          onClick={showDetails}>
          <RemoveRedEye sx={{ color: primary.pureWhite }} />
        </Button>
        <Button
          sx={{
            minWidth: 'unset',
            width: 30,
            height: 30,
            bgcolor: '#FFE7E6',
            borderRadius: '6px',
            marginY: 0.4,
            border: '1px solid #DB9F9D',
            '&:hover': { bgcolor: '#DB9F9D' },
          }}
          onClick={cancelBooking}>
          <Close sx={{ color: '#D25B58' }} />
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: 90,
        bgcolor: '#FFFFFF',
        border: 1,
        borderColor: '#D4D4D4',
        borderRadius: '6px',
        marginY: 2,
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      {renderPhoto()}
      {renderMeetingDetails()}
      {renderButtons()}
      {showMeetingDetails && (
        <RoomDetails
          isOpen={showMeetingDetails}
          handleClose={() => {
            setShowMeetingDetails(false);
          }}
          room={room}
          isBooked={true}
          cancelBooking={cancelBooking}
        />
      )}
      {isShowConfirmForm && (
        <ConfirmationForm
          isOpen={isShowConfirmForm}
          handleClose={() => {
            setIsShowConfirmForm(false);
          }}
          funcExe={requestCancelBooking}
          text={'cancel this booking?'}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default Room;
