import React, { FunctionComponent, useState } from 'react';
import InformationModal from 'components/common/Modal/InformationModal';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import InputField from 'components/common/InputField';
import useRenameColumn from 'hooks/Applications/useRenameColumn';
import { useApplicationContext } from 'services/contexts/Applications/ApplicationsContext';
import { useParams } from 'react-router';
import Loading from 'components/common/Loading';

interface IRenameColumnModalProps {
  isOpen: boolean;
  handleClose: () => void;
  newColumnName: string;
  setNewColumnName: (value: string) => void;
  id: number;
  name: string;
}

const RenameColumnModal: FunctionComponent<IRenameColumnModalProps> = (
  props,
) => {
  const { isOpen, handleClose, newColumnName, setNewColumnName, id, name } =
    props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    setNewColumnName(e.target.value);
  }

  const {
    applicationOverviewData,
    setApplicationOverviewData,
    selectedApplicationProcessId,
  } = useApplicationContext();
  const { ecosystemName } = useParams();

  const renameColumn = useRenameColumn({
    selectedApplicationProcessId,
    applicationOverviewData,
    setApplicationOverviewData,
    ecosystemName,
    statusId: id,
    newColumnName,
    setIsLoading,
  });

  function handleRename() {
    renameColumn();
    handleClose();
  }

  if (isLoading) {
    return <Loading height={200} />;
  }

  return (
    <InformationModal
      isOpen={isOpen}
      handleClose={handleClose}
      headerText='Rename column'
      primaryText='Save'
      primaryOnClick={handleRename}
      secondaryText='Cancel'
      secondaryOnClick={handleClose}
      headerSize='1.875rem'
      width='36rem'
      buttonSx={{
        '&:hover': {
          color: '#13253E',
          backgroundColor: '#EBF1F9',
        },
      }}>
      <Box sx={{ width: '30rem', marginY: '2rem' }}>
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '0.875rem',
            marginBottom: '1rem',
          }}>
          Column name
        </Typography>
        <InputField
          value={newColumnName}
          onChange={handleChange}
          placeholder={name}
          maxLength={25}
        />
      </Box>
    </InformationModal>
  );
};

export default RenameColumnModal;
