import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { Controller, FieldErrors, useWatch } from 'react-hook-form';
import {
  IApplicationAssessmentOption,
  IApplicationAssessmentSection,
  IApplicationAssessmentTemplate,
} from 'utils/models';
import { FlexBox } from 'utils/styledComponents';
import { ControlButtonStyled } from '../ApplicationBuilder';
import { primary } from 'theme';
import { Add, Close, ErrorRounded } from '@mui/icons-material';
import InputField from 'components/common/InputField';
import AssessmentBuilderSubSections from './AssessmentBuilderSubSections';
import AssessmentPercentageInput from './AssessmentPercentageInput';

interface IAssessmentBuilderSection {
  control: any;
  errors: FieldErrors<IApplicationAssessmentTemplate>;
  sectionIndex: number;
  resetField: any;
  newOptions: IApplicationAssessmentOption[];
  appendSection: (a: any) => any;
  removeSection: (a: any) => any;
  isWeighted: boolean;
}

const AssessmentBuilderSection: FunctionComponent<IAssessmentBuilderSection> = (
  props,
) => {
  const {
    control,
    sectionIndex,
    errors,
    resetField,
    newOptions,
    appendSection,
    removeSection,
    isWeighted,
  } = props;
  const theme = useTheme();

  const sections = useWatch({
    control,
    name: 'sections',
  });

  const anySectionWeightProvided = sections?.some(
    (section: IApplicationAssessmentSection) =>
      section.weight !== undefined && section.weight > 0,
  );

  const totalSectionWeight = anySectionWeightProvided
    ? sections.reduce(
        (total: number, section: IApplicationAssessmentSection) => {
          if (section.weight === undefined || section.weight === 0) {
            return total;
          }
          return total + parseFloat(section.weight?.toString() || '0');
        },
        0,
      )
    : null;

  const isWeightsValid =
    totalSectionWeight === 0 ||
    totalSectionWeight === 100 ||
    totalSectionWeight === null;

  return (
    <>
      <Box
        sx={{
          marginTop: '1.25rem',
        }}>
        <Typography variant={'h6'} sx={{ fontWeight: 'bold', ml: '3.125rem' }}>
          Section name*
        </Typography>
        <FlexBox
          sx={{
            marginBottom: '1.25rem',
          }}>
          <Box sx={{ width: '3rem' }} />
          <Controller
            name={`sections.${sectionIndex}.name`}
            control={control}
            rules={{
              required: sectionIndex !== 0 && 'Please, enter a name of section',
            }}
            render={({ field }) => (
              <InputField
                {...field}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ) => {
                  field.onChange(e);
                }}
                helperText={errors.sections && errors.sections.message}
                helperIcon={errors.sections && 'warning'}
                error={!!errors?.sections?.[sectionIndex]?.name}
                width={'31.25rem'}
                maxLength={255}
                placeholder={'Create a name for the section'}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    opacity: sectionIndex === 0 ? '200%' : '100%',
                    '& .MuiInputBase-input': {
                      WebkitTextFillColor: primary.mainBlackFont,
                    },
                  },
                }}
              />
            )}
          />
          <ControlButtonStyled
            disabled={sectionIndex === 0}
            onClick={() => removeSection(sectionIndex)}>
            <Close />
          </ControlButtonStyled>
          {isWeighted && (
            <FlexBox sx={{ gap: '1.25rem' }}>
              <Box
                sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant={'body1'}
                  sx={{ color: primary.natural3, ml: '1.25rem' }}>
                  All subsections in a section must be equal to 100% in weight
                </Typography>
                <ErrorRounded
                  sx={{ color: primary.yellowStar, ml: '0.25rem' }}
                />
              </Box>
              <AssessmentPercentageInput
                control={control}
                errors={errors}
                sectionIndex={sectionIndex}
                isWeightsValid={isWeightsValid}
                bgColor={primary.natural6}
                border={`1px solid ${primary.natural5}`}
              />
            </FlexBox>
          )}
        </FlexBox>
        <AssessmentBuilderSubSections
          resetField={resetField}
          errors={errors}
          control={control}
          sectionIndex={sectionIndex}
          newOptions={newOptions}
          isWeighted={isWeighted}
        />
      </Box>
      <ControlButtonStyled
        sx={{
          '& .MuiSvgIcon-root, .MuiBox-root': {
            transition: 'all 0.3s ease-in-out',
          },
          ':hover': {
            '& .MuiSvgIcon-root': {
              color: primary.pureWhite,
            },
            '& .MuiBox-root': {
              backgroundColor: primary.dark,
            },
          },
          mt: '1rem',
        }}
        onClick={() =>
          appendSection({
            name: '',
            subSections: [
              {
                name: '',
                questions: [
                  {
                    name: '',
                    options: newOptions,
                  },
                ],
              },
            ],
          })
        }>
        <Box
          sx={{
            border: '1px solid black',
            borderRadius: '100%',
            lineHeight: '0.75',
            backgroundColor: primary.pureWhite,
          }}>
          <Add
            sx={{
              fontSize: '2rem',
            }}
          />
        </Box>
        <Typography
          sx={{
            marginLeft: '0.625rem',
            fontSize: '1.15rem',
          }}>
          Add a new section
        </Typography>
      </ControlButtonStyled>
    </>
  );
};

export default AssessmentBuilderSection;
