import React, { useContext, useState } from 'react';
import { ICompanyProfileTemplate } from 'utils/models';
import { CUSTOM_COMPANY_PROFILE_ROUTE, putData } from 'utils/requests';
import { useNavigate, useParams } from 'react-router';
import { AlertContext, ALERT_TYPES } from 'components/Alert/AlertContext';
import { useCompanyTemplateContext } from '../CompanyTemplateContext';
import { primary } from 'theme';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { IconPicker } from '../../../common/IconPicker';

export const CompanyTemplateActionButtons = () => {
  const { ecosystemName, companyTemplateId } = useParams();
  const { addAlert } = useContext(AlertContext);
  const { companyTemplate, setCompanyTemplate } = useCompanyTemplateContext();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  async function saveTemplate() {
    setLoading(true);

    if (!companyTemplate) return;

    if (companyTemplate && companyTemplate.name !== '') {
      try {
        const data: ICompanyProfileTemplate = await putData(
          CUSTOM_COMPANY_PROFILE_ROUTE,
          [{ name: 'ecosystemName', value: ecosystemName }],
          companyTemplate,
        );

        addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: 'Company Template Saved',
        });

        if (companyTemplateId === 'new') {
          setCompanyTemplate(data);
        }
        navigate(`/ecosystem/${ecosystemName}/admin/custom-company-profiles`);
      } catch (e: any) {
        console.error('error', e);
        addAlert({
          type: ALERT_TYPES.ERROR,
          message: e.message,
        });
      }
    } else {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: 'You must give template a name',
      });
    }

    setLoading(false);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '2rem',
      }}>
      <Button
        onClick={() => {
          setCompanyTemplate(null);
          navigate(`/ecosystem/${ecosystemName}/admin/custom-company-profiles`);
        }}
        sx={{
          padding: '0.5rem 4rem',
          backgroundColor: primary.additionalLightBlue,
        }}>
        <IconPicker name='EditOff' sx={{ color: primary.dark }} />
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: '700',
            color: primary.dark,
            marginLeft: '0.2rem',
          }}>
          Cancel
        </Typography>
      </Button>
      <Button
        onClick={() => saveTemplate()}
        sx={{
          padding: '0.5rem 5rem',
          backgroundColor: primary.dark,
        }}>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: '700',
            color: primary.pureWhite,
          }}>
          Save
        </Typography>
      </Button>
    </Box>
  );
};
