import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Checkbox, Theme, Typography, useTheme } from '@mui/material';
import { ICompanyJourney } from 'utils/models';
import Avatar from 'components/common/Avatar';
import { previewText } from 'utils/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.45rem 0.6rem',
    paddingRight: '0.25rem',
    borderRadius: '6px',
    backgroundColor: (props: any) =>
      props.index % 2 !== 0 ? '#f5f5f5' : 'transparent',
  },
  cont: {
    display: 'flex',
    alignItems: 'center',
  },
  infoCont: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0.75rem',

    '& h6': {
      fontSize: '0.85rem !important',
      color: `${theme.palette.primary.dark} !important`,
      lineHeight: '1rem !important',
      marginTop: '0.3rem !important',
    },

    '& p': {
      fontSize: '0.7rem !important',
    },
  },
}));

interface IAssignJourneysCompany {
  company: ICompanyJourney;
  index: number;
  isSelected: boolean;
  onCheck: () => void;
}

const AssignJourneysCompany: FunctionComponent<IAssignJourneysCompany> = (
  props,
) => {
  const { company, index, isSelected, onCheck } = props;
  const { companyName, companyLogo, companyAssignedJourney } = company;
  const classes = useStyles({ index });
  const theme = useTheme();
  const haveJourney = !!companyAssignedJourney;

  return (
    <div className={classes.root}>
      <div className={classes.cont}>
        <Avatar
          source={companyLogo}
          height={40}
          width={40}
          radius={120}
          border={true}
        />
        <div className={classes.infoCont}>
          <Typography variant={'h6'}>{previewText(companyName, 17)}</Typography>
          <Typography
            variant={'body1'}
            color={haveJourney ? theme.palette.primary.main : '#9e9e9e'}>
            {haveJourney
              ? previewText(companyAssignedJourney, 22)
              : 'No journey applied'}
          </Typography>
        </div>
      </div>
      <Checkbox
        sx={{ color: '#bdbdbd' }}
        checked={isSelected}
        onChange={() => onCheck()}
      />
    </div>
  );
};

export default AssignJourneysCompany;
