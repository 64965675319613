import { useParams } from 'react-router';
import { IEvent } from 'utils/models';
import { EVENT_DELETE_IMAGE_ROUTE, deleteData } from 'utils/requests';
import { useContext } from 'react';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';

interface IDeletePhoto {
  event: IEvent;
}

const useDeleteEventImage = (props: IDeletePhoto) => {
  const { event } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  async function deleteEventImage() {
    try {
      await deleteData(EVENT_DELETE_IMAGE_ROUTE, [
        { name: 'id', value: event.id },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        title: 'Event photo deleted',
        message: 'Event photo deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  return deleteEventImage;
};

export default useDeleteEventImage;
