import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IApplicationAssessmentTemplateBase } from 'utils/models';
import { FlexBox } from 'utils/styledComponents';
import TemplateCard from './TemplateCard';
import {
  APPLICATION_ASSESSMENT_TEMPLATES_BASIC,
  getData,
} from 'utils/requests';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import Loading from 'components/common/Loading';

interface ITemplatesGrid {}

const TemplatesGrid: FunctionComponent<ITemplatesGrid> = () => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [templatesList, setTemplateList] = useState<
    IApplicationAssessmentTemplateBase[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchTemplateList();
  }, []);

  async function fetchTemplateList() {
    setIsLoading(true);

    try {
      const data = await getData(APPLICATION_ASSESSMENT_TEMPLATES_BASIC, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setTemplateList(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  return (
    <Box
      sx={{
        margin: '4rem 0 0 3rem',
        maxWidth: '100%',
      }}>
      <FlexBox
        sx={{
          gap: '1.25rem',
          mb: '1.25rem',
        }}>
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '2rem',
            lineHeight: '3.75rem',
          }}>
          All assessment forms
        </Typography>
      </FlexBox>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid container sx={{ width: '100%' }}>
          {templatesList?.map((template) => (
            <Grid
              key={template.id}
              item
              xs={12}
              sm={4}
              md={3}
              lg={2}
              xl={2}
              sx={{
                padding: '1.25rem',
              }}>
              <TemplateCard {...template} setTemplateList={setTemplateList} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default TemplatesGrid;
