import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import Modal from '../common/Modal';
import { IJourneyGoal } from 'utils/models';
import { IJourneyPerson } from '../Admin/ManageJourneys/JourneyTemplate/JourneyTemplatePerson';
import JourneyCompanyMember from '../Admin/ManageJourneys/JourneyTemplate/JourneyCompanyMember';
import { useJourneyContext } from './JourneyContext';
import MainButton, { ButtonType } from '../common/MainButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  resources: {
    marginBottom: '3px',
    display: 'flex',
    gap: '0.3rem',
    flexDirection: 'column',
    width: '100%',
  },
  persons: {
    marginBottom: '1.5rem',
  },
  cont: {
    '& button': {
      height: '30px !important',
      width: '115px !important',
      fontWeight: 600,
      margin: '0 !important',
      fontSize: '13px !important',
      marginTop: '0.35rem !important',
      justifyContent: 'start',
      paddingLeft: '10px !important',
      marginLeft: '-7.5px !important',

      '& svg': {
        height: '22px',
        width: '22px',
      },
    },
  },
  linksCont: {
    marginTop: '0.8rem',
    width: '100%',
  },
  linkCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '0.3rem',
  },
  iconCont: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: '15px',
    width: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    border: '1px solid rgba(11, 57, 67, 0.5)',
    marginRight: '7px',

    '& svg': {
      height: '17px',
      width: '17px',
      color: theme.palette.primary.dark,
    },
  },
}));

interface IJourneyAssignMembers {
  goal: IJourneyGoal;
  open: boolean;
  assignedMembers: IJourneyPerson[];
  setAssignedMembers: (assignedMembers: IJourneyPerson[]) => void;
  close: () => void;
  save: () => void;
}

const JourneyAssignMembers: FunctionComponent<IJourneyAssignMembers> = (
  props,
) => {
  const { goal, open, close, assignedMembers, setAssignedMembers, save } =
    props;
  const { name } = goal;
  const classes = useStyles();
  const { companyMembers, isSmallLoading } = useJourneyContext();

  function renderPersons() {
    return companyMembers.map((member: IJourneyPerson, i) => (
      <JourneyCompanyMember
        key={member.id}
        person={member}
        isLast={i === companyMembers.length - 1}
        assignedMembers={assignedMembers}
        setAssignedMembers={setAssignedMembers}
        goalId={goal.id}
      />
    ));
  }

  function renderContent() {
    return (
      <div className={classes.cont}>
        <div>
          <Typography variant={'h6'} fontSize={16}>
            People
          </Typography>
          <div
            className={classes.resources}
            style={{ marginTop: assignedMembers.length > 0 ? '0.2rem' : '0' }}>
            {renderPersons()}
            <MainButton
              text={'Save'}
              type={ButtonType.FILLED}
              loading={isSmallLoading}
              onClick={() => {
                save();
              }}
              style={{
                alignSelf: 'center',
                justifyContent: 'center!important',
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Modal
      width={500}
      open={open}
      close={close}
      isActerioTheme={true}
      title={'Assign Members'}>
      <div className={classes.root}>{renderContent()}</div>
    </Modal>
  );
};

export default JourneyAssignMembers;
