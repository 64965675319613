import React, { FunctionComponent, useState } from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import CommonModal from 'components/common/Modal/CommonModal';
import FooterButtonGroup, {
  IButtonGroupProps,
} from 'components/common/Modal/FooterButtonGroup';
import { primary } from 'theme';
import { Error, FilterAlt, FilterAltOff } from '@mui/icons-material';
import CloseModalButton from 'components/common/Modal/CloseModalButton';

interface IList {
  value: string;
  onChange: (value: string) => void;
  options: string[];
}

const ListOfCompaniesPopover: FunctionComponent<IList> = (props) => {
  const { value, onChange, options } = props;

  const theme = useTheme();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>('');

  const buttonGroup: IButtonGroupProps = {
    primaryButton: {
      text: 'Filter',
      onClick: () => {
        onChange(selectedValue);
        setIsOpen(false);
      },
    },
    secondaryButton: {
      text: 'Close',
      onClick: () => setIsOpen(false),
    },
  };

  const listOfCompanies = options.sort((a, b) => a.localeCompare(b));

  const columns = 4;
  const itemsPerColumn = Math.ceil(listOfCompanies.length / columns);
  const companyColumns: string[][] = [];

  for (let i = 0; i < columns; i++) {
    companyColumns.push(
      listOfCompanies.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn),
    );
  }

  function renderModal() {
    return (
      <CommonModal open={isOpen} onClose={() => setIsOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90vw',
            bgcolor: 'background.paper',
            boxShadow: 2,
            p: '2rem',
            pl: '4rem',
            borderRadius: '12px',
          }}>
          <Box
            sx={{
              overflow: 'auto',
              minHeight: '40rem',
              maxHeight: '80vh',
              mr: '-2rem',
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: '1.5rem',
              }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Error
                  sx={{ color: theme.palette.primary.main, mr: '0.5rem' }}
                />
                <Typography sx={{ fontStyle: 'italic' }}>
                  Filtering by company will clear other filters selected
                  previously
                </Typography>
              </Box>
              <CloseModalButton
                handleClose={() => setIsOpen(false)}
                sx={{ mr: '1rem' }}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {companyColumns.map((column, columnIndex) => (
                <Box key={columnIndex} sx={{ flex: 1, mx: '0.5rem' }}>
                  {column.map((option, index) => {
                    const isANewLetter =
                      index === 0 ||
                      option.charAt(0).toUpperCase() !==
                        column[index - 1].charAt(0).toUpperCase();
                    return (
                      <Box
                        key={option}
                        onClick={() => setSelectedValue(option)}>
                        {isANewLetter && (
                          <Typography
                            fontWeight='bold'
                            sx={{
                              fontSize: '1.5rem',
                              color: theme.palette.primary.main,
                              my: '1rem',
                            }}>
                            {option.charAt(0).toUpperCase()}
                          </Typography>
                        )}
                        <Typography
                          sx={{
                            cursor: 'pointer',
                            fontSize: '1rem',
                            color:
                              selectedValue === option
                                ? theme.palette.primary.main
                                : primary.natural3,
                            my: '0.5rem',
                          }}>
                          {option}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              ))}
            </Box>
          </Box>
          <FooterButtonGroup {...buttonGroup} />
        </Box>
      </CommonModal>
    );
  }

  function handleClick() {
    if (value) {
      onChange('');
      setIsOpen(false);
    } else {
      if (isOpen) return;
      setIsOpen(true);
    }
  }

  return (
    <Button
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
        border: `1px solid ${primary.natural6}`,
        bgcolor: primary.pureWhite,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 1,
        mx: '1rem',
        px: '1rem',
      }}>
      {value ? (
        <FilterAltOff
          sx={{ color: theme.palette.primary.main, mr: '0.5rem' }}
        />
      ) : (
        <FilterAlt sx={{ color: theme.palette.primary.main, mr: '0.5rem' }} />
      )}
      <Typography>{value ? 'Clear' : 'Company'}</Typography>
      {isOpen && renderModal()}
    </Button>
  );
};

export default ListOfCompaniesPopover;
