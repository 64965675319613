import React, { FunctionComponent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Grid, Theme, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { ECOADMIN_HANDLE_JOIN_REQUEST_ROUTE, postData } from 'utils/requests';
import { useParams } from 'react-router';
import { IJoinRequest } from 'utils/models';
import Loading from 'components/common/Loading';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { primary } from 'theme';
import { Box } from '@mui/system';
import CommonButton from 'components/common/CommonButton';

const useStyles = makeStyles((theme: Theme) => ({
  cont: {
    backgroundColor: 'white',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
  },
  header: {
    padding: '0.75rem 3rem',

    '& h6': {
      fontSize: '1.1rem !important',
    },
  },
}));

interface IManageJoinRequests {
  joinRequests: IJoinRequest[];
}

const ManageJoinRequests: FunctionComponent<IManageJoinRequests> = (props) => {
  const { joinRequests } = props;
  const { ecosystemName } = useParams();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);

  const [selectedRequestId, setSelectedRequestId] = useState<number | null>(
    null,
  );
  const [requests, setRequests] = useState<IJoinRequest[] | null>(joinRequests);
  const [loading, setLoading] = useState<boolean>(false);

  async function handleJoinRequest(userid: number, accept: boolean) {
    if (!requests) return null;

    setSelectedRequestId(userid);
    setLoading(true);

    try {
      await postData(ECOADMIN_HANDLE_JOIN_REQUEST_ROUTE, [
        {
          name: 'userid',
          value: userid,
        },
        {
          name: 'accept',
          value: accept,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `Join request ${accept ? 'accepted' : 'denied'}`,
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
    setSelectedRequestId(null);
    setRequests(requests.filter((requests) => requests.userId !== userid));
  }

  function renderRequests() {
    if (!requests) return null;

    return requests.map((request, i) => {
      const { userId, userFullName, userEmail } = request;
      const selected =
        selectedRequestId !== null && selectedRequestId === userId;

      return (
        <Grid
          container
          key={`request-${i}`}
          sx={{
            borderBottom: '1px solid #e0e0e0',
            display: 'flex',
            alignItems: 'center',
            py: '0.75rem',
          }}>
          <Grid item xs={4}>
            <Typography sx={{ color: primary.mainBlackFont, ml: '3rem' }}>
              {userFullName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ color: primary.natural2 }}>
              {userEmail}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {loading && selected ? (
              <Loading size={20} height={25} width={85} />
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CommonButton
                  text='Deny'
                  icon={<ClearIcon />}
                  isLoading={loading}
                  onClick={() => handleJoinRequest(userId, false)}
                  bgcolor={primary.warningRed}
                  color={primary.pureWhite}
                  sx={{ mr: '1rem' }}
                />
                <CommonButton
                  text='Accept'
                  icon={<CheckIcon />}
                  isLoading={loading}
                  onClick={() => handleJoinRequest(userId, true)}
                  bgcolor={primary.successGreen}
                  color={primary.pureWhite}
                  sx={{ mr: '1rem' }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      );
    });
  }

  function renderContent() {
    if (loading && !requests) return <Loading />;

    return (
      <Fade in={true} timeout={1000}>
        <div className={classes.cont}>
          <div className={classes.header}>
            <Typography
              sx={{
                fontSize: '1.25rem',
                mt: '0.75rem',
                color: primary.natural2,
              }}>
              Manage join requests
            </Typography>
          </div>
          <Grid
            container
            sx={{
              borderBottom: '1px solid #e0e0e0',
              pb: '0.5rem',
              mt: '0.75rem',
            }}>
            <Grid item xs={4}>
              <Typography sx={{ color: primary.natural3, ml: '3rem' }}>
                Name
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ color: primary.natural3 }}>Email</Typography>
            </Grid>
            <Grid item xs={4}>
              <div />
            </Grid>
          </Grid>
          {renderRequests()}
        </div>
      </Fade>
    );
  }

  return (
    <div>
      <Typography variant={'h5'}>Manage join requests</Typography>
      {renderContent()}
    </div>
  );
};

export default ManageJoinRequests;
