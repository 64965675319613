import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { ButtonBase, Theme, Typography, useTheme } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ISharedProfilePeople } from 'utils/models';
import Avatar from 'components/common/Avatar';
import { useLocation, useNavigate } from 'react-router-dom';
import { getEcosystemNameFromPath } from 'utils/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '0.6rem',
    paddingTop: '0.2rem',
  },
  cont: {
    display: 'flex',
    alignItems: 'center',
    gap: '12.5px',

    '& p': {
      fontWeight: 600,
      fontSize: '10px !important',
    },
  },
  actionCont: {
    display: 'flex',
    alignItems: 'center',
    gap: '7.5px',
  },
  actionBtn: {
    maxWidth: '20px !important',
    maxHeight: '20px !important',
    borderRadius: '4px !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',

    '& svg': {
      height: '16px !important',
      width: '16px !important',
      color: theme.palette.primary.dark,
      marginRight: '10px',
    },
  },
  info: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
  },
  tag: {
    height: '24px',
    minWidth: '3.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    marginRight: '7px',
    padding: '0 7.5px',
    marginTop: '5px',

    '& p': {
      fontSize: '10px !important',
      color: 'black !important',
      marginTop: '2px !important',
    },
  },
}));

export interface IJourneyPerson extends ISharedProfilePeople {
  id: number;
}

interface IJourneyTemplatePerson {
  person: IJourneyPerson;
  isLast: boolean;
  deletePerson?: (personId: number) => void;
}

const JourneyTemplatePerson: FunctionComponent<IJourneyTemplatePerson> = (
  props,
) => {
  const { person, deletePerson, isLast } = props;
  const {
    id,
    accessLevelName,
    accessLevelColor,
    profilePic,
    fullName,
    primaryCompany,
  } = person;
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  function navigatePerson() {
    return navigate(
      `/ecosystem/${getEcosystemNameFromPath(location.pathname)}/members/${id}`,
    );
  }

  return (
    <div
      className={classes.root}
      style={{
        borderBottom: isLast ? 'none' : '1px solid #eee',
      }}>
      <div className={classes.cont}>
        <Avatar
          source={profilePic}
          height={35}
          width={35}
          radius={100}
          onClick={navigatePerson}
        />
        <div className={classes.info}>
          <Typography variant={'h6'}>{fullName}</Typography>
          <Typography
            variant={'body2'}
            style={{
              color: !primaryCompany ? '#9e9e9e' : theme.palette.primary.main,
            }}>
            {primaryCompany || 'No Primary Company'}
          </Typography>
        </div>
      </div>
      <div className={classes.actionCont}>
        <div
          className={classes.tag}
          style={{ backgroundColor: accessLevelColor }}>
          <Typography variant={'body1'}>{accessLevelName}</Typography>
        </div>
        <ButtonBase className={classes.actionBtn} onClick={navigatePerson}>
          <Visibility />
        </ButtonBase>
        {!!deletePerson && (
          <ButtonBase
            className={classes.actionBtn}
            onClick={() => deletePerson(id)}>
            <DeleteIcon />
          </ButtonBase>
        )}
      </div>
    </div>
  );
};

export default JourneyTemplatePerson;
