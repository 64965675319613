import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import WindowIcon from '@mui/icons-material/Window';
import MainButton, { ButtonType } from 'components/common/MainButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddAccessLevel from './AddAccessLevel';
import AccessLevel from './AccessLevel';
import { IAccessLevelsProps } from 'pages/Admin/AdminManageAccess';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  intro: {
    '& button': {
      width: '175px !important',
      minWidth: '175px !important',
      height: '45px !important',
      marginLeft: '0 !important',

      '& svg': {
        height: 27,
        width: 27,
      },
    },
  },
  cont: {
    marginTop: '0.75rem',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px',
  },
  header: {
    marginBottom: '3rem',

    '& h5': {
      fontSize: '1.35rem !important',
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: '5px',
        height: 32,
        width: 32,
        marginTop: '-3px',
        color: theme.palette.primary.main,
      },
    },

    '& span': {
      fontSize: '0.75rem !important',
      marginLeft: '37.5px',
      display: 'block',
    },
  },
}));

const AccessLevels: FunctionComponent<IAccessLevelsProps> = (props) => {
  const { accessLevelDtos, defaultAccessLevel, setAccessLevelDtos } = props;
  const classes = useStyles();

  const [showAddLevel, setShowAddLevel] = useState<boolean>(false);

  function getAccessLevelId(name: string) {
    const accessLevelDto = accessLevelDtos.find(
      (a) => a.accessLevel.accessLevelName === name,
    );
    return !accessLevelDto ? -1 : accessLevelDto.accessLevel.id;
  }

  function renderAccessLevels() {
    return accessLevelDtos.map((accessLevelDto, i) => (
      <AccessLevel
        key={`access-level-${i}`}
        accessLevelDto={accessLevelDto}
        accessLevelDtos={accessLevelDtos}
        setAccessLevelDtos={setAccessLevelDtos}
        getAccessLevelId={getAccessLevelId}
        isDefault={defaultAccessLevel.id === accessLevelDto.accessLevel.id}
      />
    ));
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.intro}>
          <div className={classes.header}>
            <Typography variant={'h5'}>
              <WindowIcon /> Access Levels
            </Typography>
            <span>
              Create, update and delete different access levels users can be
              assigned to
            </span>
          </div>
          <MainButton
            text={'Add access level'}
            type={ButtonType.FILLED}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setShowAddLevel(true)}
          />
        </div>
        <div className={classes.cont}>{renderAccessLevels()}</div>
      </div>
      <AddAccessLevel
        show={showAddLevel}
        onClose={() => setShowAddLevel(false)}
        accessLevelDtos={accessLevelDtos}
        setAccessLevelDtos={setAccessLevelDtos}
        getAccessLevelId={getAccessLevelId}
      />
    </>
  );
};

export default AccessLevels;
