import React, { useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import { useCompanyTemplateContext } from './CompanyTemplateContext';
import { Box } from '@mui/system';
import { IconPicker } from '../../common/IconPicker';
import InputField from 'components/common/InputField';
import { CompanyTemplateActionButtons } from './components/CompanyTemplateActionButtons';

export const CompanyTemplateNameSection = () => {
  const theme = useTheme();

  const { companyTemplate, setCompanyTemplate } = useCompanyTemplateContext();
  const [hasError, setHasError] = useState(false);
  const [helperText, setHelperText] = useState('');

  function updateTemplateName(name: string) {
    if (!companyTemplate) return;

    if (name === '') {
      setHasError(true);
      setHelperText('Give company template a name');
    } else {
      setHasError(false);
      setHelperText('');
    }

    setCompanyTemplate({ ...companyTemplate, name });
  }

  function handleBlur() {
    if (!companyTemplate?.name) {
      setHasError(true);
      setHelperText('Give company template a name');
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2.5rem',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            gap: '3rem',
          }}>
          <Typography variant={'h4'} sx={{ textWrap: 'nowrap' }}>
            Company profile builder
          </Typography>
          <Box sx={{ position: 'relative' }}>
            <InputField
              placeholder={'Create a name for this template*'}
              value={companyTemplate?.name || ''}
              onChange={(e) => updateTemplateName(e.target.value)}
              onBlur={handleBlur}
              width={'32rem'}
              helperText={helperText}
              error={hasError}
              sx={{
                position: 'absolute',
                top: '0',
                marginTop: '-0.5rem',
              }}
            />
          </Box>
        </Box>
        <CompanyTemplateActionButtons />
      </Box>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}>
        Fields marked with a filled icon
        <IconPicker name='Error' sx={{ color: theme.palette.primary.main }} />
        are required. Click the icon to toggle between mandatory and optional.
      </Typography>
    </Box>
  );
};
