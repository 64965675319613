import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ITag } from 'utils/models';
import { SxProps, useTheme } from '@mui/material';

interface TagAutocompleteProps {
  tags: ITag[];
  usedTags: ITag[];
  onTagChange: (tags: ITag[]) => void;
  sx?: SxProps;
}

const TagAutocomplete: React.FC<TagAutocompleteProps> = ({
  tags,
  usedTags,
  onTagChange,
  sx,
}) => {
  const [newTagInput, setNewTagInput] = useState('');

  const theme = useTheme();

  const createNewTagOption = (inputValue: string) => ({
    id: null,
    name: `Add "${inputValue}"`,
  });

  const options = [
    ...tags,
    newTagInput && !tags.find((tag) => tag.name === newTagInput)
      ? createNewTagOption(newTagInput)
      : null,
  ].filter(Boolean);

  const handleTagChange = (
    event: React.SyntheticEvent<Element, Event>,
    selectedTags: (string | ITag | { id: null; name: string } | null)[],
  ) => {
    const updatedTags: ITag[] = selectedTags
      .map((tag) => {
        if (typeof tag === 'string') {
          return { name: tag };
        } else if (tag && tag.id === null) {
          return { name: tag.name.slice(5, -1) };
        } else {
          return tag as ITag;
        }
      })
      .filter(Boolean);

    onTagChange(updatedTags);
  };

  return (
    <Autocomplete
      multiple
      forcePopupIcon={false}
      id='tags'
      readOnly={false}
      options={options}
      value={usedTags}
      onChange={handleTagChange}
      onInputChange={(event, newInputValue) => {
        setNewTagInput(newInputValue);
      }}
      getOptionLabel={(option) => {
        if (option === null) {
          return '';
        } else {
          return typeof option === 'string' ? option : option.name;
        }
      }}
      disableClearable
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} placeholder={'Search tags'} />
      )}
      freeSolo
      inputValue={newTagInput}
      sx={{
        minWidth: 'unset',
        maxWidth: '100%',
        width: '100%',
        marginLeft: '0px',
        borderRadius: '5px !important',
        input: {
          '&::placeholder': {
            color: 'black',
          },
        },
        '& .MuiAutocomplete-inputRoot': {
          borderRadius: '5px',
        },
        '& .MuiButtonBase-root': {
          bgcolor: theme.palette.primary.light,
          color: '#333333',
        },
        ...sx,
      }}
    />
  );
};

export default TagAutocomplete;
