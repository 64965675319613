import React, { FunctionComponent } from 'react';
import { Box, SxProps } from '@mui/material';

const BASE_WIDTH = 200;

export enum StageProgress {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface IStageProgress {
  todo: number;
  inProgress: number;
  finished: number;
}

export const INITIAL_STAGE_PROGRESS: IStageProgress = {
  todo: 0,
  inProgress: 0,
  finished: 0,
};

export const getProgressKey = (progress: StageProgress | undefined) => {
  if (progress === StageProgress.IN_PROGRESS) return 'inProgress';
  if (progress === StageProgress.FINISHED) return 'finished';
  return 'todo';
};

interface IJourneyProgressBar {
  progress: IStageProgress | undefined;
  width?: any;
  backgroundColor?: string;
  borderColor?: string;
  sx?: SxProps;
}

const JourneyProgressBar: FunctionComponent<IJourneyProgressBar> = (props) => {
  const { progress } = props;
  const { todo, inProgress, finished } = progress || {
    ...INITIAL_STAGE_PROGRESS,
  };
  const numGoals = todo + inProgress + finished;
  const width = props.width ?? BASE_WIDTH;
  const goalWidth = width / numGoals;

  return (
    <Box
      sx={{
        height: '0.625rem',
        width: {
          xs: `${props.width * 0.7}px`,
          md: `${props.width * 0.9}px`,
          lg: `${props.width}px`,
          xl: `${props.width * 1.2}px`,
        },
        borderRadius: '1.25rem',
        backgroundColor: (props: any) => props.backgroundColor || '#B9D4D8',
        display: 'flex',
        overflow: 'hidden',
      }}>
      {finished > 0 && (
        <Box
          sx={{
            width: goalWidth * finished + 4,
            borderRadius: '1.25rem',
            backgroundColor: '#A2F0CF',
            zIndex: 2,
          }}
        />
      )}
      {inProgress > 0 && (
        <Box
          sx={{
            borderRadius: '1.25rem',
            backgroundColor: '#FFDC8D',
            // width: goalWidth * inProgress,
            marginLeft: inProgress !== numGoals ? '-0.25rem' : '0',
          }}
        />
      )}
    </Box>
  );
};

export default JourneyProgressBar;
