import React, { FunctionComponent, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Back from 'components/common/Back';
import { Box } from '@mui/material';
import CommonButton, { ButtonSize } from 'components/common/CommonButton';
import { colorPallete, naturalPallete, primary } from 'theme';
import { Block, Download, FiberManualRecord } from '@mui/icons-material';
import { IListButton } from 'utils/models';
import MoveFowardToButton from 'components/common/MoreOptionsButton/MoveFowardToButton';
import ApplicantInfo from './ApplicantInfo';
import { useApplicationContext } from 'services/contexts/Applications/ApplicationsContext';
import useMoveApplicant from 'hooks/Applications/useMoveApplicant';
import InformationModal from 'components/common/Modal/InformationModal';
import useBack from 'hooks/Common/useBack';
import useUpdateApplicant from 'hooks/Applications/useUpdateApplicant';
import Loading from 'components/common/Loading';
import { ApplicantRatingModal } from './ApplicantRatingModal';
import { StarRating } from 'components/common/StarRating';
import { useNavigate } from 'react-router';

interface IApplicantDetails {}

const ApplicantDetails: FunctionComponent<IApplicantDetails> = () => {
  const {
    applicationDetailsData,
    applicationOverviewData,
    selectedApplicationProcessId,
    setApplicationOverviewData,
  } = useApplicationContext();

  const [selcetedColumnId, setSelectedColumnId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState<boolean>(false);

  const updateParams = {
    applicantId: applicationDetailsData?.applicant.id,
    destinationColumnId: selcetedColumnId,
    selectedApplicationProcessId,
    setIsLoading,
    successPopup,
    // setApplicationOverviewData,
  };
  const patchApplicant = useUpdateApplicant(updateParams);

  useEffect(() => {
    const moveAppProps = {
      applicantId: applicationDetailsData?.applicant.id,
      selcetedColumnId,
      applicationOverviewData,
      setApplicationOverviewData,
    };
    useMoveApplicant(moveAppProps);
    patchApplicant();
    setSelectedColumnId(null);
  }, [selcetedColumnId]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const { navigateBack } = useBack();

  const navigate = useNavigate();

  if (!applicationOverviewData || !applicationDetailsData) return <Loading />;

  function goToPdf() {
    window.open(window.location.href + '/pdf', '_blank');
  }

  const buttonList: IListButton[] = [
    {
      name: 'Select stage:',
      onClick: () => null,
    },
    ...applicationOverviewData.statuses
      .filter(
        (status) =>
          status.name !== 'Rejected' &&
          status.name !== applicationDetailsData.status.name,
      )
      .map((status) => ({
        name: status.name,
        onClick: () => {
          setSelectedColumnId(status.id ?? null);
        },

        icon: FiberManualRecord,
      })),
  ];

  const rejectButton = {
    name: 'Reject',
    onClick: () => {
      const rejectedStatus = applicationOverviewData.statuses.find(
        (status) => status.name === 'Rejected',
      );
      const id = rejectedStatus ? rejectedStatus.id ?? null : null;
      setSelectedColumnId(id);
    },
    icon: Block,
  };

  function successPopup(statusId: number) {
    const rejectColumn = applicationOverviewData?.statuses.find(
      (status) => status.name === 'reject',
    );
    const rejectId = rejectColumn?.id;
    let message = '';
    if (statusId === rejectId) {
      message = 'Success! The candidate has been rejected.';
    } else {
      message = 'Success! The candidate has been moved.';
    }
    setStatusMessage(message);
    setIsModalOpen(true);
  }

  const renderHeader = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='h4' sx={{ marginY: '1.8rem' }}>
          {applicationDetailsData.applicant.companyName ??
            applicationOverviewData.name}
        </Typography>
        <Box
          onClick={() => setIsRatingModalOpen(true)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.25rem',
            ml: '1.5rem',
            color: primary.additionalDarkOrange,
          }}>
          <StarRating
            stars={applicationDetailsData.applicant.averageRating}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                opacity: '0.5',
              },
            }}
          />
        </Box>
      </Box>
    );
  };

  const renderButtons = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Back text='Back to all applicants' sx={{ margin: '0 !important' }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',

            gap: '1.2rem',
          }}>
          <CommonButton
            text='PDF'
            onClick={goToPdf}
            size={ButtonSize.SMALL}
            bgcolor={primary.soft}
            color={primary.mainBlackFont}
            sx={{
              width: '8rem',
              margin: 'auto',
              paddingY: '1.2rem',
            }}
            icon={<Download />}
          />
          <CommonButton
            text='Reject'
            onClick={rejectButton.onClick}
            bgcolor={colorPallete.additionalPink}
            color={naturalPallete.mainBlackFont}
            size={ButtonSize.SMALL}
            sx={{
              width: '12rem',
              margin: 'auto',
              paddingY: '1.2rem',
              '&:hover': {
                bgcolor: colorPallete.additionalDarkPink,
                color: primary.pureWhite,
              },
            }}
            icon={<Block />}
          />
          <MoveFowardToButton buttonList={buttonList} />
          {renderModal()}
          {isRatingModalOpen && (
            <ApplicantRatingModal
              applicantId={applicationDetailsData?.applicant.id}
              isOpen={isRatingModalOpen}
              onClose={() => setIsRatingModalOpen(false)}
            />
          )}
        </Box>
      </Box>
    );
  };

  const renderModal = () => {
    return (
      <InformationModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        primaryText='Return to all applicants'
        primaryOnClick={navigateBack}
        secondaryText='Stay here'
        secondaryOnClick={() => setIsModalOpen(false)}
        headerText={statusMessage}>
        <div>Return to all applicants or stay here?</div>
      </InformationModal>
    );
  };

  const renderApplicantInfo = () => {
    return (
      <ApplicantInfo
        sections={applicationDetailsData.sections}
        applicant={applicationDetailsData.applicant}
      />
    );
  };

  if (isLoading) return <Loading />;

  return (
    <Box
      sx={{
        marginX: '3rem',
        maxWidth: '73%',
      }}>
      {renderHeader()}
      {renderButtons()}
      {renderApplicantInfo()}
    </Box>
  );
};

export default ApplicantDetails;
