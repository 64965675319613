import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { ButtonBase, Theme, Typography } from '@mui/material';
import { Edit, Link } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: '1px solid #eee',
    paddingBottom: '0.4rem',
  },
  iconCont: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: '15px',
    width: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    border: '1px solid rgba(11, 57, 67, 0.5)',
    marginRight: '7px',

    '& svg': {
      height: '17px',
      width: '17px',
      color: theme.palette.primary.dark,
    },
  },
  actionCont: {
    marginTop: '-7px',
    display: 'flex',
    alignItems: 'center',
    gap: '7.5px',
  },
  actionBtn: {
    maxWidth: '20px !important',
    maxHeight: '20px !important',
    borderRadius: '4px !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',

    '& svg': {
      height: '16px !important',
      width: '16px !important',
      color: theme.palette.primary.dark,
      marginRight: '10px',
    },
  },
}));

export interface IJourneyLink {
  id?: number;
  name: string;
  url: string;
}

interface IJourneyTemplateLink {
  index: number;
  link: IJourneyLink;
  deleteLink: (linkIndex: number) => void;
  updateLink: (linkIndex: number) => void;
}

const JourneyTemplateLink: FunctionComponent<IJourneyTemplateLink> = (
  props,
) => {
  const { index, link, deleteLink, updateLink } = props;
  const { id, name, url } = link;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.iconCont}>
        <a className={classes.icon} target={'_blank'} href={url}>
          <Link />
        </a>
        <Typography
          variant={'body2'}
          fontSize={12}
          style={{ marginRight: '10px', marginTop: '2px' }}>
          {name}
        </Typography>
      </div>
      <div className={classes.actionCont}>
        <ButtonBase
          className={classes.actionBtn}
          onClick={() => updateLink(index)}>
          <Edit />
        </ButtonBase>
        <ButtonBase
          className={classes.actionBtn}
          onClick={() => deleteLink(index)}>
          <DeleteIcon />
        </ButtonBase>
      </div>
    </div>
  );
};

export default JourneyTemplateLink;
