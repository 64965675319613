import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import {
  Fade,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@mui/material';
import InputField from '../../common/InputField';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ICompanyKPI, ICompanyKPIEntry } from 'utils/models';
import { DatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  info: {
    paddingRight: '25px',
    paddingTop: '22.5px',
  },
  entries: {
    paddingLeft: '25px',
    borderLeft: '1px solid #d4d4d4',
    paddingBottom: '10px',
    marginBottom: '10px',
    minHeight: '25rem',
    maxHeight: '25rem',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    maxWidth: '18.75rem',

    '& h6': {
      fontSize: '0.75rem !important',
      marginRight: '10px',
      width: '9.375rem',
    },
  },
  entriesHeader: {
    marginBottom: '5px',
    display: 'flex',

    '& h6': {
      fontSize: '0.7rem !important',
      textTransform: 'uppercase',
    },
  },
  entry: {
    display: 'flex',
  },
  entryField: {
    width: '7rem',
    marginBottom: '0.5rem',
    marginRight: '0.5rem',
  },
  datePicker: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      width: '10rem',
      maxHeight: '38px',
      borderRadius: '5px',
      marginRight: '0.6rem',
    },
  },
  entriesBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  deleteBtn: {
    height: '27.5px !important',
    width: '27.5px !important',
    minWidth: '27.5px !important',
    padding: '4px !important',
    backgroundColor: 'transparent !important',
    marginLeft: '5px !important',
    marginTop: '2px !important',

    '& svg': {
      height: '20px',
      width: '20px',
      color: theme.palette.primary.dark,
    },
  },
  addBtn: {
    marginTop: '5px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: '4px !important',
    padding: '5px 12px !important',
    paddingRight: '15px !important',

    '& h6': {
      fontSize: '0.7rem !important',
      lineHeight: '1rem !important',
      fontWeight: 800,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '2px !important',
      color: 'white !important',

      '& svg': {
        height: '18px',
        width: '18px',
        marginRight: '7.5px',
      },
    },
  },
}));

interface ICompanyEditKPI {
  kpi: ICompanyKPI;
  setEditedKPI: (kpi: ICompanyKPI) => void;
}

const CompanyEditKPI: FunctionComponent<ICompanyEditKPI> = (props) => {
  const { kpi, setEditedKPI } = props;
  const classes = useStyles();

  function EntryInputField({
    entry,
    index,
    field,
    type,
  }: {
    entry: any;
    index: any;
    field: any;
    type: any;
  }) {
    const [localValue, setLocalValue] = useState(entry[field]);

    useEffect(() => {
      setLocalValue(entry[field]);
    }, [entry]);

    return (
      <div className={classes.entryField}>
        <InputField
          value={localValue}
          type={type}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setLocalValue(Number(event.target.value));
          }}
          onBlur={() => {
            updateKPIEntry(
              {
                ...entry,
                [field]: localValue,
              },
              index,
            );
          }}
        />
      </div>
    );
  }

  function updateKPIEntry(kpiEntry: ICompanyKPIEntry, index: number) {
    const updated = kpi.keyPerformanceIndicatorEntry.map((entry, i) => {
      return index === i ? kpiEntry : entry;
    });

    setEditedKPI({
      ...kpi,
      keyPerformanceIndicatorEntry: updated,
    });
  }

  function deleteKPIEntry(index: number) {
    const updatedEntries = kpi.keyPerformanceIndicatorEntry.filter(
      (entry, i) => index !== i,
    );

    setEditedKPI({
      ...kpi,
      keyPerformanceIndicatorEntry: updatedEntries,
    });
  }

  function addNewKPIEntry() {
    const newKpiEntry: ICompanyKPIEntry = {
      value: 0,
      goal: 100,
      zonedDateTime: null,
    };

    setEditedKPI({
      ...kpi,
      keyPerformanceIndicatorEntry: [
        ...kpi.keyPerformanceIndicatorEntry,
        newKpiEntry,
      ],
    });
  }

  function renderAddNewKPIEntryBtn() {
    return (
      <Button className={classes.addBtn} onClick={addNewKPIEntry}>
        <Typography variant={'h6'}>
          <AddCircleOutlineIcon /> Add
        </Typography>
      </Button>
    );
  }

  function renderEntry(entry: ICompanyKPIEntry, index: number) {
    const { id, zonedDateTime, value, goal } = entry;

    return (
      <Fade in={true} key={`entry-${id}-${Math.random()}`}>
        <div className={classes.entry}>
          <DatePicker
            format='dd/MM/yyyy'
            value={zonedDateTime ? new Date(zonedDateTime) : null}
            onChange={(newDate: Date | null) => {
              if (
                newDate !== null &&
                newDate !== undefined &&
                newDate?.toString() !== 'Invalid Date'
              ) {
                updateKPIEntry(
                  {
                    ...entry,
                    zonedDateTime: newDate.toISOString(),
                  },
                  index,
                );
              }
            }}
            className={classes.datePicker}
          />
          <EntryInputField
            entry={entry}
            index={index}
            field='value'
            type='number'
          />
          {kpi.showGoals && (
            <EntryInputField
              entry={entry}
              index={index}
              field='goal'
              type='number'
            />
          )}
          <Button
            className={classes.deleteBtn}
            onClick={() => deleteKPIEntry(index)}>
            <DeleteForeverIcon />
          </Button>
        </div>
      </Fade>
    );
  }

  function renderInfo() {
    return (
      <div className={classes.info}>
        <div className={classes.infoRow}>
          <Typography variant={'h6'}>Name of KPI</Typography>{' '}
          <InputField
            value={kpi.name}
            placeholder={'New clients'}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setEditedKPI({
                ...kpi,
                name: event.target.value,
              });
            }}
          />
        </div>
        <div className={classes.infoRow}>
          <Typography variant={'h6'}>Unit</Typography>{' '}
          <InputField
            value={kpi.unit}
            placeholder={'%'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setEditedKPI({
                ...kpi,
                unit: event.target.value,
              })
            }
          />
        </div>
        <div
          className={classes.infoRow}
          style={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}>
          <Typography
            variant={'h6'}
            style={{ marginTop: '10px', width: '95px' }}>
            Type
          </Typography>
          <RadioGroup defaultValue={kpi.showGoals ? 'showGoals' : 'monthly'}>
            <FormControlLabel
              value='monthly'
              label='Only monthly value'
              control={
                <Radio
                  size='small'
                  onChange={(value) =>
                    setEditedKPI({
                      ...kpi,
                      showGoals: false,
                    })
                  }
                />
              }
            />
            <FormControlLabel
              value='showGoals'
              label='Monthly value and goal'
              control={
                <Radio
                  size='small'
                  onChange={(value) =>
                    setEditedKPI({
                      ...kpi,
                      showGoals: true,
                    })
                  }
                />
              }
            />
          </RadioGroup>
        </div>
      </div>
    );
  }

  function renderEntries() {
    return (
      <div className={classes.entries}>
        <div className={classes.entriesHeader}>
          {kpi.keyPerformanceIndicatorEntry.length > 0 && (
            <>
              <Typography variant={'h6'} width={'10.8rem'}>
                Month
              </Typography>
              <Typography variant={'h6'} width={'7rem'} marginRight={'0.5rem'}>
                Value
              </Typography>
              {kpi.showGoals && (
                <Typography variant={'h6'} width={'7rem'}>
                  Goal
                </Typography>
              )}
            </>
          )}
        </div>
        <div className={classes.entriesBody}>
          {kpi.keyPerformanceIndicatorEntry.map((entry, index) =>
            renderEntry(entry, index),
          )}
        </div>
        {renderAddNewKPIEntryBtn()}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {renderInfo()}
      {renderEntries()}
    </div>
  );
};

export default CompanyEditKPI;
