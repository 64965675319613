import React, { FunctionComponent } from 'react';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import CardTag from '../common/CardTag';
import { IEvent } from 'utils/models';
import GridPost from '../Events/GridView/GridPost/GridPost';
import { ArrowForwardIos } from '@mui/icons-material';
import { Box } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../common/Loading';
import { getDateWithTime } from 'utils/utils';
import { useEventsContext } from 'services/contexts/Events/EventsContext';
import EventDetails from '../Events/EventDetails';
import EditEvent from '../Events/EditEvent';

interface IUpcomingEvents {}

const UpcomingEvents: FunctionComponent<IUpcomingEvents> = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const { ecosystemName } = useParams();

  const {
    events,
    isLoading,
    isShowEventDetails,
    chosenEvent,
    isShowEdit,
    callCloseEventDetails,
    callCloseEditEvent,
  } = useEventsContext();

  function renderNoUpcomingEvents() {
    return (
      <Box sx={{ paddingTop: '2.5rem' }}>
        <Typography
          fontWeight='bold'
          variant={'body1'}
          fontSize={16}
          gutterBottom>
          Visit events tab to discover the upcoming events...
        </Typography>
        <Typography fontWeight='bold' variant={'body1'} marginBottom={6}>
          See whats coming and create your own events!
        </Typography>
      </Box>
    );
  }

  function renderEvents() {
    if (events.length === 0) return;

    function filterUpcomingEvents(events: IEvent[], amount: number): IEvent[] {
      const currentDate = new Date();

      const upcomingEvents = events.filter((event) => {
        if (event.isEnd) return false; // We skip end events

        const eventDate = new Date(event.startDate);
        if (event.endTime) {
          const [hours, minutes] = event.endTime.split(':').map(Number);
          eventDate.setHours(hours, minutes, 0, 0);
        }

        const endEvent = events.find((e) => e.isEnd && e.id === event.id);
        if (endEvent) {
          const endDate = new Date(endEvent.startDate);
          return endDate >= currentDate;
        }
        return currentDate <= eventDate;
      });

      const sortedEvents = upcomingEvents.sort(
        (a, b) => getDateWithTime(a) - getDateWithTime(b),
      );

      return sortedEvents.slice(0, amount);
    }

    const upcomingEvents = filterUpcomingEvents(events, 6);

    return (
      <Grid container spacing={'2rem'} marginBottom={4}>
        {upcomingEvents?.map((event, index) => (
          <Grid
            item
            xs={6}
            sm={12}
            lg={6}
            key={event.id + Math.random().toString()}>
            <GridPost
              event={event}
              isHome={true}
              handleClose={callCloseEventDetails}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  function renderNavigateButton() {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: events.length !== 0 ? 'right' : 'left',
        }}>
        <Button onClick={() => navigate(`/ecosystem/${ecosystemName}/events`)}>
          <Typography
            variant='h6'
            fontSize={events.length !== 0 ? 14 : 16}
            fontStyle={events.length !== 0 ? '' : 'italic'}>
            {events.length !== 0
              ? 'See more upcoming events'
              : 'Navigate to the events tab here'}
          </Typography>
          <ArrowForwardIos sx={{ color: theme.palette.primary.dark }} />
        </Button>
      </Box>
    );
  }

  if (isLoading)
    return (
      <Box marginRight={25}>
        <Loading />
      </Box>
    );

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'inherit',
          borderRadius: '4px',
          width: '100%',
          position: 'relative',
          height: '100%',
        }}>
        <CardTag
          text={'Events'}
          sx={{ position: 'absolute', top: 'calc(2rem - 50px)', left: 0 }}
        />
        {(events?.length ?? 0) !== 0
          ? renderEvents()
          : renderNoUpcomingEvents()}
        {renderNavigateButton()}
        {!!chosenEvent && (
          <EventDetails
            isOpen={isShowEventDetails}
            handleClose={callCloseEventDetails}
            event={chosenEvent}
          />
        )}
        {isShowEdit && !!chosenEvent && (
          <EditEvent
            isOpen={isShowEdit}
            handleClose={callCloseEditEvent}
            eventToEdit={chosenEvent}
          />
        )}
      </Box>
    </>
  );
};

export default UpcomingEvents;
