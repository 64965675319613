import React, { FunctionComponent } from 'react';
import { Box, Fade, SxProps } from '@mui/material';
import MainButton, { ButtonType } from './MainButton';
import useBack from 'hooks/Common/useBack';
import { ArrowBackIos } from '@mui/icons-material';

interface IBack {
  text: string;
  sx?: SxProps;
}

const Back: FunctionComponent<IBack> = (props) => {
  const { text, sx } = props;
  const { paths, numPaths, isSubRoute, navigateBack } = useBack();
  const fadeOutOnRoutes = paths.includes('journey');

  return !isSubRoute ? null : (
    <Fade
      in={numPaths > 2 && !fadeOutOnRoutes}
      timeout={{ enter: 1000, exit: 200 }}>
      <Box>
        <MainButton
          text={text}
          type={ButtonType.BLANK}
          onClick={navigateBack}
          startIcon={<ArrowBackIos />}
          style={{
            justifyContent: 'flex-start',
            padding: '0',
            fontSize: '1.2rem',
            ...sx,
          }}
        />
      </Box>
    </Fade>
  );
};

export default Back;
