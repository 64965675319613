import React, { FunctionComponent } from 'react';
import { ISideMenuSection } from 'utils/models';
import SideMenu from '../SideMenu/SideMenu';
import { Box } from '@mui/system';
import SideMenuSections from '../SideMenu/SideMenuSections';
import SideMenuBackButton from '../Admin/AdminBackButton';

export enum EventsMenuKeys {
  Events = 'Events',
  MyEvents = 'My_Events',
  Forms = 'Forms',
  Connect = 'Connect',
}

interface IEventMenu {
  menuItems: ISideMenuSection[];
  selectedOption: EventsMenuKeys;
}

const EventsSideMenu: FunctionComponent<IEventMenu> = (props) => {
  const { menuItems, selectedOption } = props;

  return (
    <SideMenu width={18}>
      <SideMenuBackButton text={'Back'} />
      <Box sx={{ mt: '2rem' }}>
        <SideMenuSections
          menuItems={menuItems}
          selectedOption={selectedOption}
        />
      </Box>
    </SideMenu>
  );
};

export default EventsSideMenu;
