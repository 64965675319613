import React, { FunctionComponent } from 'react';
import { Box, Card, Typography, useTheme } from '@mui/material';
import Avatar from '../common/Avatar';
import { LocationOn } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AccessLevelTagCard from '../common/AccessLevelTagCard';
import { primary } from 'theme';
import { IPeople, ITag } from 'utils/models';
import TimezoneOffsetDisplay from 'components/common/TimezoneOffsetDisplay';
import TagDisplay from 'components/common/Tags/TagDisplay';
import { useMobileContext } from 'services/contexts/MobileContext';

const MemberCard: FunctionComponent<IPeople> = (props) => {
  const {
    id,
    profilePic,
    fullName,
    subtitle,
    primaryCompany,
    locationString,
    accessLevelName,
    accessLevelColor,
    primaryCompanyLogo,
    usedTags,
    timezone,
  } = props;

  const navigate = useNavigate();
  const { isMobile } = useMobileContext();

  const theme = useTheme();

  const titleTags: ITag[] = [
    {
      name: accessLevelName,
    },
  ];

  function renderAccessLevelTag() {
    return (
      <AccessLevelTagCard
        accessLevelName={accessLevelName}
        accessLevelColor={accessLevelColor}
        height={'1.5rem'}
        borderRadius={'5px 0 0 5px'}
        sx={{ borderRadius: '7px 5px 5px 7px', marginBottom: '1.2rem' }}
      />
    );
  }

  function renderPhoto() {
    const avatarSize = isMobile ? 105 : 128;
    return (
      <Avatar
        source={profilePic}
        height={avatarSize}
        width={avatarSize}
        radius={avatarSize}
        className={{
          cursor: 'pointer',
        }}
      />
    );
  }

  function renderName() {
    return <Typography variant={'h5'}>{fullName}</Typography>;
  }

  function renderTitle() {
    return (
      <Typography
        variant={'body2'}
        sx={{
          marginTop: '0.3rem',
          height: '1.5rem',
        }}>
        {subtitle || ''}
      </Typography>
    );
  }

  function renderCompanyLogo() {
    return primaryCompany ? (
      <img
        src={primaryCompanyLogo}
        alt={primaryCompany}
        style={{
          height: '3.5rem',
          objectFit: 'contain',
        }}
      />
    ) : null;
  }

  function renderLocation() {
    return (
      <>
        <LocationOn
          sx={{
            color: theme.palette.primary.main,
            fontSize: { xs: '1.2rem', md: '0.9rem' },
            marginRight: 0.5,
            marginTop: 0.2,
          }}
        />
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            fontSize: { xs: '1.2rem', md: '0.8rem' },
          }}>
          {locationString || 'Not provided'}
        </Typography>
      </>
    );
  }

  function renderTags() {
    return (
      <Box
        sx={{
          width: '100%',
          maxWidth: '27.5rem',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          overflow: 'hidden',
          marginTop: '0.5rem',
        }}>
        <TagDisplay
          tags={usedTags && usedTags.length > 0 ? usedTags : titleTags}
          oneRow
        />
      </Box>
    );
  }

  return (
    <Card
      onClick={() => navigate(`${id}`)}
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: primary.pureWhite,
        border: '1px solid #D4D4D4',
        borderRadius: '12px',
        padding: '1rem',
        cursor: 'pointer',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
            paddingBottom: 0,
            flex: 1,
          }}>
          <Box>{renderPhoto()}</Box>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              padding: '0 0 0 1rem',
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
              {renderAccessLevelTag()}
            </Box>
            {renderName()}
            {renderTitle()}
            <Box
              sx={{
                marginTop: '0.5rem',
                marginRight: '1rem',
              }}>
          {/*     {renderCompanyLogo()} */}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            marginY: '0',
            paddingY: '0',
            marginTop: 'auto',
            alignItems: 'center',
          }}>
          {renderLocation()} <TimezoneOffsetDisplay timezoneRegion={timezone} />
        </Box>
        <Box
          sx={{
            marginY: '0',
            paddingY: '0',
            marginTop: 'auto',
          }}>
          {renderTags()}
        </Box>
      </Box>
    </Card>
  );
};

export default MemberCard;
