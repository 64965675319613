import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useContext, useEffect, useState } from 'react';
import { IApplicationForm } from 'utils/models';
import { APPLICATIONS_APPLY, getData } from 'utils/requests';

export interface IFetchForm {
  formId: number | string | undefined;
}

const useFetchForm = (props: IFetchForm) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form, setForm] = useState<IApplicationForm | null>(null);

  const { formId } = props;
  const { addAlert } = useContext(AlertContext);

  if (!formId) return { isLoading: true, form: null };

  useEffect(() => {
    fetchForm();
  }, []);

  async function fetchForm() {
    setIsLoading(true);
    try {
      const data = await getData(
        APPLICATIONS_APPLY,
        [
          {
            name: 'hashedId',
            value: formId,
          },
        ],
        true,
      );

      setForm(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  return { isLoading, form };
};

export default useFetchForm;
