import React, { FunctionComponent, useEffect, useState } from 'react';
import MainButton, { ButtonType } from 'components/common/MainButton';
import ResourceCard from 'components/Resources/ResourceCard';
import NewResourceModal from 'components/Resources/NewResourceModal';
import ConfirmDeleteResource from 'components/Resources/ConfirmDeleteResource';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Fade, Typography } from '@mui/material';
import { IResource } from 'utils/models';
import { useParams } from 'react-router';
import { getData, RESOURCE_ROUTE } from 'utils/requests';
import Loading from 'components/common/Loading';
import {
  getEcosystemInfo,
  getStoredResources,
  storeResources,
} from 'utils/storage';
import { useMobileContext } from 'services/contexts/MobileContext';
import { Box } from '@mui/system';

interface OwnProps {}

const Resources: FunctionComponent<OwnProps> = (props) => {
  const { isMobile } = useMobileContext();
  const { ecosystemName } = useParams();

  const [resources, setResources] = useState<IResource[] | null>(
    getStoredResources(),
  );
  const [showNewResource, setShowNewResource] = useState<boolean>(false);
  const [resourceToDelete, setResourceToDelete] = useState<IResource | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const renderButton = getEcosystemInfo().accessLevel.canAddResources;

  useEffect(() => {
    if (resources) {
      storeResources(resources);
    }
  }, [resources]);

  useEffect(() => {
    if (!resources) {
      fetchResources();
    }
  }, []);

  async function fetchResources() {
    setLoading(true);

    try {
      const data = await getData(RESOURCE_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setResources(data);
    } catch (e) {
      console.error('error', e);
    }

    setLoading(false);
  }

  function updateResource(resource: IResource, remove: boolean) {
    if (!resources) return;

    if (remove) {
      setResources(resources.filter(({ name }) => name !== resource.name));
    } else {
      setResources([resource, ...resources]);
    }
  }

  function renderResourceCards() {
    if (!resources) return null;
    if (resources.length == 0)
      return <Typography variant={'h4'}>No results</Typography>;

    return resources.map((resource, i) => {
      return (
        <ResourceCard
          key={`${resource.name}-${i}`}
          resource={resource}
          setResource={setResourceToDelete}
        />
      );
    });
  }

  return loading ? (
    <Loading />
  ) : (
    <Fade in={true} timeout={1000}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: isMobile ? '100%' : '60%',
          minWidth: '12.5rem',
          marginX: { xs: '2rem', md: 'auto' },
          gap: '1.875rem',
          marginBottom: '6rem',
        }}>
        <NewResourceModal
          showNewResource={showNewResource}
          updateResources={updateResource}
          setShowNewResource={setShowNewResource}
        />
        <ConfirmDeleteResource
          resource={resourceToDelete}
          updateResources={updateResource}
          setResource={setResourceToDelete}
        />
        {renderButton && (
          <MainButton
            text={'Add new resource'}
            type={ButtonType.FILLED}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setShowNewResource(true)}
            style={{ fontSize: '18px', maxWidth: '292px' }}
          />
        )}
        {renderResourceCards()}
      </Box>
    </Fade>
  );
};

export default Resources;
