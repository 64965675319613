import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import InputField from 'components/common/InputField';
import Loading from 'components/common/Loading';
import InformationModal from 'components/common/Modal/InformationModal';
import React, { FunctionComponent } from 'react';

interface INewColumnModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleAddNewColumn: (newColumnName: string) => void;
  newColumnName: string;
  setNewColumnName: (value: string) => void;
  isAddLoading: boolean;
}

const NewColumnModal: FunctionComponent<INewColumnModalProps> = (props) => {
  const {
    isOpen,
    handleClose,
    handleAddNewColumn,
    newColumnName,
    setNewColumnName,
    isAddLoading,
  } = props;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    setNewColumnName(e.target.value);
  }
  return (
    <InformationModal
      isOpen={isOpen}
      handleClose={handleClose}
      headerText='New column'
      primaryText='Add'
      primaryOnClick={() => handleAddNewColumn(newColumnName)}
      secondaryText='Cancel'
      secondaryOnClick={handleClose}
      headerSize='1.875rem'
      width='36rem'
      buttonSx={{
        '&:hover': {
          color: '#13253E',
          backgroundColor: '#EBF1F9',
        },
      }}>
      {isAddLoading ? (
        <Box
          sx={{
            minHeight: '7.5rem',
            display: 'flex',
            alignItems: 'center',
          }}>
          <Loading height={40} />
        </Box>
      ) : (
        <Box sx={{ width: '30rem', marginY: '2rem' }}>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '0.875rem',
              marginBottom: '1rem',
            }}>
            Column name
          </Typography>
          <InputField
            value={newColumnName}
            onChange={handleChange}
            placeholder='Column name'
            maxLength={25}
          />
        </Box>
      )}
    </InformationModal>
  );
};

export default NewColumnModal;
