import React, { FunctionComponent, useRef, useState } from 'react';
import { Box, SxProps, Typography, useTheme } from '@mui/material';
import MainButton, { ButtonType } from './MainButton';
import { AddAPhoto, AddPhotoAlternate, Delete } from '@mui/icons-material';
import ImageCropper from './ImageCropper';
import { naturalPallete } from 'theme';
import { FlexBox } from 'utils/styledComponents';
import Loading from './Loading';

export enum RemoveType {
  Company = 'company',
  Member = 'member',
  Profile = 'profile',
}

interface IUploadImage {
  onUpload: (file: any) => void;
  initialSrc?: string;
  showButtons?: boolean;
  maxFileSize?: boolean;
  hideBorder?: boolean;
  borderColor?: string;
  height?: number;
  width?: string | number;
  uploadText?: string;
  isDisabled?: boolean;
  removeType?: RemoveType;
  onRemove?: () => void;
  disableRemove?: boolean;
  full?: boolean;
  crop?: boolean;
  aspectRatioWidth?: number;
  aspectRatioHeight?: number;
  roundCropper?: boolean;
  cameraIconOffset?: string | number;
  isLoading?: boolean;
  sx?: SxProps;
  deleteFunction?: () => void;
}

const UploadImage: FunctionComponent<IUploadImage> = (props) => {
  const {
    onUpload,
    initialSrc,
    showButtons,
    maxFileSize,
    height,
    width,
    uploadText,
    hideBorder,
    borderColor,
    isDisabled,
    onRemove,
    removeType,
    disableRemove,
    full,
    crop,
    aspectRatioWidth,
    aspectRatioHeight,
    roundCropper,
    sx,
    cameraIconOffset,
    isLoading,
    deleteFunction,
  } = props;

  const placeholderCompany = '/images/placeholders/company.png';
  const placeholderProfile = '/images/placeholders/profile.png';
  const placeholderMember = '/images/placeholders/member.png';

  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadedImg, setUploadedImg] = useState<Blob | MediaSource>();
  const [initialSource, setInitialSource] = useState<any>(initialSrc);
  const [showImageCropper, setShowImageCropper] = useState<boolean>(false);
  const theme = useTheme();
  const disabled = isDisabled;

  function onClick() {
    if (disabled) return;
    inputRef.current && inputRef.current.click();
  }

  function onImageChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (!!maxFileSize && file.size > maxFileSize) {
        return console.log('to large file');
      }
      setUploadedImg(file);

      if (crop) {
        setShowImageCropper(true);
      } else {
        onUpload(file);
      }
    }
  }

  function handleRemove() {
    onRemove && onRemove();
    setUploadedImg(undefined);
    setInitialSource(null);
  }

  function renderImg() {
    let src = !!uploadedImg ? URL.createObjectURL(uploadedImg) : initialSource;
    if ((!src && removeType) || (!initialSource && !!uploadedImg)) {
      if (removeType === RemoveType.Company) src = placeholderCompany;
      if (removeType === RemoveType.Profile) src = placeholderProfile;
      if (removeType === RemoveType.Member) src = placeholderMember;
    }

    return src ? (
      <img src={src} />
    ) : (
      <FlexBox
        sx={{
          flexDirection: 'column',

          '& svg': {
            height: '2rem',
            width: '2rem',
            color: theme.palette.primary.dark,
            marginBottom: uploadText ? '0.5rem' : '0',
          },
        }}>
        <AddPhotoAlternate sx={{ fontSize: 40, marginBottom: '0.5rem' }} />
        <Typography variant='body1'>Add photo</Typography>
        {uploadText && <Typography variant={'body1'}>{uploadText}</Typography>}
      </FlexBox>
    );
  }

  return (
    <FlexBox
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        '& input': {
          display: 'none',
        },
      }}>
      <Box
        sx={{
          width: '100%',
          aspectRatio: 2,
          borderRadius: '8px',
          border: hideBorder
            ? 'none'
            : `1px solid ${borderColor ?? naturalPallete.natural6}`,
          position: 'relative',
          transition: 'all 0.5s linear',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          '& img': {
            height: 'auto',
            maxHeight: full ? '90%' : '90%',
            width: full ? 'auto' : '90%',
            maxWidth: full ? '100%' : '90%',
            borderRadius: '8px',
          },
          ...sx,
        }}
        onClick={onClick}
        style={{
          cursor: disabled ? 'default' : 'pointer',
          background: 'rgba(0, 0, 0, 0.1)',
        }}>
        {renderImg()}
      </Box>
      {true && (
        <Box sx={{ display: 'flex' }}>
          {!!deleteFunction && (
            <Box
              sx={{
                position: 'relative',
                bottom: '1.5rem',
                right: cameraIconOffset ?? '-12rem',
                borderRadius: '100%',
                ml: '-3rem',
                width: '3rem',
                height: '3rem',
                bgcolor: theme.palette.primary.light,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                cursor: 'pointer',
                ':hover': {
                  bgcolor: theme.palette.primary.main,
                  color: naturalPallete.pureWhite,
                },
              }}
              onClick={deleteFunction}>
              {isLoading ? <Loading /> : <Delete sx={{ mr: '-0.5rem' }} />}
            </Box>
          )}
          <Box
            sx={{
              position: 'relative',
              bottom: '1.5rem',
              right: cameraIconOffset ?? '-12rem',
              borderRadius: '100%',
              width: '3rem',
              height: '3rem',
              bgcolor: theme.palette.primary.light,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              borderTopLeftRadius: !!deleteFunction ? 0 : '100%',
              borderBottomLeftRadius: !!deleteFunction ? 0 : '100%',
              ':hover': {
                bgcolor: theme.palette.primary.main,
                color: naturalPallete.pureWhite,
              },
            }}
            onClick={onClick}>
            {isLoading ? (
              <Loading />
            ) : (
              <AddAPhoto sx={{ ml: !!deleteFunction ? '-0.5rem' : '0' }} />
            )}
          </Box>
        </Box>
      )}
      {showButtons && (
        <FlexBox
          sx={{
            justifyContent: 'space-between',
            marginTop: '1rem',
            '& button': {
              width: '5rem ',
              height: '2rem ',
              margin: '0',
              fontSize: '0.8rem',
              borderRadius: '8px',
              padding: '0.5rem',
              textTransform: 'capitalize',
              lineHeight: '1 ',
            },
          }}>
          <MainButton
            text={'Remove'}
            type={ButtonType.OUTLINED}
            disabled={false}
            borderSize={1}
            onClick={handleRemove}
          />
          <MainButton
            text={'Change'}
            type={ButtonType.FILLED}
            onClick={onClick}
          />
        </FlexBox>
      )}
      <input
        ref={inputRef}
        type='file'
        accept='image/png, image/jpeg'
        multiple={false}
        onChange={onImageChange}
      />

      {showImageCropper && aspectRatioHeight && aspectRatioWidth && (
        <ImageCropper
          showImageCropper={showImageCropper}
          setShowImageCropper={setShowImageCropper}
          uploadedImage={uploadedImg}
          setUploadedImage={setUploadedImg}
          onUpload={onUpload}
          aspectRatioHeight={aspectRatioHeight}
          aspectRatioWidth={aspectRatioWidth}
          roundCropper={roundCropper}
        />
      )}
    </FlexBox>
  );
};

export default UploadImage;
