import { Divider, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import InputField from 'components/common/InputField';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import {
  IApplicationAssessmentOption,
  IApplicationAssessmentTemplate,
} from 'utils/models';
import { FlexBox } from 'utils/styledComponents';
import { ControlButtonStyled } from '../ApplicationBuilder';
import { primary } from 'theme';
import {
  CloseFullscreen,
  Error,
  ErrorRounded,
  OpenInFull,
} from '@mui/icons-material';
import AssessmentConfigurationOption from './AssessmentConfigurationOption';
import CommonButton from 'components/common/CommonButton';
import { useNavigate, useParams } from 'react-router';
import InformationModal from 'components/common/Modal/InformationModal';
import usePutAssessmentTemplate from 'hooks/Applications/usePostNewAssessmentTemplate';
import useFetchAssessmentTemplate from 'hooks/Applications/useFetchAssessmentTemplate';
import { useAssessmentContext } from 'services/contexts/Applications/AssessmentContext';
import Loading from 'components/common/Loading';
import AssessmentBuilderSection from './AssessmentBuilderSection';
import CommonSwitch from 'components/common/CommonSwitch';
import {
  getStoredNewAssessmentTemplate,
  removeStoredNewAssessmentTemplate,
  storeNewAssessmentTemplate,
} from 'utils/storage';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';

interface IBuilder {
  isEdit?: boolean;
}

const AssessmentBuilder: FunctionComponent<IBuilder> = (props) => {
  const { isEdit } = props;
  const navigate = useNavigate();
  const { ecosystemName } = useParams();
  const theme = useTheme();

  const { addAlert } = useContext(AlertContext);

  const [isRatingOpen, setIsRatingOpen] = useState<boolean>(true);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isSuccessfulModalOpen, setIsSuccessfulModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(false);

  const { selectedAssessmentProcessId } = useAssessmentContext();

  const storedAssessmentTemplate = getStoredNewAssessmentTemplate();

  const defaultAssessmentTemplate: IApplicationAssessmentTemplate = {
    name: '',
    configuration: {
      numberOfOptions: 5,
      isSameOptions: false,
      options: [],
      isWeighted: true,
    },
    sections: [
      {
        name: 'First section',
        subSections: [
          {
            name: 'Subsection 1',
            questions: [
              {
                name: '',
                options: [
                  { name: '' },
                  { name: '' },
                  { name: '' },
                  { name: '' },
                  { name: '' },
                ],
              },
            ],
            comment: '',
          },
        ],
      },
    ],
  };

  defaultAssessmentTemplate.sections[0].subSections[0].questions[0].options = //Set options to the seleceted length
    Array.from(
      { length: defaultAssessmentTemplate.configuration.numberOfOptions },
      () => ({ name: '' }),
    );

  defaultAssessmentTemplate.configuration.options = //Set options to the seleceted length
    Array.from(
      { length: defaultAssessmentTemplate.configuration.numberOfOptions },
      () => ({ name: '' }),
    );

  const {
    control,
    handleSubmit,
    reset,
    resetField,
    clearErrors,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<IApplicationAssessmentTemplate>({
    defaultValues: storedAssessmentTemplate || defaultAssessmentTemplate,
    mode: 'onTouched',
  });

  const formData = useWatch({
    control,
    defaultValue: defaultAssessmentTemplate,
  });

  const postNewAssessment = usePutAssessmentTemplate({
    assessmentTemplate: formData as IApplicationAssessmentTemplate,
    setIsLoading,
  });

  const fetchAssessmentTemplate = useFetchAssessmentTemplate({
    assessmentTemplateId: selectedAssessmentProcessId,
    setIsLoading: setIsFetchLoading,
    updateFormWithData,
  });

  const newOptions: IApplicationAssessmentOption[] = formData.configuration
    ?.isSameOptions
    ? formData.configuration?.options ?? []
    : Array.from({ length: getValues().configuration.numberOfOptions }, () => ({
        name: '',
      }));

  const {
    fields: sectionFields,
    append: appendSection,
    remove: removeSection,
  } = useFieldArray({
    name: 'sections',
    control,
  });

  const configurationSettings = getValues().configuration;

  const numberOfQuestions = getValues().sections.reduce(
    (sum, section) =>
      sum +
      section.subSections.reduce(
        (subSum, subSection) => subSum + subSection.questions.length,
        0,
      ),
    0,
  );

  const numberOfCharactersInOptions = configurationSettings.options.reduce(
    (sum, option) => sum + (option.name ? option.name.length : 0),
    0,
  );

  const isConfigOn = configurationSettings.isSameOptions;

  useEffect(() => {
    const updateOptions = () => {
      if (!configurationSettings.isSameOptions) return;

      const options = configurationSettings.options;

      if (!sectionFields) return;

      sectionFields.forEach((section, sectionIndex) => {
        //There still exists a bug where it not always update the last section. It can be fixed with: getValues().sections.forEach, BUT it comes at expense of huge performance loss.
        if (!section.subSections) return;

        section.subSections.forEach((subSection, subSectionIndex) => {
          const questions = getValues(
            `sections.${sectionIndex}.subSections.${subSectionIndex}.questions`,
          );
          if (!questions) return;
          questions.forEach((question, questionIndex) => {
            const questionPath =
              `sections.${sectionIndex}.subSections.${subSectionIndex}.questions.${questionIndex}` as const;
            const optionsPath = `${questionPath}.options` as const;

            setValue(
              optionsPath,
              options.map((option) => ({ ...option })),
            );

            options.forEach((option, optionsIndex) => {
              if (option.name) {
                clearErrors(`${optionsPath}.${optionsIndex}`);
              }
            });
          });
        });
      });
    };

    updateOptions();
  }, [isConfigOn, numberOfCharactersInOptions, numberOfQuestions]);

  useEffect(() => {
    if (!isEdit) return;
    fetchAssessmentTemplate();
  }, []);

  function updateFormWithData(template: IApplicationAssessmentTemplate) {
    reset(template);
  }

  const onAssessmentFormSubmit: SubmitHandler<IApplicationAssessmentTemplate> =
    async function (): Promise<void> {
      const updatedAssessment = await postNewAssessment();

      if (updatedAssessment) {
        reset(updatedAssessment);
        setIsSuccessfulModalOpen(true);
        removeStoredNewAssessmentTemplate();
        // setIsLoadingFalse(false);
      }
    };

  function handleIsSameOptionChange() {
    const isSameOptions = getValues('configuration.isSameOptions');
    setValue('configuration.isSameOptions', !isSameOptions);
  }

  console.log(formData);
  function handleIsWeightedInPercentages() {
    const isWeighted = getValues('configuration.isWeighted');
    setValue('configuration.isWeighted', !isWeighted);
    if (!isWeighted) return;

    sectionFields.forEach((section, sectionIndex) => {
      setValue(`sections.${sectionIndex}.weight`, 0);

      if (!section.subSections) return;

      section.subSections.forEach((subSection, subSectionIndex) => {
        setValue(
          `sections.${sectionIndex}.subSections.${subSectionIndex}.weight`,
          0,
        );

        const questions = getValues(
          `sections.${sectionIndex}.subSections.${subSectionIndex}.questions`,
        );
        if (!questions) return;
        questions.forEach((question, questionIndex) => {
          const questionPath =
            `sections.${sectionIndex}.subSections.${subSectionIndex}.questions.${questionIndex}` as const;
          const optionsPath = `${questionPath}.options` as const;

          setValue(
            `sections.${sectionIndex}.subSections.${subSectionIndex}.questions.${questionIndex}.weight`,
            0,
          );
        });
      });
    });
  }

  function handleCancel() {
    removeStoredNewAssessmentTemplate();
    reset(defaultAssessmentTemplate);
    navigate(`/ecosystem/${ecosystemName}/admin/applications`);
    window.location.reload();
  }

  function handleSave() {
    storeNewAssessmentTemplate(formData as IApplicationAssessmentTemplate);
    addAlert({
      type: ALERT_TYPES.SUCCESS,
      message: 'Templated saved',
    });
  }

  function renderCancelModal() {
    if (!isCancelModalOpen) return;
    return (
      <InformationModal
        isOpen={isCancelModalOpen}
        handleClose={() => setIsCancelModalOpen(false)}
        primaryText='Confirm'
        primaryOnClick={handleCancel}
        primaryBgColor={primary.warningRed}
        primaryBgHoverColor={primary.warningPlaceholder}
        secondaryText='Cancel'
        secondaryOnClick={() => setIsCancelModalOpen(false)}
        headerText={'Are you sure you want to leave application builder?'}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex' }}>
            <Error
              sx={{
                marginRight: '0.25rem',
                color: primary.warningRed,
                width: '2rem',
              }}
            />
            <Typography sx={{ mb: '1.25rem' }}>
              Are you sure you want to cancel? Any unsaved changes will be lost.
            </Typography>
          </Box>
          <Typography sx={{ ml: '2.25rem' }}>
            Click 'Confirm' to clear the progress and return to the processes
            page, or 'Cancel' to continue editing.
          </Typography>
        </Box>
      </InformationModal>
    );
  }

  function renderSuccessfulModal() {
    return (
      <InformationModal
        isOpen={isSuccessfulModalOpen}
        handleClose={() => setIsSuccessfulModalOpen(false)}
        primaryText='Close'
        primaryOnClick={() => setIsSuccessfulModalOpen(false)}
        headerText={
          isEdit
            ? 'Your assessment form has been successfully updated!'
            : 'Your assessment form has been successfully created!'
        }>
        <Typography sx={{ mb: '0.5rem' }}>
          You can find and manage this form in the 'Templates' tab.
          {/* Click 'Edit' to make changes if needed. */}
        </Typography>
      </InformationModal>
    );
  }

  function renderHeader() {
    return (
      <>
        <FlexBox
          sx={{
            gap: '1.25rem',
          }}>
          <h2>Assessment builder</h2>
          <img
            src={'/images/placeholders/beta.png'}
            alt={'beta'}
            style={{
              maxWidth: '5rem',
            }}
          />
        </FlexBox>

        <FlexBox
          className={'startDate'}
          sx={{
            gap: '2.5rem',
            alignItems: errors.name ? 'center' : 'end',
            width: '100%',
          }}>
          <FlexBox
            sx={{
              width: '100%',
              gap: '2.5rem',
            }}>
            <FlexBox
              sx={{
                minWidth: '40%',
                maxWidth: '37.5rem',
              }}>
              <Controller
                name={'name'}
                control={control}
                rules={{
                  required: 'Please enter assessment name.',
                }}
                render={({ field }) => (
                  <InputField
                    {...field}
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >,
                    ) => {
                      field.onChange(e);
                    }}
                    placeholder={'Create a name for the assessment'}
                    maxLength={255}
                    label={'Assessment name*'}
                    helperText={errors.name && errors.name.message}
                    helperIcon={errors.name && 'warning'}
                    error={!!errors.name}
                    formLabelStyles={{
                      marginTop: 0,
                    }}
                  />
                )}
              />
            </FlexBox>

            <FlexBox
              sx={{
                mt: !errors.name ? '2.25rem' : '0.625rem',
                mb: '0.625rem',
              }}>
              <CommonSwitch
                onClick={handleIsWeightedInPercentages}
                checked={getValues('configuration.isWeighted')}
              />
              <Typography sx={{ color: primary.natural3, m: '0.25rem' }}>
                Enable weighting in %
              </Typography>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <Divider
          sx={{
            height: '2px',
            marginY: '1.875rem',
          }}
        />
      </>
    );
  }

  function renderConfiguration() {
    return (
      <FlexBox
        sx={{
          flexDirection: 'column',
          alignItems: 'start',
          borderRadius: '12px',
          boxShadow: theme.shadows[1],
          padding: '1.563rem 1.875rem',
          width: '100%',
        }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Tooltip
            title={
              'Scoring of labels is based on their order, with the first answer receiving the fewest points and the last answer receiving the most points.'
            }>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Typography variant={'h6'} sx={{ fontWeight: 'bold' }}>
                Rating scale configuration
              </Typography>
              <Typography
                variant={'body1'}
                sx={{ color: primary.natural3, ml: '1.25rem' }}>
                Rating scale (1-5) will be applied to all criteria within the
                assessment form.
              </Typography>
              <ErrorRounded sx={{ color: primary.yellowStar, ml: '0.25rem' }} />
            </Box>
          </Tooltip>
          <ControlButtonStyled onClick={() => setIsRatingOpen((prev) => !prev)}>
            {isRatingOpen ? <CloseFullscreen /> : <OpenInFull />}
          </ControlButtonStyled>
        </Box>

        <Box
          sx={{
            display: !isRatingOpen ? 'none' : 'flex',
            flexDirection: 'column',
            width: '100%',
          }}>
          <FlexBox sx={{ mt: '0.625rem', mb: '0.625rem' }}>
            <CommonSwitch onClick={handleIsSameOptionChange} />
            <Typography sx={{ color: primary.natural3, m: '0.25rem' }}>
              Apply same labels to each criteria in assessment form
            </Typography>
          </FlexBox>

          <AssessmentConfigurationOption
            resetField={resetField}
            errors={errors}
            control={control}
          />
        </Box>
      </FlexBox>
    );
  }

  function renderSections() {
    return (
      <>
        {sectionFields.map((section, sectionIndex) => (
          <AssessmentBuilderSection
            key={section.id}
            resetField={resetField}
            errors={errors}
            control={control}
            sectionIndex={sectionIndex}
            newOptions={newOptions}
            appendSection={appendSection}
            removeSection={removeSection}
            isWeighted={!!formData.configuration?.isWeighted}
          />
        ))}
      </>
    );
  }

  const renderButtons = () => {
    return (
      <FlexBox
        className={'end'}
        sx={{
          marginTop: '2.2rem',
        }}>
        <FlexBox
          sx={{
            gap: '1.5rem',
          }}>
          <CommonButton
            onClick={handleSave}
            text={'Save as draft'}
            bgcolor={'transparent'}
            color={primary.dark}
            sx={{
              width: '12.5rem',
              height: '3rem',
            }}
            isLoading={isLoading}
          />
          <CommonButton
            onClick={() => setIsCancelModalOpen(true)}
            text={'Cancel'}
            bgcolor={'transparent'}
            color={primary.dark}
            sx={{
              width: '12.5rem',
              height: '3rem',
            }}
            isLoading={isLoading}
          />
          {renderCancelModal()}
          <CommonButton
            submit
            sx={{
              width: '12.5rem',
              height: '3rem',
            }}
            text={isEdit ? 'Update template' : 'Create new template'}
            bgcolor={primary.dark}
            isLoading={isLoading}
          />
          {renderSuccessfulModal()}
        </FlexBox>
      </FlexBox>
    );
  };

  if (isFetchLoading)
    return (
      <Box
        sx={{
          padding: '3.125rem',
          maxWidth: '100%',
        }}>
        <FlexBox
          sx={{
            gap: '1.25rem',
          }}>
          <h2>Assessment builder</h2>
          <img
            src={'/images/placeholders/beta.png'}
            alt={'beta'}
            style={{
              maxWidth: '5rem',
            }}
          />
        </FlexBox>
        <Loading />
      </Box>
    );

  return (
    <Box
      sx={{
        padding: '3.125rem',
        maxWidth: '100%',
      }}>
      <form onSubmit={handleSubmit(onAssessmentFormSubmit)}>
        {renderHeader()}
        {renderConfiguration()}
        {renderSections()}
        {renderButtons()}
      </form>
    </Box>
  );
};

export default AssessmentBuilder;
