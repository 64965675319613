import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import React, { FunctionComponent, useEffect } from 'react';
import useFetchTagGroups from 'hooks/Common/useFetchTagGroups';

interface IAddTagGroup {
  selectedTagGroup: any;
  setSelectedTagGroup: (e: number) => void;
  setTags: any;
  onTagGroupSelect?: (tagGroup: any) => void;
}

const AddTagGroup: FunctionComponent<IAddTagGroup> = (props) => {
  const { selectedTagGroup, setSelectedTagGroup, setTags, onTagGroupSelect } =
    props;

  const { tagGroups } = useFetchTagGroups();

  useEffect(() => {
    if (selectedTagGroup !== null) {
      const selectedGroup = tagGroups.find(
        (group) => group.id === selectedTagGroup,
      );
      if (selectedGroup) {
        setTags(selectedGroup.tags);
        if (onTagGroupSelect) {
          onTagGroupSelect(selectedGroup);
        }
      }
    }
  }, [selectedTagGroup, tagGroups]);

  return (
    <div>
      <Typography
        variant='body1'
        sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
        Tag Group
      </Typography>
      <FormControl fullWidth>
        <Select
          value={selectedTagGroup || ''}
          onChange={(e) => setSelectedTagGroup(e.target.value as number)}
          placeholder='Select Tag Group'
          sx={{ borderRadius: '5px !important' }}>
          {tagGroups.map((group) => (
            <MenuItem key={group.id} value={group.id}>
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default AddTagGroup;
