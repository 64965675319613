import React, { FunctionComponent } from 'react';
import { Box, Card } from '@mui/material';
import CardTag from 'components/common/CardTag';
import { primary } from 'theme';

interface ICompanySection {
  title?: string;
  children?: JSX.Element;
  simple?: boolean;
  headerAction?: JSX.Element;
  noPadding?: boolean;
  shadow?: boolean;
}

const CompanySection: FunctionComponent<ICompanySection> = (props) => {
  const { title, children, simple, headerAction, noPadding, shadow } = props;

  return (
    <>
      <Box>
        <Box>
          <CardTag
            text={title || 'Section'}
            sx={{
              display: 'inline-flex',
              my: '0.5rem',
            }}
          />
          <div>{headerAction}</div>
        </Box>
        <Card sx={{ borderRadius: '10px' }}>
          <Box
            sx={{
              borderRadius: '10px',
              border: `1px solid ${primary.natural6}`,
            }}>
            <Box sx={{ padding: '0' }}>{children}</Box>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default CompanySection;
