import React, {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import Loading from 'components/common/Loading';
import { IEvent } from 'utils/models';
import {
  getEcosystemInfo,
  getStoredEvents,
  getStoredLoadedMonths,
  storeEvents,
  storeLoadedMonths,
  updateEvents,
} from 'utils/storage';
import {
  EVENTS_BY_DATE_ROUTE,
  EVENTS_ROUTE,
  EVENT_ATTEND_ROUTE,
  getData,
  putData,
} from 'utils/requests';
import {
  areSetsEqual,
  findElementsNotInSet1,
  formatDateAs_yyyy_MM_dd,
  getDateWithTime,
} from 'utils/utils';

const useEvents = () => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const todaysDate = new Date();
  const todaysMonth = todaysDate.getMonth();

  const [events, setEvents] = useState<IEvent[]>(getStoredEvents() ?? []);
  const [loadedMonths, setLoadedMonths] = useState<number[]>(
    getStoredLoadedMonths() ?? [],
  );

  const [monthsToShow, setMonthsToShow] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCalendarLoading, setIsCalendarLoading] = useState<boolean>(false);
  const [isAttendingLoading, setIsAttendingLoading] = useState<boolean>(false);

  const [filteredEvents, setFilteredEvents] = useState<IEvent[]>([]);

  const [isShowAddEvent, setIsShowAddEvent] = useState<boolean>(false);

  const [isShowEventDetails, setIsShowEventDetails] = useState<boolean>(false);

  const [chosenEvent, setChosenEvent] = useState<IEvent | null>(null);

  const [isShowEdit, setIsShowEdit] = useState<boolean>(false);

  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  useEffect(() => {
    if (getStoredEvents() !== null) return;
    fetchEvents();
  }, []);

  useEffect(() => {
    if (events) setFilteredEvents(events);
    storeEvents(events);
  }, [events]);

  useEffect(() => {
    if (areSetsEqual(monthsToShow, loadedMonths)) return;

    const missingMonths = findElementsNotInSet1(loadedMonths, monthsToShow);

    for (const month of missingMonths) {
      fetchEventsByMonth(month);
    }
  }, [monthsToShow]);

  useEffect(() => {
    storeLoadedMonths(loadedMonths);
  }, [loadedMonths]);

  function calculateDateFromMonths(monthOffset: number): Date {
    const today = new Date();
    const newDate = new Date(today);

    newDate.setMonth(today.getMonth() + monthOffset);

    return new Date(newDate.getFullYear(), newDate.getMonth(), 1);
  }

  async function fetchEvents() {
    if (!ecosystemName) return null;
    setIsLoading(true);
    try {
      const data = await getData(EVENTS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'startDate', value: formatDateAs_yyyy_MM_dd(new Date()) },
      ]);
      setEvents(data);
      storeEvents(data);

      const latestMonth = data.reduce(
        (maxMonth: number, event: { startDate: string | number | Date }) => {
          const eventMonth = new Date(event.startDate).getMonth() + 1;
          return eventMonth > maxMonth ? eventMonth : maxMonth;
        },
        todaysMonth + 1,
      );

      const monthsRange = Array.from(
        { length: latestMonth - todaysMonth + 1 },
        (_, i) => todaysMonth + i,
      );

      setLoadedMonths(monthsRange);
      setMonthsToShow([todaysMonth, todaysMonth + 1]);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  async function fetchEventsByMonth(monthNumber: number) {
    if (!ecosystemName) return null;
    setIsCalendarLoading(true);

    const numberOfMonthsDifferance = monthNumber - todaysMonth;
    const dateToFetch = calculateDateFromMonths(numberOfMonthsDifferance);

    if (loadedMonths.includes(monthNumber)) return;

    try {
      const data = await getData(EVENTS_BY_DATE_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        {
          name: 'startDate',
          value: formatDateAs_yyyy_MM_dd(dateToFetch),
        },
      ]);

      setEvents((prev) => [...prev, ...data]);

      setLoadedMonths((prev) => {
        if (!prev.includes(monthNumber)) {
          return [...prev, monthNumber];
        }
        return prev;
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
    setIsCalendarLoading(false);
  }

  function callCloseEventDetails() {
    setIsShowEventDetails(false);
    setChosenEvent(null);
  }

  function callCloseEditEvent() {
    setIsShowEdit(false);
  }

  async function putIsAttending(event: IEvent, userId: number) {
    setIsAttendingLoading(true);

    try {
      const updatedEvent = await putData(EVENT_ATTEND_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'id', value: event.id },
        {
          name: 'attendeeId',
          value: userId,
        },
      ]);

      const events = getStoredEvents();
      if (events === null) return;

      events.splice(
        events.findIndex((e: IEvent) => e.id === event.id),
        1,
      );

      const updatedEvents = [...events, updatedEvent].sort(
        (a, b) => getDateWithTime(a) - getDateWithTime(b),
      );

      updateEvents(updatedEvents);

      addAlert({ type: ALERT_TYPES.SUCCESS, message: 'Status Updated' });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsAttendingLoading(false);
  }

  return {
    events,
    isLoading,
    filteredEvents,
    isShowAddEvent,
    monthsToShow,
    todaysMonth,
    isCalendarLoading,
    isShowEventDetails,
    chosenEvent,
    isShowEdit,
    isAttendingLoading,
    isDuplicate,
    setIsShowEdit,
    setChosenEvent,
    setEvents,
    setIsLoading,
    setMonthsToShow,
    fetchEventsByMonth,
    setFilteredEvents,
    setIsShowAddEvent,
    setIsShowEventDetails,
    callCloseEventDetails,
    callCloseEditEvent,
    putIsAttending,
    setIsDuplicate,
  };
};

const EventsContext = createContext({} as ReturnType<typeof useEvents>);

export const EventsProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const eventsContext = useEvents();
  const { isLoading, events } = eventsContext;

  if (!events) return <>{children}</>;

  if (isLoading || Object.keys(getEcosystemInfo()).length < 1) {
    return <Loading marginTop={90} />;
  }

  return (
    <EventsContext.Provider value={eventsContext}>
      {children}
    </EventsContext.Provider>
  );
};

export const useEventsContext = () => useContext(EventsContext);
