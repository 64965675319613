import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import Modal, { IBaseModal } from '../common/Modal';
import { acterioTheme, mainFont } from 'theme';
import InputField from '../common/InputField';
import { ACCOUNT_CHANGE_PASSWORD_ROUTE, patchData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0 1rem',
    paddingBottom: '0.3rem',
    marginBottom: '1rem',
  },
  helper: {
    fontFamily: `${mainFont} !important`,
    marginTop: '7px !important',
    fontSize: '0.875rem !important',
    display: 'block',
  },
  label: {
    textAlign: 'left',
    fontWeight: '700!important',
    marginBottom: '7px',
    color: 'white !important',
  },
}));

const ChangePassword: FunctionComponent<IBaseModal> = (props) => {
  const { open, close } = props;
  const { isMobile } = useMobileContext();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);

  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  async function changePassword() {
    setLoading(true);

    try {
      await patchData(ACCOUNT_CHANGE_PASSWORD_ROUTE, [
        { name: 'oldPassword', value: oldPassword },
        { name: 'newPassword', value: newPassword },
        { name: 'confirmPassword', value: confirmPassword },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Password updated',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
    close();
  }

  function renderContent() {
    return (
      <div className={classes.root}>
        <InputField
          value={oldPassword}
          label={'Old password'}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setOldPassword(event.target.value)
          }
          type={'password'}
        />

        <InputField
          value={newPassword}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setNewPassword(event.target.value)
          }
          readonly={loading}
          type={'password'}
          label={'New password'}
          helperText={
            'Use 8 or more characters with a mix of letters, numbers & capitals'
          }
        />
        <InputField
          value={confirmPassword}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setConfirmPassword(event.target.value)
          }
          readonly={loading}
          type={'password'}
          label={'Confirm password'}
          helperText={'Enter the new password again'}
        />
      </div>
    );
  }

  return (
    <Modal
      open={open}
      close={close}
      width={isMobile ? 360 : 600}
      isActerioTheme={true}
      title={'Change Password'}
      largeHeader={true}
      showFooter={true}
      largeFooter={true}
      disabledSave={!oldPassword && !newPassword && !confirmPassword}
      footerButtonsFlexStart={true}
      saveButtonText={'Change Password'}
      saveAction={() => changePassword()}
      backgroundColor={'white'}
      loading={loading}
      headerColor={acterioTheme.light}>
      {renderContent()}
    </Modal>
  );
};

export default ChangePassword;
