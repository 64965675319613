import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IApplicantDetails } from 'utils/models';
import { APPLICATIONS_PROCESS_APPLICANT, getData } from 'utils/requests';

interface IFetchKanbanBoard {
  selectedApplicationProcessId: string | null;
  selectedApplicantId: string | null;
  setIsLoading: (b: boolean) => void;
}

const useFetchApplicantDetails = (props: IFetchKanbanBoard) => {
  const { selectedApplicationProcessId, selectedApplicantId, setIsLoading } =
    props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [data, setData] = useState<IApplicantDetails | null>(null);

  const fetchApplicantDetails = useCallback(async () => {
    if (!selectedApplicationProcessId || !selectedApplicantId)
      return () => setData(null);

    setIsLoading(true);

    try {
      const data = await getData(APPLICATIONS_PROCESS_APPLICANT, [
        {
          name: 'applicationProcessId',
          value: selectedApplicationProcessId,
        },
        {
          name: 'applicantId',
          value: selectedApplicantId,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);
      setData(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }, [selectedApplicantId, selectedApplicationProcessId, ecosystemName]);

  useEffect(() => {
    fetchApplicantDetails();
  }, [fetchApplicantDetails]);

  return { data };
};

export default useFetchApplicantDetails;
