import {
  Button,
  CircularProgress,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import { naturalPallete, primary } from 'theme';

type BreakpointKey = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export enum ButtonSize {
  SMALL,
  MEDIUM,
  LARGE,
}

export enum ButtonVariant {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  BLANK = 'blank',
}

export interface IHoverColor {
  color?: string;
  bgcolor?: string;
}

export interface IButton {
  text?: string;
  onClick?: (() => void) | ((event: React.MouseEvent<HTMLElement>) => void);
  variant?: ButtonVariant | Partial<Record<BreakpointKey, ButtonVariant>>;
  color?: string;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  isDisabled?: boolean;
  bgcolor?: string | Partial<Record<BreakpointKey, string>>;
  submit?: boolean;
  isLoading?: boolean;
  sx?: SxProps;
  size?: ButtonSize | Partial<Record<BreakpointKey, ButtonSize>>;
  hoverColor?: IHoverColor;
}

const CommonButton: FunctionComponent<IButton> = (props) => {
  const {
    text,
    sx,
    onClick,
    variant = ButtonVariant.FILLED,
    isDisabled,
    isLoading,
    icon,
    endIcon,
    submit,
    color,
    bgcolor,
    size,
    hoverColor,
  } = props;

  const theme = useTheme();

  let variantStyle;

  const filledStyle = {
    bgcolor: bgcolor ?? theme.palette.primary.dark,
    color: color ?? primary.pureWhite,
  };

  switch (variant) {
    case ButtonVariant.FILLED:
      variantStyle = filledStyle;
      break;
    case ButtonVariant.OUTLINED:
      variantStyle = {
        bgcolor: 'transparent',
        color: color ?? theme.palette.primary.dark,
        border: `2px solid ${bgcolor ?? theme.palette.primary.dark}`,

        '&:hover': {
          bgcolor:
            hoverColor?.bgcolor ?? bgcolor ?? theme.palette.primary.light,
          color: hoverColor?.color ?? color ?? theme.palette.primary.dark,
        },
      };
      break;
    case ButtonVariant.BLANK:
      variantStyle = {
        bgcolor: 'transparent',
        color: color ?? theme.palette.primary.dark,

        '&:hover': {
          bgcolor: hoverColor ?? bgcolor ?? theme.palette.primary.light,
        },
      };
      break;
    default:
      variantStyle = filledStyle;
  }

  return (
    <Button
      onClick={onClick}
      sx={{
        minWidth: '10rem',
        width: '11rem',
        borderRadius: '6px',
        paddingY:
          size === ButtonSize.SMALL
            ? '0.5rem'
            : size === ButtonSize.LARGE
            ? '1.5rem'
            : '0.75rem',
        paddingX: '1rem',
        height:
          size === ButtonSize.LARGE
            ? '4rem'
            : size === ButtonSize.SMALL
            ? '2rem'
            : '2.5rem',

        '&:disabled': {
          backgroundColor: naturalPallete.natural5,
          color: primary.pureWhite,
          border: 'none',
        },

        '&:hover': {
          bgcolor: hoverColor?.bgcolor ?? theme.palette.primary.light,
          color: hoverColor?.color ?? primary.dark,
          boxShadow: '0px 0px 4px 0px #00000040',
          // boxShadow: '2px 3px 4px 0px #FFFFFF40 inset',
        },

        ...variantStyle,
        ...sx,
      }}
      type={submit ? 'submit' : 'button'}
      disabled={isDisabled || isLoading}
      startIcon={!isLoading && icon}
      endIcon={!isLoading && endIcon}>
      <Typography
        variant='body1'
        sx={{
          lineHeight: '0.875rem',
          color: 'inherit',
          wordWrap: 'break-word',
          fontWeight: 500,
        }}>
        {isLoading ? (
          <CircularProgress
            color={color ? 'inherit' : 'primary'}
            size={25}
            style={{ marginTop: '2px' }}
          />
        ) : (
          <span>{text}</span>
        )}
      </Typography>
    </Button>
  );
};

export default CommonButton;
