import { Grid, useTheme } from '@mui/material';
import CloseModalButton from 'components/common/Modal/CloseModalButton';
import CommonModal from 'components/common/Modal/CommonModal';
import ModalBox from 'components/common/Modal/ModalBox';
import React, { FunctionComponent, useState } from 'react';
import { primary } from 'theme';
import { IUploadFile } from 'utils/models';

interface ICompanyImageCard {
  file: IUploadFile;
}

const CompanyImageCard: FunctionComponent<ICompanyImageCard> = (props) => {
  const { file } = props;

  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <Grid item xs={4}>
      <img
        src={file.url}
        style={{
          borderRadius: '8px',
          width: '100%',
          aspectRatio: 3 / 2,
          cursor: 'pointer',
        }}
        onClick={() => setIsModalOpen(true)}
      />
      <CommonModal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalBox sx={{ p: '0.25rem', pb: '0rem' }} width={'37rem'}>
          <>
            <CloseModalButton
              handleClose={() => setIsModalOpen(false)}
              color={primary.mainBlackFont}
              background={true}
              bgColor={primary.pureWhite}
              hoverColor={theme.palette.primary.contrastText}
              sx={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                zIndex: '9',
              }}
            />
            <img
              src={file.url}
              style={{
                borderRadius: '8px',
                width: '100%',
              }}
            />
          </>
        </ModalBox>
      </CommonModal>
    </Grid>
  );
};

export default CompanyImageCard;
