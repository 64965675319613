import React, { FunctionComponent } from 'react';

interface CustomIconProps {
  color: string;
}

const CommentIcon: FunctionComponent<CustomIconProps> = (props) => {
  const { color } = props;

  return (
    <svg viewBox='0 0 123 123' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M122.667 61.3333C122.667 27.4589 95.208 0 61.3337 0C27.4593 0 0.000335693 27.4589 0.000335693 61.3333C0.000335693 95.2077 27.4593 122.667 61.3337 122.667C70.3704 122.672 79.2966 120.68 87.4739 116.834L115.301 122.538C116.294 122.741 117.323 122.694 118.294 122.403C119.265 122.111 120.149 121.583 120.866 120.866C121.583 120.149 122.111 119.265 122.403 118.294C122.695 117.322 122.741 116.294 122.538 115.301L116.834 87.4736C120.68 79.2963 122.672 70.37 122.667 61.3333ZM88.9337 52.1333C91.3736 52.1333 93.7137 53.1026 95.439 54.8279C97.1644 56.5533 98.1337 58.8933 98.1337 61.3333V61.3947C98.1337 63.8347 97.1644 66.1747 95.439 67.9C93.7137 69.6254 91.3736 70.5947 88.9337 70.5947H88.8723C86.4323 70.5947 84.0923 69.6254 82.3669 67.9C80.6416 66.1747 79.6723 63.8347 79.6723 61.3947V61.3333C79.6723 58.8933 80.6416 56.5533 82.3669 54.8279C84.0923 53.1026 86.4323 52.1333 88.8723 52.1333H88.9337ZM61.3337 52.1333C63.7736 52.1333 66.1137 53.1026 67.839 54.8279C69.5644 56.5533 70.5337 58.8933 70.5337 61.3333V61.3947C70.5337 63.8347 69.5644 66.1747 67.839 67.9C66.1137 69.6254 63.7736 70.5947 61.3337 70.5947H61.2723C58.8323 70.5947 56.4923 69.6254 54.7669 67.9C53.0416 66.1747 52.0723 63.8347 52.0723 61.3947V61.3333C52.0723 58.8933 53.0416 56.5533 54.7669 54.8279C56.4923 53.1026 58.8323 52.1333 61.2723 52.1333H61.3337ZM42.9337 61.3333C42.9337 58.8933 41.9644 56.5533 40.239 54.8279C38.5137 53.1026 36.1737 52.1333 33.7337 52.1333H33.6723C31.2323 52.1333 28.8923 53.1026 27.167 54.8279C25.4416 56.5533 24.4723 58.8933 24.4723 61.3333V61.3947C24.4723 63.8347 25.4416 66.1747 27.167 67.9C28.8923 69.6254 31.2323 70.5947 33.6723 70.5947H33.7337C36.1737 70.5947 38.5137 69.6254 40.239 67.9C41.9644 66.1747 42.9337 63.8347 42.9337 61.3947V61.3333Z'
        fill={color}
      />
    </svg>
  );
};

export default CommentIcon;
