import Cropper, { Area, Point } from 'react-easy-crop';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import {
  Checkbox,
  FormControlLabel,
  Slider,
  Theme,
  useTheme,
} from '@mui/material';
import Modal from '../common/Modal';
import { Box } from '@mui/system';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  cropperContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
}));

interface OwnProps {
  showImageCropper: boolean;
  setShowImageCropper: React.Dispatch<React.SetStateAction<boolean>>;
  uploadFunction?: (editedAvatar: Blob) => Promise<void>;
  uploadedImage?: Blob | MediaSource | undefined;
  setUploadedImage?: React.Dispatch<Blob | MediaSource>;
  onUpload?: (image: Blob) => void;
  aspectRatioWidth: number;
  aspectRatioHeight: number;
  roundCropper?: boolean;
}

const ImageCropper: FunctionComponent<OwnProps> = (props) => {
  const classes = useStyles();

  const theme = useTheme();

  const {
    showImageCropper,
    setShowImageCropper,
    uploadFunction,
    uploadedImage,
    setUploadedImage,
    aspectRatioWidth,
    aspectRatioHeight,
    onUpload,
    roundCropper,
  } = props;
  const CROP_AREA_ASPECT = aspectRatioWidth / aspectRatioHeight;

  const { isMobile } = useMobileContext();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [triggerFileInputClick, setTriggerFileInputClick] =
    useState<boolean>(false);

  const [imageSrc, setImageSrc] = useState<any>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [zoom, setZoom] = useState<number>(1);
  const [minZoom, setMinZoom] = useState<number>(0.1);
  const maxZoom = 4;
  const [restrictPosition, setRestrictPostion] = useState<boolean>(true);

  useEffect(() => {
    if (showImageCropper) {
      setTriggerFileInputClick(true);
    }

    setTimeout(() => setTriggerFileInputClick(false), 0);
  }, [showImageCropper]);

  useEffect(() => {
    if (triggerFileInputClick && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [triggerFileInputClick]);

  useEffect(() => {
    if (restrictPosition) {
      setMinZoom(1);
      if (zoom <= 1) {
        setZoom(1);
      }
      setCrop({ x: 0, y: 0 });
    } else {
      setMinZoom(0.1);
    }
  }, [restrictPosition]);

  useEffect(() => {
    if (uploadedImage) setImageSrc(URL.createObjectURL(uploadedImage));
  }, [uploadedImage]);

  const onFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
    }
  };

  function readFile(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  function toBlob(canvas: HTMLCanvasElement): Promise<Blob | null> {
    return new Promise((resolve) => {
      canvas.toBlob(resolve);
    });
  }

  async function urltoFile(url: string, filename: string, mimeType: string) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  async function getCroppedImg(imageSrc: string): Promise<Blob> {
    const canvas = document.createElement('canvas');
    canvas.width = 500 * aspectRatioWidth;
    canvas.height = 500 * aspectRatioHeight;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    const image = new Image();
    image.src = imageSrc;

    image.addEventListener('load', () => {
      context.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        500 * aspectRatioWidth,
        500 * aspectRatioHeight,
      );
    });

    await toBlob(canvas);
    setImageSrc(null);
    return urltoFile(canvas.toDataURL(), 'profilePic', 'image/png');
  }

  const saveCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc);
      if (uploadFunction) {
        uploadFunction(croppedImage);
      } else if (setUploadedImage && onUpload) {
        setUploadedImage(croppedImage);
        onUpload(croppedImage);
        setShowImageCropper(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  function renderCropper() {
    return (
      <div>
        {imageSrc ? (
          <Box>
            <React.Fragment>
              <div className={classes.cropperContainer}>
                <Cropper
                  zoomSpeed={0.4}
                  minZoom={minZoom}
                  maxZoom={maxZoom}
                  cropShape={roundCropper ? 'round' : 'rect'}
                  image={imageSrc}
                  crop={crop}
                  aspect={CROP_AREA_ASPECT}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  restrictPosition={restrictPosition}
                  onZoomChange={setZoom}
                  zoom={zoom}
                  showGrid={false}
                />
              </div>
            </React.Fragment>
            {renderSlidebar()}
          </Box>
        ) : (
          <input
            ref={fileInputRef}
            id='fileInput'
            type='file'
            onChange={onFileChange}
            accept='image/png, image/jpeg'
          />
        )}
      </div>
    );
  }

  function renderSlidebar() {
    return (
      <Box
        sx={{
          width: '90%',
          margin: 'auto',
          height: '80px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '0.5rem',
        }}
        className='controls'>
        <FormControlLabel
          control={
            <Checkbox
              checked={restrictPosition}
              onChange={(event) => setRestrictPostion(event.target.checked)}
              sx={{
                color: `${theme.palette.primary.dark} !important`,
              }}
            />
          }
          label={'Restrict cropping area to the photo size'}
        />

        <Slider
          value={zoom}
          min={minZoom}
          max={maxZoom}
          step={0.02}
          aria-labelledby='Zoom'
          onChange={(e, zoom) => setZoom(zoom as number)}
          sx={{ paddingY: '1.5rem', color: theme.palette.primary.dark }}
        />
      </Box>
    );
  }

  return (
    <Modal
      open={showImageCropper}
      close={() => {
        setShowImageCropper(false);
        setImageSrc(null);
      }}
      width={isMobile ? 320 : 600}
      isActerioTheme={true}
      title={'Crop image'}
      largeHeader={true}
      showFooter={true}
      largeFooter={true}
      saveAction={() => saveCroppedImage()}
      footerButtonsFlexStart={true}
      saveButtonText={'Finish'}
      backgroundColor={'white'}
      headerColor={theme.palette.primary.light}>
      {renderCropper()}
    </Modal>
  );
};

export default ImageCropper;
