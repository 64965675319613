import React, { FunctionComponent } from 'react';
import { Box, Card, useTheme } from '@mui/material';
import { ICompanyField, ITag } from 'utils/models';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import CardTag from 'components/common/CardTag';
import { colorPallete, naturalPallete, primary } from 'theme';
import RichTextEditor from 'components/common/RichTextEditor';
import { Event } from '@mui/icons-material';
import { FlexBox } from 'utils/styledComponents';
import { DatePicker } from '@mui/x-date-pickers';
import { formatDateAs_yyyy_MM_dd, isEmptyRichText } from 'utils/utils';
import CompanySectionUploadFiles from '../CompanySectionUploadFiles';
import TagsField from 'components/common/Tags/TagsField';
import CompanyEditPeopleField from './CompanyEditPeopleField';

interface ICompanyEditSideSection {}

const CompanyEditSideSection: FunctionComponent<
  ICompanyEditSideSection
> = ({}) => {
  const {
    companyProfile,
    updateCompanyField,
    checkIfInfoIsMissing,
    removeSelectedTag,
  } = useCompanyContext();
  const theme = useTheme();

  function renderRichTextEditor(field: ICompanyField) {
    checkIfInfoIsMissing(field, 'richText');
    return (
      <Box
        sx={{
          px: '0.5rem',
          pt: '0.5rem',
          pb: '0.5rem',
          border:
            field.required && isEmptyRichText(field.richText)
              ? `1px solid ${colorPallete.warningRed}`
              : '',
          borderRadius: '6px',
        }}>
        <RichTextEditor
          sx={{
            '& .ql-container': {
              borderRadius: '0 0 6px 6px',
            },
            '& .ql-toolbar': {
              borderRadius: '6px 6px 0 0',
            },
          }}
          value={field.richText}
          onChange={(value) => updateCompanyField(field.id, value)}
        />
      </Box>
    );
  }

  function renderDateEditor(field: ICompanyField) {
    checkIfInfoIsMissing(field, 'localDate');
    return (
      <FlexBox
        sx={{
          p: '0.5rem',
          gap: '0.5rem',
          border:
            field.required && !field.localDate
              ? `1px solid ${colorPallete.warningRed}`
              : '',
          borderRadius: '6px',
        }}>
        <Event sx={{ color: theme.palette.primary.main }} />
        <DatePicker
          value={new Date(field.localDate as string)}
          onChange={(value) => {
            updateCompanyField(
              field.id,
              value ? formatDateAs_yyyy_MM_dd(value as Date) : null,
            );
          }}
          format='dd/MM/yyyy'
          sx={{
            border: `1px solid ${
              field.required && !field.localDate
                ? colorPallete.warningRed
                : 'none'
            }`,
            borderRadius: '8px',
            '.MuiInputBase-root': {
              fontSize: '1rem',
              height: '2.5rem',
              width: '12.5rem',
              color:
                field.required && !field.localDate
                  ? colorPallete.warningRed
                  : 'inherit',
            },
            '.MuiSvgIcon-root': {
              padding: '0rem',
              margin: '0rem',
            },
          }}
        />
      </FlexBox>
    );
  }

  function renderTagsEditor(field: ICompanyField) {
    checkIfInfoIsMissing(field, 'usedTags');

    const { tagGroup, usedTags: selectedTags = [], id } = field;
    const tagOptions = tagGroup ? tagGroup.tags : [];

    return (
      <Box sx={{ padding: '1rem 0.5rem 0rem 0.5rem' }}>
        <FlexBox
          sx={{
            width: '100%',
            mb: '1rem',
            whiteSpace: 'wrap',
          }}>
          <TagsField
            tags={selectedTags}
            tagOptions={tagOptions}
            selectedTags={selectedTags}
            updateCompanyField={(id: string, newTags: ITag[]) =>
              updateCompanyField(Number(id), newTags)
            }
            id={id.toString()}
            updateTags={(tagName: string) => {
              const tagToRemove = selectedTags.find(
                (tag: ITag) => tag.name === tagName,
              );
              if (tagToRemove) {
                removeSelectedTag(id, tagToRemove);
              }
            }}
            sx={{
              border:
                selectedTags.length < 1
                  ? `1px solid ${primary.warningRed}`
                  : `1px solid ${primary.natural6}`,
            }}
          />
        </FlexBox>
      </Box>
    );
  }

  function renderPeopleEditor(field: ICompanyField) {
    // if (!field.people) return;

    return <CompanyEditPeopleField field={field} />;
  }

  function renderEditors() {
    return companyProfile!.sidebar.map(
      (field: ICompanyField, index: number) => {
        if (field.type === 'PEOPLE' && field.name === 'Team') return null;
        return (
          <Box key={'sidebar-editor-' + field.id}>
            <CardTag
              text={field.name || 'Section'}
              sx={{
                display: 'inline-flex',
                my: '0.5rem',
              }}
            />
            <Card
              sx={{
                border: `1px solid ${naturalPallete.natural6}`,
                mb: '1rem',
              }}>
              {field.type === 'FILES' ? (
                <CompanySectionUploadFiles field={field} />
              ) : null}
              {field.type === 'RICH_TEXT' ? renderRichTextEditor(field) : null}
              {field.type === 'DATE' ? renderDateEditor(field) : null}
              {field.type === 'TAGS' ? renderTagsEditor(field) : null}
              {field.type === 'PEOPLE' ? renderPeopleEditor(field) : null}
              {/* {field.type === 'TEXT' ? renderTextEditor(field) : null} */}
            </Card>
          </Box>
        );
      },
    );
  }

  return <> {renderEditors()} </>;
};

export default CompanyEditSideSection;
