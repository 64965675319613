import { Button, SxProps, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { primary } from 'theme';

interface IClose {
  handleClose: () => void;
  color?: string;
  sx?: SxProps;
  hoverColor?: string;
  background?: boolean;
  bgColor?: string;
}

const CloseModalButton: FunctionComponent<IClose> = (props) => {
  const { handleClose, sx, color, hoverColor, background, bgColor } = props;

  const theme = useTheme();

  return (
    <Button
      variant={'contained'}
      onClick={handleClose}
      sx={{
        borderColor: color ?? theme.palette.primary.dark,
        bgcolor: background ? bgColor ?? primary.natural6 : 'inherit',
        borderRadius: '16px',
        minWidth: 'unset',
        width: '25px',
        height: '25px',
        p: '4px',
        pt: '5px',
        '&:hover': {
          bgcolor: hoverColor ?? theme.palette.primary.light,
          borderColor: hoverColor ?? theme.palette.primary.light,
        },

        ...sx,
      }}>
      <CloseIcon
        sx={{
          color: color ?? theme.palette.primary.dark,
          width: '13,18px',
          height: '13,18px',
        }}
      />
    </Button>
  );
};

export default CloseModalButton;
