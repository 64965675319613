import { SxProps, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { getAccount } from 'utils/storage';
import { getOffset } from 'utils/utils';
import { primary } from 'theme';

interface ITimezoneOffest {
  sx?: SxProps;
  color?: string;
  displayLocation?: boolean;
  timezoneRegion?: string;
}

const TimezoneOffsetDisplay: FunctionComponent<ITimezoneOffest> = (props) => {
  const { sx, color, displayLocation, timezoneRegion } = props;

  const currentTimezone = timezoneRegion ?? getAccount().timezone;
  const offset = getOffset(currentTimezone);

  return (
    <Typography
      sx={{
        ml: '0.5rem',
        mt: '0.125rem',
        textAlign: 'center',
        fontSize: '0.75rem',
        color: color ?? primary.mainBlackFont,
        ...sx,
      }}>
      {displayLocation && currentTimezone + ' '}
      {offset}
    </Typography>
  );
};

export default TimezoneOffsetDisplay;
