import React, { FunctionComponent, useState } from 'react';
import PostItem from './PostItem';
import { IAccount, IAnnouncement } from '../../utils/models';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material';
import SeeMoreButton from '../common/SeeMoreButton';
import { useComment } from '../../hooks/Announcements/useComment';

interface IAnnouncementProps {
  user: IAccount;
  announcement: IAnnouncement;
  pinAnnouncement: (id: number, pinned: boolean) => Promise<void>;
  deleteAnnouncement: (id: number) => Promise<void>;
  editAnnouncement: (
    id: number,
    editedTitle: string,
    editedBody: string,
    accessLevels: number[],
    editedImage: File | null,
    closeModal: () => void,
  ) => Promise<void>;
}

const Announcement: FunctionComponent<IAnnouncementProps> = (props) => {
  const {
    announcement,
    pinAnnouncement,
    deleteAnnouncement,
    editAnnouncement,
    user,
  } = props;
  const theme = useTheme();

  const [comments, setComments] = useState(announcement.announcementComments);
  const [showAllComments, setShowAllComments] = useState(false);

  const { deleteComment } = useComment(setComments);

  const toggleComments = () => {
    setShowAllComments((prevState) => !prevState);
  };

  const visibleComments = showAllComments ? comments : comments.slice(-2);

  return (
    <Box
      sx={{
        padding: '0.5rem 0.5rem 1.25rem 0',
        position: 'relative',
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}>
      <PostItem
        post={announcement}
        setComments={setComments}
        announcementId={announcement.id}
        onPin={() => pinAnnouncement(announcement.id, announcement.pinned)}
        onDelete={() => deleteAnnouncement(announcement.id)}
        accessLevelIds={announcement.accessLevelIds}
        editAnnouncement={editAnnouncement}
      />
      {comments.length > 2 && (
        <SeeMoreButton
          toggleComments={toggleComments}
          length={comments.length}
          showAllComments={showAllComments}
        />
      )}
      <Box
        sx={{
          marginLeft: '3.125rem',
        }}>
        {visibleComments
          .slice()
          .reverse()
          .map((comment) => (
            <PostItem
              userId={user.userId}
              setComments={setComments}
              announcementId={announcement.id}
              key={comment.id}
              post={comment}
              editAnnouncement={editAnnouncement}
              onDelete={() => deleteComment(comment.id)}
            />
          ))}
      </Box>
    </Box>
  );
};

export default Announcement;
