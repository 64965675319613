import {
  Card,
  CardContent,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import { acterioTheme } from 'theme';

interface IDateTag {
  date: string;
  width?: number | string;
  height?: number | string;
  fontSizeMonth?: number | string;
  fontSizeDay?: number | string;
  sx?: SxProps;
}

const DateTag: FunctionComponent<IDateTag> = (props) => {
  const { date, width, height, fontSizeMonth, fontSizeDay, sx } = props;
  const theme = useTheme();

  const dateObject = new Date(date);
  const day = dateObject.getDate();
  const month = dateObject
    .toLocaleString('en-us', { month: 'short' })
    .toUpperCase();

  return (
    <Card
      sx={{
        width: width ?? '60px',
        height: height ?? '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: acterioTheme.pureWhite,
        ...sx,
      }}
      elevation={2}>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography
          sx={{
            fontSize: fontSizeMonth ?? 12,
            color: theme.palette.primary.main,
            marginTop: 1,
            textTransform: 'lowercase',
            '&::first-letter': {
              textTransform: 'capitalize',
            },
          }}>
          {month}
        </Typography>
        <Typography
          sx={{
            fontSize: fontSizeDay ?? 18,
            fontWeight: 'bold',
          }}>
          {day}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DateTag;
