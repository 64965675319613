import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import InputField from 'components/common/InputField';
import { useParams } from 'react-router';
import { APPLICATION_ASSESSMENTS, getData } from 'utils/requests';
import { FlexBox } from 'utils/styledComponents';
import CommonButton from 'components/common/CommonButton';
import Loading from 'components/common/Loading';
import AssessmentPage from './AssessmentPage';
import { IAssessmentApplicantListItem } from 'utils/models';

interface IStartAssessmentProps {}

const StartAssessment: FunctionComponent<IStartAssessmentProps> = (props) => {
  const {} = props;

  const { hashedId } = useParams();

  const { addAlert } = useContext(AlertContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [assessorName, setAssessorName] = useState<string>('');

  const [emailError, setEmailError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const [indexOfSelectedApplicant, setIndexOfSelectedApplicant] =
    useState<number>(0);
  const [listOfApplicants, setListOfApplicants] = useState<
    IAssessmentApplicantListItem[]
  >([]);

  useEffect(() => {
    setIndexOfSelectedApplicant(findActiveApplicant(listOfApplicants));
  }, [listOfApplicants]);

  useEffect(() => {
    if (email && isEmailCorrect(email)) {
      setEmailError(false);
    }
    if (!!assessorName) {
      setNameError(false);
    }
  }, [email, assessorName]);

  function checkObligatoryInfo() {
    if (!email) {
      setEmailError(true);
    } else {
      setEmailError(!isEmailCorrect(email));
    }
    if (!assessorName) {
      setNameError(true);
    }

    return (
      !!email &&
      !!assessorName &&
      !emailError &&
      !nameError &&
      isEmailCorrect(email)
    );
  }

  function isEmailCorrect(email: string) {
    const atSymbolPattern = /@/;
    const fullEmailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    return atSymbolPattern.test(email) && fullEmailPattern.test(email);
  }

  async function startAssessment() {
    const canStartAssessment = checkObligatoryInfo();
    if (!canStartAssessment) return;
    setIsLoading(true);

    try {
      const data = await getData(
        APPLICATION_ASSESSMENTS,
        [
          {
            name: 'hashedId',
            value: hashedId,
          },
          {
            name: 'email',
            value: email,
          },
          {
            name: 'password',
            value: assessorName,
          },
        ],
        true,
      );

      setListOfApplicants(data);
      setIsLoggedIn(true);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Starting assessment...',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function findActiveApplicant(list: IAssessmentApplicantListItem[]) {
    const indexOfActiveApplicant = list.findIndex(
      (item: IAssessmentApplicantListItem) => item.isActive === true,
    );

    return indexOfActiveApplicant ?? 0;
  }

  function renderLoginForm() {
    return (
      <Container
        sx={{
          '& .MuiContainer-root': {
            width: '65%',
          },
        }}>
        <Container
          sx={{
            width: '100%',
            maxWidth: '60rem',
            paddingBottom: '3rem',
          }}>
          <FlexBox
            sx={{
              gap: '2rem',
              flexDirection: 'column',
              alignItems: 'start',
            }}>
            <Typography variant='h2'>Start assessment </Typography>

            <Box sx={{ width: '100%' }}>
              <Typography variant='h3'>
                Please enter your name and email to start the assessment.
              </Typography>
              <Box sx={{ marginY: '2rem' }}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                  }}>
                  <InputField
                    value={email}
                    placeholder={'Please write your email address here...'}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setEmail(event.target.value)
                    }
                    error={emailError}
                    label={'Email address'}
                  />
                </Box>
              </Box>

              <Box sx={{ marginY: '2rem' }}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                  }}>
                  <InputField
                    value={assessorName}
                    placeholder={'Please write your name here...'}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setAssessorName(event.target.value)
                    }
                    label={'Name'}
                    error={nameError}
                  />
                </Box>
              </Box>
            </Box>
            <CommonButton
              text={'Start assessment'}
              onClick={startAssessment}
              bgcolor={'black'}
              sx={{
                borderRadius: '0px',
                marginTop: '1rem',
                width: '12.5rem',
                height: '3rem',
                '&:hover': {
                  backgroundColor: 'black',
                  boxShadow: '0px 4px 4px 0px #00000040',
                },
              }}
            />
          </FlexBox>
        </Container>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        minWidth: '100%',
        minHeight: '100vh',
        backgroundColor: 'white',
        //lines below helps to get rid of padding from parent component
        marginTop: '-3rem',
        marginLeft: '-0.3rem',
        marginRight: '-0.3rem',
        paddingTop: '3rem',
      }}>
      {isLoading ? (
        <Loading />
      ) : isLoggedIn ? (
        <AssessmentPage
          listOfApplicants={listOfApplicants}
          setListOfApplicants={setListOfApplicants}
          email={email}
          assessorName={assessorName}
          indexOfSelectedApplicant={indexOfSelectedApplicant}
          setIndexOfSelectedApplicant={setIndexOfSelectedApplicant}
        />
      ) : (
        renderLoginForm()
      )}
    </Container>
  );
};

export default StartAssessment;
