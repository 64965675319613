import React, { FunctionComponent, useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { useParams } from 'react-router';
import BookingSideMenu, {
  BookingMenuKeys,
} from 'components/Booking/BookingSideMenu';
import { ISideMenuSection } from 'utils/models';
import Loading from 'components/common/Loading';
import Availability from 'components/Booking/Availability/Availability';
import MyBookings from 'components/Booking/PeopleBooking/MyBookings';
import BookingRequests from 'components/Booking/Requests/BookingRequests';
import UpcomingMeetings from 'components/Booking/PeopleUpcoming/UpcomingMeetings';
import RoomBooking from 'components/Booking/RoomBooking/RoomBooking';
import RoomAvailability from 'components/Booking/RoomAvailability/RoomAvailability';
import ManageRooms from 'components/Booking/ManageRooms/ManageRooms';
import BookedRooms from 'components/Booking/RoomsUpcoming/BookedRooms';
import { getEcosystemInfo } from 'utils/storage';
import ConnectCalendarPopup from 'components/Booking/ConnectCalendarPopup';
import { Box } from '@mui/system';
import { useMobileContext } from 'services/contexts/MobileContext';
import {
  EventAvailable,
  MeetingRoom,
  Person,
  QuestionMark,
  Settings,
  SubdirectoryArrowRight,
} from '@mui/icons-material';
import { historyNavigate } from 'utils/utils';

interface IEventsProps {
  defaultMenuOption?: BookingMenuKeys;
}

const Booking: FunctionComponent<IEventsProps> = (props) => {
  const { ecosystemName } = useParams();
  const { defaultMenuOption } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isMobile, setMenuSections, setIsMenuVisible, setSelectedMenuOption } =
    useMobileContext();

  const [isTriggerPopup, setIsTriggerPopup] = useState<boolean>(false);

  const { accessLevel } = getEcosystemInfo();

  useEffect(() => {
    if (!isTriggerPopup) return;

    setIsTriggerPopup(false);
  }, [isTriggerPopup]);

  useEffect(() => {
    if (!isMobile) return;

    setIsMenuVisible(true);
    setMenuSections(sections);
    setSelectedMenuOption(selectedOption);
  }, [isMobile]);

  const getDefaultMenuOption = () => {
    if (defaultMenuOption) {
      return defaultMenuOption;
    } else if (accessLevel.canScheduleMeetings) {
      return BookingMenuKeys.People;
    } else if (accessLevel.canSetAvailability) {
      return BookingMenuKeys.People_Availability;
    } else if (accessLevel.canBookRooms) {
      return BookingMenuKeys.Rooms;
    } else {
      return BookingMenuKeys.Room_Availability;
    }
  };

  const [selectedOption, setSelectedOption] = useState<BookingMenuKeys>(
    getDefaultMenuOption(),
  );

  function setMenuAndMobileChoice(key: BookingMenuKeys) {
    setMenuOption(key);

    if (!isMobile) return;

    setSelectedMenuOption(key);
  }

  function setMenuOption(menuKey: BookingMenuKeys) {
    switch (menuKey) {
      case BookingMenuKeys.People:
        setSelectedOption(BookingMenuKeys.People);
        historyNavigate(`/ecosystem/${ecosystemName}/booking/people/book`);
        break;
      case BookingMenuKeys.Rooms:
        setSelectedOption(BookingMenuKeys.Rooms);
        historyNavigate(`/ecosystem/${ecosystemName}/booking/rooms/book`);
        break;
      case BookingMenuKeys.People_Bookings:
        setSelectedOption(BookingMenuKeys.People_Bookings);
        historyNavigate(`/ecosystem/${ecosystemName}/booking/people/upcoming`);
        break;
      case BookingMenuKeys.Room_Bookings:
        setSelectedOption(BookingMenuKeys.Room_Bookings);
        historyNavigate(`/ecosystem/${ecosystemName}/booking/rooms/upcoming`);
        break;
      case BookingMenuKeys.Requests:
        setSelectedOption(BookingMenuKeys.Requests);
        historyNavigate(`/ecosystem/${ecosystemName}/booking/people/requests`);
        break;
      case BookingMenuKeys.People_Availability:
        setSelectedOption(BookingMenuKeys.People_Availability);
        historyNavigate(
          `/ecosystem/${ecosystemName}/booking/people/availability`,
        );
        break;
      case BookingMenuKeys.Manage_Rooms:
        setSelectedOption(BookingMenuKeys.Manage_Rooms);
        historyNavigate(`/ecosystem/${ecosystemName}/booking/rooms/manage`);
        break;
      case BookingMenuKeys.Room_Availability:
        setSelectedOption(BookingMenuKeys.Room_Availability);
        historyNavigate(
          `/ecosystem/${ecosystemName}/booking/rooms/availability`,
        );
        break;
      default:
        break;
    }
  }

  function renderSection() {
    if (selectedOption === BookingMenuKeys.People_Availability) {
      return <Availability />;
    }

    if (selectedOption === BookingMenuKeys.Requests) {
      return <BookingRequests />;
    }

    if (selectedOption === BookingMenuKeys.People_Bookings) {
      return <UpcomingMeetings />;
    }

    if (selectedOption === BookingMenuKeys.People) {
      return <MyBookings />;
    }

    if (selectedOption === BookingMenuKeys.Rooms) {
      return <RoomBooking />;
    }

    if (selectedOption === BookingMenuKeys.Room_Availability) {
      return <RoomAvailability />;
    }

    if (selectedOption === BookingMenuKeys.Manage_Rooms) {
      return <ManageRooms />;
    }

    if (selectedOption === BookingMenuKeys.Room_Bookings) {
      return <BookedRooms />;
    }

    return <MyBookings />;
  }

  const sections: ISideMenuSection[] = [
    {
      key: BookingMenuKeys.People,
      text: 'People',
      icon: <Person />,
      onClick: () => {
        setMenuAndMobileChoice(BookingMenuKeys.People);
      },
      hasAccess: accessLevel.canScheduleMeetings,
    },
    {
      key: BookingMenuKeys.People_Bookings,
      text: 'Your bookings',
      icon: <EventAvailable />,
      onClick: () => setMenuAndMobileChoice(BookingMenuKeys.People_Bookings),
      hasAccess:
        accessLevel.canScheduleMeetings ||
        accessLevel.canSetAvailability ||
        accessLevel.canBookRooms,
    },
    {
      key: BookingMenuKeys.Requests,
      text: 'Requests',
      icon: <QuestionMark />,
      onClick: () => setMenuAndMobileChoice(BookingMenuKeys.Requests),
      hasAccess:
        accessLevel.canScheduleMeetings || accessLevel.canSetAvailability,
    },
    {
      key: BookingMenuKeys.People_Availability,
      text: 'Your availability',
      icon: <Settings />,
      onClick: () =>
        setMenuAndMobileChoice(BookingMenuKeys.People_Availability),
      hasAccess: accessLevel.canSetAvailability,
    },

    {
      key: BookingMenuKeys.Rooms,
      text: 'Facilities',
      icon: <MeetingRoom />,
      onClick: () => setMenuAndMobileChoice(BookingMenuKeys.Rooms),
      hasAccess: accessLevel.canBookRooms,
    },
    {
      key: BookingMenuKeys.Room_Bookings,
      text: 'Your bookings',
      icon: <EventAvailable />,
      onClick: () => setMenuAndMobileChoice(BookingMenuKeys.Room_Bookings),
      hasAccess: accessLevel.canBookRooms,
    },
    {
      key: BookingMenuKeys.Manage_Rooms,
      text: 'Manage facilities',
      icon: <Settings />,
      onClick: () => setMenuAndMobileChoice(BookingMenuKeys.Manage_Rooms),
      hasAccess: accessLevel.canSetRoomAvailability,
    },
    {
      key: BookingMenuKeys.Room_Availability,
      text: 'Facilities settings',
      icon: <MeetingRoom />,
      onClick: () => setMenuAndMobileChoice(BookingMenuKeys.Room_Availability),
      hasAccess: accessLevel.canSetRoomAvailability,
    },
    {
      key: BookingMenuKeys.Connect,
      text: 'Connect to calendar',
      icon: <SubdirectoryArrowRight />,
      onClick: () => setIsTriggerPopup(true),
      hasAccess: true,
      isSpecial: true,
    },
  ];

  return (
    <Fade in={true} timeout={1000}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          marginTop: '-3rem',
          minHeight: 'calc(100vh - 5rem)',
          justifyContent: 'flex-end',
        }}>
        <ConnectCalendarPopup isTriggerPopup={isTriggerPopup} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            width: '100%',
          }}>
          <Box>
            {!isMobile && (
              <BookingSideMenu
                menuItems={sections}
                selectedOption={selectedOption}
              />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}>
            {isLoading ? (
              <Loading marginTop={90} />
            ) : (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  marginLeft: { xs: '2rem', md: '3rem' },
                  justifyContent: {
                    xs: 'start',
                    sm:
                      selectedOption === BookingMenuKeys.People
                        ? 'center'
                        : 'start',
                  },
                }}>
                {renderSection()}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default Booking;
