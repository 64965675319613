import { LocationCity, LocationOn, PhotoCamera } from '@mui/icons-material';
import { Fade, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import IconAndText from 'components/common/IconAndText';
import ImageCropper from 'components/common/ImageCropper';
import TagAutocomplete from 'components/common/Tags/TagAutocomplete';
import TagDisplay from 'components/common/Tags/TagDisplay';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { naturalPallete, primary } from 'theme';
import { IProfile, ITag } from 'utils/models';
import {
  ACCOUNT_UPLOAD_PIC_ROUTE,
  postData,
} from 'utils/requests';
import { updateAccount } from 'utils/storage';
interface IPhotoSection {
  member: IProfile;
  isEditProfile: boolean;
  updateMember: (key: string, value: any) => void;
  saveEditProfile: () => void;
}

const MemberPhotoSection: FunctionComponent<IPhotoSection> = (props) => {
  const { member, isEditProfile, updateMember, saveEditProfile } = props;

  const navigate = useNavigate();
  const { ecosystemName, profileOwnerId } = useParams();
  const { addAlert } = useContext(AlertContext);
  const location = useLocation();

  const theme = useTheme();

  const [showImageCropper, setShowImageCropper] = useState<boolean>(false);
  const [profilePicInUse, setProfilePicInUse] = useState<string>(member.profilePic);

  const updateProfilePic = async function (croppedAvatar: Blob) {
    const formData = new FormData();
    formData.append('profile_image', croppedAvatar, 'profilePic.png');

    try {
      const accProfilePicData: string = await postData(
        ACCOUNT_UPLOAD_PIC_ROUTE,
        undefined,
        formData,
      );
      updateAccount({
        profilePicture: accProfilePicData,
      });
      saveEditProfile();
      setShowImageCropper(false);
      setProfilePicInUse(accProfilePicData);
      navigate(location.pathname);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  };

  function renderTags() {
    if (!member) return null;

    const usedTags = member.usedTags;
    const unusedTags = member.unusedTags;

    if (!isEditProfile) {
      return <TagDisplay tags={usedTags} />;
    }

    return (
      <div>
        <TagAutocomplete
          usedTags={usedTags}
          tags={unusedTags}
          onTagChange={(updatedTags: ITag[]) => {
            updateMember('usedTags', updatedTags);
          }}
          sx={{
            '& .MuiOutlinedInput-root': !isEditProfile
              ? {
                  '& > fieldset': {
                    border: 'none',
                  },
                }
              : {
                  '& > fieldset': {
                    borderColor:
                      usedTags.length === 0 ? primary.warningRed : undefined,
                  },
                },
            borderColor: 'red',
            width: '100%',
            minHeight: '12.5rem',
            input: {
              '&::placeholder': {
                color: 'black',
              },
            },
          }}
        />
      </div>
    );
  }

  function renderCompanies() {
    if (!member) return null;

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            opacity: isEditProfile ? 0.4 : 1,
            display: 'flex',
            flexDirection: 'column',
          }}>
          {!member.companyNameAndLogoList ||
          !member.companyNameAndLogoList.length ? (
            <Typography>No companies</Typography>
          ) : (
            member.companyNameAndLogoList.map((company, i) => (
              <Box
                key={company.name}
                sx={{
                  mb: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <img
                  key={`company-${i}`}
                  src={company.logo}
                  style={{
                    height: '6rem',
                    maxHeight: '6rem',
                    cursor: !isEditProfile ? 'pointer' : 'default',
                  }}
                  onClick={() => {
                    if (isEditProfile) return;
                    navigate(
                      `/ecosystem/${ecosystemName}/companies/${company.id}`,
                    );
                  }}
                />
                <Typography
                  sx={{ color: naturalPallete.natural3, mt: '0.25rem' }}>
                  {company.name}
                </Typography>
              </Box>
            ))
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '-10rem',
        pb: '6rem',
      }}>
      <Box>
        <img
          src={profilePicInUse}
          alt='profile picture'
          style={{
            aspectRatio: 1,
            width: '20rem',
            borderRadius: '100%',
            cursor: isEditProfile ? 'pointer' : 'default',
          }}
          onClick={isEditProfile ? () => setShowImageCropper(true) : undefined}
        />
        <Fade in={isEditProfile}>
          <Box
            sx={{
              height: '2.5rem',
              width: '2.5rem',
              minWidth: '2rem',
              position: 'relative',
              top: '-4.5rem',
              left: '17.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              backgroundColor: theme.palette.primary.light,
              border: '2px solid white',
            }}
            onClick={
              isEditProfile ? () => setShowImageCropper(true) : undefined
            }>
            <PhotoCamera
              sx={{
                height: '1.5rem',
                width: '1.5rem',
                color: theme.palette.primary.dark,
                cursor: isEditProfile ? 'pointer' : 'default',
              }}
            />
          </Box>
        </Fade>
      </Box>
      <ImageCropper
        showImageCropper={showImageCropper}
        setShowImageCropper={setShowImageCropper}
        uploadFunction={updateProfilePic}
        aspectRatioHeight={1}
        aspectRatioWidth={1}
        roundCropper
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          mt: '1.25rem',
          width: '80%',
        }}>
        <AccessLevelTagCard
          accessLevelName={member?.accessLevelName}
          accessLevelColor={member?.accessLevelColor}
        />
        <Box sx={{ width: '100%', mt: '0.75rem' }}>
          <IconAndText
            icon={<LocationOn />}
            text={member?.country}
            updateText={(value: string) => {
              updateMember('country', value);
            }}
            isEdit={isEditProfile}
          />
          <IconAndText
            icon={<LocationCity />}
            text={member?.city}
            updateText={(value: string) => {
              updateMember('city', value);
            }}
            isEdit={isEditProfile}
          />
        </Box>
        <Box sx={{ width: '100%', mt: '2rem' }}>{renderTags()}</Box>
        <Box sx={{ width: '100%', mt: '2rem' }}>{renderCompanies()}</Box>
      </Box>
    </Box>
  );
};

export default MemberPhotoSection;
