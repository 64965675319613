import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import { Droppable } from 'react-beautiful-dnd';
import DraggableAccessLevelUser from './DraggableAccessLevelUser';
import { IAccessLevelDto } from 'utils/models';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: (props: any) =>
      props.index % 2 === 0 ? 'white' : 'transparent',
    minHeight: '31.25rem',
    width: '220px',
    minWidth: '220px',
    borderRadius: '4px',
    padding: '1.5rem 0.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    marginBottom: '1.5rem',
    textAlign: 'center',

    '& h6': {
      fontSize: '1rem !important',
    },
  },
  cont: {
    marginTop: '1rem',
  },
}));

interface IDroppableAccessLevel {
  accessLevelDto: IAccessLevelDto;
  index: number;
  ecosystemName: string;
}

const DroppableAccessLevel: FunctionComponent<IDroppableAccessLevel> = (
  props,
) => {
  const { accessLevelDto, index, ecosystemName } = props;
  const { accessLevel, usersAndId } = accessLevelDto;
  const { id, accessLevelName } = accessLevel;
  const classes = useStyles({ index });

  function renderUsers() {
    return usersAndId.map(({ id, name }, i) => (
      <DraggableAccessLevelUser
        key={`draggable-access-level-${id}}`}
        id={id}
        name={name}
        index={i}
        accessLevelIndex={index}
        ecosystemName={ecosystemName}
      />
    ));
  }

  return (
    <Droppable droppableId={`droppable-${id}`}>
      {(provided, snapshot) => (
        <div
          className={classes.root}
          {...provided.droppableProps}
          ref={provided.innerRef}>
          <div className={classes.header}>
            <Typography variant={'h6'}>{accessLevelName}</Typography>
          </div>
          <div className={classes.cont}>{renderUsers()}</div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableAccessLevel;
