import { IEvent, RecurringOption } from 'utils/models';
import { EVENT_ROUTE, deleteData } from 'utils/requests';
import { useParams } from 'react-router';
import { useEventsContext } from 'services/contexts/Events/EventsContext';
import { updateEvents } from 'utils/storage';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useContext } from 'react';

interface IDeleteProps {
  event: IEvent;
  setIsLoading: (b: boolean) => void;
  handleClose: () => void;
  recurringOption?: RecurringOption;
}

const useDeleteEvent = (props: IDeleteProps) => {
  const { event, setIsLoading, handleClose, recurringOption } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const { events, setEvents } = useEventsContext();

  async function deleteEvent() {
    setIsLoading(true);
    try {
      await deleteData(EVENT_ROUTE, [
        {
          name: 'id',
          value: event.id,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
        {
          name: 'recurringOption',
          value: recurringOption ?? '',
        },
      ]);

      if (event.id === undefined) {
        console.error('Event id is undefined');
      } else {
        let updatedEvents = null;

        if (recurringOption === RecurringOption.ALL) {
          updatedEvents = (events as IEvent[]).filter(
            ({ id }) =>
              id?.toString().split('_')[0] !==
              event.id?.toString().split('_')[0],
          );
        } else if (recurringOption === RecurringOption.FUTURE) {
          const baseRecurringId = event.id?.toString().split('_')[0];
          const eventStartDate = new Date(event.startDate);

          updatedEvents = (events as IEvent[])
            .filter(
              ({ id }) =>
                id?.toString().split('_')[0] !==
                event.id?.toString().split('_')[0],
            )
            .concat(
              (events as IEvent[]).filter(({ id, startDate }) => {
                const eventIdBase = id?.toString().split('_')[0];
                return (
                  eventIdBase === baseRecurringId &&
                  new Date(startDate) < eventStartDate
                );
              }),
            );
        } else {
          updatedEvents = (events as IEvent[]).filter(
            ({ id }) => id !== event.id,
          );
        }

        setEvents(updatedEvents);
        updateEvents(updatedEvents);
      }

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        title: 'Event Deleted',
        message: 'Event Deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
    handleClose();
  }

  return deleteEvent;
};

export default useDeleteEvent;
