import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import React, { FunctionComponent, useContext, useState } from 'react';
import { primary } from 'theme';
import MoreOptionsOverlay from 'components/common/MoreOptionsButton/MoreOptionsOverlay';
import { IApplicationAssessmentTemplateCard, IListButton } from 'utils/models';
import { ContentCopyRounded, DoubleArrowRounded } from '@mui/icons-material';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';
import { useNavigate, useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { formatDateEuropean } from 'utils/utils';
import { getEcosystemInfo } from '../../../../utils/storage';
import { useAssessmentContext } from 'services/contexts/Applications/AssessmentContext';
import { AdminMenuKeys } from 'components/Admin/AdminSideMenu';
import { useApplicationMenuContext } from 'services/contexts/Applications/ApplicationMenuContext';

interface IAssessmentProcessCardProps {
  process: IApplicationAssessmentTemplateCard;
}

const AssessmentProcessCard: FunctionComponent<
  IApplicationAssessmentTemplateCard
> = (props) => {
  const {
    active,
    id,
    assessmentName,
    applicationProcessName,
    assessmentUrl,
    createdDate,
    updatedDate,
    numberOfApplicantsWithAssessments,
    numberOfCompletedAssessments,
    statusName,
  } = props;
  const navigate = useNavigate();
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const { setSelectedReportId } = useAssessmentContext();

  const { setSelectedOption, setIsComingFromAssessmentPage } =
    useApplicationMenuContext();

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const isActive = active;

  const buttonList: IListButton[] = [
    {
      name: 'Copy link',
      onClick: copyText,
      icon: ContentCopyRounded,
    },
  ];

  function copyText() {
    navigator.clipboard.writeText(assessmentUrl);
    addAlert({
      type: ALERT_TYPES.SUCCESS,
      message: 'Link copied successfully!',
    });
    setPopoverAnchorEl(null);
  }

  function handleOpenReport() {
    if (!id) return;
    setSelectedReportId(id);
    setIsComingFromAssessmentPage(true);
    setSelectedOption(AdminMenuKeys.AssessmentReports);
  }

  return (
    <Box
      sx={{
        width: '26rem',
        height: '26rem',
        dropShadow: '0px 0px 4px 0px #000000',
        borderRadius: '30px',
        padding: '1.563rem 1.875rem',
        margin: '0.5rem',
        bgcolor: primary.additionalLightBlue,
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '23.5rem',
        }}>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '0.875rem',
            lineHeight: '1.313rem',
          }}>
          {formatDateEuropean(new Date(createdDate), 'short')}
        </Typography>
        {getEcosystemInfo().accessLevel.canManageApplications && (
          <MoreOptionsOverlay
            sx={{ bgcolor: 'inherit' }}
            popoverAnchorEl={popoverAnchorEl}
            setPopoverAnchorEl={setPopoverAnchorEl}>
            <MoreOptionsButtons buttonList={buttonList} />
          </MoreOptionsOverlay>
        )}
      </Box>
      <Box sx={{ minHeight: '4.5rem', height: '4.5rem' }}>
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '1.25rem',
            lineHeight: '2.344rem',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
          }}>
          {applicationProcessName}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontWeight: '600',
          fontSize: '1.25rem',
          lineHeight: '2rem',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 1,
          overflow: 'hidden',
          color: primary.natural3,
          mb: '3rem',
        }}>
        Stage: {statusName}
      </Typography>
      <Box
        sx={{
          width: '21.875rem',
          height: '5.2rem',
          backgroundColor: '#fff',
          borderRadius: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Box>
          <Typography
            sx={{
              fontSize: '1.75rem',
              margin: '0.5rem',
              fontWeight: 'bold',
              color: isActive ? primary.successGreen : primary.natural5,
            }}>
            {isActive ? 'Active' : 'Inactive'}
          </Typography>
        </Box>
        {isActive && (
          <Box sx={{ mr: '0.5rem' }}>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '1.563rem',
                color: 'black',
                textAlign: 'center',
              }}>
              {numberOfApplicantsWithAssessments}
            </Typography>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '1rem',
                color: 'black',
                textAlign: 'center',
              }}>
              applicants
            </Typography>
            <Typography
              sx={{
                color: '#4D4D4D',
                fontWeight: '500',
                fontSize: '0.8rem',
                textAlign: 'center',
              }}>
              in process
            </Typography>
          </Box>
        )}
      </Box>
      <Button
        onClick={handleOpenReport}
        sx={{
          width: '21.875rem',
          height: '2rem',
          color: 'black',
          fontSize: '1rem',
          fontWeight: '600',
          paddingY: '1.3rem',
          mt: '1.75rem',
          bgcolor: primary.soft,
        }}>
        <DoubleArrowRounded /> Review ratings
      </Button>
    </Box>
  );
};

export default AssessmentProcessCard;
