import React, { FunctionComponent } from 'react';
import { useFieldArray } from 'react-hook-form';
import InputField from '../../common/InputField';
import { FlexBox } from '../../../utils/styledComponents';
import { ControlButtonStyled } from './ApplicationBuilder';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Typography } from '@mui/material';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { IApplicationTemplate } from '../../../utils/models';
import CloseIcon from '@mui/icons-material/Close';

interface OptionsProps {
  control: any;
  register: UseFormRegister<any>;
  errors: FieldErrors<IApplicationTemplate>;
  sectionIndex: number;
  questionIndex: number;
  resetField: any;
}

const Options: FunctionComponent<OptionsProps> = (props) => {
  const { sectionIndex, questionIndex, control, register, errors, resetField } =
    props;

  const {
    fields: optionsFields,
    append: appendOption,
    remove: removeOption,
  } = useFieldArray({
    name: `sections.${sectionIndex}.questions.${questionIndex}.dropdownOptions`,
    control,
  });

  const staticErrorPath =
    errors?.sections?.[sectionIndex]?.questions?.[questionIndex]
      ?.dropdownOptions?.[0]?.name;

  return (
    <FlexBox
      sx={{
        flexDirection: 'column',
        alignItems: 'start',
        gap: '0.5rem',
        marginTop: '0.5rem',
      }}>
      <FlexBox>
        <InputField
          helperText={staticErrorPath && staticErrorPath.message}
          helperIcon={errors.sections && 'warning'}
          error={!!staticErrorPath}
          placeholder={'Enter an option'}
          maxLength={255}
          {...register(
            `sections.${sectionIndex}.questions.${questionIndex}.dropdownOptions.0.name`,
            { required: 'Please, enter an option' },
          )}
        />
        <ControlButtonStyled
          sx={{
            marginBottom: staticErrorPath ? '1.7rem' : '',
          }}
          disabled={true}>
          <CloseIcon />
        </ControlButtonStyled>
      </FlexBox>
      {optionsFields.map((option, optionIndex: number) => {
        if (optionIndex === 0) return null;

        const errorPath =
          errors?.sections?.[sectionIndex]?.questions?.[questionIndex]
            ?.dropdownOptions?.[optionIndex]?.name;

        return (
          <FlexBox key={option.id}>
            <InputField
              helperText={errorPath && errorPath.message}
              helperIcon={errors.sections && 'warning'}
              error={!!errorPath}
              placeholder={'Enter an option'}
              {...register(
                `sections.${sectionIndex}.questions.${questionIndex}.dropdownOptions.${optionIndex}.name`,
                { required: 'Please, enter an option' },
              )}
            />
            <ControlButtonStyled
              sx={{
                marginBottom: errorPath ? '1.7rem' : '',
              }}
              onClick={() => removeOption(optionIndex)}>
              <CloseIcon />
            </ControlButtonStyled>
          </FlexBox>
        );
      })}
      <ControlButtonStyled
        sx={{
          marginTop: '0.25rem',
        }}
        onClick={() =>
          appendOption({
            name: '',
          })
        }>
        <AddCircleIcon />{' '}
        <Typography
          sx={{
            marginLeft: '0.625rem',
          }}>
          Add option
        </Typography>
      </ControlButtonStyled>
    </FlexBox>
  );
};

export default Options;
