import React, { FunctionComponent } from 'react';

interface IAvatarProps {
  source: string;
  height: number | string;
  width: number | string;
  radius: number;
  widthAuto?: boolean | string;
  heightAuto?: boolean | string;
  maxHeight?: number | string;
  maxWidth?: number | string;
  border?: boolean;
  fullSize?: boolean;
  onClick?: () => void;
  className?: React.CSSProperties; // Optional className prop as a style object
}

const Avatar: FunctionComponent<IAvatarProps> = (props) => {
  const {
    source,
    height,
    width,
    radius,
    widthAuto,
    heightAuto,
    maxHeight,
    maxWidth,
    border,
    fullSize,
    onClick,
    className, // Destructure the className prop
  } = props;

  // Merge the provided className style object with the default styles
  const mergedStyles: React.CSSProperties = {
    borderRadius: `${radius}px`,
    maxHeight: maxHeight ?? undefined,
    maxWidth: maxWidth ?? undefined,
    border: border ? '1px solid #e0e0e0' : 'none',
    cursor: 'pointer',
    ...className,
    height: fullSize ? '100%' : undefined,
    width: fullSize ? '100%' : undefined,
  };

  return (
    <img
      onClick={onClick}
      src={source}
      height={heightAuto ? 'auto' : height}
      width={widthAuto ? 'auto' : width}
      style={mergedStyles}
    />
  );
};

export default Avatar;
