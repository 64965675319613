import React, { FunctionComponent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material';
import Modal from '../common/Modal';
import { HOME_NEW_ANNOUNCEMENT_ROUTE, postFormData } from 'utils/requests';
import { useParams } from 'react-router';
import { IAnnouncement } from 'utils/models';
import { storeAnnouncements, updateHome } from 'utils/storage';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import RichTextEditor from '../common/RichTextEditor';
import UploadImage from '../common/UploadImage';
import SelectAccessLevels from '../common/SelectAccessLevels';
import { useMobileContext } from 'services/contexts/MobileContext';
import EmailUsersCheckbox from '../common/EmailUsersCheckbox';
import { Box } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0.55rem',
    maxWidth: '490px',
    gap: '10px',
    '& h6': {
      marginBottom: '5px',
    },
  },
}));

interface ICreateNewAnnouncement {
  open: boolean;
  close: () => void;
  announcements: IAnnouncement[];
  setAnnouncements: (announcement: IAnnouncement[]) => void;
}

const CreateNewAnnouncement: FunctionComponent<ICreateNewAnnouncement> = (
  props,
) => {
  const { open, close, announcements, setAnnouncements } = props;
  const { ecosystemName } = useParams();
  const { isMobile } = useMobileContext();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const [title, setTitle] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [accessLevels, setAccessLevels] = useState<number[]>([]);
  const [sendEmails, setSendEmails] = useState<boolean>(false);

  const isAllRequiredFilled =
    body.trim().length > 0 && body !== '<p><br></p>' && accessLevels.length > 0;

  async function saveNewAnnouncement() {
    setLoading(true);
    try {
      const formData = new FormData();

      const announcementRequest = JSON.stringify({
        title,
        body,
        ecosystemName,
        accessLevelIds: accessLevels,
        sendEmails: sendEmails,
        type: 'announcement',
      });

      formData.append(
        'announcementRequest',
        new Blob([announcementRequest], { type: 'application/json' }),
      );

      if (imageUrl) {
        formData.append('image', imageUrl);
      }

      const data = await postFormData(HOME_NEW_ANNOUNCEMENT_ROUTE, formData);

      const updated = [
        ...announcements.filter(({ pinned }) => pinned),
        data,
        ...announcements.filter(({ pinned }) => !pinned),
      ];

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Announcement added',
      });

      setAnnouncements(updated);
      setTitle('');
      setBody('');
      setAccessLevels([]);
      updateHome({
        announcements: updated,
      });
      storeAnnouncements(updated);

      close();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function renderContent() {
    return (
      <div className={classes.main}>
        <Box
          sx={{
            minHeight: '200px',
            marginBottom: '35px',
            marginTop: '25px',
            position: 'relative',
          }}>
          <RichTextEditor
            value={body}
            onChange={(value: string) => {
              setBody(value);
            }}
            readonly={loading}
            height={170}
            maxLength={10000}
          />
        </Box>
        <SelectAccessLevels
          accessLevelIds={accessLevels}
          onAccessGroupChange={setAccessLevels}
        />
        <UploadImage
          width='100%'
          onUpload={(file) => setImageUrl(file)}
          height={200}
          borderColor={theme.palette.primary.dark}
          hideBorder
          full
          showButtons={true}
          onRemove={() => setImageUrl(null)}
          crop={true}
          aspectRatioWidth={3}
          aspectRatioHeight={1}
        />
        <EmailUsersCheckbox
          name='announcement'
          sendEmails={sendEmails}
          setSendEmails={setSendEmails}
        />
      </div>
    );
  }

  return (
    <Modal
      width={isMobile ? 360 : 550}
      title={'Create new announcement'}
      open={open}
      close={close}
      showFooter={true}
      largeFooter={true}
      headerColor={'white'}
      headerBorder={true}
      footerButtonsFlexStart={true}
      loading={loading}
      disabledSave={!isAllRequiredFilled}
      isAllRequiredFilled={isAllRequiredFilled}
      saveButtonText={'Post'}
      saveAction={() => saveNewAnnouncement()}
      backgroundColor={'white'}>
      {renderContent()}
    </Modal>
  );
};

export default CreateNewAnnouncement;
