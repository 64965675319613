import React, { FunctionComponent, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Box } from '@mui/system';
import { FlexBox } from 'utils/styledComponents';
import InputField from 'components/common/InputField';
import CloseIcon from '@mui/icons-material/Close';
import { ApplicationQuestionTypes, IApplicationTemplate } from 'utils/models';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Tooltip, Typography, useTheme } from '@mui/material';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { ControlButtonStyled } from './ApplicationBuilder';
import { primary } from 'theme';
import Options from './Options';
import ApplicationBuilderTagGroupsSelect from './ApplicationBuilderTagGroupsSelect';
import ForwardedSelectDropdown from './ApplicationBuilderSelect';
import WarningDeleteModal from './WarningDeleteModal';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface IApplicationQuestionsProps {
  control: any;
  register: UseFormRegister<any>;
  errors: FieldErrors<IApplicationTemplate>;
  sectionIndex: number;
  formData: IApplicationTemplate;
  resetField: any;
  applicantsInProgress: number;
  newSections: number[];
  isEdit?: boolean;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
}

export const ApplicationQuestionTypeLabels: {
  [key in ApplicationQuestionTypes]: string;
} = {
  [ApplicationQuestionTypes.TEXT]: 'Single-line input (default)',
  [ApplicationQuestionTypes.TEXT_AREA]: 'Text area',
  [ApplicationQuestionTypes.DATE]: 'Date-picker',
  [ApplicationQuestionTypes.FILE]: 'File uploader',
  [ApplicationQuestionTypes.TAGS]: 'Tags',
  [ApplicationQuestionTypes.CHECKBOX]: 'Multiple choice selection',
  [ApplicationQuestionTypes.RADIO]: 'Single choice selection',
  [ApplicationQuestionTypes.LINK]: 'Web link',
};

export const ApplicationBuilderQuestions: FunctionComponent<
  IApplicationQuestionsProps
> = (props) => {
  const {
    control,
    register,
    sectionIndex,
    errors,
    formData,
    resetField,
    applicantsInProgress,
    newSections,
    isEdit,
    setValue,
    getValues,
  } = props;
  const theme = useTheme();
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
  const [focusedQuestionIndex, setFocusedQuestionIndex] = useState<number>();
  const [newQuestions, setNewQuestions] = useState<number[]>([]);
  const selectedTypes = [
    ApplicationQuestionTypes.TEXT,
    ApplicationQuestionTypes.TEXT_AREA,
    ApplicationQuestionTypes.FILE,
    ApplicationQuestionTypes.TAGS,
    ApplicationQuestionTypes.RADIO,
    ApplicationQuestionTypes.CHECKBOX,
    ApplicationQuestionTypes.LINK,
  ];
  const {
    fields: questionFields,
    append: appendQuestion,
    remove: removeQuestion,
  } = useFieldArray<any>({
    name: `sections.${sectionIndex}.questions`,
    control,
  });

  const isShowHideButton = isEdit && applicantsInProgress > 0;

  const handleAddQuestion = () => {
    appendQuestion({
      question: '',
      description: '',
      type: ApplicationQuestionTypes.TEXT,
    });
    setNewQuestions([...newQuestions, questionFields.length]); // Добавьте индекс нового вопроса в состояние
  };

  function handleHideQuestion(questionIndex: number) {
    const isCurrentlyHidden = getValues(
      `sections.${sectionIndex}.questions.${questionIndex}.isHidden`,
    );
    setValue(
      `sections.${sectionIndex}.questions.${questionIndex}.isHidden`,
      !isCurrentlyHidden,
    );
  }

  return (
    <>
      <WarningDeleteModal
        isWarningModalOpen={isWarningModalOpen}
        setIsWarningModalOpen={setIsWarningModalOpen}
        actionOnConfirm={() => {
          removeQuestion(focusedQuestionIndex);
          setIsWarningModalOpen(false);
        }}
      />
      {questionFields.map((question, questionIndex) => {
        const errorPath =
          errors?.sections?.[sectionIndex]?.questions?.[questionIndex]
            ?.question;
        const tagsErrorPath =
          errors?.sections?.[sectionIndex]?.questions?.[questionIndex]?.tagGroup
            ?.id;
        const isTags =
          formData?.sections[sectionIndex].questions[questionIndex].type ===
          ApplicationQuestionTypes.TAGS;
        const isDropDown =
          formData?.sections[sectionIndex].questions[questionIndex].type ===
          ApplicationQuestionTypes.CHECKBOX;
        const isRadio =
          formData?.sections[sectionIndex].questions[questionIndex].type ===
          ApplicationQuestionTypes.RADIO;

        const isQuestionHidden = getValues(
          `sections.${sectionIndex}.questions.${questionIndex}.isHidden`,
        );

        return (
          <Box
            key={question.id}
            sx={{
              paddingLeft: '3.125rem',
              marginTop: '1.25rem',
            }}>
            <FlexBox
              sx={{
                gap: '1.563rem',
              }}>
              <FlexBox
                className={'center'}
                sx={{
                  width: '30px',
                  height: '30px',
                  backgroundColor: theme.palette.primary.main,
                  color: primary.pureWhite,
                  borderRadius: '100%',
                }}>
                {questionIndex + 1}
              </FlexBox>

              <FlexBox
                sx={{
                  flexDirection: 'column',
                  alignItems: 'start',
                  borderRadius: '12px',
                  boxShadow: theme.shadows[1],
                  padding: '1.563rem 1.875rem',
                  width: '100%',
                }}>
                <FlexBox
                  className={'between'}
                  sx={{
                    alignItems: 'start',
                    width: '100%',
                    gap: '1.625rem',
                  }}>
                  <InputField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        opacity:
                          sectionIndex === 0 && questionIndex < 2
                            ? '200%'
                            : '100%',
                        '& .MuiInputBase-input': {
                          WebkitTextFillColor: primary.mainBlackFont,
                        },
                      },
                    }}
                    {...register(
                      `sections.${sectionIndex}.questions.${questionIndex}.question`,
                      { required: 'Please, type the question' },
                    )}
                    helperText={errorPath && errorPath.message}
                    helperIcon={errors.sections && 'warning'}
                    error={!!errorPath}
                    maxLength={255}
                    placeholder={'Type in question'}
                    width={'70%'}
                    clearBackground
                  />
                  <Controller
                    control={control}
                    name={`sections.${sectionIndex}.questions.${questionIndex}.type`}
                    render={({ field }) => (
                      <ForwardedSelectDropdown
                        disabled={
                          applicantsInProgress > 0 &&
                          !newQuestions.includes(questionIndex) &&
                          !newSections.includes(sectionIndex)
                        }
                        {...field}
                        options={selectedTypes}
                        getOptionLabel={(option: ApplicationQuestionTypes) =>
                          ApplicationQuestionTypeLabels[option]
                        }
                        value={field.value}
                        onChange={(value) => {
                          resetField(
                            `sections.${sectionIndex}.questions.${questionIndex}.dropdownOptions`,
                          );
                          resetField(
                            `sections.${sectionIndex}.questions.${questionIndex}.tagGroup`,
                          );
                          field.onChange(value);
                        }}
                        sx={{
                          boxShadow: theme.shadows[2],
                          height: '2.5rem',
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                            boxShadow: 'inset 0 0 0 0.063rem transparent',
                            transition: 'all .3s ease',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
                            {
                              boxShadow:
                                applicantsInProgress > 0
                                  ? ''
                                  : `inset 0 0 0 0.063rem ${theme.palette.primary.main}`,
                            },
                        }}
                      />
                    )}
                  />
                  {isShowHideButton && (
                    <ControlButtonStyled
                      onClick={() => handleHideQuestion(questionIndex)}>
                      <Tooltip
                        title={
                          'Hide the question - hiding the question will make it disappear from future forms, but will preserve answers on the already filled out application forms'
                        }>
                        {isQuestionHidden ? <VisibilityOff /> : <Visibility />}
                      </Tooltip>
                    </ControlButtonStyled>
                  )}
                  <ControlButtonStyled
                    disabled={sectionIndex === 0 && questionIndex < 1}
                    onClick={() => {
                      if (applicantsInProgress > 0) {
                        setFocusedQuestionIndex(questionIndex);
                        setIsWarningModalOpen(true);
                      } else {
                        removeQuestion(questionIndex);
                      }
                    }}>
                    <CloseIcon />
                  </ControlButtonStyled>
                </FlexBox>
                <FlexBox
                  sx={{
                    marginTop: errorPath ? '0.5rem' : '1.25rem',
                    alignItems: 'start',
                    width: '100%',
                    //width: isTags ? '80%' : '100%',
                    gap: '1.625rem',
                  }}>
                  <InputField
                    {...register(
                      `sections.${sectionIndex}.questions.${questionIndex}.description`,
                    )}
                    placeholder={'Description'}
                    maxLength={2000}
                    clearBackground
                    multiline
                    width={'70%'}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        minHeight: '2.5rem',
                        padding: '0.438rem 0 0.313rem 0',
                        '& .MuiInputBase-input': {},
                      },
                    }}
                  />
                  {isTags && (
                    <ApplicationBuilderTagGroupsSelect
                      resetField={resetField}
                      sectionIndex={sectionIndex}
                      questionIndex={questionIndex}
                      control={control}
                      tagsErrorPath={tagsErrorPath}
                    />
                  )}
                </FlexBox>

                {(isDropDown || isRadio) && (
                  <Options
                    resetField={resetField}
                    errors={errors}
                    sectionIndex={sectionIndex}
                    questionIndex={questionIndex}
                    control={control}
                    register={register}
                  />
                )}
              </FlexBox>
            </FlexBox>
          </Box>
        );
      })}
      <ControlButtonStyled
        sx={{
          marginTop: '0.25rem',
          paddingLeft: '6.525rem',
        }}
        onClick={handleAddQuestion}>
        <AddCircleIcon />{' '}
        <Typography
          sx={{
            marginLeft: '0.625rem',
          }}>
          Add a field
        </Typography>
      </ControlButtonStyled>
    </>
  );
};
