import * as React from 'react';
import { SVGProps } from 'react';

type IEventBackgroundBlur = SVGProps<SVGSVGElement>

const EventBackgroundBlur = (props: IEventBackgroundBlur) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    style={{ position: 'absolute', bottom: 0, left: 0 }}
    width='563'
    height='464'
    viewBox='0 0 563 464'
    fill='none'>
    <g filter='url(#filter0_bd_397_12643)'>
      <path
        d='M-0.000976562 25.9205C-0.000976562 19.337 -0.000208616 25.9205 -0.000543475 14H329.692L532.737 217.309C538.356 222.935 540.077 231.378 537.121 238.759C534.452 245.424 535.404 243.053 533.311 248.266L454.644 464H4.5H2.5H-0.000976562V25.9205Z'
        fill='#1B1C1C'
        fillOpacity='0.7'
        shapeRendering='crispEdges'
      />
    </g>
    <defs>
      <filter
        id='filter0_bd_397_12643'
        x='-11.9279'
        y='0.0853013'
        width='574.329'
        height='475.842'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation='5.96344' />
        <feComposite
          in2='SourceAlpha'
          operator='in'
          result='effect1_backgroundBlur_397_12643'
        />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dx='21.866' dy='-11.9269' />
        <feGaussianBlur stdDeviation='0.993907' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.0941176 0 0 0 0 0.180392 0 0 0 0 0.305882 0 0 0 0.25 0'
        />
        <feBlend
          mode='normal'
          in2='effect1_backgroundBlur_397_12643'
          result='effect2_dropShadow_397_12643'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect2_dropShadow_397_12643'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);

export default EventBackgroundBlur;
