import { useContext, useEffect, useState } from 'react';
import { getData, JOURNEY_TEMPLATES_ROUTE } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { IJourney } from 'utils/models';

const useJourneyTemplates = () => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [journeys, setJourneys] = useState<IJourney[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchJourneys();
  }, [ecosystemName]);

  async function fetchJourneys() {
    if (!ecosystemName) return null;
    setLoading(true);

    try {
      const data = await getData(JOURNEY_TEMPLATES_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setJourneys(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return {
    loading,
    journeys,
  };
};

export default useJourneyTemplates;
