import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme, Typography, useTheme } from '@mui/material';
import Modal from 'components/common/Modal';
import InputField from 'components/common/InputField';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { IAccessLevelDto } from 'utils/models';
import { formatNumOf } from 'utils/utils';
import ColorPicker from 'components/common/ColorPicker';
import {
  ACCESS_CONTROL_DELETE_LEVEL_ROUTE,
  ACCESS_CONTROL_EDIT_LEVEL_ROUTE,
  deleteData,
  putData,
} from 'utils/requests';
import { useParams } from 'react-router';
import SelectDropdown from 'components/common/SelectDropdown';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: '4rem',
  },
  cont: {
    display: 'flex',
    justifyContent: 'space-between',

    '& h6': {
      marginRight: '10px !important',
      marginTop: '5px !important',
      minWidth: '78px !important',
    },
  },
  input: {
    maxWidth: '28.75rem',
    minWidth: '28.75rem',
  },
  accessLevel: {
    backgroundColor: 'white',
    padding: '1rem',
    maxWidth: '16.875rem',
    minWidth: '16.875rem',
    height: '15rem',
    maxHeight: '15rem',
    borderRadius: '4px',
    border: '2px solid #e0e0e0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    textAlign: 'center',

    '& h6': {
      fontSize: '1.1rem !important',
    },

    '& p': {
      fontSize: '0.9rem !important',
    },

    '& button': {
      marginTop: '15px !important',
      minWidth: '28px !important',
      width: '28px !important',
      height: '28px !important',
      maxHeight: '28px !important',
      borderRadius: '50% !important',
      backgroundColor: `${theme.palette.primary.light} !important`,

      '& svg': {
        height: 20,
        width: 20,
        color: theme.palette.primary.dark,
      },
    },
  },
  desc: {
    padding: '0 0.75rem',
    marginTop: '10px',

    '& span': {
      fontSize: '0.75rem',
      color: theme.palette.primary.main,
    },
  },
  dropdown: {
    maxWidth: '15.625rem',
    minWidth: '15.625rem',
    marginTop: '0.469rem',
  },
}));

interface IAccessLevelProps {
  accessLevelDto: IAccessLevelDto;
  accessLevelDtos: IAccessLevelDto[];
  setAccessLevelDtos: (accessLevelDtos: IAccessLevelDto[]) => void;
  getAccessLevelId: (name: string) => number;
  isDefault: boolean;
}

const AccessLevel: FunctionComponent<IAccessLevelProps> = (props) => {
  const {
    accessLevelDto,
    accessLevelDtos,
    setAccessLevelDtos,
    getAccessLevelId,
    isDefault,
  } = props;
  const { accessLevel, numberOfUsers } = accessLevelDto;
  const { id, accessLevelName, description, color } = accessLevel;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const classes = useStyles();
  const theme = useTheme();

  const haveAccessLevels = accessLevelDtos.length > 1;
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [newTemplate, setNewTemplate] = useState<string>(
    haveAccessLevels ? accessLevelDtos[0].accessLevel.accessLevelName : '',
  );
  const [editedName, setEditedName] = useState<string>(accessLevelName);
  const [editedDescription, setEditedDescription] =
    useState<string>(description);
  const [editedColor, setEditedColor] = useState<string>(color);

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (!showEdit && showConfirmDelete) {
      interval = setInterval(() => {
        setShowConfirmDelete(false);
      }, 500);
    }

    return () => clearInterval(interval);
  }, [showEdit]);

  async function deleteAccessLevel() {
    setLoading(true);

    try {
      await deleteData(ACCESS_CONTROL_DELETE_LEVEL_ROUTE, [
        { name: 'id', value: id },
        {
          name: 'accessLevelToAssignUserToId',
          value: getAccessLevelId(newTemplate),
        },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setAccessLevelDtos(
        accessLevelDtos.filter((a) => a.accessLevel.id !== id),
      );

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Access level deleted',
      });

      setShowEdit(false);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  async function updateAccessLevel() {
    setLoading(true);

    try {
      await putData(ACCESS_CONTROL_EDIT_LEVEL_ROUTE, [
        { name: 'id', value: id },
        { name: 'name', value: editedName },
        { name: 'description', value: editedDescription },
        { name: 'color', value: encodeURIComponent(editedColor) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      const updatedLevels = accessLevelDtos.map((dto) => {
        if (dto.accessLevel.id === id) {
          return {
            ...dto,
            accessLevel: {
              ...dto.accessLevel,
              accessLevelName: editedName,
              description: editedDescription,
              color: editedColor,
            },
          };
        }

        return dto;
      });
      setAccessLevelDtos(updatedLevels);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Access level updated',
      });

      setShowEdit(false);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function isDisabled() {
    if (showConfirmDelete) return false;

    return (
      editedName === accessLevelName &&
      editedDescription === description &&
      editedColor === color
    );
  }

  function getOptions() {
    const haveAccessLevels = accessLevelDtos.length > 0;
    if (!haveAccessLevels) return [];

    return accessLevelDtos
      .filter((a) => a.accessLevel.id !== id)
      .map(({ accessLevel }) => accessLevel.accessLevelName);
  }

  function renderModalContent() {
    if (showConfirmDelete) {
      return (
        <div className={classes.root}>
          <div className={classes.cont}>
            <Typography variant={'h6'}>
              Are you sure you want to delete this access level?
            </Typography>
          </div>
          <div className={classes.cont} style={{ marginTop: '2rem' }}>
            <Typography variant={'body2'}>
              All users with access level will be assigned
            </Typography>
          </div>
          <div className={classes.dropdown}>
            <SelectDropdown
              value={newTemplate}
              onChange={setNewTemplate}
              options={getOptions()}
              disabled={loading}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.cont}>
          <Typography variant={'h6'}>Name</Typography>
          <div className={classes.input}>
            <InputField
              value={editedName}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setEditedName(event.target.value)
              }
            />
          </div>
        </div>
        <div className={classes.cont}>
          <Typography variant={'h6'}>Description</Typography>
          <div className={classes.input}>
            <InputField
              value={editedDescription}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setEditedDescription(event.target.value)
              }
              multiline={true}
              rows={3}
            />
          </div>
        </div>
        <div className={classes.cont} style={{ justifyContent: 'flex-end' }}>
          <ColorPicker
            color={editedColor}
            onSelectedColor={setEditedColor}
            noMargin={true}
          />
        </div>
      </div>
    );
  }

  function renderAccessLevel() {
    return (
      <Fade in={true} timeout={1000}>
        <div className={classes.accessLevel}>
          <div>
            <Typography variant={'h6'}>{accessLevelName}</Typography>
            <Typography variant={'body2'}>
              {formatNumOf(numberOfUsers, 'user')}
            </Typography>
          </div>
          <div className={classes.desc}>
            <span>Description</span>
            <Typography variant={'body2'}>{description}</Typography>
          </div>
          <Button onClick={() => setShowEdit(true)}>
            <EditIcon />
          </Button>
        </div>
      </Fade>
    );
  }

  return (
    <>
      {renderAccessLevel()}
      <Modal
        open={showEdit}
        close={() => setShowEdit(false)}
        title={showConfirmDelete ? 'Delete access level?' : 'Edit access level'}
        largeHeader={true}
        showFooter={true}
        largeFooter={true}
        headerColor={
          showConfirmDelete ? '#FFCDD2' : theme.palette.primary.light
        }
        saveButtonColor={'#EF5350'}
        loading={loading}
        saveButtonText={showConfirmDelete ? 'Delete' : undefined}
        disabledSave={isDisabled()}
        cancelAction={
          showConfirmDelete ? () => setShowConfirmDelete(false) : undefined
        }
        saveAction={() =>
          showConfirmDelete ? deleteAccessLevel() : updateAccessLevel()
        }
        footerRemoveAction={
          isDefault || !haveAccessLevels || showConfirmDelete
            ? undefined
            : () => setShowConfirmDelete(true)
        }
        footerRemoveText={'Remove'}>
        {renderModalContent()}
      </Modal>
    </>
  );
};

export default AccessLevel;
