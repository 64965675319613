import React from 'react';
import { SxProps } from '@mui/system';
import {
  CalendarMonth,
  ErrorOutline,
  Error,
  Close,
  EditOff,
  AddCircle,
  AccountCircle,
  WorkRounded,
  LocationOnRounded,
  Factory,
  TurnedIn,
  AssignmentIndRounded,
  SellRounded,
  CheckCircleRounded,
  FlagRounded,
  InsertDriveFileRounded,
  ApartmentRounded,
  InfoRounded,
  EventRounded,
  LocationSearchingRounded,
  SourceRounded,
  MonetizationOnRounded,
  FileUpload,
  Event,
  Cancel,
  Language,
  Shield,
  StarOutlineRounded,
  StarRounded,
} from '@mui/icons-material';

const availableIcons: Record<string, React.ReactElement> = {
  Industry: <Factory />,
  CalendarMonth: <CalendarMonth />,
  EditOff: <EditOff />,
  Error: <Error />,
  ErrorOutline: <ErrorOutline />,
  Close: <Close />,
  Cancel: <Cancel />,
  AddCircle: <AddCircle />,
  AccountCircle: <AccountCircle />,
  WorkRounded: <WorkRounded />,
  LocationOnRounded: <LocationOnRounded />,
  TurnedIn: <TurnedIn />,
  AssignmentIndRounded: <AssignmentIndRounded />,
  SellRounded: <SellRounded />,
  CheckCircleRounded: <CheckCircleRounded />,
  FlagRounded: <FlagRounded />,
  InsertDriveFileRounded: <InsertDriveFileRounded />,
  ApartmentRounded: <ApartmentRounded />,
  InfoRounded: <InfoRounded />,
  EventRounded: <EventRounded />,
  LocationSearchingRounded: <LocationSearchingRounded />,
  SourceRounded: <SourceRounded />,
  MonetizationOnRounded: <MonetizationOnRounded />,
  FileUpload: <FileUpload />,
  AccountCirlce: <AccountCircle />,
  Event: <Event />,
  Language: <Language />,
  Shield: <Shield />,
  StarOutlineRounded: <StarOutlineRounded />,
  StarRounded: <StarRounded />,
};

interface IconPickerProps {
  name: string | undefined;
  sx?: SxProps;
}

export const IconPicker = ({ name = '', sx }: IconPickerProps) => {
  const matchedIcon = availableIcons[name];

  if (!matchedIcon) return <Language sx={{ ...sx }} />;

  return (
    matchedIcon && <matchedIcon.type {...matchedIcon.props} sx={{ ...sx }} />
  );
};
