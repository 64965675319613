import React, { FunctionComponent, ReactElement } from 'react';
import { Link, Typography, useTheme } from '@mui/material';
import { FlexBox } from '../../../utils/styledComponents';
import {
  ApplicationQuestionTypes,
  IApplicationAnswer,
} from '../../../utils/models';
import { Box } from '@mui/system';
import DownloadIcon from '@mui/icons-material/Download';
import CircleIcon from '@mui/icons-material/Circle';
import handleFileDownload from 'components/common/HandleFileDownload';
import { primary } from 'theme';

//NOTE: this object connected to acceptableFileFormats in ApplicationDropZon.tsx
const mimeTypeReplacements: { [key: string]: string } = {
  'application/pdf': 'PDF',
  'text/plain': 'Text',
  'application/vnd.ms-powerpoint': 'PowerPoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'PowerPoint Presentation',
  'application/msword': 'Word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'Word Document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel',
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'image/gif': 'GIF',
};

interface AnswersProps {
  answer: IApplicationAnswer | undefined;
  type: ApplicationQuestionTypes;
}

const Answers: FunctionComponent<AnswersProps> = (props) => {
  const { answer, type } = props;
  const theme = useTheme();

  const getFriendlyMimeType = (mimeType: string) => {
    return mimeTypeReplacements[mimeType] || mimeType;
  };

  const renderText = (answer: IApplicationAnswer) => (
    <Typography
      variant='h6'
      sx={{
        wordWrap: 'break-word',
      }}>
      {answer.text}
    </Typography>
  );

  const renderTextArea = (answer: IApplicationAnswer) => (
    <Typography
      variant='h6'
      sx={{
        wordWrap: 'break-word',
      }}>
      {answer.textArea}
    </Typography>
  );

  const renderFile = (answer: IApplicationAnswer) => {
    const fileType = getFriendlyMimeType(answer.file?.type || '');

    return (
      <FlexBox sx={{ flexDirection: 'column', alignItems: 'start' }}>
        <Typography sx={{ textDecoration: 'underline' }}>
          [{fileType}] {answer.file?.name}
        </Typography>
        <Link
          sx={{ textDecoration: 'none' }}
          target='_blank'
          component='button'
          onClick={() =>
            handleFileDownload(
              answer.file?.url as string,
              answer.file?.name as string,
            )
          }>
          <FlexBox sx={{ gap: '0.75rem' }}>
            <DownloadIcon sx={{ fontSize: '1.3rem' }} />{' '}
            <Typography variant={'h6'}>Download</Typography>
          </FlexBox>
        </Link>
      </FlexBox>
    );
  };

  const renderRadio = (answer: IApplicationAnswer) => {
    return (
      <Typography
        variant='h6'
        sx={{
          wordWrap: 'break-word',
        }}>
        {answer.radio?.name}
      </Typography>
    );
  };

  const renderCheckBoxes = (answer: IApplicationAnswer) => {
    return (
      <>
        {answer.selectedOptions?.map((option) => (
          <FlexBox
            sx={{
              gap: '0.5rem',
            }}
            key={option.id}>
            <CircleIcon
              sx={{
                fontSize: '0.4rem',
              }}
            />
            <Typography variant='h6'>{option.name}</Typography>
          </FlexBox>
        ))}
      </>
    );
  };

  const renderTags = (answer: IApplicationAnswer) => {
    return (
      <FlexBox
        sx={{
          gap: '0.25rem',
        }}>
        {answer.tags?.map((tag) => (
          <Box
            sx={{
              borderRadius: '12px',
              padding: '5px 10px',
              fontSize: '0.85rem',
              backgroundColor: theme.palette.primary.light,
              color: '#333333',
              textAlign: 'center',
            }}
            key={tag.id}>
            #{tag.name}
          </Box>
        ))}
      </FlexBox>
    );
  };

  const renderLink = (answer: IApplicationAnswer) => (
    <Typography
      variant='h6'
      onClick={() => window.open(answer.url, '_blank')}
      sx={{
        wordWrap: 'break-word',
        color: primary.additionalDeepBlue,
        textDecoration: 'underline',
        cursor: 'pointer',
      }}>
      {answer.url}
    </Typography>
  );

  const renderers: {
    [key in ApplicationQuestionTypes]?: (answer: any) => ReactElement;
  } = {
    [ApplicationQuestionTypes.TEXT]: renderText,
    [ApplicationQuestionTypes.TEXT_AREA]: renderTextArea,
    [ApplicationQuestionTypes.FILE]: renderFile,
    [ApplicationQuestionTypes.RADIO]: renderRadio,
    [ApplicationQuestionTypes.CHECKBOX]: renderCheckBoxes,
    [ApplicationQuestionTypes.TAGS]: renderTags,
    [ApplicationQuestionTypes.LINK]: renderLink,
  };

  const getRenderer = (type: Partial<ApplicationQuestionTypes>) =>
    renderers[type] || (() => <Typography>N/A</Typography>);

  if (
    !answer ||
    (type === ApplicationQuestionTypes.TEXT && !answer.text) ||
    (type === ApplicationQuestionTypes.TEXT_AREA && !answer.textArea) ||
    (type === ApplicationQuestionTypes.FILE && !answer.file?.name) ||
    (type === ApplicationQuestionTypes.RADIO && !answer.radio) ||
    (type === ApplicationQuestionTypes.CHECKBOX && !answer.selectedOptions) ||
    (type === ApplicationQuestionTypes.TAGS && !answer.tags) ||
    (type === ApplicationQuestionTypes.LINK && !answer.url)
  ) {
    return (
      <Typography
        variant={'h6'}
        sx={{
          marginLeft: '4rem',
        }}>
        N/A
      </Typography>
    );
  }

  const render = getRenderer(type);

  return (
    <Box
      sx={{
        marginTop: '1.5rem',
        marginX: '4rem',
      }}>
      {render(answer)}
    </Box>
  );
};

export default Answers;
