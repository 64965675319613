import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '3.125rem 0',
    width: '100%',
    '& h5': {
      fontSize: '1.6rem !important',
      marginBottom: '2rem !important',
    },
  },
  cont: {},
}));

interface IManageTheme {
  section: JSX.Element | null;
}

const ManageTheme: FunctionComponent<IManageTheme> = (props) => {
  const { section } = props;
  const classes = useStyles();

  return <div className={classes.root}>{section}</div>;
};

export default ManageTheme;
