import React, { FunctionComponent } from 'react';

interface IFile {
  file: string | undefined;
}

const GetFileIcon: FunctionComponent<IFile> = (props) => {
  const { file } = props;

  if (!file) return <img src='/images/icons/all-other-types.svg' alt='file' />;

  switch (file) {
    case 'txt':
      return <img src='/images/icons/text.svg' alt='txt' />;
    case 'pdf':
      return <img src='/images/icons/pdf.svg' alt='pdf' />;
    case 'doc':
      return <img src='/images/icons/doc.svg' alt='doc' />;
    case 'docx':
      return <img src='/images/icons/doc.svg' alt='docx' />;
    case 'pp':
      return <img src='/images/icons/pp.svg' alt='pp' />;
    case 'pptx':
      return <img src='/images/icons/pp.svg' alt='pptx' />;
    case 'xlsx':
      return <img src='/images/icons/xslx.svg' alt='xlsx' />;
    case 'jpg':
      return <img src='/images/icons/all-img.svg' alt='jpg' />;
    case 'png':
      return <img src='/images/icons/all-img.svg' alt='png' />;
    case 'gif':
      return <img src='/images/icons/all-other-types.svg' alt='gif' />;
    default:
      return <img src='/images/icons/all-other-types.svg' alt='file' />;
  }
};

export default GetFileIcon;
