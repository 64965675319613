import React, { FunctionComponent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme, useTheme } from '@mui/material';
import Modal from '../common/Modal';
import { IEcosystemStatus } from 'utils/models';
import { postData, SUPER_ADMINN_TOGGLE_ECOSYSTEM_STATUS } from 'utils/requests';
import { AlertContext, ALERT_TYPES } from '../Alert/AlertContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginRight: 'auto',
    marginLeft: 'auto',
    '& td': {
      padding: '0 15px',
      textAlign: 'center',
    },
  },
}));

interface IEcosystemStatusProps {
  showEcosystemStatus: boolean;
  close: () => void;
  ecosystems: IEcosystemStatus[];
  updateEcosystem: () => void;
}

const allEcosystemsOption = 'All Ecosystems';

const EcosystemStatus: FunctionComponent<IEcosystemStatusProps> = (props) => {
  const { showEcosystemStatus, close, ecosystems, updateEcosystem } = props;
  const classes = useStyles();

  const theme = useTheme();

  const { addAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(false);

  async function toggleEcosystemStatus(ecosystemName: string) {
    setLoading(true);
    try {
      const data = await postData(SUPER_ADMINN_TOGGLE_ECOSYSTEM_STATUS, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);
      updateEcosystem();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setLoading(false);
  }

  function renderEcosystemContent() {
    return (
      <table className={classes.root}>
        <thead>
          <tr>
            <th>Ecosystem Name</th>
            <th>Status</th>
            <th>Toggle Button</th>
          </tr>
        </thead>
        <tbody>
          {ecosystems.length &&
            ecosystems.map((ecosystem: IEcosystemStatus, index: number) => {
              return (
                <tr key={index}>
                  <td>{ecosystem.ecosystemName}</td>
                  <td>{ecosystem.disabled ? 'Disabled' : 'Enabled'}</td>
                  <td>
                    <Button
                      disabled={loading}
                      onClick={() =>
                        toggleEcosystemStatus(ecosystem.ecosystemName)
                      }>
                      Toggle
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  }

  return (
    <Modal
      open={showEcosystemStatus}
      close={close}
      isActerioTheme={true}
      title={'Manage Status Of Ecosystems'}
      largeHeader={true}
      backgroundColor={'white'}
      headerColor={theme.palette.primary.light}
      loading={loading}>
      {renderEcosystemContent()}
    </Modal>
  );
};

export default EcosystemStatus;
