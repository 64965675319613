import React, { FunctionComponent } from 'react';
import { Box, /* Fade,  */Typography, } from '@mui/material';
import { useJourneyContext } from './JourneyContext';
import { useTheme } from '@mui/material/styles';

interface IJourneyHeader {}

const JourneyHeader: FunctionComponent<IJourneyHeader> = (props) => {
  const { stage, isStageCompleted } = useJourneyContext();
  const theme = useTheme();

  return !stage ? null : (
    <div>
   {/* <Fade in={isStageCompleted()} timeout={400}> */}
      <Box>
        <Box
          sx={{
            width: 'fit-content',
            overflow: 'none',
            height: '3.125rem',
            borderRadius: '5px',
            backgroundColor: theme.palette.primary.light,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 1.5rem',
            border: '1px solid ' + theme.palette.primary.main,
            marginBottom: '1rem',
          }}>
          <Typography sx={{ fontWeight: 400, fontSize: '1.563rem' }}>
            {stage.name}
          </Typography>
        </Box>
        {stage.description && (
          <Box
            sx={{
              minHeight: '6rem',
              width: { sm: '50%', md: '80%', lg: '71.188rem' },
              border: '1px solid #E0E0E0',
              borderRadius: '0.375rem',
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}>
            <Typography
              sx={{ fontWeight: 400, fontSize: '1rem', lineHeight: '1.5rem', paddingLeft: '1rem' }}>
              {stage.description}
            </Typography>
          </Box>
        )}
      </Box>
   {/*    </Fade> */}
    </div>
  );
};

export default JourneyHeader;
