import React, { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import CompanySection from '../CompanySection';
import CompanyField from '../CompanyField';
import { isEmptyRichText } from 'utils/utils';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';

export interface ICompanySideSection {}

const CompanySideSection: FunctionComponent<ICompanySideSection> = (props) => {
  const { companyProfile } = useCompanyContext();

  const { sidebar } = companyProfile!;

  const { renderCompanyField } = CompanyField();

  function renderSidebar() {
    return sidebar.map((field) => {
      const companyField = renderCompanyField(field);
      const noData =
        (field.type === 'TEXT' && !field.text) ||
        (field.type === 'TAGS' && field.usedTags === undefined) ||
        (field.type === 'RICH_TEXT' && isEmptyRichText(field.richText)) ||
        (field.type === 'FILES' && (!field.files || !field.files?.length)) ||
        (field.type === 'PEOPLE' &&
          (!field.people ||
            field.name === 'Team' ||
            field.people?.length === 0)); // Hide whole box if no people

      return (
        <Box sx={{ marginBottom: '1rem' }} key={field.id}>
          {noData ? null : (
            <>
              {companyField && (
                <CompanySection title={field.name}>
                  {companyField}
                </CompanySection>
              )}
            </>
          )}
        </Box>
      );
    });
  }

  return <Box>{renderSidebar()}</Box>;
};

export default CompanySideSection;
