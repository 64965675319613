import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import LoginForm from 'components/Login/LoginForm';
import RegisterForm from 'components/Login/RegisterForm';
import PasswordReset from 'components/Login/PasswordReset';
import PasswordResetRequestEmail from 'components/Login/PasswordResetRequestEmail';
import { useParams } from 'react-router';
import JoinByInvite from 'components/Login/JoinByInvite';
import { Box } from '@mui/system';
import Unsubscribe from 'components/Login/Unsubscribe';

const useStyles = makeStyles(() => ({
  logo: {
    width: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '50rem',
    marginTop: '2rem',
  },
  loginBox: {
    display: 'flex',
    background: 'linear-gradient(153deg, #1B3650 -27.92%, #2D4D6A 124.95%)',
    backgroundSize: 'cover',
    borderRadius: '10px',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '35px',
    paddingBottom: '1.875rem',
    rowGap: '1.875rem',
    color: 'white',

    '& button': {
      width: '100% !important',
      height: '2.813rem !important',
    },
  },
}));

interface OwnProps {
  formToDisplay: string;
}

const Login: FunctionComponent<OwnProps> = (props) => {
  const { formToDisplay } = props;
  const classes = useStyles();
  const { secretKey } = useParams();
  return (
    <Box
      className={classes.container}
      sx={{
        minHeight: { xs: 50, lg: 800 },
        marginTop: { xs: 0, lg: '2rem' },
        flexDirection: { xs: 'column-reverse', lg: 'row' },
        maxWidth: '120rem',
        width: { xs: '100%', md: '80vw' },
        margin: 'auto',
      }}>
      <Box
        className={classes.loginBox}
        sx={{ width: { xs: '100%', lg: '35%' } }}>
        {formToDisplay === 'login' && <LoginForm />}
        {formToDisplay === 'signup' && <RegisterForm />}
        {formToDisplay === 'password-reset-request-email' && (
          <PasswordResetRequestEmail />
        )}
        {formToDisplay === 'unsubscribe' && <Unsubscribe />}
        {formToDisplay === 'password-reset' && <PasswordReset />}
        {formToDisplay === 'join-by-invite' && secretKey && (
          <JoinByInvite secretKey={secretKey} />
        )}
      </Box>
      <Box
        sx={{
          width: { xs: '100%', lg: '50%' },
          marginBottom: { xs: '2rem', lg: 0 },
          marginLeft: { xs: 0, lg: '8rem' },
        }}>
        <img src={'/images/logo.png'} className={classes.logo} alt='logo' />
      </Box>
    </Box>
  );
};

export default Login;
