import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import AssignJourneysSearch, {
  IAssignJourneysSearch,
} from './AssignJourneysSearch';
import AssignJourneysCompanies from './AssignJourneysCompanies';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    borderBottom: '1px solid #e0e0e0',
    backgroundColor: 'white',
    padding: '1rem',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    width: '100%',
    height: '5rem',
    zIndex: 100,

    '& button': {
      maxWidth: '165px !important',
      maxHeight: '45px !important',
      margin: '7.5px !important',
    },
  },
  cont: {
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
  },
}));

type IAssignJourneysHeader = IAssignJourneysSearch

const AssignJourneysHeader: FunctionComponent<IAssignJourneysHeader> = (
  props,
) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.cont}>
        <AssignJourneysSearch {...props} />
        <AssignJourneysCompanies />
      </div>
    </div>
  );
};

export default AssignJourneysHeader;
