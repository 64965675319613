import { DoneAll, HighlightOff } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { primary } from 'theme';
import useFetchAccessLevels from 'hooks/Common/useFetchAccessLevels';

interface ISelectAccessLevels {
  accessLevelIds: number[];
  onAccessGroupChange: (selectedGroupIds: any[]) => void;
  isOneSelection?: boolean;
}

const SelectAccessLevels: FunctionComponent<ISelectAccessLevels> = (props) => {
  const { accessLevelIds, onAccessGroupChange, isOneSelection } = props;
  const { accessLevel } = useFetchAccessLevels();

  const theme = useTheme();

  const [selectedAccessGroupIds, setSelectedAccessGroupIds] = useState<any>(
    accessLevelIds ?? [],
  );

  function handleSelectAllAccessGroups() {
    const allIds = accessLevel.map((option: any) => option.accessLevel.id);
    setSelectedAccessGroupIds(allIds);
  }

  useEffect(() => {
    if (isOneSelection && selectedAccessGroupIds.length > 1) {
      const lastItem = selectedAccessGroupIds[1];
      setSelectedAccessGroupIds([lastItem]);
    }
    onAccessGroupChange(selectedAccessGroupIds);
  }, [selectedAccessGroupIds, isOneSelection]);

  return (
    <div>
      {!isOneSelection && (
        <Typography
          variant='body1'
          sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Select which groups should have access*
        </Typography>
      )}
      <FormControl fullWidth>
        <Select
          multiple
          value={selectedAccessGroupIds}
          onChange={(e) => setSelectedAccessGroupIds(e.target.value)}
          sx={{ height: '2.5rem' }}
          renderValue={(selected) =>
            selected.length > 0
              ? selected
                  .map((selectedGroupId: any) => {
                    const selectedGroup = accessLevel.find(
                      (option: any) =>
                        option.accessLevel.id === selectedGroupId,
                    );
                    return selectedGroup
                      ? selectedGroup.accessLevel.accessLevelName
                      : '';
                  })
                  .join(', ')
              : 'Placeholder'
          }>
          <MenuItem disabled value=''>
            Select access group
          </MenuItem>
          {accessLevel.length &&
            accessLevel.map((option: any, i: number) => (
              <MenuItem key={i} value={option.accessLevel.id}>
                {!isOneSelection ? (
                  <Checkbox
                    checked={
                      selectedAccessGroupIds.indexOf(option.accessLevel.id) > -1
                    }
                  />
                ) : (
                  <Radio
                    checked={
                      selectedAccessGroupIds.indexOf(option.accessLevel.id) > -1
                    }
                  />
                )}
                <ListItemText primary={option.accessLevel.accessLevelName} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {!isOneSelection && (
        <>
          <Button
            onClick={handleSelectAllAccessGroups}
            sx={{
              marginTop: 2,
              bgcolor: theme.palette.primary.dark,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              paddingX: 1.5,
              marginRight: 2,
              '&:hover': { backgroundColor: theme.palette.primary.main },
            }}>
            <DoneAll sx={{ color: primary.pureWhite, marginRight: 1 }} />
            <Typography color={primary.pureWhite}>Invite all groups</Typography>
          </Button>
          <Button
            onClick={() => setSelectedAccessGroupIds([])}
            sx={{
              marginTop: 2,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              paddingX: 1.5,
              '&:hover': { backgroundColor: theme.palette.primary.light },
            }}>
            <HighlightOff
              sx={{ color: theme.palette.primary.dark, marginRight: 1 }}
            />
            <Typography color={theme.palette.primary.dark}>
              Clear all groups
            </Typography>
          </Button>
        </>
      )}
    </div>
  );
};

export default SelectAccessLevels;
