import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import AssignJourneysHeader from './AssignJourneys/AssignJourneysHeader';
import AssignJourneysSidebar from './AssignJourneys/AssignJourneysSidebar';
import { AssignJourneysProvider } from './AssignJourneys/AssignJourneysContext';
import JourneysPreviews from './JourneysPreviews';
import ContWithStyledScroll from 'components/common/ContWithStyledScroll';
import AssignJourneysSave from './AssignJourneys/AssignJourneysSave';
import { ICompanyJourney } from 'utils/models';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import {
  getData,
  JOURNEY_ASSIGN_COMPANY_DATA_ROUTE,
} from 'utils/requests';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '-3rem',
  },
  cont: {
    borderRadius: '6px',
    border: '1px solid #e0e0e0',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  main: {
    height: '28.125rem',
    width: '100%',
    display: 'flex',
  },
  previewCont: {
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: '25rem',
    maxHeight: '28.125rem',
    minWidth: '33.75rem',
    padding: '1.25rem 1.7rem 0rem 1.7rem',
  },
}));

interface IAssignJourneys {}

const AssignJourneys: FunctionComponent<IAssignJourneys> = (props) => {
  const classes = useStyles();
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [companies, setCompanies] = useState<ICompanyJourney[] | null>(null);
  const [clearSearch, setClearSearch] = useState<boolean>(false);

  useEffect(() => {
    loadCompanies();
  }, []);

  async function loadCompanies() {
    try {
      const loadedCompanies: ICompanyJourney[] = await getData(
        JOURNEY_ASSIGN_COMPANY_DATA_ROUTE,
        [{ name: 'ecosystemName', value: ecosystemName }],
      );

      const sortedByJourneyPresent = loadedCompanies.sort(
        (a, b) =>
          Number(!!b.companyAssignedJourney) -
          Number(!!a.companyAssignedJourney),
      );
      setCompanies(sortedByJourneyPresent);
      setClearSearch(true);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  return (
    <AssignJourneysProvider>
      <div className={classes.root}>
        <Typography variant={'h5'}>Assign Journeys</Typography>
        <div className={classes.cont}>
          <AssignJourneysHeader
            companies={companies}
            onFilter={setCompanies}
            clearSearch={clearSearch}
            setClearSearch={() => setClearSearch(false)}
          />
          <div className={classes.main}>
            <AssignJourneysSidebar companies={companies} />
            <ContWithStyledScroll className={classes.previewCont} hide={true}>
              <JourneysPreviews selectable={true} />
            </ContWithStyledScroll>
          </div>
        </div>
      </div>
      <AssignJourneysSave reloadCompanies={loadCompanies} />
    </AssignJourneysProvider>
  );
};

export default AssignJourneys;
