import React, { FunctionComponent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import Modal from '../common/Modal';
import { IResource } from 'utils/models';
import { deleteData, RESOURCE_ROUTE } from 'utils/requests';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  deleteMessage: {
    padding: '15px',
    fontWeight: 400,
    maxWidth: '28.125rem',
  },
}));

interface OwnProps {
  resource: IResource | null;
  updateResources: (resource: IResource, remove: boolean) => any;
  setResource: (resource: IResource | null) => any;
}

const ResourceCard: FunctionComponent<OwnProps> = (props) => {
  const { resource, setResource, updateResources } = props;
  const classes = useStyles();
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState<boolean>(false);

  const { isMobile } = useMobileContext();

  async function deleteResource(resource: IResource | null) {
    if (!resource) return null;

    setLoading(true);
    try {
      await deleteData(RESOURCE_ROUTE, [
        {
          name: 'resourceName',
          value: resource.name,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      updateResources(resource, true);
      setResource(null);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Resource deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return (
    <Modal
      open={!!resource}
      close={() => setResource(null)}
      saveAction={() => deleteResource(resource)}
      backgroundColor={'white'}
      backdropStrength={0.9}
      title={'Are you sure you want to delete this resource?'}
      headerColor={'#FFCDD2'}
      showFooter={true}
      largeHeader={true}
      largeFooter={true}
      width={isMobile ? 320 : 600}
      cancelButtonText={'Cancel'}
      saveButtonText={'Delete'}
      saveButtonColor={'#EF5350'}
      loading={loading}>
      <Typography variant={'body1'} className={classes.deleteMessage}>
        This action is irreversible and you will NOT be able to recover the
        resource.
      </Typography>
    </Modal>
  );
};

export default ResourceCard;
