import React, { ChangeEvent, FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import InputField from 'components/common/InputField';
import JourneyTemplateAddStage from './JourneyTemplateAddStage';
import { useJourneyTemplateContext } from './JourneyTemplateContext';
import JourneyTemplateStage from './JourneyTemplateStage';
import JourneyTemplateDelete from './JourneyTemplateDelete';
import SideMenu from 'components/SideMenu/SideMenu';
import SideMenuBackButton from 'components/Admin/AdminBackButton';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { Box } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  cont: {
    marginTop: '2rem',
    paddingRight: '2rem',
    paddingLeft: '1rem',

    '& h5': {
      marginLeft: '2rem !important',
      fontSize: '1.25rem !important',
      textTransform: 'uppercase',
    },
  },
  stages: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1.5rem',
    marginTop: '1rem',
    transition: '0.3s ease-in-out all',
  },
}));

interface IJourneyTemplateMenu {}

const JourneyTemplateMenu: FunctionComponent<IJourneyTemplateMenu> = (
  props,
) => {
  const classes = useStyles();
  const {
    journey,
    stage,
    stageIndex,
    updateName,
    addNewStage,
    setJourney,
    setStage,
    setStageIndex,
  } = useJourneyTemplateContext();

  function onDragEnd(result: DropResult) {
    if (!result.destination || journey === null) return;

    const reorderedStages = Array.from(journey.stages);
    const [movedStage] = reorderedStages.splice(result.source.index, 1);
    reorderedStages.splice(result.destination.index, 0, movedStage);

    const updatedJourney = { ...journey, stages: reorderedStages };
    setJourney(updatedJourney);
    setStageIndex(result.destination.index);
    setStage(reorderedStages[result.destination.index]);
  }

  function renderStages() {
    if (!journey || !stage) return null;

    return journey.stages.map((journeyStage, index) => (
      <JourneyTemplateStage
        key={`stage-${index}`}
        index={index}
        selected={stageIndex === index}
        stage={journeyStage}
      />
    ));
  }

  return !journey ? null : (
    <SideMenu>
      <SideMenuBackButton text={'Journeys'} />
      <div className={classes.cont}>
        <InputField
          value={journey.name}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            updateName(event.target.value)
          }
          placeholder={'Journey name...'}
        />
        <div className={classes.stages}>
          <Box sx={{ width: 420, maxHeight: 475, overflow: 'auto' }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='stages'>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={classes.stages}>
                    {renderStages()}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <JourneyTemplateAddStage
            nextIndex={journey.stages.length + 1}
            addNewStage={addNewStage}
          />
          <Box
            sx={{
              position: 'fixed',
              bottom: '0',
              right: '-1',
              zIndex: 1,
              marginBottom: '2rem',
            }}>
            <JourneyTemplateDelete />
          </Box>
        </div>
      </div>
    </SideMenu>
  );
};

export default JourneyTemplateMenu;
