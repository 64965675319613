import React, { FunctionComponent } from 'react';
import { FlexBox } from '../../utils/styledComponents';
import { naturalPallete } from '../../theme';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material';

interface SeeMoreButtonProps {
  toggleComments: () => void;
  showAllComments: boolean;
  length: number;
}

const SeeMoreButton: FunctionComponent<SeeMoreButtonProps> = (props) => {
  const { toggleComments, showAllComments, length } = props;
  const theme = useTheme();

  return (
    <FlexBox className={'center'}>
      <FlexBox
        className={'center'}
        sx={{
          width: '60%',
          '::before': {
            content: '""',
            flexGrow: 1,
            borderBottom: `1px solid ${naturalPallete.natural6}`,
            marginRight: '1rem',
          },
          '::after': {
            content: '""',
            flexGrow: 1,
            borderBottom: `1px solid ${naturalPallete.natural6}`,
            marginLeft: '1rem',
          },
        }}>
        <Button
          onClick={toggleComments}
          sx={{
            display: 'block',
            margin: '10px 0',
            textAlign: 'left',
          }}>
          {showAllComments ? 'Hide comments' : `See all (${length}) comments`}
          <Box
            sx={{
              display: 'inline-block',
              marginLeft: '0.5rem',
              marginBottom: '0.15rem',
              width: '0',
              height: '0',
              borderLeft: '5px solid transparent',
              borderRight: '5px solid transparent',
              borderTop: `5px solid ${theme.palette.primary.main}`,
              transition: 'transform 0.3s ease',
              transform: showAllComments ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export default SeeMoreButton;
