import React, { FunctionComponent } from 'react';

interface SendIconProps {
  color: string;
}

const SendIcon: FunctionComponent<SendIconProps> = (props) => {
  const { color } = props;

  return (
    <svg viewBox='0 0 119 119' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M117.712 13.5264C120.616 5.49557 112.833 -2.2866 104.803 0.623315L6.65183 36.1202C-1.40587 39.0368 -2.38032 50.0313 5.03222 54.3256L36.3625 72.4639L64.3393 44.4871C65.6068 43.2629 67.3044 42.5855 69.0664 42.6008C70.8285 42.6161 72.514 43.3229 73.76 44.5689C75.006 45.8149 75.7128 47.5005 75.7281 49.2625C75.7434 51.0246 75.0661 52.7222 73.8419 53.9896L45.8651 81.9665L64.01 113.297C68.2976 120.709 79.2921 119.728 82.2087 111.677L117.712 13.5264Z'
        fill={color}
      />
    </svg>
  );
};
export default SendIcon;
