import React, { FunctionComponent } from 'react';
import { useApplicationContext } from 'services/contexts/Applications/ApplicationsContext';
import ApplicantDetails from './ApplicantDetails';
import ApplicationsGrid from './ApplicationsGrid';
import ApplicationsOverview from './ApplicationsOverview';

interface IAppRouter {}

const ApplicationRouter: FunctionComponent<IAppRouter> = (props) => {
  const { selectedApplicationProcessId, selectedApplicantId } =
    useApplicationContext();

  if (!selectedApplicationProcessId) return <ApplicationsGrid />;
  if (!selectedApplicantId) return <ApplicationsOverview />;
  if (selectedApplicationProcessId && selectedApplicantId)
    return <ApplicantDetails />;

  return <>Whoops! Something went wrong! Try again.</>;
};

export default ApplicationRouter;
