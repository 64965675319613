const handleFileDownload = async (url: string, name: string) => {
  try {
    const response = await fetch(url, {
      mode: 'cors',
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error: any) {
    console.error('Error downloading the file', error);
    alert('Error downloading the file: ' + error.message);
  }
};

export default handleFileDownload;
