import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import CompanyProfilePreviews from './CompanyProfilePreviews';
import { useAssignCompanyProfilesContext } from './AssignCompanyProfileContext';
import Loading from 'components/common/Loading';

interface ICompanyProfileOverview {}

const CompanyProfileOverview: FunctionComponent<ICompanyProfileOverview> = (
  props,
) => {
  //const { isEditing, setIsEditing } = useAssignCompanyProfilesContext();
  const { companyProfileTemplates, isLoading } =
    useAssignCompanyProfilesContext();
  const nocompanyProfileTemplates = companyProfileTemplates.length === 0;

  return (
    <Box>
      <Typography
        sx={{ fontWeight: '600', fontSize: '2.5rem', lineHeight: '3.75rem' }}>
        Company profile templates
      </Typography>
      <Typography
        sx={{
          fontWeight: '400',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          color: '#4D4D4D',
          marginY: '1.5rem',
        }}>
        Customize company profile templates, create new, edit or delete existing
        ones
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {isLoading ? (
          <Box sx={{ margin: '10rem' }}>
            <Loading height={0} size={40} />
          </Box>
        ) : (
          <>
            {nocompanyProfileTemplates && (
              <Typography variant={'h6'} fontSize={28} color={'#e0e0e0'}>
                No templates yet
              </Typography>
            )}
            <CompanyProfilePreviews />
          </>
        )}
        {/*   <MainButton
            text={isEditing ? 'Save' : 'Edit templates'}
            type={ButtonType.BLANK}
            startIcon={isEditing ? <Check /> : <Edit />}
            onClick={() => setIsEditing((prev) => !prev)}
          /> */}
      </Box>
    </Box>
  );
};

export default CompanyProfileOverview;
