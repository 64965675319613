import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import { Box, SxProps } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: (props: any) =>
      props.hide
        ? '0px'
        : props.margin !== undefined
        ? `${props.margin}px`
        : '1rem',
    marginLeft: '0',
    marginRight: '0.75rem',
    '&::-webkit-scrollbar': {
      width: (props: any) =>
        props.hide ? '0em !important' : '0.6em !important',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 12px ${theme.palette.primary.light} !important`,
      webkitBoxShadow: `inset 0 0 12px ${theme.palette.primary.light} !important`,
      borderRadius: (props: any) =>
        props.radius ? `${props.radius}px !important` : '10px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderRadius: (props: any) =>
        props.radius ? `${props.radius}px !important` : '10px !important',
    },
  },
}));

interface IContWithStyledScroll {
  children: React.ReactNode;
  className?: string;
  radius?: number;
  margin?: number;
  hide?: boolean;
  style?: SxProps;
}

const ContWithStyledScroll: FunctionComponent<IContWithStyledScroll> = (
  props,
) => {
  const { children, className, radius, margin, hide, style } = props;
  const classes = useStyles({ radius, margin, hide });

  return (
    <Box sx={style} className={clsx(classes.root, className)}>
      {children}
    </Box>
  );
};

export default ContWithStyledScroll;
