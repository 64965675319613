import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import MainButton, { ButtonType } from 'components/common/MainButton';
import Metrics from 'components/SuperAdmin/Metrics';
import { AlertContext, ALERT_TYPES } from 'components/Alert/AlertContext';
import { getData, SUPER_ADMIN_DISABLED_ECOSYSTEMS } from 'utils/requests';
import { IEcosystemStatus } from 'utils/models';
import CreateNewEcosystem from 'components/SuperAdmin/CreateNewEcosystem';
import EcosystemStatus from 'components/SuperAdmin/EcosystemStatus';
import BugReports from 'components/SuperAdmin/BugReports';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '60vh',
    position: 'relative',
    width: '80vw',
    margin: 'auto',

    '& h1': {
      fontSize: '7rem !important',
    },

    '& button': {
      marginTop: '5rem !important',
    },
  },
}));

interface ISuperAdmin {}

const SuperAdmin: FunctionComponent<ISuperAdmin> = (props) => {
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);
  const [ecosystems, setEcosystems] = useState<IEcosystemStatus[]>([]);
  const [showMetrics, setShowMetrics] = useState<boolean>(false);
  const [showNewEcosystem, setShowNewEcosystem] = useState<boolean>(false);
  const [showBugReports, setShowBugReports] = useState<boolean>(false);
  const [showEcosystemStatus, setShowEcosystemStatus] =
    useState<boolean>(false);

  useEffect(() => {
    fetchDisabledEcosystems();
  }, []);

  async function fetchDisabledEcosystems() {
    try {
      const data = await getData(SUPER_ADMIN_DISABLED_ECOSYSTEMS, []);

      setEcosystems(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  return (
    <div className={classes.root}>
      <MainButton
        text={'Generate Metrics'}
        type={ButtonType.FILLED}
        onClick={() => setShowMetrics(true)}
      />
      <Metrics
        showMetrics={showMetrics}
        close={() => setShowMetrics(false)}
        ecosystems={ecosystems}
      />
      <MainButton
        text={'Create new ecosystem'}
        type={ButtonType.FILLED}
        onClick={() => setShowNewEcosystem(true)}
      />
      <CreateNewEcosystem
        showNewEcosystem={showNewEcosystem}
        close={() => setShowNewEcosystem(false)}
      />
      <MainButton
        text={'Manage Ecosystem Enabled Status'}
        type={ButtonType.FILLED}
        onClick={() => setShowEcosystemStatus(true)}
      />
      <EcosystemStatus
        showEcosystemStatus={showEcosystemStatus}
        ecosystems={ecosystems}
        close={() => setShowEcosystemStatus(false)}
        updateEcosystem={() => fetchDisabledEcosystems()}
      />
      <MainButton
        text={'Bug Reports'}
        type={ButtonType.FILLED}
        onClick={() => setShowBugReports(true)}
      />
      <BugReports
        showBugReports={showBugReports}
        close={() => setShowBugReports(false)}
      />
    </div>
  );
};

export default SuperAdmin;
