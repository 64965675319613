import { Box, SxProps } from '@mui/system';
import InputField from 'components/common/InputField';
import React, { FunctionComponent } from 'react';
import { Controller, FieldErrors } from 'react-hook-form';
import { primary } from 'theme';
import { IApplicationAssessmentTemplate } from 'utils/models';

interface AssessmentPercentageInputProps {
  control: any;
  errors: FieldErrors<IApplicationAssessmentTemplate>;
  sectionIndex: number;
  subSectionIndex?: number;
  questionIndex?: number;
  isWeightsValid: boolean;
  bgColor?: string;
  border?: string;
  sx?: SxProps;
}

// Utility function to construct the field name
const constructFieldName = (
  sectionIndex: number,
  subSectionIndex?: number,
  questionIndex?: number,
): string => {
  const nameParts = [`sections.${sectionIndex}`];
  if (subSectionIndex !== undefined) {
    nameParts.push(`subSections.${subSectionIndex}`);
  }
  if (questionIndex !== undefined) {
    nameParts.push(`questions.${questionIndex}`);
  }
  nameParts.push('weight');
  return nameParts.join('.');
};

// Utility function to get nested property in an object given an array of keys
const getNestedProperty = (obj: any, keys: (string | number)[]) => {
  return keys.reduce((result, key) => (result ? result[key] : undefined), obj);
};

// Function to construct the field path as an array of keys
const constructFieldPath = (
  sectionIndex: number,
  subSectionIndex?: number,
  questionIndex?: number,
): (string | number)[] => {
  const path: (string | number)[] = ['sections', sectionIndex];
  if (subSectionIndex !== undefined) {
    path.push('subSections', subSectionIndex);
  }
  if (questionIndex !== undefined) {
    path.push('questions', questionIndex);
  }
  path.push('weight');
  return path;
};

const AssessmentPercentageInput: FunctionComponent<
  AssessmentPercentageInputProps
> = (props) => {
  const {
    control,
    errors,
    sectionIndex,
    subSectionIndex,
    questionIndex,
    isWeightsValid,
    bgColor,
    border,
    sx,
  } = props;

  // Use utility functions to construct fieldName and fieldPath
  const fieldName = constructFieldName(
    sectionIndex,
    subSectionIndex,
    questionIndex,
  );
  const fieldPath = constructFieldPath(
    sectionIndex,
    subSectionIndex,
    questionIndex,
  );
  const errorField = getNestedProperty(errors, fieldPath);

  return (
    <Box sx={{ ...sx, width: '6rem', maxWidth: '6rem', minWidth: '6rem' }}>
      <Controller
        name={fieldName}
        control={control}
        rules={{
          min: { value: 0, message: 'Percentage cannot be less than 0%' },
          max: { value: 100, message: 'Percentage cannot be more than 100%' },
          validate: (value) => {
            if (value === undefined || value === '') {
              return true;
            }
            if (!isWeightsValid) {
              return 'Total section weights must add up to 100% or be empty.';
            }
            if (!Number.isInteger(Number(value))) {
              return 'Percentage must be a whole number (no decimals).';
            }
            return !isNaN(value) || 'Percentage must be a valid number';
          },
        }}
        render={({ field }) => (
          <InputField
            {...field}
            type='number'
            onChange={(e) => field.onChange(e.target.value)}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            helperText={errorField?.message}
            helperIcon={errorField && 'warning'}
            error={!!errorField}
            width={'6rem'}
            maxLength={5}
            placeholder='%'
            inputProps={{
              step: 1,
              min: 0,
              max: 100,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                ml: '1.25rem',
                bgcolor: bgColor ?? '',
                border: border ?? '',
                '& .MuiInputBase-input': {
                  WebkitTextFillColor: primary.mainBlackFont,
                },
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default AssessmentPercentageInput;
