import React, { FunctionComponent, useEffect, useState } from 'react';
import NewCompany from './NewCompany';
import { useMobileContext } from 'services/contexts/MobileContext';
import { Box, useTheme } from '@mui/material';
import CommonModal from 'components/common/Modal/CommonModal';
import ModalHeader from 'components/common/Modal/ModalHeader';
import { primary } from 'theme';

interface ICompanies {
  isTriggerAddNew?: boolean;
}

const Companies: FunctionComponent<ICompanies> = (props) => {
  const { isTriggerAddNew } = props;
  const { isMobile } = useMobileContext();

  const theme = useTheme();

  const [addNewCompany, setAddNewCompany] = useState<boolean>(false);

  useEffect(() => {
    if (!isTriggerAddNew) return;
    setAddNewCompany(true);
  }, [isTriggerAddNew]);

  return (
    <>
      <CommonModal open={addNewCompany} onClose={() => setAddNewCompany(false)}>
        <Box
          sx={{
            width: '40rem',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 24,
            padding: '2rem 3rem',
            backgroundColor: primary.pureWhite,
            borderRadius: '10px',
          }}>
          <ModalHeader
            handleClose={() => setAddNewCompany(false)}
            text={'Create a new company'}
            textColor={primary.mainBlackFont}
            headerSize={'2rem'}
          />
          <NewCompany
            closed={!addNewCompany}
            setAddNewCompany={setAddNewCompany}
          />
        </Box>
      </CommonModal>
    </>
  );
};

export default Companies;
