import React, { FunctionComponent } from 'react';
import WriteAnnouncement from './WriteAnnouncement';
import { IAnnouncement } from 'utils/models';
import { getAccount, getEcosystemInfo } from 'utils/storage';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import useAnnouncementActions from '../../hooks/Announcements/useAnnouncementActions';
import Announcement from './Announcement';

interface IAnnouncements {
  announcements: IAnnouncement[];
  setAnnouncements: (announcement: IAnnouncement[]) => void;
}

const Announcements: FunctionComponent<IAnnouncements> = (props) => {
  const { announcements, setAnnouncements } = props;

  const { pinAnnouncement, deleteAnnouncement, editAnnouncement } =
    useAnnouncementActions(announcements, setAnnouncements);
  const user = getAccount();
  function renderAnnouncements() {
    if (announcements.length === 0) {
      return (
        <Box
          sx={{
            height: '3.125rem',
            padding: '1rem 2rem',
            color: '#757575',
          }}>
          <Typography variant={'body2'}>No announcements posted yet</Typography>
        </Box>
      );
    }

    return announcements.map((announcement, i) => {
      return (
        <Announcement
          user={user}
          key={`announcement-${announcement.id}`}
          announcement={announcement}
          pinAnnouncement={pinAnnouncement}
          deleteAnnouncement={deleteAnnouncement}
          editAnnouncement={editAnnouncement}
        />
      );
    });
  }

  return (
    <Box>
      {getEcosystemInfo().accessLevel?.canCreateAnnouncements && (
        <WriteAnnouncement
          announcements={announcements}
          setAnnouncements={setAnnouncements}
        />
      )}
      {renderAnnouncements()}
    </Box>
  );
};

export default Announcements;
