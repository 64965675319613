import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { SxProps, Theme } from '@mui/material';
import { white } from 'theme';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '53px',
    width: '100%',
    maxWidth: '100%',
    borderRadius: '4px !important',
    margin: '10px !important',

    '&:disabled': {
      backgroundColor: '#eee !important',
      color: '#757575 !important',
      border: 'none !important',
    },
  },
  filled: {
    backgroundColor: (props: any) =>
      props.backgroundColor
        ? `${props.backgroundColor} !important`
        : `${theme.palette.primary.dark} !important`,
    color: `${white} !important`,
  },
  outlined: {
    backgroundColor: 'transparent !important',
    color: `${theme.palette.primary.dark} !important`,
    border: (props: any) =>
      props.borderSize
        ? `${props.borderSize}px solid ${theme.palette.primary.dark} !important`
        : `2px solid ${theme.palette.primary.dark} !important`,
  },
  blank: {
    backgroundColor: 'transparent !important',
    color: `${theme.palette.primary.dark} !important`,
  },
  small: {
    width: '95px !important',
    height: '30px !important',
    margin: '0 !important',
    fontSize: '0.75rem !important',
    borderRadius: '4px !important',
    padding: '2px 5px !important',
  },
}));

export enum ButtonType {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  BLANK = 'blank',
}

interface IButton {
  text: string;
  type: ButtonType;
  onClick?: () => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: SxProps | undefined;
  disabled?: boolean;
  borderSize?: number;
  small?: boolean;
  disableRipple?: boolean;
  backgroundColor?: string;
  submit?: boolean;
  centerIcon?: boolean;
  loading?: boolean | undefined;
  noMargin?: boolean;
}

const MainButton: FunctionComponent<IButton> = (props) => {
  const {
    text,
    type,
    style,
    startIcon,
    endIcon,
    onClick,
    disabled,
    borderSize,
    small,
    disableRipple,
    backgroundColor,
    submit,
    centerIcon,
    loading,
  } = props;
  const classes = useStyles({ borderSize, backgroundColor, centerIcon });

  return (
    <Button
      className={clsx(classes.root, {
        [classes.blank]: type === ButtonType.BLANK,
        [classes.outlined]: type === ButtonType.OUTLINED,
        [classes.filled]: type === ButtonType.FILLED,
        [classes.small]: small,
      })}
      type={submit ? 'submit' : 'button'}
      startIcon={!loading && !centerIcon && startIcon}
      endIcon={!loading && !centerIcon && endIcon}
      sx={style}
      disabled={disabled || loading}
      disableRipple={disableRipple}
      onClick={onClick}>
      {loading ? (
        <CircularProgress
          color={'primary'}
          size={25}
          style={{ marginTop: '2px' }}
        />
      ) : (
        <span>{centerIcon ? startIcon ?? endIcon : text}</span>
      )}
    </Button>
  );
};

export default MainButton;
