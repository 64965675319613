import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ITag } from 'utils/models';
import { Box, SxProps } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: (props: any) => (props.oneRow ? 'nowrap' : 'wrap'),
    alignContent: 'flex-start',
  },
  tag: {
    borderRadius: '12px',
    padding: '5px 10px',
    fontSize: '0.85rem',
    backgroundColor: theme.palette.primary.light,
    margin: '0.25rem',
    color: '#333333',
    textAlign: 'center',
  },
}));

interface ITagDisplay {
  tags: ITag[] | undefined;
  oneRow?: boolean;
  maxTags?: number;
  sx?: SxProps;
}

const TagDisplay: FunctionComponent<ITagDisplay> = (props) => {
  const { tags, oneRow, maxTags, sx } = props;
  const classes = useStyles({ oneRow });

  let tagsToDisplay = tags;

  function renderTags() {
    if (!tagsToDisplay) {
      return null;
    }
    if (maxTags) {
      tagsToDisplay = tagsToDisplay?.slice(0, 3);
    }
    return tagsToDisplay.map((tag, i) => {
      const tagToDisplay = tag.name.startsWith('#') ? tag.name : `#${tag.name}`;
      return (
        <Box
          key={`${tag}-${i}`}
          className={classes.tag}
          sx={{
            maxHeight: 30,
            overflow: i < 3 ? '' : 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}>
          {tagToDisplay}
        </Box>
      );
    });
  }

  return (
    <Box
      className={classes.root}
      sx={{
        height: '100%',
        ...sx,
      }}>
      {renderTags()}
    </Box>
  );
};

export default TagDisplay;
