import { existsEcosystemTheme, getEcosystemTheme } from './utils/storage';

export const mainFont = 'Inter';

export const white = '#fafafa';
export const black = '#16181b';
export const grey = '#9e9e9e';
export const darkGrey = '#757575';

export const boxShadow = 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px';
export const cardShadow = 'rgba(0, 0, 0, 0.16) 0px 1px 4px';

const getStoredTheme = () => {
  const themeExists = existsEcosystemTheme();
  const theme = themeExists ? getEcosystemTheme() : acterioTheme;
  return theme;
};

export const naturalPallete = {
  mainBlackFont: '#1A1A1A',
  placeholderFont: '#1A1A1Aa3',
  primaryDisabledFont: '#13253E80',
  greyBorder: '#e0e0e0',

  natural1: '#333333',
  natural2: '#4D4D4D',
  natural3: '#666666',
  natural4: '#999999',
  natural5: '#B3B3B3',
  natural6: '#CCCCCC',
  natural7: '#E6E6E6',
  natural8: '#F2F2F2',
  natural9: '#FAFAFA',

  pureWhite: '#FFFFFF',
  whiteHighlight: '#F5F5F5',
};

export const colorPallete = {
  warningRed: '#CD2D2D',
  warningPlaceholder: '#CD2D2Da3',
  warning: '#F09898',
  warningHover: '#F6D5D5',

  successGreen: '#2DCC88',

  additionalGreen: '#C8F7DC',
  additionalLightGreen: '#EBFFF3',
  additionalDarkGreen: '#17BD90',

  additionalLightBlue: '#D5DEFF',
  additionalDeepBlue: '#5B9BE9',

  additionalPink: '#FFD3E2',
  additionalDarkPink: '#F95888',
  additionalLightPink: '#FFECF3',

  additionalOrange: '#FEE4CB',
  additionalLightOrange: '#FFF3E8',
  additionalDarkOrange: '#EB9357',

  additionalGrey: '#EFEFEF',
  greyButtonDefault: '#DCDCDC',
  greyButtonHover: '#848484',

  yellowStar: '#FFC700',
};

export const acterioTheme = {
  dark: '#13253E',
  main: '#138CC9',
  light: '#D0EDFB',
  soft: '#EBF1F9',

  ...naturalPallete,
  ...colorPallete,
};

export const primary = {
  ...acterioTheme,
  ...naturalPallete,
  ...colorPallete,
};
