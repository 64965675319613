import React, { FunctionComponent } from 'react';
import { Typography, Box, useTheme, SxProps } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
// import WebAssetIcon from '@mui/icons-material/WebAsset';
import FacebookIcon from '@mui/icons-material/Facebook';
import { X } from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import { Link } from 'react-router-dom';
import { primary } from 'theme';
import { FlexBox } from 'utils/styledComponents';

export interface ISocials {
  website: string | null;
  linkedin: string | null;
  instagram: string | null;
  facebook: string | null;
  twitter: string | null;
  email: string | null;
  telephone: string | null;
  address: string | null;
}

interface ICompanyContact {
  email?: string;
  telephone?: string;
  address?: string;
  sx?: SxProps;
  deactivate?: boolean;
}

const CompanyContact: FunctionComponent<ICompanyContact> = (props) => {
  const { email, telephone, address, deactivate } = props;
  const { companyProfile } = useCompanyContext();
  const theme = useTheme();

  function renderContactInfo() {
    return (
      <Box sx={{ color: primary.dark }}>
        {telephone && companyProfile!.telephone !== '' && (
          <FlexBox sx={{ justifyContent: 'end', mb: '0.5rem' }}>
            <Typography variant={'body1'} sx={{ mr: '1rem' }}>
              {(companyProfile!.telephone as string) || telephone}
            </Typography>
            <PhoneIcon />
          </FlexBox>
        )}
        {email && companyProfile!.email !== '' && (
          <FlexBox sx={{ justifyContent: 'end', mb: '0.5rem' }}>
            <Typography variant={'body1'} sx={{ mr: '1rem' }}>
              {(companyProfile!.email as string) || email}
            </Typography>
            <EmailIcon />
          </FlexBox>
        )}
      </Box>
    );
  }

  function renderSocials() {
    const { website, facebook, instagram, x, linkedin } = companyProfile!;

    const socials = { facebook, instagram, x, linkedin };

    return Object.keys(socials)
      .filter((key) => !!socials[key as keyof typeof socials])
      .map((key) => {
        const icon =
          // key === 'website' ? (
          //   <WebAssetIcon />
          // ) :

          key === 'facebook' ? (
            <FacebookIcon />
          ) : key === 'instagram' ? (
            <InstagramIcon />
          ) : key === 'x' ? (
            <X />
          ) : key === 'linkedin' ? (
            <LinkedInIcon />
          ) : null;

        return (
          <FlexBox
            key={key}
            sx={{
              ml: '0.5rem',
              alignItems: 'center',
            }}>
            {deactivate ? (
              <FlexBox style={{ color: primary.dark }}>{icon}</FlexBox>
            ) : (
              <Link
                to={socials[key as keyof typeof socials]!}
                target={'_blank'}
                style={{
                  color: primary.dark,
                }}>
                {icon}
              </Link>
            )}
          </FlexBox>
        );
      });
  }

  return (
    <Box>
      {renderContactInfo()}
      <FlexBox sx={{ justifyContent: 'end' }}>{renderSocials()}</FlexBox>
    </Box>
  );
};

export default CompanyContact;
