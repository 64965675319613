import AssessmentsGrid from 'components/Admin/Applications/Assessment/AssessmentsGrid';
import React, { FunctionComponent, useEffect } from 'react';
import { useAssessmentContext } from 'services/contexts/Applications/AssessmentContext';

interface IAssessmentRouter {}

const AssessmentRouter: FunctionComponent<IAssessmentRouter> = (props) => {
  const { selectedAssessmentProcessId, setSelectedAssessmentProcessId } =
    useAssessmentContext();

  useEffect(() => {
    setSelectedAssessmentProcessId(null);
  }, []);

  if (!selectedAssessmentProcessId) return <AssessmentsGrid />;

  return <>Assessment Template...</>;
};

export default AssessmentRouter;
