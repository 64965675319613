import React, { FunctionComponent, useState } from 'react';
import {
  Theme,
  Divider,
  Grid,
  Typography,
  Button,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import GridPost from './GridPost/GridPost';
import { IEvent } from 'utils/models';
import { Box } from '@mui/system';
import { ArrowForwardIos } from '@mui/icons-material';
import { getDateWithTime } from 'utils/utils';
import { useEventsContext } from 'services/contexts/Events/EventsContext';
import Loading from 'components/common/Loading';

const useStyles = makeStyles((theme: Theme) => ({
  placeholder: {
    height: '185px',
    padding: '1rem 2rem',
    '& p': {
      color: 'black',
    },
  },
}));

interface GridViewProps {}

const GridView: FunctionComponent<GridViewProps> = () => {
  const {
    filteredEvents,
    setMonthsToShow,
    todaysMonth,
    isCalendarLoading,
    callCloseEventDetails,
  } = useEventsContext();
  const events = filteredEvents;
  const classes = useStyles();

  const theme = useTheme();

  const [monthsIntoTheFuture, setMonthsIntoTheFuture] = useState<number[]>([1]);

  function pushNextMonth() {
    const lastMonth = monthsIntoTheFuture[monthsIntoTheFuture.length - 1];
    setMonthsIntoTheFuture((prevMonths) => [...prevMonths, lastMonth + 1]);
    setMonthsToShow((prev) => [...prev, lastMonth + todaysMonth + 1]);
  }

  function getCurrentDate() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentMonthName = currentDate.toLocaleString('en-us', {
      month: 'long',
    });
    const capitalizedMonthName =
      currentMonthName.charAt(0).toUpperCase() + currentMonthName.slice(1);
    const currentDay = currentDate.getDate();

    return {
      year: currentYear,
      month: currentMonth,
      monthName: capitalizedMonthName,
      day: currentDay,
    };
  }

  function getNewMonthsDate(monthsIntoTheFutureIndex: number) {
    const currentDate = new Date();
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + monthsIntoTheFutureIndex,
      1,
    );
    const year = nextMonth.getFullYear();
    const month = nextMonth.getMonth() + 1;
    const monthSmallName = nextMonth.toLocaleString('en-us', {
      month: 'long',
    });
    const monthName =
      monthSmallName.charAt(0).toUpperCase() + monthSmallName.slice(1);
    return { year, month, monthName };
  }

  const todaysDate = getCurrentDate();

  function filterEventsByMonth(
    events: IEvent[],
    month: number,
    year: number,
  ): IEvent[] {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (!events) return [];

    const upcomingEvents = events.filter((event) => {
      if (event.isEnd) return false;

      const eventDate = new Date(event.startDate);

      if (
        eventDate.getMonth() + 1 !== month ||
        eventDate.getFullYear() !== year
      ) {
        return false; // Exclude events not in the specified month and year.
      }

      if (event.startTime) {
        const [hours, minutes] = event.startTime.split(':').map(Number);
        eventDate.setHours(hours, minutes, 0, 0);
      }

      const endEvent = events.find((e) => e.isEnd && e.id === event.id);
      if (endEvent) {
        const endDate = new Date(endEvent.startDate);
        return endDate >= currentDate;
      }
      return currentDate <= eventDate;
    });

    const sortedEvents = upcomingEvents.sort(
      (a, b) => getDateWithTime(a) - getDateWithTime(b),
    );

    return sortedEvents;
  }

  const thisMonthsEvents = filterEventsByMonth(
    events,
    todaysDate.month,
    todaysDate.year,
  );

  function renderEvents(passedEvents: IEvent[]) {
    if (passedEvents.length === 0) {
      return (
        <div className={classes.placeholder}>
          <Typography variant={'body2'}>
            There are no upcoming events this month...
          </Typography>
        </div>
      );
    }

    return (
      <Grid item container spacing={4} marginBottom={4}>
        {passedEvents.map((event, index) => (
          <Grid item xs={12} md={4} sm={6} key={`${event.id},${index}`}>
            <GridPost event={event} handleClose={callCloseEventDetails} />
          </Grid>
        ))}
      </Grid>
    );
  }

  function renderMoreEventsButton() {
    if (isCalendarLoading) return <Loading marginTop={-300} />;
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={pushNextMonth} disabled={events.length === 0}>
          {events.length !== 0 ? (
            <Box display='flex'>
              <Typography variant='h6' fontSize={14}>
                See more upcoming events...
              </Typography>
              <ArrowForwardIos sx={{ color: theme.palette.primary.dark }} />
            </Box>
          ) : (
            <Box>
              <Typography variant='h6' fontSize={14}>
                There are currently no events to be displayed...
              </Typography>
            </Box>
          )}
        </Button>
      </Box>
    );
  }

  function renderMonthDivider(date: any) {
    return (
      <Grid container alignItems='center' sx={{ marginBottom: 2 }}>
        <Grid item>
          <Typography
            variant='h5'
            sx={{
              fontSize: 24,
              fontWeight: 'bold',
              marginBottom: 2,
              marginRight: 2,
              marginTop: 1,
            }}>{`${date.monthName} ${date.year}`}</Typography>
        </Grid>
        <Grid item xs={true} sx={{ width: '100%' }}>
          <Divider
            sx={{
              height: 'calc(100% - 0.5em)',
              width: '100%',
              marginTop: '-0.5em',
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function renderNextMonthsEvents(monthsAway: number) {
    const newMonthsDate = getNewMonthsDate(monthsAway);
    const newMonthsEvents = filterEventsByMonth(
      events,
      ((todaysDate.month + monthsAway - 1) % 12) + 1,
      newMonthsDate.year,
    );

    return (
      <Box key={Math.random()}>
        {renderMonthDivider(newMonthsDate)}
        {renderEvents(newMonthsEvents)}
      </Box>
    );
  }

  function renderFutureEvents() {
    return (
      <Box>
        {monthsIntoTheFuture.map((element: number) =>
          renderNextMonthsEvents(element),
        )}
      </Box>
    );
  }

  function renderCurrentMonth() {
    return (
      <Box>
        {renderMonthDivider(todaysDate)}
        {renderEvents(thisMonthsEvents)}
      </Box>
    );
  }

  return (
    <Grid width={'100%'}>
      {renderCurrentMonth()}
      {renderFutureEvents()}
      {renderMoreEventsButton()}
    </Grid>
  );
};

export default GridView;
