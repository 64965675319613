import React, { ChangeEvent, FunctionComponent } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import InputField from '../../common/InputField';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import { Box } from '@mui/system';
import {
  Email,
  Facebook,
  Instagram,
  LinkedIn,
  Phone,
  X,
} from '@mui/icons-material';
import { naturalPallete } from 'theme';
import CloseModalButton from 'components/common/Modal/CloseModalButton';

interface ICompanyEditSocialMedia {}

const CompanyEditSocialMedia: FunctionComponent<ICompanyEditSocialMedia> = (
  props,
) => {
  const { companyProfile, updateCompanyInfo } = useCompanyContext();
  const { linkedin, facebook, x, instagram, email, telephone } =
    companyProfile!;

  const theme = useTheme();

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item>
          <Email />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Email address*</Typography>
        </Grid>
        <Grid item xs={7}>
          <InputField
            value={email ?? ''}
            placeholder={'Company Email'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateCompanyInfo('email', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={1} sx={{ width: '5rem' }}>
          <CloseModalButton
            handleClose={() => updateCompanyInfo('email', '')}
            color={naturalPallete.mainBlackFont}
            sx={{ boxShadow: 'none' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item>
          <Phone />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Telephone</Typography>
        </Grid>
        <Grid item xs={7}>
          <InputField
            value={telephone ?? ''}
            placeholder={'Company Telephone'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateCompanyInfo('telephone', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={1} sx={{ width: '5rem' }}>
          <CloseModalButton
            handleClose={() => updateCompanyInfo('telephone', '')}
            color={naturalPallete.mainBlackFont}
            sx={{ boxShadow: 'none' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item>
          <LinkedIn />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Linkedin</Typography>
        </Grid>
        <Grid item xs={7}>
          <InputField
            value={linkedin ?? ''}
            placeholder={'Company Linkedin'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateCompanyInfo('linkedin', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={1} sx={{ width: '5rem', justifyContent: 'flex-end' }}>
          <CloseModalButton
            handleClose={() => updateCompanyInfo('linkedin', '')}
            color={naturalPallete.mainBlackFont}
            sx={{ boxShadow: 'none' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item>
          <Facebook />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Facebook</Typography>
        </Grid>
        <Grid item xs={7}>
          <InputField
            value={facebook ?? ''}
            placeholder={'Company Facebook'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateCompanyInfo('facebook', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={1} sx={{ width: '5rem', justifyContent: 'flex-end' }}>
          <CloseModalButton
            handleClose={() => updateCompanyInfo('facebook', '')}
            color={naturalPallete.mainBlackFont}
            sx={{ boxShadow: 'none' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item>
          <X />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>X</Typography>
        </Grid>
        <Grid item xs={7}>
          <InputField
            value={x ?? ''}
            placeholder={'Company X'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateCompanyInfo('x', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={1} sx={{ width: '5rem', justifyContent: 'flex-end' }}>
          <CloseModalButton
            handleClose={() => updateCompanyInfo('x', '')}
            color={naturalPallete.mainBlackFont}
            sx={{ boxShadow: 'none' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item>
          <Instagram />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Instagram</Typography>
        </Grid>
        <Grid item xs={7}>
          <InputField
            value={instagram ?? ''}
            placeholder={'Company Instagram'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateCompanyInfo('instagram', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={1} sx={{ width: '5rem', justifyContent: 'flex-end' }}>
          <CloseModalButton
            handleClose={() => updateCompanyInfo('instagram', '')}
            color={naturalPallete.mainBlackFont}
            sx={{ boxShadow: 'none' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyEditSocialMedia;
