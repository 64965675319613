import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme, Typography, Box, useTheme } from '@mui/material';
import {
  // ChangeCircle,
  // Replay,
  AddCircleOutlineOutlined,
  WarningAmber,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { primary } from 'theme';
import InputField from 'components/common/InputField';
import {
  BOOKING_ROOM_MANAGE,
  patchFormData,
  postFormData,
} from 'utils/requests';
import { useParams } from 'react-router';
import UploadImage from 'components/common/UploadImage';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import Loading from 'components/common/Loading';
import RichTextEditor from 'components/common/RichTextEditor';
import { IAvailabilityRadio, IRoom, TimeSpanKeys } from 'utils/models';
import { authenticateWithMicrosoft } from './MicrosoftRoomIntergration';
import CommonModal from 'components/common/Modal/CommonModal';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '40rem',
    margin: '0 auto',
    paddingBottom: '1rem',
  },
  cont: {},
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    display: 'flex',
  },
  modalContent: {
    height: '37.5rem',
    overflowY: 'auto',
  },
}));

interface AddRoomProps {
  isOpen: boolean;
  handleClose: () => void;
  rooms: IRoom[];
  setRooms: (rooms: any[]) => void;
  roomToEdit?: IRoom;
}

export const defaultRoom: IRoom = {
  title: '',
  description: '',
  capacity: undefined,
  location: '',
  picture: '',
  isActive: true,
  microsoftRoomAddress: '',
  availability: {
    booking: {
      choice: IAvailabilityRadio.DAYS,
      amount: 0,
      format: TimeSpanKeys.Days,
      startDate: '',
      endDate: '',
      timeslotOption: 0,
    },
    weeklyHours: {
      mon: {
        active: false,
        times: [{ startTime: '06:00', endTime: '22:00' }],
      },
      tue: {
        active: false,
        times: [{ startTime: '06:00', endTime: '22:00' }],
      },
      wed: {
        active: false,
        times: [{ startTime: '06:00', endTime: '22:00' }],
      },
      thu: {
        active: false,
        times: [{ startTime: '06:00', endTime: '22:00' }],
      },
      fri: {
        active: false,
        times: [{ startTime: '06:00', endTime: '22:00' }],
      },
      sat: {
        active: false,
        times: [{ startTime: '10:00', endTime: '12:00' }],
      },
      sun: {
        active: false,
        times: [{ startTime: '10:00', endTime: '12:00' }],
      },
    },
  },
};

const AddRoom: FunctionComponent<AddRoomProps> = (props) => {
  const { isOpen, handleClose, rooms, setRooms, roomToEdit } = props;
  const classes = useStyles();
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const { isMobile } = useMobileContext();

  const [coverImg, setCoverImg] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDefaultAvailability, setIsDefaultAvailability] = useState(false);
  const [isMicrosoftRoomAddressChanged, setIsMicrosoftRoomAddressChanged] =
    useState(false);

  const [currentRoom, setCurrentRoom] = useState<IRoom>(
    roomToEdit ?? defaultRoom,
  );

  const isAllRequiredFilled = !(
    currentRoom.title === '' ||
    (currentRoom.capacity ?? 0) < 1 ||
    currentRoom.description === '' ||
    currentRoom.location === ''
  );

  function setTitle(newTitle: string) {
    setCurrentRoom({ ...currentRoom, title: newTitle });
  }

  function setDescription(newDescription: string) {
    setCurrentRoom({ ...currentRoom, description: newDescription });
  }

  function setRoomCapacity(newCapacity: number) {
    if (newCapacity < 0) return;
    setCurrentRoom({ ...currentRoom, capacity: newCapacity });
  }

  function setLocation(newLocation: string) {
    setCurrentRoom({ ...currentRoom, location: newLocation });
  }

  function setMicrosoftRoomAddress(newAddress: string) {
    setIsMicrosoftRoomAddressChanged(true);
    setCurrentRoom({ ...currentRoom, microsoftRoomAddress: newAddress });
  }

  function removePhoto() {
    setCoverImg(null);
  }

  function renderHeader() {
    return (
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h5' sx={{ marginBottom: '1rem', paddingTop: 1 }}>
          {!isLoading
            ? !!roomToEdit
              ? 'Edit facility'
              : 'Create new facility'
            : !!roomToEdit
            ? 'Submiting changes...'
            : 'Creating new facility...'}
        </Typography>
        <Button
          variant='outlined'
          onClick={handleClose}
          sx={{
            marginBottom: '1rem',
            borderColor: theme.palette.primary.dark,
            width: 20,
          }}>
          <CloseIcon sx={{ color: theme.palette.primary.dark, fontSize: 20 }} />
        </Button>
      </Box>
    );
  }

  function renderAddPhoto() {
    return (
      <Box>
        <UploadImage
          width='100%'
          onUpload={(file) => setCoverImg(file)}
          height={200}
          borderColor={theme.palette.primary.dark}
          hideBorder
          full
          showButtons={true}
          onRemove={removePhoto}
          crop={true}
          aspectRatioWidth={2}
          aspectRatioHeight={1}
          initialSrc={roomToEdit?.picture}
          cameraIconOffset={'-18rem'}
        />
      </Box>
    );
  }

  function renderName() {
    return (
      <InputField
        value={currentRoom.title}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setTitle(event.target.value)
        }
        placeholder='Enter room name'
        readonly={isLoading}
        label={'Room name*'}
      />
    );
  }

  function renderLocation() {
    return (
      <InputField
        value={currentRoom.location}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setLocation(event.target.value)
        }
        placeholder='Enter room location'
        readonly={isLoading}
        label={'Location*'}
      />
    );
  }

  function renderMicrosoftRoomAddress() {
    return (
      <InputField
        value={currentRoom.microsoftRoomAddress || ''}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setMicrosoftRoomAddress(event.target.value)
        }
        placeholder='Enter email address of Microsoft room'
        readonly={isLoading}
        type={'email'}
        label={'Microsoft Room & Equipment Intergration'}
      />
    );
  }

  function renderDescription() {
    return (
      <Box marginBottom={'4rem'}>
        <Typography
          variant='body1'
          sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
          Room Description*
        </Typography>
        <Box
          sx={{
            display: 'flex',
            marginBottom: { xs: '10rem', sm: '3rem', md: '0rem' },
          }}>
          <RichTextEditor
            value={currentRoom.description}
            onChange={setDescription}
            readonly={isLoading}
            height={150}
          />
        </Box>
      </Box>
    );
  }

  function renderCreateButton() {
    return (
      <Box>
        {!isAllRequiredFilled && (
          <Box display='flex' sx={{ marginTop: '3.5rem' }}>
            <WarningAmber sx={{ marginRight: 1 }} />
            <Typography>Not all required filleds are filled!</Typography>
          </Box>
        )}
        <Box
          display='flex'
          justifyContent='space-between'
          sx={{ marginTop: isAllRequiredFilled ? 2 : 1 }}>
          <Button
            onClick={!!roomToEdit ? editRoom : saveNewRoom}
            disabled={isLoading || !isAllRequiredFilled}
            sx={{
              bgcolor: !isAllRequiredFilled
                ? primary.natural2
                : theme.palette.primary.dark,
              marginTop: '1rem',
              borderRadius: '6px',
              '&:hover': {
                backgroundColor: !isAllRequiredFilled
                  ? primary.natural2
                  : theme.palette.primary.main,
              },
            }}>
            <AddCircleOutlineOutlined sx={{ color: primary.pureWhite }} />
            <Typography
              sx={{
                color: primary.pureWhite,
                paddingLeft: 1,
                marginRight: 1,
              }}>
              {!!roomToEdit ? 'Save changes' : 'Create facility'}
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  }

  function renderRoomCapacity() {
    return (
      <Box display='flex' alignItems='center'>
        <InputField
          type='number'
          value={currentRoom.capacity}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setRoomCapacity(Number(event.target.value))
          }
          placeholder='Number'
          readonly={isLoading}
          label={'Room Capacity*'}
        />
      </Box>
    );
  }

  function renderAvailability() {
    return (
      <Box sx={{ marginTop: '1.5rem' }}>
        <Box display='flex'>
          <Typography
            variant='body1'
            sx={{ fontWeight: 'bold', marginRight: '1rem' }}>
            Availability
          </Typography>
          {isDefaultAvailability && (
            <Typography color={primary.natural2}>
              Default taken from room Availability
            </Typography>
          )}
        </Box>
        <Box marginY={'1rem'}>
          <Typography color={primary.natural2}>
            The Availability of the room can be set in the "Set room
            availability" tab. It is recommended to set it to your office
            opening hours.
          </Typography>
        </Box>
        {/* <Box marginTop='1rem' marginLeft={-1.1}>
              <Button onClick={() => setIsDefaultAvailability(prev=>!prev)} sx={{ bgcolor: 'inherit' }} >
                {isDefaultAvailability ? <ChangeCircle sx={{ color: acterioTheme.dark }} />  : <Replay sx={{ color: acterioTheme.dark, transform: 'rotate(-45deg)' }} />}
                <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: acterioTheme.dark, textDecoration: 'underline', marginX: 1}}>
                  {isDefaultAvailability ? 'Override availability' : 'Revert to default'}
                </Typography>
              </Button>
            </Box>   */}
      </Box>
    );
  }

  async function saveNewRoom() {
    setIsLoading(true);

    try {
      const roomData: any = {
        ecosystemName: ecosystemName,
        title: currentRoom.title,
        description: currentRoom.description,
        capacity: currentRoom.capacity,
        location: currentRoom.location,
        isActive: currentRoom.isActive,
        microsoftRoomAddress: currentRoom.microsoftRoomAddress,
      };

      const formData = new FormData();

      for (const key in roomData) {
        formData.append(key, roomData[key]);
      }

      if (coverImg) formData.append('image', coverImg, coverImg.name);

      const response = await postFormData(BOOKING_ROOM_MANAGE, formData, false);

      setRooms([...rooms, response]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'New room created',
      });
      if (
        currentRoom.microsoftRoomAddress &&
        ecosystemName &&
        response.id &&
        isMicrosoftRoomAddressChanged
      ) {
        authenticateWithMicrosoft(ecosystemName, response.id);
      }
      handleClose();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  async function editRoom() {
    setIsLoading(true);

    try {
      const roomData: any = {
        ecosystemName: ecosystemName,
        id: currentRoom.id,
        title: currentRoom.title,
        description: currentRoom.description,
        capacity: currentRoom.capacity,
        location: currentRoom.location,
        isActive: currentRoom.isActive,
        microsoftRoomAddress: currentRoom.microsoftRoomAddress,
      };

      const formData = new FormData();

      for (const key in roomData) {
        formData.append(key, roomData[key]);
      }

      if (coverImg) formData.append('image', coverImg, coverImg.name);

      const response = await patchFormData(
        BOOKING_ROOM_MANAGE,
        formData,
        false,
      );

      const editedRoomIndex = rooms.findIndex((r) => r.id === currentRoom.id);
      const updatedRooms = [...rooms];
      updatedRooms[editedRoomIndex] = response;

      setRooms(updatedRooms);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Facility edited',
      });

      if (currentRoom.microsoftRoomAddress && ecosystemName && currentRoom.id) {
        authenticateWithMicrosoft(ecosystemName, currentRoom.id);
      }
      handleClose();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  return (
    <CommonModal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '94%' : 700,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
        <Box>
          {renderHeader()}
          <Box className={classes.modalContent} marginRight={'-1rem'}>
            {isLoading ? (
              <Loading height={545} />
            ) : (
              <Box sx={{ marginRight: '1rem' }}>
                {renderAddPhoto()}
                {renderName()}
                {renderDescription()}
                {renderRoomCapacity()}
                {renderLocation()}
                {renderMicrosoftRoomAddress()}
                {renderAvailability()}
              </Box>
            )}
            {renderCreateButton()}
          </Box>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default AddRoom;
