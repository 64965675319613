import { Box, Link, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { naturalPallete } from 'theme';
import { FlexBox } from 'utils/styledComponents';
import GetFileIcon from './GetFileIcon';
import { IUploadFile } from 'utils/models';
import { Download, RemoveRedEye } from '@mui/icons-material';
import handleFileDownload from './HandleFileDownload';

interface IFileDisplay {
  file: IUploadFile;
}

const FileDisplay: FunctionComponent<IFileDisplay> = (props) => {
  const { file } = props;
  if (!file) return null;

  const theme = useTheme();

  return (
    <FlexBox
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        m: '1rem',
      }}>
      <FlexBox
        sx={{
          gap: '0.5rem',
        }}>
        <Box sx={{ img: { height: '1.7rem' } }}>
          <GetFileIcon file={file.name.split('.').pop()} />
        </Box>
        <Box
          sx={{
            a: {
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
                textDecorationColor: naturalPallete.natural4,
              },
            },
          }}>
          <Link href={file.url} target='_blank' rel='noopener noreferrer'>
            <Typography
              sx={{
                ':hover': {
                  color: naturalPallete.natural2,
                },
              }}>
              {file.name}
            </Typography>
          </Link>
        </Box>
      </FlexBox>
      <FlexBox sx={{ gap: '0.5rem' }}>
        <Link
          sx={{
            color: naturalPallete.mainBlackFont,
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }}
          target='_blank'
          component='button'
          onClick={() =>
            handleFileDownload(file.url as string, file.name as string)
          }>
          <Download />
        </Link>

        <Link
          sx={{
            color: naturalPallete.mainBlackFont,
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }}
          target='_blank'
          href={file.url as string}>
          <RemoveRedEye />
        </Link>
      </FlexBox>
    </FlexBox>
  );
};

export default FileDisplay;
