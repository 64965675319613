import { useContext, useEffect, useState } from 'react';
import { IMentor } from 'utils/models';
import { getStoredMentors, storeMentors } from 'utils/storage';
import { getData, MEETING_PEOPLE_ROUTE } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';

const useMentors = () => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [mentors, setMentors] = useState<IMentor[] | null>(getStoredMentors());
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchMentors();
  }, []);

  async function fetchMentors() {
    if (!ecosystemName) return null;
    setLoading(true);

    try {
      const data = await getData(MEETING_PEOPLE_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setMentors(data);
      storeMentors(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return {
    loading,
    mentors,
    setMentors,
  };
};

export default useMentors;
