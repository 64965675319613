import { Button, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { primary } from 'theme';
import { Add } from '@mui/icons-material';
import AddRoom from './AddRoom';
import { IRoom } from 'utils/models';
import Room from './Room';
import Loading from 'components/common/Loading';
import {
  BOOKING_ROOM_IS_AVAILABILITY_SET,
  BOOKING_ROOMS,
  getData,
} from 'utils/requests';

const ManageRooms = () => {
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();

  const theme = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowAddRoom, setIsShowAddRoom] = useState<boolean>(false);
  const [rooms, setRooms] = useState<IRoom[]>([]);
  const [isAvailability, setIsAvailability] = useState<boolean>(false);

  useEffect(() => {
    fetchAvailability();
    fetchRooms();
  }, []);

  async function fetchRooms() {
    setIsLoading(true);

    try {
      const data = await getData(BOOKING_ROOMS, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setRooms(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  async function fetchAvailability() {
    setIsLoading(true);

    try {
      const data = await getData(BOOKING_ROOM_IS_AVAILABILITY_SET, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setIsAvailability(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderRoomsList() {
    if (!isAvailability) {
      return (
        <Typography variant={'h5'} color={theme.palette.primary.main}>
          Please set an availability for your ecosystem before creating a room.
        </Typography>
      );
    }

    return (
      <Box marginTop={'0.5rem'}>
        {rooms.length ? (
          rooms.map((room, i) => (
            <Room key={i} room={room} rooms={rooms} setRooms={setRooms} />
          ))
        ) : (
          <Typography color={theme.palette.primary.main}>
            There are no facilities in the database. Create a new room using the
            + button.
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Box justifyContent='left' marginTop='2rem' marginBottom='2rem'>
        <Typography variant='h5' gutterBottom fontSize={'1.375rem'}>
          Manage Rooms
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          maxWidth: '71.25rem',
          minWidth: { xs: '80vw', sm: '70vw', md: '60vw', lg: '70rem' },
          minHeight: '45rem',
          bgcolor: '#F0F0F0',
          border: 1,
          borderColor: '#D4D4D4',
          borderRadius: '6px',
        }}>
        {isAvailability && (
          <Box
            sx={{
              bgcolor: '#FFFFFF',
              borderBottom: 1,
              borderColor: '#D4D4D4',
              borderRadius: '6px',
              padding: '1rem',
            }}>
            <Button
              onClick={() => setIsShowAddRoom(true)}
              sx={{
                backgroundColor: theme.palette.primary.dark,
                borderRadius: '6px',
                marginTop: 0,
                '&:hover': { bgcolor: theme.palette.primary.main },
              }}>
              <Add sx={{ color: primary.pureWhite }} />
              <Typography
                sx={{
                  marginLeft: 1,
                  marginRight: 1,
                  color: primary.pureWhite,
                }}>
                New Facility
              </Typography>
            </Button>
          </Box>
        )}
        <Box sx={{ width: '100%', padding: '1rem' }}>
          {isLoading ? <Loading /> : renderRoomsList()}
        </Box>
        {isShowAddRoom && (
          <AddRoom
            isOpen={isShowAddRoom}
            handleClose={() => {
              setIsShowAddRoom(false);
            }}
            rooms={rooms}
            setRooms={setRooms}
          />
        )}
      </Box>
    </Box>
  );
};

export default ManageRooms;
