import React, { FunctionComponent, useState } from 'react';

import { ControlButtonStyled } from '../Admin/Applications/ApplicationBuilder';
import CloseIcon from '@mui/icons-material/Close';
import { CometChat, Group } from '@cometchat/chat-sdk-javascript';
import { FlexBox } from '../../utils/styledComponents';
import InputField from '../common/InputField';
import CommonButton from '../common/CommonButton';
import { Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';

interface JoinProtectedGroupDemoProps {
  group: CometChat.Group;
  setIsPasswordMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveGroupChat: React.Dispatch<React.SetStateAction<Group | undefined>>;
  sx?: SxProps;
}

const PasswordMenu: FunctionComponent<JoinProtectedGroupDemoProps> = (
  props,
) => {
  const { group, setIsPasswordMenuActive, setActiveGroupChat, sx } = props;
  const [password, setPassword] = useState<string>('');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const theme = useTheme();

  function handleJoinClick() {
    if (password.length === 0) {
      setIsError(true);
      setErrorMessage('Please, type a password');
    } else {
      CometChat.joinGroup(
        group.getGuid(),
        CometChat.GroupType.Password,
        password,
      )
        .then((group: CometChat.Group) => {
          setActiveGroupChat(group);
          setIsPasswordMenuActive(false);
        })
        .catch((error) => {
          setIsError(true);
          setErrorMessage(error.message);
        });
    }
  }

  return (
    <FlexBox
      sx={{
        position: 'relative',
        width: '30rem',
        height: '30rem',
        backgroundColor: theme.palette.primary.light,
        flexDirection: 'column',
        justifyContent: 'center',
        ...sx,
      }}>
      <Typography
        variant='h4'
        sx={{
          marginBottom: '2rem',
        }}>
        Please, enter a password
      </Typography>

      <FlexBox
        sx={{
          gap: '0.5rem',
          flexDirection: 'column',
        }}>
        <InputField
          width={'10rem'}
          error={isError}
          value={password}
          helperText={errorMessage}
          onChange={(e) => setPassword(e.target.value)}
          type={'password'}
        />
        <CommonButton
          text={'Enter'}
          sx={{
            width: '17.9rem',
          }}
          onClick={handleJoinClick}
        />
      </FlexBox>

      <ControlButtonStyled
        onClick={() => setIsPasswordMenuActive(false)}
        sx={{
          position: 'absolute',
          top: '0.3rem',
          right: '0.3rem',
        }}>
        <CloseIcon />
      </ControlButtonStyled>
    </FlexBox>
  );
};

export default PasswordMenu;
