import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import Modal, { IBaseModal } from '../common/Modal';
import { acterioTheme, mainFont } from 'theme';
import InputField from '../common/InputField';
import { ACCOUNT_DELETE, deleteData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { useNavigate } from 'react-router';
import { clearAllStorage } from 'utils/storage';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0 1rem',
    paddingBottom: '0.3rem',
    marginBottom: '1rem',
  },
  cont: {
    padding: '0.6rem 0',
    marginBottom: '0.4rem',

    '& h6': {
      fontSize: '1.05rem !important',
      marginBottom: '0.3rem !important',
      color: `${acterioTheme.dark} !important`,
    },
  },
  helper: {
    fontFamily: `${mainFont} !important`,
    marginTop: '7px !important',
    fontSize: '0.825rem !important',
    display: 'block',
  },
}));

const DeleteAccount: FunctionComponent<IBaseModal> = (props) => {
  const { open, close } = props;
  const { isMobile } = useMobileContext();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);
  const navigate = useNavigate();

  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  async function deleteAccount() {
    setLoading(true);

    try {
      await deleteData(ACCOUNT_DELETE, [
        { name: 'confirmPassword', value: password },
      ]);

      clearAllStorage();
      navigate('/login');
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        title: '👋 Bye',
        message: 'Account Deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function renderContent() {
    return (
      <div className={classes.root}>
        <InputField
          value={password}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setPassword(event.target.value)
          }
          type={'password'}
          label={'Enter Password'}
        />
      </div>
    );
  }

  return (
    <Modal
      open={open}
      close={close}
      width={isMobile ? 360 : 600}
      isActerioTheme={true}
      title={'Delete Your Acterio Account'}
      largeHeader={true}
      showFooter={true}
      largeFooter={true}
      disabledSave={!password}
      footerButtonsFlexStart={true}
      saveButtonText={'Delete Account'}
      saveAction={() => deleteAccount()}
      backgroundColor={'white'}
      loading={loading}
      headerColor={acterioTheme.light}>
      {renderContent()}
    </Modal>
  );
};

export default DeleteAccount;
