import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Fade, Grid, Typography } from '@mui/material';
import CompanyWorkspace from './CompanyWorkSpace';
import Button from '@mui/material/Button';
import CompanyKPI from './CompanyKPI';
import CompanyGoals from './CompanyGoals';
import CompanyAdminDocuments from './CompanyAdminDocuments';
import CompanyResourceLinks from './CompanyResourceLinks';
import CompanySection from '../CompanySection';
import {
  COMPANY_NEW_NOTE_ROUTE,
  COMPANY_PROFILE_PRIVATE_ROUTE,
  COMPANY_PUT_WORKSPACE_SECTION_ROUTE,
  COMPANY_UPDATE_NOTE_ROUTE,
  getData,
  patchData,
  postData,
  putData,
} from 'utils/requests';
import { useParams } from 'react-router';
import Loading from 'components/common/Loading';
import {
  ICompanyDoc,
  ICompanyGoal,
  ICompanyKPI,
  ICompanyPrivateProfileInfo,
  ICompanySection,
  ICompanySharedNote,
} from 'utils/models';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useNavigate } from 'react-router-dom';
import MainButton, { ButtonType } from 'components/common/MainButton';
import CompanyEditNote from '../EditCompany/CompanyEditNote';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import CompanyPeople from '../ViewCompany/CompanyPeople';
import CompanyProblemAndMarket, {
  ICompanyForm,
} from './CompanyProblemAndMarket';
import { Box, useTheme } from '@mui/system';

enum MenuOption {
  // About = 'About',
  Team = 'Team',
  //Company_Stage = 'Company_Stage',
  Problem_And_Market = 'Problem_And_Market',
  //Product = 'Product',
  KPI = 'KPI',
}

export const MAX_NUM_LIST_ITEMS = 4;

interface ICompanyPrivateProfile {}

const CompanyPrivateProfile: FunctionComponent<ICompanyPrivateProfile> = (
  props,
) => {
  const { companyProfile } = useCompanyContext();

  const { name, canEditCompanyProfile } = companyProfile!;

  const { ecosystemName } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { addAlert } = useContext(AlertContext);

  const [note, setNote] = useState<ICompanySharedNote>(emptyNote());
  const [showNote, setShowNote] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<MenuOption>(
    MenuOption.Team,
  );
  const [patchingNote, setPatchingNote] = useState<boolean>(false);
  const [menuOptions, setMenuOptions] = useState({
    Team: 'Team',
    Problem_And_Market: 'Problem & Market',
    KPI: 'KPI',
  });
  const [privateCompany, setPrivateCompany] =
    useState<ICompanyPrivateProfileInfo | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchPrivateCompany();
  }, []);

  async function fetchPrivateCompany() {
    setLoading(true);

    try {
      const data: ICompanyPrivateProfileInfo = await getData(
        COMPANY_PROFILE_PRIVATE_ROUTE,
        [
          {
            name: 'companyName',
            value: encodeURIComponent(name),
          },
          {
            name: 'ecosystemName',
            value: ecosystemName,
          },
        ],
      );

      setPrivateCompany(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }
  //FOR TESTING ON FE ONLY
  // function updateSections(data: ICompanyForm) {
  //   setMenuOptions((prev) => ({
  //     ...prev,
  //     Problem_And_Market: data.sectionName,
  //   }));

  //   setPrivateCompany((prev) => {
  //     if (!prev) return prev;
  //     return { ...prev, companyWorkspaceSections: data.sections };
  //   });
  // }
  // REWORKED VERSION OF FUNCTION ABOVE
  async function updateSections(formData: ICompanyForm) {
    if (!formData || !formData.sections) return;
    setLoading(true);

    try {
      const data: ICompanySection[] = await putData(
        COMPANY_PUT_WORKSPACE_SECTION_ROUTE,
        [
          {
            name: 'companyName',
            value: encodeURIComponent(name),
          },
          {
            name: 'ecosystemName',
            value: ecosystemName,
          },
          { name: 'workspaceSectionsName', value: formData.sectionName },
        ],
        formData.sections,
      );

      setPrivateCompany((prev) => {
        if (!prev) return prev;

        const updatedPrivateProfile = {
          ...prev,
          companyWorkspaceSections: data,
        };

        if (formData.sectionName) {
          updatedPrivateProfile.workspaceSectionsName = formData.sectionName;
        }

        return updatedPrivateProfile;
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  }

  function emptyNote(): ICompanySharedNote {
    return {
      id: -1,
      noteTitle: '',
      noteContent: '',
      noteTag: '',
      dateCreated: '',
      simplifiedDate: '',
    };
  }

  async function saveOnBlur(title: string, content: string) {
    if (note.noteTitle === '' && note.noteContent === '') return;
    if (note.id !== -1) return await patchNote(title, content);
    return addNote(title, content);
  }

  async function saveNote() {
    if (note.noteTitle === '' && note.noteContent === '') return;
    if (note.id !== -1)
      return await patchNote(note.noteTitle, note.noteContent);
    return addNote(note.noteTitle, note.noteContent);
  }

  async function addNote(title: string, content: string) {
    setSaveLoading(true);

    try {
      const data = await postData(COMPANY_NEW_NOTE_ROUTE, [
        { name: 'noteTitle', value: encodeURIComponent(title) },
        { name: 'noteContent', value: encodeURIComponent(content) },
        { name: 'companyName', value: encodeURIComponent(name) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'New note added',
      });

      updateNote(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setSaveLoading(false);
  }

  async function patchNote(title: string, content: string) {
    setPatchingNote(true);

    try {
      const data = await patchData(COMPANY_UPDATE_NOTE_ROUTE, [
        {
          name: 'noteId',
          value: note.id,
        },
        {
          name: 'companyName',
          value: encodeURIComponent(name),
        },
        {
          name: 'noteTitle',
          value: encodeURIComponent(title),
        },
        {
          name: 'noteContent',
          value: encodeURIComponent(content),
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);
      updateNote(data, true);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setPatchingNote(false);
  }

  if (loading) return <Loading />;
  if (!privateCompany) return null;

  const {
    companyAdminDocs,
    companyGoals,
    companySharedDocs,
    companySharedNotes,
    keyPerformanceIndicators,
    resourceLinks,
    canUploadAdminDocs,
    journeyId,
  } = privateCompany;

  function updateNote(
    note: ICompanySharedNote,
    updated?: boolean,
    deleted?: boolean,
  ) {
    if (!privateCompany) return;

    const updatedNotes = deleted
      ? companySharedNotes.filter(({ id }) => id !== note.id)
      : updated
      ? companySharedNotes.map((n) => {
          if (note.id === n.id) {
            return note;
          }

          return n;
        })
      : [note, ...companySharedNotes];

    setPrivateCompany({
      ...privateCompany,
      companySharedNotes: updatedNotes,
    });

    if (deleted) {
      return setNote(updatedNotes[0]);
    }

    setNote(note);
  }

  function updateDocs(doc: ICompanyDoc, deleted: boolean) {
    if (!privateCompany) return;
    const { id, docType } = doc;

    const key =
      docType === 'resource'
        ? 'resourceLinks'
        : docType === 'admin'
        ? 'companyAdminDocs'
        : docType === 'shared'
        ? 'companySharedDocs'
        : '';

    let collection =
      docType === 'resource'
        ? privateCompany.resourceLinks
        : docType === 'admin'
        ? privateCompany.companyAdminDocs
        : docType === 'shared'
        ? privateCompany.companySharedDocs
        : [];

    if (!deleted) {
      collection = [doc, ...collection];
    } else {
      collection = collection.filter((c) => c.id !== id);
    }

    setPrivateCompany({
      ...privateCompany,
      [key]: collection,
    });
  }

  function updateGoals(goals: ICompanyGoal[]) {
    if (!privateCompany) return;

    setPrivateCompany({
      ...privateCompany,
      companyGoals: goals,
    });
  }

  function updateKPIs(kpis: ICompanyKPI[]) {
    if (!privateCompany) return;

    setPrivateCompany({
      ...privateCompany,
      keyPerformanceIndicators: kpis,
    });
  }

  function renderMenu() {
    return [...Object.entries(menuOptions)].map(([key, value]) => (
      <Button
        key={key}
        sx={{
          backgroundColor: key === activeSection ? 'primary.dark' : 'white',
          padding: '8px 15px',
          margin: '3px 0',
          borderRadius: '4px',
          fontWeight: 800,
          width: '100%',
          justifyContent: 'flex-start',
          border:
            key === activeSection
              ? `1px solid ${theme.palette.primary.dark}`
              : '1px solid #e0e0e0',
          '&:hover': {
            backgroundColor: key === activeSection ? 'primary.dark' : '',
          },
          '& h6': {
            fontSize: '0.8rem',
            color: key === activeSection ? 'white' : 'inherit',
          },
        }}
        onClick={() => {
          setActiveSection(key as MenuOption);
          saveNote();
          setNote(emptyNote());
          setShowNote(false);
        }}>
        <Typography variant={'h6'}>
          {key === MenuOption.Problem_And_Market &&
          privateCompany?.workspaceSectionsName
            ? privateCompany?.workspaceSectionsName
            : value.replaceAll('_', ' ').replace('And', '&')}
        </Typography>
      </Button>
    ));
  }

  function renderMainContent() {
    if (showNote) {
      if (!note) return;
      return (
        <CompanyEditNote
          saveNote={saveOnBlur}
          saveLoading={saveLoading || patchingNote}
          companyName={name}
          note={note}
          setNote={setNote}
          updateNoteDisplay={(
            n: ICompanySharedNote,
            updated?: boolean,
            deleted?: boolean,
          ) => updateNote(n, updated, deleted)}
        />
      );
    }

    // if (activeSection === MenuOption.About) {
    //   return (
    //     <CompanyAboutUs
    //       description={about.description}
    //       pitchVideoUrl={about.pitchVideoUrl}
    //       sideBySide={about.sideBySide}
    //     />
    //   );
    // }

    if (activeSection === MenuOption.Team) {
      return <CompanyPeople people={companyProfile!.team} />;
    }

    if (activeSection === MenuOption.Problem_And_Market) {
      if (!privateCompany) return null;

      return (
        <CompanyProblemAndMarket
          updateSections={updateSections}
          sections={privateCompany.companyWorkspaceSections}
          tabName={
            privateCompany.workspaceSectionsName ??
            menuOptions.Problem_And_Market
          }
          isLoading={loading}
        />
      );
    }

    if (activeSection === MenuOption.KPI) {
      return (
        <CompanyKPI
          keyPerformanceIndicators={keyPerformanceIndicators}
          companyName={name}
          updateKPIs={updateKPIs}
          canEditCompanyProfile={canEditCompanyProfile}
        />
      );
    }

    return null;
  }

  return (
    <>
      <Grid item xs={12} md={2} lg={2}>
        <Fade in={true} timeout={1000}>
          <div>
            {renderMenu()}
            {!!journeyId && (
              <Box
                sx={{
                  '& button': {
                    margin: '0.5rem 0',
                    height: '47px',
                  },
                }}>
                <MainButton
                  text={'Journey'}
                  onClick={() => {
                    saveNote();
                    navigate('journey');
                  }}
                  startIcon={<AssignmentTurnedInIcon />}
                  type={ButtonType.FILLED}
                />
              </Box>
            )}
          </div>
        </Fade>
      </Grid>
      <Grid item xs={12} md={7} lg={7}>
        <CompanyGoals
          goals={companyGoals}
          updateGoals={updateGoals}
          companyName={name}
          canEditCompanyProfile={canEditCompanyProfile}
        />
        {renderMainContent()}
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <CompanyWorkspace
          patchingNote={patchingNote}
          companyName={name}
          setNote={setNote}
          showNote={showNote}
          setShowNote={(show: boolean) => setShowNote(show)}
          sharedNotes={companySharedNotes}
          sharedDocs={companySharedDocs}
          updateDocs={updateDocs}
          canEditCompanyProfile={canEditCompanyProfile}
        />
        <div style={{ marginTop: '10px' }}>
          <CompanySection noPadding={true}>
            <div>
              <CompanyAdminDocuments
                companyName={name}
                documents={companyAdminDocs}
                updateDocs={updateDocs}
                seeMore={
                  privateCompany.companyAdminDocs.length > MAX_NUM_LIST_ITEMS
                }
                canUploadAdminDocs={canUploadAdminDocs}
              />
              <CompanyResourceLinks
                updateDocs={updateDocs}
                resourceLinks={resourceLinks}
                companyName={name}
                canEditCompanyProfile={canEditCompanyProfile}
              />
            </div>
          </CompanySection>
        </div>
      </Grid>
    </>
  );
};

export default CompanyPrivateProfile;
