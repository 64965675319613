import React, { FunctionComponent, useEffect, useState } from 'react';
import { ALERT_TYPES, IAlert } from './AlertContext';
import { makeStyles } from '@mui/styles';
import { Slide, Theme, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    overflow: 'hidden',
    lineHeight: '20px',
    right: 30,
    bottom: (props: any) => props.bottomPos,
    transition: 'bottom 2s linear',
    backgroundColor: '#1e272e',
    minWidth: '21.875rem',
    maxWidth: '21.875rem',
    minHeight: '4.375rem',
    borderRadius: '8px',
    cursor: 'pointer',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    zIndex: 10000,
  },
  icon: {
    minWidth: '4.375rem',
    maxWidth: '4.375rem',
    height: '4.375rem',
    backgroundColor: '#2f3542',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '5px',
  },
  message: {
    padding: '0 10px',
    textAlign: 'left',
    wordBreak: 'break-word',
    '& h6': {
      letterSpacing: '0.5px',
      color: 'white !important',
      textTransform: 'capitalize',
      fontWeight: 400,
    },

    '& p': {
      margin: '0',
      fontSize: '12px !important',
      color: '#a4b0be !important',
    },
  },
  helperIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  indicator: {
    position: 'absolute',
    left: 0,
    height: '5rem',
    width: '5px',
    backgroundColor: (props: any) => props.alertColor,
  },
}));

export const BASE_DURATION = 8000;
const MIN_DURATION = 5000;
const ERR_DURATION = 200000;
const TRANSITION_DURATION = 600;
const TRANSITION_OFFSET = 200;
const TRANSITION = 'cubic-bezier(0.68, -0.6, 0.32, 1.6)';
const GAP = 130;
const START_POS = 110;

const ALERT_THEMES: { [key: string]: string } = {
  info: '#778beb',
  error: '#ef3d59',
  success: '#00cc99',
  warning: '#f2c94c',
};

interface OwnProps {
  alert: IAlert;
  alertIndex: number;
  removeAlert: () => void;
}

const Alert: FunctionComponent<OwnProps> = (props) => {
  const { alert, alertIndex, removeAlert } = props;

  const alertColor = ALERT_THEMES[alert.type];
  const classes = useStyles({
    alertColor,
    bottomPos: (alertIndex + 1) * GAP - START_POS,
  });

  let duration = alert.duration ?? BASE_DURATION;
  if (duration < MIN_DURATION) duration = MIN_DURATION;
  if (alert.type === ALERT_TYPES.ERROR) duration = ERR_DURATION;

  const [close, setClose] = useState<boolean>(false);

  useEffect(() => {
    const dismiss = setTimeout(() => {
      removeAlert();
    }, duration);

    const slideOut = setTimeout(() => {
      setClose(true);
    }, duration - TRANSITION_DURATION);

    return () => {
      clearTimeout(dismiss);
      clearTimeout(slideOut);
    };
  }, []);

  useEffect(() => {
    let dismiss: NodeJS.Timeout;

    if (close) {
      dismiss = setTimeout(() => {
        removeAlert();
      }, TRANSITION_DURATION - TRANSITION_OFFSET);
    }

    return () => (close ? clearInterval(dismiss) : undefined);
  }, [close]);

  function renderIcon() {
    if (alert.type === ALERT_TYPES.ERROR) {
      return (
        <ErrorOutlineIcon sx={{ color: alertColor, height: 25, width: 25 }} />
      );
    }

    if (alert.type === ALERT_TYPES.SUCCESS) {
      return (
        <CheckCircleOutlineIcon
          sx={{ color: alertColor, height: 25, width: 25 }}
        />
      );
    }

    return <InfoIcon sx={{ color: alertColor, height: 25, width: 25 }} />;
  }

  function handleOnClick() {
    if (alert.action) {
      alert.action();
    }

    setClose(true);
  }

  return (
    <Slide
      direction={'left'}
      in={!close}
      timeout={TRANSITION_DURATION}
      easing={TRANSITION}
      mountOnEnter
      unmountOnExit>
      <div className={classes.root} onClick={() => handleOnClick()}>
        <div className={classes.icon}>{renderIcon()}</div>
        <div className={classes.indicator} />
        <div className={classes.message}>
          <Typography variant={'h6'}>{alert.title ?? alert.type}</Typography>
          <Typography variant={'body1'}>{alert.message}</Typography>
        </div>
        <div className={classes.helperIcon}>
          <CloseIcon sx={{ color: '#576574', height: 20, width: 20 }} />
        </div>
      </div>
    </Slide>
  );
};

export default Alert;
