import React, {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import Loading from 'components/common/Loading';
import {
  ApplicationStatus,
  IApplicant,
  IApplicantDetails,
  IApplicationOverview,
  IApplicationProccess,
  IApplicationTemplate,
} from 'utils/models';
import useFetchAllProcesses from 'hooks/Applications/useFetchAllProcesses';
import useFetchKanbanBoardData from 'hooks/Applications/useFetchKanbanBoardData';
import { getStoredNewApplication, storeNewApplication } from 'utils/storage';
import useFetchApplicantDetails from 'hooks/Applications/useFetchApplicantDetails';

export enum Layout {
  Kanban = 'kanban',
  List = 'list',
}

const useApplication = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [layoutMode, setLayoutMode] = useState<Layout>(Layout.Kanban);

  const [newApplicationSchema, setNewApplicationSchema] =
    useState<IApplicationTemplate | null>(getStoredNewApplication());

  const [applicationProcesses, setApplicationProcesses] = useState<
    IApplicationProccess[] | null
  >(null);

  const [applicationOverviewData, setApplicationOverviewData] =
    useState<IApplicationOverview | null>(null);

  const [applicationDetailsData, setApplicationDetailsData] =
    useState<IApplicantDetails | null>(null);

  const [selectedApplicationProcessId, setSelectedApplicationProcessId] =
    useState<string | null>(null);

  const [selectedApplicantId, setSelectedApplicantId] = useState<string | null>(
    null,
  );

  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  const [applicationStatus, setApplicationStatus] = useState<ApplicationStatus>(
    ApplicationStatus.ACTIVE,
  );

  const [selectedApplicationId, setSelectedApplicationId] = useState<
    number | string | undefined
  >(undefined);

  const fetchAllProps = { setIsLoading, setProcesses: setApplicationProcesses };
  const fetchAllProcesses = useFetchAllProcesses(fetchAllProps);

  const fetchKanbanProps = {
    selectedApplicationProcessId,
    setIsLoading,
    setApplicationOverviewData,
  };
  const { data: applicantOverviewData } =
    useFetchKanbanBoardData(fetchKanbanProps);

  const fetchDetailsProps = {
    selectedApplicationProcessId,
    selectedApplicantId,
    setIsLoading,
    setApplicationDetailsData,
  };
  const { data: aplicantDetailsData } =
    useFetchApplicantDetails(fetchDetailsProps);

  useEffect(() => {
    fetchAllProcesses();
  }, []);

  useEffect(() => {
    setApplicationOverviewData(applicantOverviewData);
  }, [applicantOverviewData]);

  useEffect(() => {
    setApplicationDetailsData(aplicantDetailsData);
  }, [aplicantDetailsData]);

  useEffect(() => {
    if (!newApplicationSchema) return;
    storeNewApplication(newApplicationSchema);
  }, [newApplicationSchema]);

  function findApplicantById(id: string | null): IApplicant | null {
    if (!id || !applicationOverviewData) return null;
    for (const column of applicationOverviewData.statuses) {
      for (const applicant of column.applicants) {
        if (!applicant.id) return null;
        if (applicant.id.toString() === id) {
          return applicant;
        }
      }
    }
    return null;
  }

  return {
    isLoading,
    newApplicationSchema,
    applicationProcesses,
    applicationOverviewData,
    selectedApplicationProcessId,
    selectedApplicantId,
    applicationDetailsData,
    applicationStatus,
    layoutMode,
    selectedApplicationId,
    isDuplicate,
    setIsLoading,
    setNewApplicationSchema,
    setApplicationProcesses,
    setApplicationOverviewData,
    setSelectedApplicationProcessId,
    setSelectedApplicantId,
    setApplicationDetailsData,
    setApplicationStatus,
    setLayoutMode,
    setSelectedApplicationId,
    setIsDuplicate,
  };
};

const ApplicationContext = createContext(
  {} as ReturnType<typeof useApplication>,
);

export const ApplicationProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const applicationContext = useApplication();
  const { isLoading } = applicationContext;
  if (isLoading) {
    return <Loading />;
  }

  return (
    <ApplicationContext.Provider value={applicationContext}>
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplicationContext = () => useContext(ApplicationContext);
