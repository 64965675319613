import {
  BreakpointOverrides,
  MenuItem,
  Radio,
  Select,
  SxProps,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent, useState } from 'react';
import { primary } from 'theme';
import CommonButton, { ButtonSize } from './CommonButton';

export interface ISelectMultiple {
  value: string | undefined;
  placeholder?: string;
  onChange: (value: any) => any;
  options: string[] | number[];
  getOptionLabel?: (option: any) => string;
  backgroundColor?: string;
  disabled?: boolean;
  height?: number | string | BreakpointOverrides;
  width?: number | string | BreakpointOverrides;
  filterButton?: boolean;
  sx?: SxProps;
  forwardedRef?: React.Ref<HTMLDivElement>;
  onClick?: () => void;
}

const SelectDropdown: FunctionComponent<ISelectMultiple> = (props) => {
  const {
    value,
    placeholder,
    onChange,
    options,
    backgroundColor,
    disabled,
    height,
    width,
    filterButton,
    getOptionLabel,
    sx,
    forwardedRef,
    onClick,
  } = props;

  const theme = useTheme();

  const [open, setOpen] = useState(false);

  function renderOptions() {
    return options.map((option, i) => {
      return (
        <MenuItem key={`select-option-${option}-index-${i}`} value={option}>
          <Radio
            checked={value === option}
            sx={{ color: theme.palette.primary.main }}
          />
          {getOptionLabel ? getOptionLabel(option) : option}
        </MenuItem>
      );
    });
  }

  function renderValueSelected(selected: string) {
    if (selected.length === 0) return placeholder ?? 'Select value...';

    return getOptionLabel ? getOptionLabel(selected) : selected;
  }

  return (
    <Select
      ref={forwardedRef}
      open={open}
      onOpen={() => {
        if (onClick) onClick();
        setOpen(true);
      }}
      onClose={() => setOpen(false)}
      disabled={disabled}
      displayEmpty
      value={value ?? ''}
      onChange={(event) => onChange(event.target.value as string)}
      renderValue={renderValueSelected}
      MenuProps={{
        sx: {
          maxHeight: '70vh',
        },
      }}
      sx={{
        width: width ?? '50%',
        minWidth: width ? 'unset' : '12rem',
        height: height ?? '2rem',
        minHeight: height ? 'unset' : '32px',
        bgcolor: backgroundColor ?? primary.pureWhite,
        borderRadius: '8px',
        '& .MuiSvgIcon-root': {
          transition: 'all 0.3s ease-in-out',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            border: '1px solid rgba(0, 0, 0, 0.23)',
          },
        ...sx,
      }}>
      {renderOptions()}
      {value !== '' && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            my: '0.5rem',
          }}>
          <CommonButton
            text='Clear selection'
            onClick={() => onChange('')}
            bgcolor={theme.palette.primary.light}
            color={primary.mainBlackFont}
            size={ButtonSize.SMALL}
            sx={{ width: '96%', margin: 'auto' }}
          />
        </Box>
      )}
    </Select>
  );
};

export default SelectDropdown;
