import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import CommonModal from 'components/common/Modal/CommonModal';
import ModalHeader from 'components/common/Modal/ModalHeader';
import React, { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router';
import { primary } from 'theme';
import { ICompanyPeople } from 'utils/models';

interface ISeePeople {
  isOpen: boolean;
  closeModal: () => void;
  people: ICompanyPeople[];
  modalTitle?: string;
}

const SeeMorePeopleModal: FunctionComponent<ISeePeople> = (props) => {
  const { isOpen, closeModal, people, modalTitle } = props;

  const { ecosystemName } = useParams();
  const navigate = useNavigate();

  return (
    <CommonModal open={isOpen} onClose={closeModal}>
      <Box
        sx={{
          width: '60vw',
          maxHeight: '40rem',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: primary.natural5 + ' transparent',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: 24,
          paddingY: '1.5rem',
          paddingX: '2rem',
          background: primary.pureWhite,
          borderRadius: '10px',
        }}>
        <ModalHeader
          handleClose={closeModal}
          text={modalTitle ?? 'Section'}
          textColor={primary.mainBlackFont}
        />
        <Grid
          container
          spacing={'2rem'}
          sx={{ textAlign: 'center', mt: 0, mb: '2rem' }}>
          {people.map((people) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              key={people.id}
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              onClick={() =>
                navigate(`/ecosystem/${ecosystemName}/members/${people.id}`)
              }>
              <img
                src={people.profilePic}
                style={{
                  height: '6rem',
                  width: '6rem',
                  borderRadius: '50%',
                  cursor: 'pointer',
                }}
              />

              <Typography variant={'h6'}>{people.fullName}</Typography>

              <Typography variant={'body1'}>
                {people.subtitle || people.role || 'No title'}
              </Typography>
              <AccessLevelTagCard
                accessLevelName={people.accessLevelName || 'Default'}
                accessLevelColor={people.accessLevelColor}
                height={'1.3rem'}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </CommonModal>
  );
};

export default SeeMorePeopleModal;
