import { Button, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { ITimes, ITimeslot } from 'utils/models';
import { primary } from 'theme';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { useMobileContext } from 'services/contexts/MobileContext';
import TimezoneOffsetDisplay from 'components/common/TimezoneOffsetDisplay';

interface IPickHours {
  selectedDate: any;
  hours: any;
  handleSelectTimeslot: any;
  selectedTimeslot: any;
  setIsConfirmHour: any;
  canBook: boolean | undefined;
}

const HoursPicker: FunctionComponent<IPickHours> = (props) => {
  const {
    selectedDate,
    hours,
    handleSelectTimeslot,
    selectedTimeslot,
    canBook,
    setIsConfirmHour,
  } = props;

  const theme = useTheme();

  const { isMobile } = useMobileContext();

  const currentDate = new Date();

  const isToday =
    selectedDate.getDate() === currentDate.getDate() &&
    selectedDate.getMonth() === currentDate.getMonth() &&
    selectedDate.getFullYear() === currentDate.getFullYear();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}>
      <Box marginLeft={'2rem'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1rem',
          }}>
          <Typography fontWeight='bold' fontSize={'2.5rem'} marginY={'2rem'}>
            {selectedDate.toLocaleString('en', {
              weekday: 'long',
              day: 'numeric',
              month: 'long',
            })}
          </Typography>
          <Typography variant='body2'>
            Displayed timezone: <TimezoneOffsetDisplay />
          </Typography>
        </Box>
        <Grid container>
          {hours &&
            hours.map((item: ITimes | ITimeslot, i: number) => {
              let isPastTime = false;

              if ('booked' in item && isToday && item.startTime) {
                const thisTime = new Date();
                const timeParts = item.startTime.split(':');
                thisTime.setHours(parseInt(timeParts[0], 10));
                thisTime.setMinutes(parseInt(timeParts[1], 10));
                thisTime.setSeconds(0);

                isPastTime = thisTime < currentDate;
              }

              return (
                <Grid
                  item
                  xs={6}
                  key={`Key${i}-item${item}`}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Button
                    onClick={() => handleSelectTimeslot(item)}
                    disabled={
                      isPastTime
                        ? isPastTime
                        : 'booked' in item
                        ? item.booked
                        : false
                    }
                    sx={{
                      bgcolor:
                        selectedTimeslot === item
                          ? theme.palette.primary.dark
                          : ('booked' in item && item.booked) || isPastTime
                          ? primary.natural7
                          : '#E4EFF0',
                      borderRadius: '6px',
                      margin: '0.5rem',
                      height: 60,
                      width: isMobile ? '100%' : 134,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color:
                        selectedTimeslot === item
                          ? theme.palette.primary.contrastText
                          : theme.palette.primary.main,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      },
                    }}>
                    <Tooltip
                      title={
                        'booked' in item && item.booked
                          ? 'Booked'
                          : 'Available for booking'
                      }>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '1.125rem',
                          textDecoration:
                            'booked' in item && item.booked
                              ? 'line-through'
                              : 'none',
                          textDecorationThickness: 2,
                          color: 'inherit',
                        }}>
                        {!canBook
                          ? `${item.startTime} - ${item.endTime}`
                          : item.startTime}
                      </Typography>
                    </Tooltip>
                  </Button>
                </Grid>
              );
            })}
        </Grid>
      </Box>
      {canBook && (
        <Box
          marginLeft={'2rem'}
          display='flex'
          flexDirection='column'
          alignItems='center'>
          {selectedTimeslot && (
            <Typography
              marginBottom={'-1rem'}
              fontWeight='bold'
              fontSize={
                '1.25rem'
              }>{`${selectedTimeslot?.startTime} - ${selectedTimeslot?.endTime}`}</Typography>
          )}
          <Button
            onClick={() => setIsConfirmHour(true)}
            disabled={!selectedTimeslot}
            sx={{
              bgcolor: !selectedTimeslot
                ? primary.natural2
                : theme.palette.primary.dark,
              width: '100%',
              height: 50,
              borderRadius: '6px',
              marginY: '2rem',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }}>
            <AddCircleOutlineOutlined
              sx={{ color: primary.pureWhite, fontSize: '1.625rem' }}
            />
            <Typography
              sx={{
                color: primary.pureWhite,
                paddingLeft: '0.5rem',
                fontSize: '1.125rem',
                fontWeight: 'bold',
              }}>
              Confirm timeslot
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default HoursPicker;
