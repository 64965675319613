import React, { FunctionComponent, useContext, useEffect } from 'react';
import Loading from 'components/common/Loading';
import { useLocation, useNavigate } from 'react-router';
import {
  BOOKING_PEOPLE_CALENDAR,
  BOOKING_ROOM_INTERGRATION,
  postData,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import {
  setIsCalendarConnected,
  setSkipCalendarConnection,
} from 'utils/storage';

interface CalendarSyncRequestProps {}

const CalendarSyncRequest: FunctionComponent<CalendarSyncRequestProps> = (
  props,
) => {
  const { addAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const code = query.get('code');
  const state = query.get('state');
  const error_description = query.get('error_description');
  const error_subcode = query.get('error_subcode');
  const error = query.get('error');

  useEffect(() => {
    if (!code || !state) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message:
          error_description ||
          error_subcode ||
          error ||
          'There was an error please contact Acterio staff',
      });
      navigate('/account');
      return;
    }

    try {
      const decodedState = JSON.parse(atob(state));
      const ecosystemName = decodeURIComponent(decodedState.ecosystemName);
      const { isGoogle, isRoom, roomId } = decodedState;

      if (!isRoom) {
        postData(BOOKING_PEOPLE_CALENDAR, [], {
          code: code,
          isGoogle: isGoogle,
        })
          .then(() => {
            addAlert({
              type: ALERT_TYPES.SUCCESS,
              message: 'Calendar Connected Successfully',
            });
            setIsCalendarConnected(true, isGoogle ? 'Google' : 'Outlook');
            setSkipCalendarConnection(true);
          })
          .catch((error) => {
            console.error('Error sending tokens to backend:', error);
            addAlert({
              type: ALERT_TYPES.ERROR,
              message: 'Failed to send tokens to backend',
            });
          });
      } else {
        postData(
          BOOKING_ROOM_INTERGRATION,
          [{ name: 'roomId', value: roomId }],
          {
            code: code,
            isGoogle: false,
          },
        )
          .then(() => {
            addAlert({
              type: ALERT_TYPES.SUCCESS,
              message: 'Calendar Connected Successfully To Room',
            });
          })
          .catch((error) => {
            console.error('Error sending tokens to backend:', error);
            addAlert({
              type: ALERT_TYPES.ERROR,
              message: 'Failed to send tokens to backend',
            });
          });
      }

      if (ecosystemName) {
        navigate(`/ecosystem/${ecosystemName}/booking/`);
      } else {
        navigate('/account');
      }
    } catch (error) {
      console.error('Error decoding state:', error);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: 'Invalid state parameter',
      });
      navigate('/dashboard');
    }
  }, []);

  return <Loading />;
};

export default CalendarSyncRequest;
