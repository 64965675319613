import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { Box, Grid, Typography } from '@mui/material';
import InputField from '../../common/InputField';
import { ICompanyPeople, ICompanyProfile } from 'utils/models';
import {
  COMPANY_DELETE_MEMBER_ROUTE,
  COMPANY_INVITE_MEMBER_ROUTE,
  deleteData,
  postData,
} from 'utils/requests';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from '../../Alert/AlertContext';
import SearchUsers from '../../common/SearchUsers';
import { getEcosystemInfo } from 'utils/storage';
import CommonButton, { ButtonSize } from 'components/common/CommonButton';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import { FlexBox } from 'utils/styledComponents';
import CloseModalButton from 'components/common/Modal/CloseModalButton';
import { primary } from 'theme';

interface ICompanyEditTeam {
  members: ICompanyPeople[];
}

const CompanyEditTeam: FunctionComponent<ICompanyEditTeam> = (props) => {
  const { members } = props;
  const { ecosystemName } = useParams();
  const { companyProfile, setCompanyProfile } = useCompanyContext();
  const { addAlert } = useContext(AlertContext);
  const accessLevel = getEcosystemInfo().accessLevel;
  const [emailToInvite, setEmailToInvite] = useState<string>('');
  const [roleForInvite, setRoleForInvite] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [inviteLoading, setInviteLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const isLoading = loading || inviteLoading || deleteLoading;

  async function inviteMember() {
    setInviteLoading(true);

    try {
      await postData(COMPANY_INVITE_MEMBER_ROUTE, [
        { name: 'inviteToEmail', value: emailToInvite },
        { name: 'role', value: roleForInvite },
        {
          name: 'companyName',
          value: encodeURIComponent(companyProfile!.name),
        },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `Email sent to ${emailToInvite}`,
      });
      setEmailToInvite('');
      setRoleForInvite('');
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setInviteLoading(false);
  }

  async function deleteMember(id: number) {
    setDeleteLoading(true);

    try {
      await deleteData(COMPANY_DELETE_MEMBER_ROUTE, [
        { name: 'userId', value: id },
        {
          name: 'companyName',
          value: encodeURIComponent(companyProfile!.name),
        },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      updateCompanyMembers(members.filter((member) => member.id !== id));
    } catch (e: any) {
      console.error('error', e);
      setDeleteLoading(false);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  function updateMemberRole(
    id: number,
    field: keyof ICompanyPeople,
    value: any,
  ) {
    if (companyProfile) {
      const updatedCompanyProfile = { ...companyProfile };
      const updatedMembers = updatedCompanyProfile.team.map((member) => {
        if (id === member.id) {
          return {
            ...member,
            [field]: value,
          };
        }
        return member;
      });

      updatedCompanyProfile.team = updatedMembers;
      setCompanyProfile(updatedCompanyProfile);
    }
  }

  function updateCompanyMembers(updatedCompanyList: ICompanyPeople[]) {
    if (!companyProfile) return;

    const updatedCompanyProfile: ICompanyProfile = {
      ...companyProfile,
      team: updatedCompanyList,
    };

    setCompanyProfile(updatedCompanyProfile);
  }

  function renderMemberList() {
    return companyProfile!.team.map((member, i) => {
      const { id, role, profilePic, fullName } = member;

      return (
        <>
          <Grid container spacing={1} key={`member-${id}`} sx={{ mb: '1rem' }}>
            <Grid item xs={5}>
              <FlexBox>
                <Box>
                  <img
                    src={profilePic}
                    style={{
                      height: '2rem',
                      width: '2rem',
                      borderRadius: '50%',
                      marginRight: '1rem',
                    }}
                  />
                </Box>
                <Typography variant={'h6'}>{fullName}</Typography>
              </FlexBox>
            </Grid>
            <Grid item xs={4}>
              <InputField
                value={role}
                onChange={(value) =>
                  updateMemberRole(id, 'role', value.target.value)
                }
                placeholder={'Position at company'}
                readonly={isLoading}
              />
            </Grid>
            <Grid item xs={1}>
              <CloseModalButton
                handleClose={() => deleteMember(id)}
                color={primary.mainBlackFont}
                sx={{ boxShadow: 'none' }}
              />
            </Grid>
          </Grid>
        </>
      );
    });
  }

  return (
    <>
      <Box>
        <Grid item xs={6} sx={{ paddingBottom: '1rem' }}>
          <SearchUsers
            onSearch={(value) => setEmailToInvite(value.emailEcoPreferred)}
            fullWidth={false}
          />
        </Grid>

        <Box>
          {accessLevel && accessLevel.canCompaniesInviteUsers && (
            <Typography variant={'h6'}>
              Or invite a member to {ecosystemName}
            </Typography>
          )}
          <Grid container spacing={1}>
            <Grid item xs={5.5}>
              <Box>
                {accessLevel && accessLevel.canCompaniesInviteUsers && (
                  <InputField
                    value={emailToInvite}
                    placeholder={"Recipient's Email Address"}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setEmailToInvite(event.target.value)
                    }
                    readonly={isLoading}
                    autoCompleteOff
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                <InputField
                  value={roleForInvite}
                  placeholder={'Role'}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setRoleForInvite(event.target.value)
                  }
                  readonly={isLoading}
                  autoCompleteOff
                />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <CommonButton
                text='Invite'
                isDisabled={!roleForInvite || !emailToInvite || isLoading}
                onClick={inviteMember}
                isLoading={inviteLoading}
                size={ButtonSize.SMALL}
                sx={{
                  width: '4rem',
                  paddingY: '1.3rem',
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Grid container spacing={1} sx={{ mb: '0.5rem', mt: '2rem' }}>
        <Grid item xs={5}>
          <Typography variant={'h6'}>Member</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant={'h6'}>Role</Typography>
        </Grid>
      </Grid>
      {renderMemberList()}
    </>
  );
};

export default CompanyEditTeam;
