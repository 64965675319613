import { APPLICATIONS_PROCESS, deleteData } from 'utils/requests';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useContext } from 'react';
import { IApplicationProccess } from 'utils/models';

interface IDeleteProps {
  id: number | undefined;
  setIsLoading: (b: boolean) => void;
  applicationProcesses: IApplicationProccess[] | null;
  setApplicationProcesses: (d: IApplicationProccess[]) => void;
  handleClose: () => void;
}

const useDeleteApplicationProcess = (props: IDeleteProps) => {
  const {
    id,
    setIsLoading,
    applicationProcesses,
    setApplicationProcesses,
    handleClose,
  } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  async function deleteProcess() {
    setIsLoading(true);
    try {
      await deleteData(APPLICATIONS_PROCESS, [
        {
          name: 'applicationProcessId',
          value: id,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      if (!applicationProcesses) return;
      const updatedProcesses = applicationProcesses?.filter(
        (process) => process.id !== id,
      );
      setApplicationProcesses(updatedProcesses);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        title: 'Process Deleted',
        message: 'Application Process Deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
    handleClose();
  }

  return deleteProcess;
};

export default useDeleteApplicationProcess;
