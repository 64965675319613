import { useContext, useEffect, useState } from 'react';
import { ACCESS_CONTROL_ROUTE, getData } from 'utils/requests';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { getStoredAccessLevels, storeAccessLevels } from 'utils/storage';

const useFetchAccessLevels = () => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [accessLevel, setAccessLevel] = useState<any>(
    getStoredAccessLevels() ?? [],
  );

  useEffect(() => {
    fetchAccessLevel();
  }, []);

  async function fetchAccessLevel() {
    try {
      const data = await getData(ACCESS_CONTROL_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);
      const accessLevelArr = data.accessLevelDtos;
      storeAccessLevels(accessLevelArr);
      setAccessLevel(accessLevelArr);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  return { accessLevel };
};

export default useFetchAccessLevels;
