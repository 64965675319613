import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import { naturalPallete, primary } from 'theme';
import { useParams } from 'react-router';
import { getAccount, getStoredMyProfile, storeMyProfile } from 'utils/storage';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { IProfile, ITag } from 'utils/models';
import { PROFILE_ROUTE, getData, patchData } from 'utils/requests';
import Loading from 'components/common/Loading';
import MemberPhotoSection from 'components/Members/Member/MemberPhotoSection';
import MemberHeaderSection from 'components/Members/Member/MemberHeaderSection';
import InputField from 'components/common/InputField';
import CardTag from 'components/common/CardTag';

interface IMemberPage {}

const Member: FunctionComponent<IMemberPage> = (props) => {
  const {} = props;
  const { ecosystemName, profileOwnerId } = useParams();
  const { userId } = getAccount();
  const { addAlert } = useContext(AlertContext);

  const [isEditProfile, setIsEditProfile] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [member, setMember] = useState<IProfile | null>();
  const [memberOriginal, setMemberOriginal] = useState<IProfile | null>();

  const MAX_TEXT_LENGTH = 255;

  useEffect(() => {
    if (profileOwnerId) {
      if (userId.toString() === profileOwnerId && getStoredMyProfile()) {
        setMember(getStoredMyProfile);
      } else {
        fetchMember();
      }
    }
  }, [profileOwnerId]);

  async function fetchMember() {
    setIsLoading(true);

    try {
      const memberData = await getData(PROFILE_ROUTE, [
        { name: 'profileOwnerId', value: profileOwnerId as string },
        { name: 'ecosystemName', value: ecosystemName as string },
      ]);
      const memberDataParsed = JSON.parse(JSON.stringify(memberData));
      setMember(memberDataParsed);
      setMemberOriginal(memberDataParsed);
      window.scrollTo(0, 0);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function updateMember(field: string, value: string | ITag[] | null) {
    if (!member) return;

    setMember({
      ...member,
      [field]: value,
    });

    if (field === 'primaryCompany' && value === 'No primary company') {
      setMember({
        ...member,
        [field]: null,
      });
    }
  }

  async function saveEditProfile() {
    setIsEditProfile(false);
    if (JSON.stringify(member) === JSON.stringify(memberOriginal)) return;
    setIsLoading(true);

    try {
      const formDataToSend = getImgToSend();

      const fieldsToUpdate = [
        { name: 'subtitle', value: member?.subtitle },
        { name: 'city', value: member?.city },
        { name: 'country', value: member?.country },
        { name: 'phone', value: member?.phoneNumber || null },
        { name: 'ecoEmail', value: member?.email },
        { name: 'mentorLink', value: member?.mentorLink },
        { name: 'linkedInLink', value: member?.linkedInLink },
        { name: 'ama', value: member?.askMeAbout },
        { name: 'iAm', value: member?.iAm },
        { name: 'iValue', value: member?.iValue },
        { name: 'achievements', value: member?.achievements },
        { name: 'ecosystemName', value: ecosystemName as string },
        { name: 'tags', value: JSON.stringify(member?.usedTags) },
        { name: 'companyName', value: member?.primaryCompany },
        { name: 'userId', value: profileOwnerId as string },
      ];
      fieldsToUpdate.forEach((field) => {
        if (field.value !== undefined && field.value !== null) {
          formDataToSend.append(field.name, field.value.toString());
        }
      });

      if (!formDataToSend) {
        setIsLoading(false);
        return;
      }

      const data = await patchData(PROFILE_ROUTE, undefined, formDataToSend);

      storeMyProfile(data);
      setMember(data);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Profile updated',
      });
    } catch (e: any) {
      setIsEditProfile(true);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function getImgToSend() {
    const imgToSend = member?.profilePic;
    if (
      imgToSend === memberOriginal?.profilePic ||
      !(imgToSend instanceof Blob)
    )
      return new FormData();

    const formData = new FormData();
    formData.append(
      'profile_image',
      imgToSend,
      'profile-pic-' + userId + '.png',
    );
    return formData;
  }

  function renderEditOrDisplay(
    value: string,
    onChange: (value: string) => void,
    placeholder?: string,
  ) {
    if (!value && !isEditProfile) {
      return (
        <Typography variant={'body1'} color={naturalPallete.natural3}>
          No information
        </Typography>
      );
    }

    if (isEditProfile) {
      return (
        <div>
          <Typography
            variant={'h6'}
            sx={{ color: !value ? primary.warningRed : undefined }}>
            {!value ? 0 : value.length}/{MAX_TEXT_LENGTH}
          </Typography>
          <InputField
            value={value}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChange(event.target.value)
            }
            multiline={true}
            rows={5}
            readonly={isLoading}
            placeholder={placeholder}
            maxLength={MAX_TEXT_LENGTH}
          />
        </div>
      );
    }

    return (
      <Typography variant={'body1'} sx={{ whiteSpace: 'pre-line' }}>
        {value}
      </Typography>
    );
  }

  function renderField(
    field: keyof IProfile,
    text: string,
    description: string,
  ) {
    if (!member) return null;
    if (!isEditProfile && !member[field]) return null;
    return (
      <Box sx={{ mb: '1rem', mr: '1.5rem' }}>
        <CardTag text={text} sx={{ display: 'inline-block', mb: '0.5rem' }} />

        <Card sx={{ p: '1rem' }}>
          {renderEditOrDisplay(
            member[field],
            (value) => updateMember(field, value),
            description,
          )}
        </Card>
      </Box>
    );
  }

  function renderTextSection() {
    return (
      <Grid container sx={{ mt: '1rem', pb: '6rem' }}>
        <Grid item xs={12}>
          {renderField(
            'iAm',
            `Who am I in ${ecosystemName}`,
            'Let users know more about you',
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: 'flex', flexDirection: 'column' }}>
          {renderField(
            'achievements',
            'Area of interest',
            'What is your area of interest?',
          )}
          {renderField(
            'askMeAbout',
            'Ask me about',
            'What can users ask you about',
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: 'flex', flexDirection: 'column' }}>
          {renderField('iValue', 'Key areas', 'What are your key areas?')}
          {/* {renderField('languages', 'Languages', 'Share languages you speak')} */}
        </Grid>
      </Grid>
    );
  }

  if (isLoading && !member) return <Loading />;

  if (!member) return null;

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          overflow: 'hidden',
          border: `1px solid ${naturalPallete.natural5}`,
        }}>
        <Box
          sx={{
            height: '12rem',
            width: '100%',
            bgcolor: naturalPallete.natural3,
          }}></Box>
        <Grid
          container
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
          }}>
          <Grid item xs={12} md={4}>
            <MemberPhotoSection
              member={member}
              updateMember={updateMember}
              isEditProfile={isEditProfile}
              saveEditProfile={saveEditProfile}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ display: 'flex', flexDirection: 'column' }}>
            <MemberHeaderSection
              member={member}
              isEditProfile={isEditProfile}
              setIsEditProfile={setIsEditProfile}
              updateMember={updateMember}
              saveEditProfile={saveEditProfile}
            />
            {renderTextSection()}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Member;
