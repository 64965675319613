import React, { FunctionComponent, useState } from 'react';
import { Fade, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  CheckCircle,
  // ControlPointDuplicate,
  DeleteRounded,
} from '@mui/icons-material';
import { ICompanyProfileTemplate } from 'utils/models';
import { useAssignCompanyProfilesContext } from './AssignCompanyProfileContext';
import { Box } from '@mui/system';
import MoreOptionsOverlay from 'components/common/MoreOptionsButton/MoreOptionsOverlay';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';
import { IListButton } from 'utils/models';
import { acterioTheme } from 'theme';
import InformationModal from 'components/common/Modal/InformationModal';
import ErrorIcon from '@mui/icons-material/Error';

interface ICompanyProfilePreview {
  companyProfileTemplate: ICompanyProfileTemplate;
  selectable?: boolean;
}

const CompanyProfilePreview: FunctionComponent<ICompanyProfilePreview> = (
  props,
) => {
  const { companyProfileTemplate, selectable } = props;
  const { id, name, updateDate } = companyProfileTemplate;
  const {
    isSelectedTemplate,
    addTemplateId,
    removeTemplateId,
    handleDeleteCompanyTemplate,
    handleDuplicateTemplate,
  } = useAssignCompanyProfilesContext();

  const navigate = useNavigate();
  const isSelected = selectable && !!id && isSelectedTemplate(id);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderModal = () => {
    return (
      <InformationModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        headerText='Are you sure you want to delete this company profile?'
        primaryText='Delete'
        primaryOnClick={() => handleDeleteCompanyTemplate(id)}
        primaryBgColor={acterioTheme.warningRed}
        secondaryText='Cancel'
        secondaryOnClick={() => setIsModalOpen(false)}>
        <div>
          <ErrorIcon
            sx={{ color: acterioTheme.warningRed, marginRight: '1rem' }}
          />
          The profile will be permanently deleted from the templates
        </div>
      </InformationModal>
    );
  };

  const buttonList: IListButton[] = [
    // {
    //   name: 'Duplicate',
    //   onClick: handleDuplicate,
    //   icon: ControlPointDuplicate,
    // },
    {
      name: 'Delete',
      onClick: () => setIsModalOpen(true),
      icon: DeleteRounded,
      textColor: acterioTheme.warningRed,
    },
  ];

  function handleOnClick() {
    if (!selectable) return navigate(`${id}`);
    if (!id) return;

    return isSelected ? removeTemplateId() : addTemplateId(id);
  }

  function renderSelected() {
    if (!selectable) return null;

    return (
      <div>
        <Fade in={isSelected} timeout={300}>
          <CheckCircle />
        </Fade>
      </div>
    );
  }

  return (
    <Box
      sx={{
        width: '12.5rem',
        height: '12.5rem',
        borderRadius: '12px',
        border: '#CCCCCC solid 1px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 2px 4px 0px #15151514',
        margin: '1rem 1rem 0 0',
        '&:hover': {
          border: `2px solid ${acterioTheme.main}`,
        },
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.625rem',
            marginLeft: '1rem',
            color: '#999999',
          }}>
          Updated {updateDate}
        </Typography>
        <MoreOptionsOverlay
          popoverAnchorEl={popoverAnchorEl}
          setPopoverAnchorEl={setPopoverAnchorEl}>
          <MoreOptionsButtons buttonList={buttonList} />
        </MoreOptionsOverlay>
      </Box>
      {renderModal()}
      {renderSelected()}
      <Box
        onClick={handleOnClick}
        component='img'
        src='/images/illustrations/Customize_profiles_grey.png'
        alt='application forms illustration'
        sx={{
          width: '5rem',
          alignSelf: 'center',
          cursor: 'pointer',
        }}
      />
      <Typography
        sx={{
          fontWeight: '600',
          fontSize: '1.25rem',
          textAlign: 'center',
        }}>
        {name}
      </Typography>
    </Box>
  );
};

export default CompanyProfilePreview;
