import React, { FunctionComponent } from 'react';
import { Box, Card } from '@mui/material';
import { CompanyProfileFieldType, ICompanyField } from 'utils/models';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import CardTag from 'components/common/CardTag';
import RichTextEditor from 'components/common/RichTextEditor';
import { colorPallete, naturalPallete } from 'theme';
import { isEmptyRichText } from 'utils/utils';
import CompanySectionUploadImages from '../CompanySectionUploadImages';

interface ICompanyEditMain {}

const CompanyEditMain: FunctionComponent<ICompanyEditMain> = () => {
  const { companyProfile, updateCompanyField, checkIfInfoIsMissing } =
    useCompanyContext();

  function renderRichTextEditors() {
    return companyProfile!.main.map((field: ICompanyField) => {
      checkIfInfoIsMissing(field, 'richText');
      if (!field) return;
      if (field.type === CompanyProfileFieldType.RICH_TEXT) {
        return (
          <Box key={'rich-text-editor-' + field.id}>
            <CardTag
              text={field.name || 'Section'}
              sx={{
                display: 'inline-flex',
                my: '0.5rem',
              }}
            />

            <Card
              sx={{
                mb: '1rem',
                border: `1px solid ${
                  field.required && isEmptyRichText(field.richText)
                    ? colorPallete.warningRed
                    : naturalPallete.natural6
                }`,
              }}>
              <Box sx={{ px: '0.5rem', pt: '0.5rem', pb: '0.5rem' }}>
                <RichTextEditor
                  sx={{
                    '& .ql-container': {
                      borderRadius: '0 0 6px 6px',
                    },
                    '& .ql-toolbar': {
                      borderRadius: '6px 6px 0 0',
                    },
                  }}
                  value={field.richText}
                  onChange={(value) => updateCompanyField(field.id, value)}
                />
              </Box>
            </Card>
          </Box>
        );
      }
      if (field.type === CompanyProfileFieldType.IMAGES) {
        return (
          <Box key={field.id}>
            <CardTag
              text={field.name || 'Section'}
              sx={{
                display: 'inline-flex',
                my: '0.5rem',
              }}
            />

            <Card
              sx={{
                mb: '1rem',
              }}>
              <Box sx={{ px: '0.5rem', pt: '0.5rem', pb: '0.5rem' }}>
                <CompanySectionUploadImages field={field} />
              </Box>
            </Card>
          </Box>
        );
      }
    });
  }

  return <>{renderRichTextEditors()} </>;
};

export default CompanyEditMain;
