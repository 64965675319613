import { FiberManualRecord, Star, Visibility } from '@mui/icons-material';
import { Divider, Grid, Typography } from '@mui/material';
import CommonButton from 'components/common/CommonButton';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { primary } from 'theme';
import { IApplicantReportOverview, IListButton } from 'utils/models';
import AssessmentApplicantReportDetails from './AssessmentApplicantReportDetails';
import MoveFowardToButton from 'components/common/MoreOptionsButton/MoveFowardToButton';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useAssessmentContext } from 'services/contexts/Applications/AssessmentContext';
import useUpdateApplicant from 'hooks/Applications/useUpdateApplicant';

interface IAssessmentReportApplicantRowProps {
  applicant: IApplicantReportOverview;
}

const AssessmentReportApplicantRow: FunctionComponent<
  IAssessmentReportApplicantRowProps
> = (props) => {
  const { applicant } = props;

  const { addAlert } = useContext(AlertContext);

  const { assessmentReportDetails, setAssessmentReportDetails } =
    useAssessmentContext();

  const statuses = assessmentReportDetails?.statuses ?? [];
  const selectedApplicationProcessId = assessmentReportDetails?.applicationId;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selcetedColumnId, setSelectedColumnId] = useState<number | null>(null);

  useEffect(() => {
    if (!selcetedColumnId) return;
    patchApplicant();
    setSelectedColumnId(null);
  }, [selcetedColumnId]);

  const updateParams = {
    applicantId: applicant.applicantId,
    destinationColumnId: selcetedColumnId,
    selectedApplicationProcessId,
    setIsLoading,
    successPopup,
  };
  const patchApplicant = useUpdateApplicant(updateParams);

  const buttonList: IListButton[] = [
    {
      name: 'Select stage:',
      onClick: () => null,
    },
    ...statuses
      .filter((status) => status.name !== applicant.currentStatus)
      .map((status) => ({
        name: status.name,
        onClick: () => {
          setSelectedColumnId(status.id ?? null);
        },

        icon: FiberManualRecord,
      })),
  ];

  function successPopup(statusId: number) {
    setAssessmentReportDetails((prev) => {
      if (!prev) return prev;
      const statusName =
        statuses.find((status) => status.id === statusId)?.name ?? 'Moved';

      return {
        ...prev,
        applicants: prev.applicants.map((a) => {
          if (a.applicantId === applicant.applicantId) {
            return {
              ...a,
              currentStatus: statusName,
            };
          }
          return a;
        }),
      };
    });

    addAlert({
      type: ALERT_TYPES.SUCCESS,
      message: 'Candidate moved',
    });
  }
  function handleViewDetails() {
    setIsModalOpen(true);
  }

  function renderApplicantDetailsModal() {
    if (!isModalOpen) return <></>;

    return (
      <>
        <AssessmentApplicantReportDetails
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          applicantId={applicant.applicantId}
        />
      </>
    );
  }

  function roundScore(input: number) {
    return Math.round(input * 100) / 100;
  }

  return (
    <Grid container item xs={12} columnSpacing={'1rem'}>
      <Grid item xs={3}>
        <Typography
          variant='body1'
          sx={{ color: primary.natural1, fontWeight: 'bold' }}>
          {applicant.applicantName}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex' }}>
        <Star sx={{ color: primary.successGreen, mr: '0.25rem' }} />
        <Typography
          variant='body1'
          sx={{ color: primary.successGreen, fontWeight: 'bold' }}>
          {roundScore(applicant.overallRating)}
        </Typography>
      </Grid>
      <Grid item xs={1.75} sx={{ display: 'flex' }}>
        {/* <Star sx={{ color: primary.natural3, mr: '0.25rem' }} /> */}
        <Typography
          variant='body1'
          sx={{ color: primary.natural3, fontWeight: 'bold' }}>
          {applicant.applicantCompanyName}
        </Typography>
      </Grid>
      <Grid item xs={1.75} sx={{ display: 'flex' }}>
        {/* <Star sx={{ color: primary.natural3, mr: '0.25rem' }} /> */}
        <Typography
          variant='body1'
          sx={{ color: primary.natural3, fontWeight: 'bold' }}>
          {applicant.currentStatus}
        </Typography>
      </Grid>
      <Grid
        item
        xs={3.5}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '1rem',
        }}>
        <CommonButton
          text='Details'
          icon={<Visibility />}
          onClick={handleViewDetails}
        />
        {isLoading ? (
          <CommonButton sx={{ width: '14rem' }} isLoading />
        ) : (
          <MoveFowardToButton buttonList={buttonList} />
        )}
      </Grid>
      <Grid xs={12}>
        <Divider sx={{ width: '100%', mt: '0.75rem', mb: '1.5rem' }} />
      </Grid>
      {renderApplicantDetailsModal()}
    </Grid>
  );
};

export default AssessmentReportApplicantRow;
