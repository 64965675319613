import React, { FunctionComponent, ReactElement } from 'react';
import CommonModal from './CommonModal';
import ModalBox from './ModalBox';
import { Box, SxProps } from '@mui/system';
import Loading from '../Loading';
import ModalHeader from './ModalHeader';
import FooterButtonGroup, { IButtonGroupProps } from './FooterButtonGroup';
import { naturalPallete, primary } from 'theme';
import { useTheme } from '@mui/material';

export interface IInfoModal {
  children?: ReactElement;
  isOpen: boolean;
  handleClose: () => void;
  isLoading?: boolean;
  maxHeight?: string | number;
  minButtonWidth?: string | number;
  noCloseButton?: boolean;
  headerSize?: string | number;
  width?: string | number;

  primaryText?: string;
  primaryOnClick?: () => void;
  primaryBgColor?: string;
  primaryBgHoverColor?: string;

  secondaryText?: string;
  secondaryOnClick?: () => void;

  tertiaryText?: string;
  tertiaryOnClick?: () => void;

  headerText: string;
  headerIcon?: ReactElement;
  headerTextColor?: string;

  buttonSx?: SxProps;
  footerButtonsSx?: SxProps;
}

const InformationModal: FunctionComponent<IInfoModal> = (props) => {
  const {
    children,
    isOpen,
    handleClose,
    isLoading,
    maxHeight,
    primaryText,
    primaryOnClick,
    primaryBgColor,
    secondaryText,
    secondaryOnClick,
    tertiaryText,
    tertiaryOnClick,
    headerText,
    headerTextColor,
    minButtonWidth,
    noCloseButton,
    headerSize,
    width,
    buttonSx,
    headerIcon,
    footerButtonsSx,
    ...sx
  } = props;

  const theme = useTheme();

  const hasSecondaryButton = secondaryText && secondaryOnClick;

  const hasTertiaryButton = tertiaryText && tertiaryOnClick;

  const buttonGroup: IButtonGroupProps = {
    primaryButton: {
      text: primaryText ?? 'Close',
      onClick: primaryOnClick ?? handleClose,
      bgcolor: primaryBgColor ?? theme.palette.primary.dark,
      sx: buttonSx,
    },
    ...(hasSecondaryButton && {
      secondaryButton: {
        text: secondaryText,
        color: primaryBgColor ?? theme.palette.primary.dark,
        onClick: secondaryOnClick,
        sx: buttonSx,
      },
    }),
    ...(hasTertiaryButton && {
      tertiaryButton: {
        text: tertiaryText,
        onClick: tertiaryOnClick,
        sx: buttonSx,
      },
    }),
  };

  return (
    <CommonModal open={isOpen} onClose={handleClose}>
      <div>
        <ModalBox width={width}>
          <Box paddingX={'1rem'}>
            <ModalHeader
              text={headerText}
              handleClose={handleClose}
              textColor={headerTextColor ?? primary.mainBlackFont}
              noCloseButton={noCloseButton}
              headerSize={headerSize}
              icon={headerIcon}
            />
            <Box
              sx={{
                maxHeight: maxHeight ?? '37.5rem',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: naturalPallete.natural5 + ' transparent',
                paddingRight: '0.5rem',
              }}
              marginRight={-2}>
              {isLoading ? <Loading height={545} /> : <Box>{children}</Box>}
            </Box>
            <FooterButtonGroup
              sx={{
                ...footerButtonsSx,
              }}
              {...buttonGroup}
              minButtonWidth={minButtonWidth}
            />
          </Box>
        </ModalBox>
      </div>
    </CommonModal>
  );
};

export default InformationModal;
