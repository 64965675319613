import React, { FunctionComponent, ReactNode } from 'react';

interface UmamiWrapperProps {
  event: string;
  children: ReactNode;
}

const UmamiWrapper: FunctionComponent<UmamiWrapperProps> = (props) => {
  const { event, children } = props;

  const handleClick = () => {
    if (window.umami) {
      window.umami.track(event);
    }
  };

  return <div onClick={handleClick}>{children}</div>;
};

export default UmamiWrapper;
