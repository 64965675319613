import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { formatDateDD } from 'utils/utils';
import Room from './Room';
import { IBookedRoom } from 'utils/models';

interface BookedRoomsListProps {
  rooms: IBookedRoom[];
  setRooms: (rooms: any) => void;
}

const BookedRoomsList: FunctionComponent<BookedRoomsListProps> = (props) => {
  const { rooms, setRooms } = props;

  function renderDays() {
    let lastDay: Date | null = null;
    return (
      <Box padding={4}>
        {rooms.length ? (
          rooms.map((room, index) => {
            const isANewDate = !(
              new Date(room.date).setHours(0, 0, 0, 0) ===
              (lastDay === null ? lastDay : lastDay.setHours(0, 0, 0, 0))
            );
            if (isANewDate) lastDay = new Date(room.date);
            return (
              <Box key={`${room.id} + ${index}`}>
                {isANewDate && (
                  <Typography fontWeight='bold' fontSize={18}>
                    {lastDay && formatDateDD(lastDay)}
                  </Typography>
                )}
                <Room room={room} setRooms={setRooms} />
              </Box>
            );
          })
        ) : (
          <Typography>There are no booked rooms...</Typography>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        bgcolor: 'inherit',
        padding: 0,
        margin: 2,
        borderRadius: 0.2,
        width: '100%',
      }}>
      <Box marginTop={-2} marginLeft={-2}>
        {renderDays()}
      </Box>
    </Box>
  );
};

export default BookedRoomsList;
