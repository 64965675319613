import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme, useTheme } from '@mui/material';
import MainButton, { ButtonType } from './MainButton';
import { Add } from '@mui/icons-material';
import { primary } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    bottom: (props: any) => (props.isMobile ? '5.5rem' : '20px'),
    right: (props: any) => (props.isMobile ? 'calc(50% - 2px)' : '4.375rem'),
    minWidth: (props: any) =>
      props.isMobile
        ? 'unset'
        : props.minWidth
        ? `${props.minWidth}px`
        : '8.75rem',

    width: (props: any) => (props.isMobile ? '24px' : 'unset'),
    zIndex: 1,

    '& button': {
      fontWeight: 600,
      fontSize: '14px !important',
      height: (props: any) => (props.isMobile ? 'unset' : '50px !important'),
      boxShadow: (props: any) =>
        props.loading ? 'none' : 'rgba(0, 0, 0, 0.35) 0px 5px 15px !important',

      '& svg': {
        height: '24px',
        width: '24px',
      },
    },
  },
}));

interface IFloatingActionButton {
  text: string;
  onClick: () => void;
  loading?: boolean;
  icon?: React.ReactNode;
  minWidth?: number;
  color?: string;
  isMobile?: boolean;
}

const FloatingActionButton: FunctionComponent<IFloatingActionButton> = (
  props,
) => {
  const { text, onClick, loading, icon, minWidth, color, isMobile } = props;
  const classes = useStyles({ minWidth, loading, isMobile });

  const theme = useTheme();

  return (
    <div className={classes.root}>
      {isMobile ? (
        <Button
          onClick={onClick}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 'unset',
            minHeight: 'unset',
            width: '4rem !important',
            height: '4rem !important',
            borderRadius: '24rem',
            bgcolor: theme.palette.primary.main,
            color: primary.pureWhite,
          }}>
          <Add />
        </Button>
      ) : (
        <MainButton
          loading={loading}
          text={text}
          type={ButtonType.FILLED}
          startIcon={icon}
          onClick={onClick}
          backgroundColor={color}
        />
      )}
    </div>
  );
};

export default FloatingActionButton;
