import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import InputField from 'components/common/InputField';
import React, { FunctionComponent } from 'react';
import {
  Controller,
  FieldErrors,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { primary } from 'theme';
import {
  IApplicationAssessmentOption,
  IApplicationAssessmentTemplate,
} from 'utils/models';
import { FlexBox } from 'utils/styledComponents';
import { ControlButtonStyled } from '../ApplicationBuilder';
import { AddCircle, Close } from '@mui/icons-material';
import AssessmentOption from './AssessmentOption';
import AssessmentPercentageInput from './AssessmentPercentageInput';

interface IAssessmentSubsectionQuestion {
  control: any;
  errors: FieldErrors<IApplicationAssessmentTemplate>;
  sectionIndex: number;
  subSectionIndex: number;
  resetField: any;
  newOptions: IApplicationAssessmentOption[];
  isWeighted: boolean;
}

const AssessmentSubsectionQuestion: FunctionComponent<
  IAssessmentSubsectionQuestion
> = (props) => {
  const {
    control,
    sectionIndex,
    subSectionIndex,
    errors,
    resetField,
    newOptions,
    isWeighted,
  } = props;

  const theme = useTheme();

  const {
    fields: questionFields,
    append: appendQuestion,
    remove: removeQuestion,
  } = useFieldArray<any>({
    name: `sections.${sectionIndex}.subSections.${subSectionIndex}.questions`,
    control,
  });

  // Watch the questions to compute total weight
  const questions = useWatch({
    control,
    name: `sections.${sectionIndex}.subSections.${subSectionIndex}.questions`,
    defaultValue: [],
  });

  // Determine if any question weight is provided
  const anyQuestionWeightProvided = questions.some(
    (question: any) => question.weight !== undefined && question.weight > 0,
  );

  // Compute total question weight
  const totalQuestionWeight = anyQuestionWeightProvided
    ? questions.reduce((total: number, question: any) => {
        const weight = parseFloat(question.weight?.toString() || '0');
        return total + weight;
      }, 0)
    : null;

  const isWeightsValid =
    totalQuestionWeight === 0 ||
    totalQuestionWeight === 100 ||
    totalQuestionWeight === null;

  return (
    <>
      {questionFields.map((question, questionIndex) => {
        const errorPath =
          errors?.sections?.[sectionIndex]?.subSections?.[subSectionIndex]
            ?.questions?.[questionIndex]?.name;

        return (
          <Box
            key={question.id}
            sx={{
              width: '100%',
              marginTop: '1.25rem',
            }}>
            <FlexBox sx={{}}>
              <FlexBox
                sx={{
                  flexDirection: 'column',
                  alignItems: 'start',
                  width: '100%',
                }}>
                <FlexBox
                  className={'between'}
                  sx={{
                    alignItems: 'start',
                    width: '100%',
                    gap: '1.625rem',
                  }}>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <FlexBox
                      className={'center'}
                      sx={{
                        width: '30px',
                        minWidth: '30px',
                        height: '30px',
                        backgroundColor: theme.palette.primary.main,
                        color: primary.pureWhite,
                        borderRadius: '100%',
                        marginRight: '0.525rem',
                        marginBottom: errorPath ? '1.7rem' : '',
                      }}>
                      {questionIndex + 1}
                    </FlexBox>
                    <Controller
                      name={`sections.${sectionIndex}.subSections.${subSectionIndex}.questions.${questionIndex}.name`}
                      control={control}
                      rules={{
                        required: 'Please, type the question',
                      }}
                      render={({ field }) => (
                        <InputField
                          {...field}
                          onChange={(
                            e: React.ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                          ) => {
                            field.onChange(e);
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              opacity:
                                sectionIndex === 0 && questionIndex < 2
                                  ? '200%'
                                  : '100%',
                              '& .MuiInputBase-input': {
                                WebkitTextFillColor: primary.mainBlackFont,
                              },
                            },
                          }}
                          helperText={errorPath && errorPath.message}
                          helperIcon={errors.sections && 'warning'}
                          error={!!errorPath}
                          maxLength={255}
                          placeholder={'Criteria/question/statement'}
                          width={'70%'}
                          clearBackground
                        />
                      )}
                    />
                  </Box>
                  {isWeighted && (
                    <AssessmentPercentageInput
                      control={control}
                      errors={errors}
                      sectionIndex={sectionIndex}
                      subSectionIndex={subSectionIndex}
                      questionIndex={questionIndex}
                      isWeightsValid={isWeightsValid}
                      bgColor={primary.additionalLightGreen}
                      border={`1px solid ${primary.successGreen}`}
                    />
                  )}
                  <ControlButtonStyled
                    disabled={sectionIndex === 0 && questionIndex < 1}
                    onClick={() => removeQuestion(questionIndex)}>
                    <Close />
                  </ControlButtonStyled>
                </FlexBox>
                <AssessmentOption
                  resetField={resetField}
                  errors={errors}
                  sectionIndex={sectionIndex}
                  subSectionIndex={subSectionIndex}
                  questionIndex={questionIndex}
                  control={control}
                />
              </FlexBox>
            </FlexBox>
          </Box>
        );
      })}
      <ControlButtonStyled
        sx={{
          marginTop: '0.75rem',
        }}
        onClick={() =>
          appendQuestion({
            name: '',
            options: newOptions,
          })
        }>
        <AddCircle />{' '}
        <Typography
          sx={{
            marginLeft: '0.625rem',
          }}>
          Add question/statement
        </Typography>
      </ControlButtonStyled>
    </>
  );
};

export default AssessmentSubsectionQuestion;
