import React, { FunctionComponent, useState } from 'react';
import { Box } from '@mui/material';
import CompanySection from '../CompanySection';
import { FlexBox } from 'utils/styledComponents';
import CommonButton from 'components/common/CommonButton';
import { naturalPallete } from 'theme';
import { KeyboardArrowRight } from '@mui/icons-material';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import PeopleInTheBoxDisplay from './PeopleInTheBoxDisplay';
import SeeMorePeopleModal from './SeeMorePeopleModal';
import { ICompanyPeople } from 'utils/models';

export interface IPeople {}

interface ICompanyPeopleDisplay {
  people: ICompanyPeople[];
}

const CompanyPeople: FunctionComponent<ICompanyPeopleDisplay> = (props) => {
  const { people } = props;

  const { companyProfile } = useCompanyContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function renderSeeMoreTeamButton() {
    return (
      <FlexBox className='end'>
        <CommonButton
          text='See more'
          onClick={openModal}
          bgcolor='none'
          color={naturalPallete.mainBlackFont}
          endIcon={<KeyboardArrowRight />}
          sx={{
            width: '10rem',
            padding: '0rem',
            '&:hover': {
              backgroundColor: naturalPallete,
            },
          }}
        />
      </FlexBox>
    );
  }

  return (
    <CompanySection title={'Team'}>
      <>
        <Box sx={{ padding: '2rem 1rem 1rem 1.5rem' }}>
          <PeopleInTheBoxDisplay people={people} />
          {renderSeeMoreTeamButton()}
        </Box>
        <SeeMorePeopleModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          people={people}
          modalTitle={
            companyProfile!.name ? `Team in ${companyProfile!.name}` : 'Team'
          }
        />
      </>
    </CompanySection>
  );
};

export default CompanyPeople;
