import { Button, Grid, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent, useState, useContext } from 'react';
import { primary } from 'theme';
import {
  Delete,
  DriveFileRenameOutline,
  Place,
  RemoveRedEye,
  VisibilityOff,
} from '@mui/icons-material';
import { IListButton, IRoom } from 'utils/models';
import RoomDetails from '../RoomBooking/RoomDetails';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { BOOKING_ROOM_MANAGE, deleteData, patchFormData } from 'utils/requests';
import AddRoom from './AddRoom';
import RichTextDisplay from 'components/common/RichTextDisplay';
import MoreOptionsOverlay from 'components/common/MoreOptionsButton/MoreOptionsOverlay';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';

interface RoomProps {
  room: IRoom;
  rooms: IRoom[];
  setRooms: (rooms: any) => void;
}

const Room: FunctionComponent<RoomProps> = (props) => {
  const { room, rooms, setRooms } = props;
  const {
    picture,
    title,
    description,
    capacity,
    location,
    availability,
    isActive,
    id,
    microsoftRoomAddress,
  } = room;

  const theme = useTheme();

  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [isShowDetails, setIsShowDetails] = useState<boolean>(false);
  const [isShowEdit, setIsShowEdit] = useState<boolean>(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const buttonList: IListButton[] = [
    {
      name: 'Edit room',
      onClick: handleEditRoom,
      icon: DriveFileRenameOutline,
    },
    {
      name: isActive ? 'Hide from' : 'Show in' + 'booking',
      onClick: handleToggleActive,
      icon: VisibilityOff,
    },
    {
      name: 'Delete room',
      onClick: handleDeleteRoom,
      icon: Delete,
      textColor: primary.warningRed,
    },
  ];

  function handleEditRoom() {
    setPopoverAnchorEl(null);
    setIsShowEdit(true);
  }

  function handleToggleActive() {
    setPopoverAnchorEl(null);
    putRoomChanges();
  }

  function handleDeleteRoom() {
    setPopoverAnchorEl(null);
    deleteRoom();
  }

  async function deleteRoom() {
    setIsLoading(true);

    try {
      const data = await deleteData(BOOKING_ROOM_MANAGE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'id', value: id },
      ]);

      setRooms(rooms.filter(({ id }) => id !== room.id));
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Successfully deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  async function putRoomChanges() {
    setIsLoading(true);

    try {
      const roomData: any = {
        ecosystemName: ecosystemName,
        id: room.id,
        title: room.title,
        description: room.description,
        capacity: room.capacity,
        location: room.location,
        isActive: !room.isActive,
        microsoftRoomAddress: room.microsoftRoomAddress,
      };

      const formData = new FormData();

      for (const key in roomData) {
        formData.append(key, roomData[key]);
      }

      const response = await patchFormData(
        BOOKING_ROOM_MANAGE,
        formData,
        false,
      );

      const editedRoomIndex = rooms.findIndex((r) => r.id === room.id);
      const updatedRooms = [...rooms];
      updatedRooms[editedRoomIndex] = response;

      setRooms(updatedRooms);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Changes saved',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  function viewRoomDetails() {
    setIsShowDetails(true);
  }

  function startBooking() {
    setIsShowDetails(false);
    setShowPreview(true);
  }

  function renderPhoto() {
    return (
      <Box sx={{ height: '100%', width: '100%' }}>
        {isActive ? (
          <Box
            sx={{
              borderRadius: '6px',
              width: '100%',
              height: '100%',
              maxWidth: 120,
              maxHeight: 120,
              overflow: 'hidden',
            }}>
            <img
              src={picture}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '6px',
                aspectRatio: 1,
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              borderRadius: '6px',
              maxWidth: 120,
              maxHeight: 120,
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              position: 'relative',
            }}>
            <VisibilityOff
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '2.5rem',
                color: 'white',
              }}
            />
            <img
              src={picture}
              style={{
                width: '100%',
                height: '100%',
                opacity: 0.3,
                borderRadius: '6px',
                aspectRatio: 1,
              }}
            />
          </Box>
        )}
      </Box>
    );
  }

  function renderName() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          marginLeft: '1rem',
          filter: isActive ? 'none' : 'blur(0px) opacity(0.5)',
        }}>
        <Box sx={{ display: 'flex' }}>
          <Typography
            sx={{
              color: theme.palette.primary.dark,
              fontWeight: 'bold',
              fontSize: '1rem',
              marginBottom: '0.5rem',
            }}>
            {title}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex' }}>
          {/* <Box
            sx={{
              bgcolor: isActive ? acterioTheme.lightHighlight : acterioTheme.greyLight,
              paddingX: 2,
              paddingY: 0.2,
              borderRadius: '6px',
              marginLeft: -0.5,
              marginRight: 1,
              width: 124,
              maxHeight: 25,              
            }}>
            <Typography
              fontSize={'0.875rem'}
              fontWeight='bold'
              color={ isActive ? acterioTheme.main : acterioTheme.dark}
              noWrap>
              09:30 - 18:30
            </Typography>
          </Box> */}
          <Box
            sx={{
              display: 'flex',
              marginTop: 0.2,
              overflow: 'hidden',
            }}>
            <Place
              sx={{
                color: isActive
                  ? theme.palette.primary.main
                  : theme.palette.primary.dark,
                fontSize: '0.875rem',
                marginRight: 0.5,
                marginTop: 0.2,
              }}
            />
            <Typography
              fontSize={'0.875rem'}
              fontWeight='bold'
              color={
                isActive
                  ? theme.palette.primary.main
                  : theme.palette.primary.dark
              }
              noWrap>
              {room.location}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  function renderDescription() {
    return (
      <Box
        sx={{
          height: '100%',
          maxHeight: 120,
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          padding: 1,
          overflow: 'hidden',
        }}>
        <Box
          sx={{
            bgcolor: '#FAFAFA',
            borderRadius: '6px',
            width: '100%',
            maxHeight: 110,
          }}>
          <RichTextDisplay
            style={{
              color: primary.natural2,
              fontSize: '0.75rem',
              textOverflow: 'ellipsis',
            }}
            text={description}
          />
        </Box>
      </Box>
    );
  }

  function renderButtons() {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          marginRight: '1rem',
        }}>
        <Button
          onClick={viewRoomDetails}
          sx={{
            width: '100%',
            minHeight: '32px',
            bgcolor: theme.palette.primary.dark,
            margin: '0.5rem',
            paddingY: '0.5rem',
            borderRadius: '6px',
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
          }}>
          <RemoveRedEye
            sx={{
              color: primary.pureWhite,
              marginLeft: 1,
              fontSize: '2.125rem',
            }}
          />
          <Typography
            sx={{
              color: primary.pureWhite,
              paddingLeft: 1,
              marginRight: 1,
              fontSize: '1rem',
            }}>
            View
          </Typography>
        </Button>
        <MoreOptionsOverlay
          popoverAnchorEl={popoverAnchorEl}
          setPopoverAnchorEl={setPopoverAnchorEl}
          text='Actions'
          size={'2.75rem'}
          sx={{
            borderRadius: 1,
            width: '16rem',
            minHeight: '32px',
          }}>
          <MoreOptionsButtons buttonList={buttonList} />
        </MoreOptionsOverlay>
      </Box>
    );
  }

  return (
    <Grid
      container
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: '#FFFFFF',
        border: 1,
        borderColor: '#D4D4D4',
        borderRadius: '6px',
        marginY: '2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Grid item xs={4} sm={3} lg={2}>
        {renderPhoto()}
      </Grid>
      <Grid item xs={3} sm={4} lg={3} height={'100%'}>
        {renderName()}
      </Grid>
      <Grid item xs={5} sm={5} lg={4}>
        {renderDescription()}
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
        sx={{
          marginTop: { xs: '2rem', md: '1rem', lg: 0 },
        }}>
        {renderButtons()}
      </Grid>
      {isShowDetails && (
        <RoomDetails
          isOpen={isShowDetails}
          handleClose={() => {
            setIsShowDetails(false);
          }}
          startBooking={startBooking}
          room={room}
          isPreview
        />
      )}
      {isShowEdit && (
        <AddRoom
          isOpen={isShowEdit}
          handleClose={() => {
            setIsShowEdit(false);
          }}
          rooms={rooms}
          setRooms={setRooms}
          roomToEdit={room}
        />
      )}
    </Grid>
  );
};

export default Room;
