import React, { FunctionComponent } from 'react';
import { Grid, Typography } from '@mui/material';
import BookingDay from './BookingDay';
import { Box } from '@mui/system';
import { primary } from 'theme';
import { IAvailability, IAvailableTimeslots } from 'utils/models';

interface MonthProps {
  calendarDays: any;
  monthIndex: number;
  availability: IAvailability | IAvailableTimeslots[];
  handleHourDateClick: (selectedDay: Date) => void;
  selectedDate?: Date;
  setSelectedDate: (date: Date) => void;
}

const BookingMonth: FunctionComponent<MonthProps> = (props) => {
  const {
    calendarDays,
    monthIndex,
    availability,
    handleHourDateClick,
    selectedDate,
    setSelectedDate,
  } = props;
  const arr = new Array(
    calendarDays[calendarDays.length - 1][0].$M !== monthIndex ? 5 : 6,
  ).fill([]);
  const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  return (
    <Box marginTop={3}>
      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '5rem',
          borderColor: primary.natural6,
          bgcolor: 'white',
        }}>
        {weekDays.map((weekDay: any, index) => (
          <Grid
            key={index}
            item
            xs={12 / 7}
            sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography fontWeight='bold' fontSize={26}>
              {weekDay}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {arr.map((week: any, index: number) => (
        <Grid key={index} container>
          {calendarDays[index].map((day: any, index: number) => {
            return (
              <Grid key={index} item xs={12 / 7}>
                <BookingDay
                  day={day}
                  monthIndex={monthIndex}
                  availability={availability}
                  handleHourDateClick={handleHourDateClick}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </Grid>
            );
          })}
        </Grid>
      ))}
    </Box>
  );
};

export default BookingMonth;
