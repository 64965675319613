import React, { FunctionComponent, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, Theme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { grey, mainFont } from 'theme';
import useFilterSearch from 'hooks/Common/useFilterSearch';
import { ICompanyJourney } from 'utils/models';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  search: {
    minWidth: '18.75rem',
    position: 'relative',
    display: 'inline-block',
    backgroundColor: 'white',

    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  input: {
    minWidth: '100% !important',

    '& fieldset': {
      borderRadius: 8,
      border: '1px solid #e0e0e0',
    },
    '& .MuiInputBase-input': {
      color: theme.palette.primary.dark,
      fontSize: '0.9rem',
      lineHeight: '36px',
      padding: '5px',
      paddingLeft: '55px',
      height: '36px',
      fontFamily: mainFont,
      caretColor: `${grey} !important`,
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::placeholder': {
        color: theme.palette.primary.dark,
      },
    },
  },
}));

export interface IAssignJourneysSearch {
  companies: ICompanyJourney[] | null;
  onFilter: (companies: ICompanyJourney[]) => void;
  clearSearch: boolean;
  setClearSearch: () => void;
}

const AssignJourneysSearch: FunctionComponent<IAssignJourneysSearch> = (
  props,
) => {
  const classes = useStyles();
  const { companies, onFilter, clearSearch, setClearSearch } = props;
  const { searchTerm, setSearchTerm } = useFilterSearch({
    collection: companies,
    filterOnField: 'companyName',
    onFilter,
  });

  useEffect(() => {
    if (!clearSearch) return;

    setSearchTerm('');
    setClearSearch();
  }, [clearSearch]);

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <SearchIcon
          style={{
            position: 'absolute',
            left: 15,
            top: 10,
            width: 30,
            height: 30,
            zIndex: 100,
            color: '#e0e0e0',
          }}
        />
        <TextField
          onChange={(e) => setSearchTerm(e.target.value)}
          className={classes.input}
          placeholder={'Search for company...'}
          value={searchTerm}
        />
      </div>
    </div>
  );
};

export default AssignJourneysSearch;
