import {
  IAccessLevel,
  IAccount,
  IAnnouncement,
  IApplicationForm,
  IApplicantProcessEditMode,
  IApplicationTemplate,
  ICompany,
  IEcosystem,
  IEcosystemInfo,
  IEvent,
  IHome,
  IMentor,
  IPeople,
  IProfile,
  IResource,
  IApplicationAssessmentTemplate,
} from './models';
import { primary } from '../theme';

export enum STORAGE_KEYS {
  ACCOUNT = 'account',
  USER = 'user',
  REFRESH_TOKEN = 'refreshToken',
  ECOSYSTEM = 'ecosystem',
  ECOSYSTEM_INFO = 'ecosystem_info',
  ECOSYSTEM_THEME = 'ecosystemTheme',
  ECOSYSTEMS = 'ecosystems',
  COMPANIES = 'companies',
  MEMBERS = 'members',
  MENTORS = 'mentors',
  RESOURCES = 'resources',
  HOME = 'home',
  ANNOUNCEMENTS = 'announcements',
  MY_PROFILE = 'my_profile',
  SKIP_PROFILE_COMPLETION = 'skip_profile_completion',
  SKIP_CALENDAR_CONNECTION = 'skip_calendar_connection',
  EVENTS = 'events',
  COMPANY_EDITING = 'company_editing',
  COMPANY_SHOW_PRIVATE = 'company_show_private',
  ACCESS_LEVELS = 'access_levels',
  LOADED_MONTHS = 'loaded_months',
  NEW_APPLICATION = 'new_application',
  EDITED_APPLICATION = 'edited_application',
  TEMPORARY_TIMEZONE = 'temporary_timezone',
  APPLICATION_FORM = 'application_form',
  NEW_ASSESSMENT_TEMPLATE = 'assessment_template',
  APPLICANT_DETAILS_IDS_LIST = 'applicant_details_ids_list',
}

export const clearCompanyStatus = () => {
  localStorage.removeItem(STORAGE_KEYS.COMPANY_EDITING);
  localStorage.removeItem(STORAGE_KEYS.COMPANY_SHOW_PRIVATE);
};

export const getCompanyEditStatus = () => {
  const status = localStorage.getItem(STORAGE_KEYS.COMPANY_EDITING);
  return status === 'true';
};

export const getCompanyPrivateStatus = () => {
  const status = localStorage.getItem(STORAGE_KEYS.COMPANY_SHOW_PRIVATE);
  return status === 'true';
};

export const setCompanyEditStatus = (editing: boolean) => {
  localStorage.setItem(STORAGE_KEYS.COMPANY_EDITING, String(editing));
};

export const setCompanyPrivateStatus = (editing: boolean) => {
  localStorage.setItem(STORAGE_KEYS.COMPANY_SHOW_PRIVATE, String(editing));
};

export const setUser = (access_token: string, refresh_token: string) => {
  localStorage.setItem(STORAGE_KEYS.USER, access_token);
  localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, refresh_token);
};

export const getUser = () => {
  return localStorage.getItem(STORAGE_KEYS.USER);
};

export const getRefreshToken = () => {
  return localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
};

export const clearEcosystemData = () => {
  localStorage.removeItem(STORAGE_KEYS.HOME);
  localStorage.removeItem(STORAGE_KEYS.EVENTS);
  localStorage.removeItem(STORAGE_KEYS.COMPANIES);
  localStorage.removeItem(STORAGE_KEYS.MEMBERS);
  localStorage.removeItem(STORAGE_KEYS.MENTORS);
  localStorage.removeItem(STORAGE_KEYS.RESOURCES);
  localStorage.removeItem(STORAGE_KEYS.ACCESS_LEVELS);
  removeStoredSkipProfileCompletion();
  removeStoredMyProfile();
};

export const clearStoredAppData = () => {
  removeStoredMembers();
  removeStoredCompanies();
  removeStoredMentors();
  removeStoredResources();
  removeStoredEvents();
  removeStoredAnnouncements();
  removeStoredLoadedMonths();
  removeStoredHome();
  removeStoredAccessLevels();
  removeStoredSkipProfileCompletion();
  removeStoredMyProfile();
};

export const storeAccessLevels = (accessLevels: any) => {
  localStorage.setItem(
    STORAGE_KEYS.ACCESS_LEVELS,
    JSON.stringify(accessLevels),
  );
};

export const getStoredAccessLevels = () => {
  const accessLevels = localStorage.getItem(STORAGE_KEYS.ACCESS_LEVELS);
  return !accessLevels ? null : (JSON.parse(accessLevels) as any);
};

export const removeStoredAccessLevels = () => {
  localStorage.removeItem(STORAGE_KEYS.ACCESS_LEVELS);
};

export const storeEvents = (events: IEvent[]) => {
  localStorage.setItem(STORAGE_KEYS.EVENTS, JSON.stringify(events));
};

export const getStoredEvents = () => {
  const events = localStorage.getItem(STORAGE_KEYS.EVENTS);
  return !events ? null : (JSON.parse(events) as IEvent[]);
};

export const removeStoredEvents = () => {
  localStorage.removeItem(STORAGE_KEYS.EVENTS);
};

export const updateEvents = (changes: any) => {
  localStorage.setItem(STORAGE_KEYS.EVENTS, JSON.stringify(changes));
};

export const storeHome = (home: IHome) => {
  localStorage.setItem(STORAGE_KEYS.HOME, JSON.stringify(home));
};

export const getStoredHome = () => {
  const home = localStorage.getItem(STORAGE_KEYS.HOME);
  return !home ? null : (JSON.parse(home) as IHome);
};

export const storeAnnouncements = (announcements: IAnnouncement[]) => {
  localStorage.setItem(
    STORAGE_KEYS.ANNOUNCEMENTS,
    JSON.stringify(announcements),
  );
};

export const getStoredAnnouncements = () => {
  const announcements = localStorage.getItem(STORAGE_KEYS.ANNOUNCEMENTS);
  return !announcements ? [] : (JSON.parse(announcements) as IAnnouncement[]);
};

export const removeStoredAnnouncements = () => {
  localStorage.removeItem(STORAGE_KEYS.ANNOUNCEMENTS);
};

export const storeLoadedMonths = (months: number[]) => {
  localStorage.setItem(STORAGE_KEYS.LOADED_MONTHS, JSON.stringify(months));
};

export const getStoredLoadedMonths = () => {
  const months = localStorage.getItem(STORAGE_KEYS.LOADED_MONTHS);
  return !months ? null : (JSON.parse(months) as number[]);
};

export const removeStoredLoadedMonths = () => {
  localStorage.removeItem(STORAGE_KEYS.LOADED_MONTHS);
};

export const storeMyProfile = (value: IProfile) => {
  localStorage.setItem(STORAGE_KEYS.MY_PROFILE, JSON.stringify(value));
};

export const getStoredMyProfile = () => {
  const profileCompletion = localStorage.getItem(STORAGE_KEYS.MY_PROFILE);
  return !profileCompletion
    ? null
    : (JSON.parse(profileCompletion) as IProfile);
};

export const removeStoredMyProfile = () => {
  localStorage.removeItem(STORAGE_KEYS.MY_PROFILE);
};

export const storeSkipProfileCompletion = (value: boolean) => {
  localStorage.setItem(
    STORAGE_KEYS.SKIP_PROFILE_COMPLETION,
    JSON.stringify(value),
  );
};

export const getStoredSkipProfileCompletion = () => {
  const skipProfileCompletion = localStorage.getItem(
    STORAGE_KEYS.SKIP_PROFILE_COMPLETION,
  );
  return !skipProfileCompletion
    ? false
    : (JSON.parse(skipProfileCompletion) as boolean);
};

export const removeStoredSkipProfileCompletion = () => {
  localStorage.removeItem(STORAGE_KEYS.SKIP_PROFILE_COMPLETION);
};

export const removeStoredHome = () => {
  localStorage.removeItem(STORAGE_KEYS.HOME);
};

export const updateHome = (changes: any) => {
  localStorage.setItem(
    STORAGE_KEYS.HOME,
    JSON.stringify({
      ...getStoredHome(),
      ...changes,
    }),
  );
};

export const storeResources = (resources: IResource[]) => {
  localStorage.setItem(STORAGE_KEYS.RESOURCES, JSON.stringify(resources));
};

export const removeStoredResources = () => {
  localStorage.removeItem(STORAGE_KEYS.RESOURCES);
};

export const getStoredResources = () => {
  const resources = localStorage.getItem(STORAGE_KEYS.RESOURCES);
  return !resources ? null : (JSON.parse(resources) as IResource[]);
};

export const storeCompanies = (companies: ICompany[]) => {
  localStorage.setItem(STORAGE_KEYS.COMPANIES, JSON.stringify(companies));
};

export const getStoredCompanies = () => {
  const companies = localStorage.getItem(STORAGE_KEYS.COMPANIES);
  return !companies ? null : (JSON.parse(companies) as ICompany[]);
};

export const existsStoredCompanies = () => {
  return !!localStorage.getItem(STORAGE_KEYS.COMPANIES);
};

export const storeMembers = (members: IPeople[]) => {
  localStorage.setItem(STORAGE_KEYS.MEMBERS, JSON.stringify(members));
};

export const getStoredMembers = () => {
  const members = localStorage.getItem(STORAGE_KEYS.MEMBERS);
  return !members ? null : (JSON.parse(members) as IPeople[]);
};

export const storeMentors = (mentors: IMentor[]) => {
  localStorage.setItem(STORAGE_KEYS.MENTORS, JSON.stringify(mentors));
};

export const getStoredMentors = () => {
  const mentors = localStorage.getItem(STORAGE_KEYS.MENTORS);
  return !mentors ? null : (JSON.parse(mentors) as IMentor[]);
};

export const removeStoredMembers = () => {
  localStorage.removeItem(STORAGE_KEYS.MEMBERS);
};

export const removeStoredCompanies = () => {
  localStorage.removeItem(STORAGE_KEYS.COMPANIES);
};

export const removeStoredMentors = () => {
  localStorage.removeItem(STORAGE_KEYS.MENTORS);
};

export const setEcosystems = (ecosystems: IEcosystem[]) => {
  localStorage.setItem(STORAGE_KEYS.ECOSYSTEMS, JSON.stringify(ecosystems));
};

export const getEcosystems = () => {
  const ecosystems = localStorage.getItem(STORAGE_KEYS.ECOSYSTEMS);
  return !ecosystems ? null : (JSON.parse(ecosystems) as IEcosystem[]);
};

export const setAccount = (account: IAccount) => {
  localStorage.setItem(STORAGE_KEYS.ACCOUNT, JSON.stringify(account));
};

export const getAccount = () => {
  const account = localStorage.getItem(STORAGE_KEYS.ACCOUNT);
  return JSON.parse(account ?? '{}') as IAccount;
};

export const setSkipCalendarConnection = (boolean: boolean) => {
  localStorage.setItem(
    STORAGE_KEYS.SKIP_CALENDAR_CONNECTION,
    JSON.stringify(boolean),
  );
};

export const getSkipCalendarConnection = (): boolean => {
  const skipCalendarConnection = localStorage.getItem(
    STORAGE_KEYS.SKIP_CALENDAR_CONNECTION,
  );
  if (skipCalendarConnection === null) {
    return false;
  }
  return JSON.parse(skipCalendarConnection);
};

export const setIsCalendarConnected = (
  bool: boolean,
  calValue?: 'Outlook' | 'Google',
) => {
  const currentAccount = getAccount();
  const updateAccount = {
    ...currentAccount,
    isCalendarConnected: bool,
    connectedCalendar: calValue ?? null,
  };
  setAccount(updateAccount);
};

export const removeAccount = () => {
  localStorage.removeItem(STORAGE_KEYS.ACCOUNT);
};

export const updateAccount = (changes: any) => {
  localStorage.setItem(
    STORAGE_KEYS.ACCOUNT,
    JSON.stringify({
      ...getAccount(),
      ...changes,
    }),
  );
};

export const existsEcosystem = () => {
  return (
    !!localStorage.getItem(STORAGE_KEYS.ECOSYSTEM) &&
    !!localStorage.getItem(STORAGE_KEYS.ECOSYSTEM_INFO)
  );
};

export const setEcosystem = (ecosystem: IEcosystem) => {
  localStorage.setItem(STORAGE_KEYS.ECOSYSTEM, JSON.stringify(ecosystem));
};

export const getEcosystem = () => {
  const ecosystem = localStorage.getItem(STORAGE_KEYS.ECOSYSTEM);
  return JSON.parse(ecosystem ?? '{}') as IEcosystem;
};

export const removeEcosystem = () => {
  localStorage.removeItem(STORAGE_KEYS.ECOSYSTEM);
};

export const setEcosystemInfo = (ecosystemInfo: IEcosystemInfo) => {
  localStorage.setItem(
    STORAGE_KEYS.ECOSYSTEM_INFO,
    JSON.stringify(ecosystemInfo),
  );
};

export const getEcosystemInfo = (): IEcosystemInfo => {
  const ecosystemInfo = localStorage.getItem(STORAGE_KEYS.ECOSYSTEM_INFO);
  const parsedInfo = JSON.parse(
    ecosystemInfo ?? '{}',
  ) as Partial<IEcosystemInfo>;

  if (!parsedInfo.accessLevel) {
    parsedInfo.accessLevel = new Proxy(
      {},
      {
        get: () => false,
      },
    ) as IAccessLevel;
  }

  return parsedInfo as IEcosystemInfo;
};

export const removeEcosystemInfo = () => {
  localStorage.removeItem(STORAGE_KEYS.ECOSYSTEM_INFO);
};

export const setEcosystemTheme = (ecoTheme: any) => {
  localStorage.setItem(
    STORAGE_KEYS.ECOSYSTEM_THEME,
    JSON.stringify({
      ...primary,
      ...ecoTheme,
    }),
  );
};

export const existsEcosystemTheme = () => {
  return !!localStorage.getItem(STORAGE_KEYS.ECOSYSTEM_THEME);
};

export const getEcosystemTheme = () => {
  const theme = localStorage.getItem(STORAGE_KEYS.ECOSYSTEM_THEME);
  return !theme ? null : JSON.parse(theme);
};

export const removeEcosystemTheme = () => {
  localStorage.removeItem(STORAGE_KEYS.ECOSYSTEM_THEME);
};

export const clearAllStorage = () => {
  localStorage.clear();
};

export const storeNewApplication = (
  newApplication: Partial<IApplicationTemplate | IApplicantProcessEditMode>,
) => {
  localStorage.setItem(
    STORAGE_KEYS.NEW_APPLICATION,
    JSON.stringify(newApplication),
  );
};

export const storeEditedApplication = (
  editedApplication: IApplicantProcessEditMode,
  id: number,
) => {
  localStorage.setItem(
    `${STORAGE_KEYS.EDITED_APPLICATION}${id}`,
    JSON.stringify(editedApplication),
  );
};

export const removeStoredEditedApplication = (id: number) => {
  localStorage.removeItem(`${STORAGE_KEYS.EDITED_APPLICATION}${id}`);
};

export const getStoredEditedApplication = (id: number) => {
  const editedApplication = localStorage.getItem(
    `${STORAGE_KEYS.EDITED_APPLICATION}${id}`,
  );
  const parsedData =
    editedApplication &&
    (JSON.parse(editedApplication) as IApplicantProcessEditMode);

  return parsedData ? parsedData : null;
};

export const getStoredNewApplication = () => {
  const newApplication = localStorage.getItem(STORAGE_KEYS.NEW_APPLICATION);
  const parsedData =
    newApplication && (JSON.parse(newApplication) as IApplicationTemplate);

  return parsedData
    ? {
        ...parsedData,
        startDate: parsedData.startDate ? new Date(parsedData.startDate) : null,
        endDate: parsedData.endDate ? new Date(parsedData.endDate) : null,
      }
    : null;
};

export const removeStoredNewApplication = () => {
  localStorage.removeItem(STORAGE_KEYS.NEW_APPLICATION);
};

export const storeNewAssessmentTemplate = (
  newAssessmentTemplate: IApplicationAssessmentTemplate,
) => {
  localStorage.setItem(
    STORAGE_KEYS.NEW_ASSESSMENT_TEMPLATE,
    JSON.stringify(newAssessmentTemplate),
  );
};

export const getStoredNewAssessmentTemplate = () => {
  const newAssessmentTemplate = localStorage.getItem(
    STORAGE_KEYS.NEW_ASSESSMENT_TEMPLATE,
  );
  const parsedData =
    newAssessmentTemplate &&
    (JSON.parse(newAssessmentTemplate) as IApplicationAssessmentTemplate);

  return parsedData ? parsedData : null;
};

export const removeStoredNewAssessmentTemplate = () => {
  localStorage.removeItem(STORAGE_KEYS.NEW_ASSESSMENT_TEMPLATE);
};

export const setStoredTimezone = (timezone: string) => {
  localStorage.setItem(STORAGE_KEYS.TEMPORARY_TIMEZONE, timezone);
};

export const getStoredTemporaryTimezone = () => {
  return localStorage.getItem(STORAGE_KEYS.TEMPORARY_TIMEZONE);
};

export const setStoredAppFormById = (
  form: IApplicationForm,
  id: string | undefined,
) => {
  if (!id || !form) return;
  localStorage.setItem(
    `${STORAGE_KEYS.APPLICATION_FORM}_${id}`,
    JSON.stringify(form),
  );
};

export const getStoredAppFormById = (id: string | undefined) => {
  const formToRetrieve = localStorage.getItem(
    `${STORAGE_KEYS.APPLICATION_FORM}_${id}`,
  );
  return !formToRetrieve
    ? null
    : (JSON.parse(formToRetrieve) as IApplicationForm);
};

export const getListOfApplicantDetailIds = () => {
  const idsList = localStorage.getItem(STORAGE_KEYS.APPLICANT_DETAILS_IDS_LIST);
  return idsList ? JSON.parse(idsList) : null;
};

export const setListOfApplicantDetailIds = (idsList: number[]) => {
  if (!idsList || !idsList.length) return;
  localStorage.setItem(
    STORAGE_KEYS.APPLICANT_DETAILS_IDS_LIST,
    JSON.stringify(idsList),
  );
};

export const clearListOfApplicantDetailIds = () => {
  // Not calling it yet, because I think it might restrict user from performing certain actions like opening a couple of downloads at once
  localStorage.removeItem(STORAGE_KEYS.APPLICANT_DETAILS_IDS_LIST);
};
