import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useState, useEffect } from 'react';

import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { primary } from 'theme';
import BookingRequest from './BookingRequest';
import {
  MEETING_MY_REQUESTS_ROUTE,
  MEETING_USER_REQUESTS_ROUTE,
  getData,
} from 'utils/requests';
import { formatDateDD } from 'utils/utils';
import Loading from 'components/common/Loading';
import { IRequest } from 'utils/models';
import { getEcosystemInfo } from 'utils/storage';
import ToggleViewButton from 'components/Events/ToggleViewButton';

const BookingRequests = () => {
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();

  const theme = useTheme();

  const accessLevels = getEcosystemInfo().accessLevel;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isPendingLoading, setIsPendingLoading] = useState<boolean>(false);
  const [isRequestsLoading, setIsRequestsLoading] = useState<boolean>(false);

  const [requests, setRequests] = useState<IRequest[]>([]);
  const [isMyRequests, setIsMyRequests] = useState<boolean>(
    accessLevels.canScheduleMeetings,
  );
  const [pendingRequests, setPendingRequests] = useState<IRequest[]>([]);
  const [requestsMadeByMe, setRequestsMadeByMe] = useState<IRequest[]>([]);

  const currentDate = new Date();

  useEffect(() => {
    if (accessLevels.canSetAvailability) {
      fetchPendingRequests();
    }
    if (accessLevels.canScheduleMeetings) {
      fetchRequestsMadeByMe();
    }
  }, []);

  useEffect(() => {
    if (isMyRequests) {
      setRequests(sortRequests(requestsMadeByMe));
    } else {
      setRequests(sortRequests(pendingRequests));
    }
  }, [isMyRequests, requestsMadeByMe, pendingRequests]);

  async function fetchPendingRequests() {
    setIsLoading(true);
    setIsPendingLoading(true);

    try {
      const data = await getData(MEETING_USER_REQUESTS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setPendingRequests(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
    setIsPendingLoading(false);
  }

  async function fetchRequestsMadeByMe() {
    setIsLoading(true);
    setIsRequestsLoading(true);

    try {
      const data = await getData(MEETING_MY_REQUESTS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setRequestsMadeByMe(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
    setIsRequestsLoading(false);
  }

  function updateRequests(requests: IRequest[]) {
    setRequests(requests);
    if (isMyRequests) {
      setRequestsMadeByMe(requests);
    } else {
      setPendingRequests(requests);
    }
  }

  function sortRequests(requests: IRequest[]) {
    return requests.slice().sort((requestA, requestB) => {
      const dateA = new Date(requestA.date);
      const dateB = new Date(requestB.date);

      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  function renderDays() {
    let lastDay: Date | null = null;
    return (
      <Box padding={4}>
        {requests.length ? (
          requests.map((request, index) => {
            const isANewDate = !(
              new Date(request.date).setHours(0, 0, 0, 0) ===
              (lastDay === null ? lastDay : lastDay.setHours(0, 0, 0, 0))
            );
            if (isANewDate) lastDay = new Date(request.date);
            const isInThePast =
              lastDay &&
              lastDay.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0);
            return (
              <Box key={`${request.id} + ${index}`}>
                {isANewDate && (
                  <Typography
                    fontWeight='bold'
                    fontSize={18}
                    sx={{ color: isInThePast ? 'red' : 'none' }}>
                    {lastDay && formatDateDD(lastDay)}
                  </Typography>
                )}
                {isInThePast && (
                  <Typography>
                    This request has a short deadline or is past the meeting
                    time!
                  </Typography>
                )}
                <BookingRequest
                  request={request}
                  isMyRequests={isMyRequests}
                  setRequests={updateRequests}
                />
              </Box>
            );
          })
        ) : (
          <Typography color={theme.palette.primary.main}>
            {isMyRequests ? (
              <Box
                sx={{
                  width: '56.25rem',
                  backgroundColor: primary.pureWhite,
                  boxShadow: '0px 20px 27px 0px #0000000D',
                  borderRadius: '0.75rem',
                  padding: '3rem',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Box
                  component='img'
                  src='/images/illustrations/Search.png'
                  alt='Search Illustration'
                  sx={{
                    width: '17.125rem',
                    alignSelf: 'center',
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '1.25rem',
                    lineHeight: '1.875rem',
                    textAlign: 'center',
                  }}>
                  It looks like you haven´t made any requests yet! Once you
                  submit a request, it will appear here for tracking and
                  updates.
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  width: '56.25rem',
                  backgroundColor: primary.pureWhite,
                  boxShadow: '0px 20px 27px 0px #0000000D',
                  borderRadius: '0.75rem',
                  padding: '3rem',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Box
                  component='img'
                  src='/images/illustrations/Search.png'
                  alt='Search Illustration'
                  sx={{
                    width: '17.125rem',
                    alignSelf: 'center',
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '1.25rem',
                    lineHeight: '1.875rem',
                    textAlign: 'center',
                  }}>
                  It looks like you´re all caught up! There are no pending
                  requests at the moment. Once new requests come in, they'll
                  show up here. Keep an eye for updates!
                </Typography>
              </Box>
            )}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ width: { xs: '100%', md: '80rem' } }}>
      <Box
        sx={{
          width: { xs: '70%', md: '100%' },
          marginTop: '4.375rem',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
        }}>
        {accessLevels.canSetAvailability && (
          <ToggleViewButton
            onClick={() => setIsMyRequests(false)}
            text={'Pending requests'}
            isActive={!isMyRequests}
            sx={{
              width: { md: '22rem', lg: '13rem' },
              height: '2.375rem',
              marginLeft: '2rem',
              marginRight: '1rem',
              marginTop: { xs: '1rem', sm: 0 },
              color: theme.palette.primary.main,
              backgroundColor: !isMyRequests
                ? theme.palette.primary.light
                : primary.whiteHighlight,
            }}
            icon={
              <Box
                sx={{
                  borderRadius: '50%',
                  width: '1.5rem',
                  height: '1.5rem',
                  color: theme.palette.primary.main,
                  bgcolor: theme.palette.primary.main,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography sx={{ color: primary.pureWhite }}>
                  {isPendingLoading ? (
                    <Loading color={primary.pureWhite} size={10} />
                  ) : !isMyRequests ? (
                    requests.length
                  ) : (
                    pendingRequests.length
                  )}
                </Typography>
              </Box>
            }
          />
        )}
        {accessLevels.canScheduleMeetings && (
          <ToggleViewButton
            onClick={() => setIsMyRequests(true)}
            text={'Your requests'}
            isActive={isMyRequests}
            sx={{
              width: { md: '22rem', lg: '13rem' },
              height: '2.375rem',
              marginTop: { xs: '1rem', sm: 0 },
              marginLeft: '1rem',
              color: theme.palette.primary.main,
              backgroundColor: isMyRequests
                ? theme.palette.primary.light
                : primary.whiteHighlight,
            }}
            icon={
              <Box
                sx={{
                  borderRadius: '50%',
                  width: '1.5rem',
                  height: '1.5rem',
                  color: theme.palette.primary.main,
                  bgcolor: theme.palette.primary.main,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography sx={{ color: primary.pureWhite }}>
                  {isRequestsLoading ? (
                    <Loading color={primary.pureWhite} size={10} />
                  ) : isMyRequests ? (
                    requests.length
                  ) : (
                    requestsMadeByMe.length
                  )}
                </Typography>
              </Box>
            }
          />
        )}
      </Box>

      {isLoading ||
      (isMyRequests && isRequestsLoading) ||
      (!isMyRequests && isPendingLoading) ? (
        <Loading />
      ) : (
        renderDays()
      )}
    </Box>
  );
};

export default BookingRequests;
