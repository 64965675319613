import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CompanyTemplateFieldContainer } from './components/CompanyTemplateFieldContainer';
import {
  CompanyProfileFieldType,
  CompanyTemplateSectionType,
  ICompanyProfileField,
} from 'utils/models';
import CommonButton, { ButtonVariant } from 'components/common/CommonButton';
import { useCompanyTemplateContext } from './CompanyTemplateContext';
import { CompanyTemplateAddSectionModal } from './components/CompanyTemplateAddSectionModal';

export const CompanyTemplateMainSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSectionFieldsAdded, setSectionFieldsAdded] = useState(false);
  const { companyTemplate, addSectionField, setSectionField } =
    useCompanyTemplateContext();

  const initialMainSectionFields: ICompanyProfileField[] = [
    {
      name: 'About Us',
      type: CompanyProfileFieldType.RICH_TEXT,
      required: false,
    },
    {
      name: 'Problem and market',
      type: CompanyProfileFieldType.RICH_TEXT,
      required: false,
    },
    {
      name: 'Vision & mission',
      type: CompanyProfileFieldType.RICH_TEXT,
      required: false,
    },
  ];

  useEffect(() => {
    if (!companyTemplate) return;

    if (companyTemplate.main.length === 0 && !isSectionFieldsAdded) {
      initialMainSectionFields.forEach((field) => {
        addSectionField(CompanyTemplateSectionType.MAIN, field);
      });
      setSectionFieldsAdded(true);
    }
  }, [companyTemplate]);

  function handleOpenModal() {
    setIsModalOpen(true);
    setSectionField({
      name: '',
      type: CompanyProfileFieldType.RICH_TEXT,
      required: false,
    });
  }

  function renderSectionFields() {
    return companyTemplate?.main.map((sectionField, index) => (
      <CompanyTemplateFieldContainer
        key={index}
        sectionType={CompanyTemplateSectionType.MAIN}
        {...sectionField}
      />
    ));
  }

  function renderModal() {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            gap: '0.5rem',
          }}>
          <CommonButton
            onClick={() => handleOpenModal()}
            icon={<AddCircleIcon />}
            text={'Add section'}
            variant={ButtonVariant.BLANK}
          />
        </Box>
        <CompanyTemplateAddSectionModal
          sectionType={CompanyTemplateSectionType.MAIN}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </>
    );
  }

  return (
    <>
      {renderSectionFields()}
      {renderModal()}
    </>
  );
};
