import React, { FunctionComponent, useContext, useState } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { ICompanyProfile } from 'utils/models';
import CompanyEditMain from './CompanyEditMain';
import { ALERT_TYPES, AlertContext } from '../../Alert/AlertContext';
import { useParams } from 'react-router';
import CompanyEditHeader from './CompanyEditHeader';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import CompanySection from '../CompanySection';
import { FlexBox } from 'utils/styledComponents';
import CommonButton from 'components/common/CommonButton';
import { EditOff } from '@mui/icons-material';
import { naturalPallete, primary } from 'theme';
import InformationModal from 'components/common/Modal/InformationModal';
import CompanyEditSideSection from './CompanyEditSideSection';
import { COMPANY_ROUTE, patchData } from 'utils/requests';
import CompanyEditTeam from './CompanyEditTeam';
import PeopleDisplay from '../ViewCompany/PeopleDisplay';

export interface ICompanyEditProfileProps {}

const CompanyEditProfile: FunctionComponent<ICompanyEditProfileProps> = (
  props,
) => {
  const {
    companyProfile,
    setCompanyProfile,
    isInfoMissing,
    setEditProfile,
    setCompanies,
    editedLogo,
  } = useCompanyContext();

  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();
  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [copyCompanyProfiles, setCopyCompanyProfiles] =
    useState<ICompanyProfile>(JSON.parse(JSON.stringify(companyProfile)));

  async function saveChanges() {
    setIsLoading(true);

    try {
      const data = await patchData(
        COMPANY_ROUTE,
        [{ name: 'ecosystemName', value: ecosystemName }],
        companyProfile,
      );

      // setCompanyProfile(data); // Do not put back in till tested well
      setCompanies(null);
      setEditProfile(false);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Changes saved',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  function renderAddRemoveTeamModal() {
    return (
      <InformationModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        primaryText='Save'
        primaryOnClick={() => setIsModalOpen(false)}
        headerText={'Add/remove a team member'}>
        <Box
          sx={{
            overflowX: 'hidden',
          }}>
          <CompanyEditTeam members={companyProfile!.team} />
        </Box>
      </InformationModal>
    );
  }

  function revertChanges() {
    if (!companyProfile) return;
    setCompanyProfile({
      ...copyCompanyProfiles,
      bannerUrl: companyProfile.bannerUrl,
    });

    setEditProfile(false);
  }

  return (
    <>
      <Grid item xs={12} md={12} lg={8}>
        <CompanyEditHeader />
        <CompanyEditMain />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', lg: 'column' },
          }}>
          <FlexBox
            sx={{
              mt: { xs: '1.5rem', lg: '0rem' },
              mb: '1rem',
              justifyContent: { xs: 'end', lg: 'center' },
            }}>
            <CommonButton
              text='Cancel'
              onClick={revertChanges}
              bgcolor={theme.palette.primary.light}
              color={naturalPallete.mainBlackFont}
              sx={{
                marginRight: '1rem',
                paddingY: '1.2rem',
                '&:hover': {
                  bgcolor: theme.palette.primary.main,
                  color: primary.pureWhite,
                },
              }}
              icon={<EditOff />}
            />
            <CommonButton
              text={'Save'}
              onClick={saveChanges}
              isLoading={isLoading}
              isDisabled={isInfoMissing}
              sx={{
                marginRight: '1rem',
                paddingY: '1.2rem',
              }}
            />
          </FlexBox>
          <Box>
            <CompanySection title={'Team'}>
              <PeopleDisplay
                people={companyProfile!.team}
                buttonText='Add/remove'
                buttonOnClick={() => setIsModalOpen(true)}
              />
            </CompanySection>
            {renderAddRemoveTeamModal()}
            <CompanyEditSideSection />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default CompanyEditProfile;
