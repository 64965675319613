import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import { ICompanyJourney } from 'utils/models';
import BusinessIcon from '@mui/icons-material/Business';
import AssignJourneysCompany from './AssignJourneysCompany';
import Loading from 'components/common/Loading';
import ContWithStyledScroll from 'components/common/ContWithStyledScroll';
import { useAssignJourneysContext } from './AssignJourneysContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '318px',
    minWidth: '318px',
    maxWidth: '318px',
    height: '100%',
    backgroundColor: 'white',
    borderBottomLeftRadius: '6px',
    borderRight: '1px solid #e0e0e0',
    padding: '1.5rem 1.25rem',
    paddingRight: '0.75rem',
  },
  companies: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    paddingRight: '1.25rem',
  },
  cont: {
    maxHeight: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  title: {
    fontSize: '0.9rem !important',
    color: `${theme.palette.primary.main} !important`,
    display: 'flex',

    '& svg': {
      height: '22px',
      width: '22px',
      marginRight: '10px',
    },
  },
}));

interface IAssignJourneysSidebar {
  companies: ICompanyJourney[] | null;
}

const AssignJourneysSidebar: FunctionComponent<IAssignJourneysSidebar> = (
  props,
) => {
  const { companies } = props;
  const { isSelectedCompany, addCompany, removeCompany } =
    useAssignJourneysContext();
  const classes = useStyles();
  const filteredCompanies = !companies
    ? []
    : companies.filter(
        (company) => company.filterOut || !('filterOut' in company),
      );

  function renderCompanies() {
    if (!companies) {
      return <Loading size={30} height={330} />;
    }

    return filteredCompanies.map((company, i) => {
      const isSelected = isSelectedCompany(company);

      return (
        <AssignJourneysCompany
          key={company.companyId}
          company={company}
          index={i}
          isSelected={isSelected}
          onCheck={() =>
            isSelected ? removeCompany(company) : addCompany(company)
          }
        />
      );
    });
  }

  return (
    <div className={classes.root}>
      <ContWithStyledScroll margin={0} className={classes.cont}>
        <Typography variant={'h6'} className={classes.title}>
          <BusinessIcon /> Companies{' '}
          {!!companies ? `(${filteredCompanies.length})` : null}
        </Typography>
        <div className={classes.companies}>{renderCompanies()}</div>
      </ContWithStyledScroll>
    </div>
  );
};

export default AssignJourneysSidebar;
