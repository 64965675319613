import { Box, Typography } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { ICompanyPeople } from 'utils/models';
import { primary } from 'theme';
import { FlexBox } from 'utils/styledComponents';
import CommonButton from '../../common/CommonButton';
import { KeyboardArrowRight } from '@mui/icons-material';
import SeeMorePeopleModal from './SeeMorePeopleModal';
import PeopleInTheBoxDisplay from './PeopleInTheBoxDisplay';

interface IPeopleDisplay {
  people: ICompanyPeople[];
  sectionTitle?: string;
  buttonText?: string;
  buttonOnClick?: () => void;
}

const PeopleDisplay: FunctionComponent<IPeopleDisplay> = (props) => {
  const { people, sectionTitle, buttonText, buttonOnClick } = props;

  const noPeopleToDisplay = people.length === 0;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function renderBottomButton() {
    return (
      <FlexBox className='end'>
        <CommonButton
          text={buttonText ?? 'See more'}
          onClick={buttonOnClick ?? openModal}
          bgcolor='none'
          color={primary.mainBlackFont}
          endIcon={<KeyboardArrowRight />}
          sx={{
            width: '10rem',
            padding: '0rem',
            '&:hover': {
              backgroundColor: primary,
            },
          }}
        />
      </FlexBox>
    );
  }

  function renderNoPeople() {
    return (
      <Box sx={{ mt: '-0.75rem' }}>
        <Typography variant={'h6'} sx={{ textAlign: 'center' }}>
          No users to display...
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ padding: '2rem 1rem 1rem 1.5rem' }}>
        {noPeopleToDisplay ? (
          <>
            {renderNoPeople()}
            {!!buttonOnClick && renderBottomButton()}
          </>
        ) : (
          <Box>
            <PeopleInTheBoxDisplay people={people} />
            {renderBottomButton()}
          </Box>
        )}
      </Box>
      <SeeMorePeopleModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        people={people}
        modalTitle={sectionTitle}
      />
    </>
  );
};

export default PeopleDisplay;
