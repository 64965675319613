import React, { FunctionComponent, useContext } from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { useParams } from 'react-router';
import {
  BOOKING_PEOPLE_CALENDAR,
  REDIRECT_URI,
  deleteData,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { getAccount, setIsCalendarConnected } from 'utils/storage';
import CommonButton, { ButtonVariant } from 'components/common/CommonButton';
import { primary } from 'theme';

interface CalendarSyncProps {
  inModal?: boolean;
}

const CalendarSync: FunctionComponent<CalendarSyncProps> = (props) => {
  const { inModal } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const { isCalendarConnected, connectedCalendar } = getAccount();

  const theme = useTheme();

  function createStateParameter(
    ecosystemName: string,
    isGoogle: boolean,
  ): string {
    const stateObj = {
      ecosystemName: encodeURIComponent(ecosystemName),
      isGoogle: isGoogle,
    };
    const stateString = JSON.stringify(stateObj);
    return btoa(stateString);
  }

  const handleOutlookLogin = () => {
    const tenant = 'common';
    const clientId = '74623c56-740e-4556-8a86-f7d71da71941';
    const responseType = 'code';
    const redirectUri = encodeURIComponent(REDIRECT_URI);
    const responseMode = 'query';
    const scope = encodeURIComponent('Calendars.ReadWrite offline_access');
    const encodedState = createStateParameter(ecosystemName ?? '', false);

    const authUrl = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&response_mode=${responseMode}&scope=${scope}&state=${encodedState}`;
    window.location.href = authUrl;
  };

  const handleGoogleLogin = () => {
    const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = encodeURIComponent(REDIRECT_URI);
    const clientId =
      '308982353535-dp930ntnhnd65nc22uu2c2ddpfs092st.apps.googleusercontent.com';
    const scope = encodeURIComponent(
      'https://www.googleapis.com/auth/calendar',
    );
    const responseType = 'code';
    const encodedState = createStateParameter(ecosystemName ?? '', true);

    const authUrl = `${rootUrl}?redirect_uri=${redirectUri}&prompt=consent&response_type=${responseType}&client_id=${clientId}&scope=${scope}&access_type=offline&state=${encodedState}`;
    window.location.href = authUrl;
  };

  function renderConnectButtons() {
    if (isCalendarConnected) return;
    return (
      <Box marginTop='1rem'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '1rem',
            justifyContent: 'space-between',
            width: '100%',
            gap: '0.5rem',
          }}>
          <CommonButton
            text={'Connect To Outlook Calendar'}
            variant={ButtonVariant.FILLED}
            bgcolor={theme.palette.primary.dark}
            onClick={handleOutlookLogin}
            sx={{ width: '100%' }}
          />
          <CommonButton
            text={'Connect To Google Calendar'}
            variant={ButtonVariant.FILLED}
            bgcolor={theme.palette.primary.dark}
            onClick={handleGoogleLogin}
            sx={{ width: '100%' }}
          />
        </Box>
      </Box>
    );
  }

  async function deleteCalendar() {
    try {
      const data = await deleteData(BOOKING_PEOPLE_CALENDAR, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);
      setIsCalendarConnected(false);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: data,
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  function renderDisconnectCalendarButton() {
    if (!isCalendarConnected) return;
    return (
      <Box
        marginTop={'1rem'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <CommonButton
          text={'Disconnect Calendar'}
          variant={ButtonVariant.FILLED}
          onClick={async () => await deleteCalendar()}
          bgcolor={theme.palette.primary.contrastText}
          color={theme.palette.primary.dark}
          hoverColor={{
            bgcolor: theme.palette.primary.light,
          }}
          sx={{
            width: inModal ? '49%' : '100%',
            mb: inModal ? '-2rem' : '',
            alignSelf: 'start',
          }}
        />
      </Box>
    );
  }

  return (
    <>
      {!inModal && (
        <Typography
          sx={{
            mb: '1rem',
            color: primary.mainBlackFont,
            fontWeight: 'bold',
            fontSize: '1.75rem',
          }}>
          {isCalendarConnected ? 'Calendar connected' : 'Connect your calendar'}
        </Typography>
      )}
      <Typography sx={{ mb: '2rem' }}>
        {!isCalendarConnected
          ? 'Connecting your calendar helps Acterio manage your availability, prevent double bookings, and update your schedule with new events.'
          : 'Your calendar is now connected to Acterio, helping manage your availability, prevent double bookings, and keeps track of your events.'}
      </Typography>
      {!isCalendarConnected && (
        <Typography variant={'h6'} sx={{ mb: '1rem' }}>
          Please choose your calendar to connect:
        </Typography>
      )}
      {isCalendarConnected && (
        <Typography sx={{ mb: '1rem' }}>
          We recommend keeping your calendar connected to ensure seamless
          scheduling and updates.
        </Typography>
      )}
      {isCalendarConnected && connectedCalendar && (
        <Typography
          fontSize={'1rem'}
          sx={{ mt: '1rem' }}
          style={{ textDecoration: 'underline' }}>
          You are currently connected with{' '}
          <span style={{ fontWeight: 'bold' }}>{connectedCalendar}</span>{' '}
          calendar.
        </Typography>
      )}
      {renderConnectButtons()}
      {renderDisconnectCalendarButton()}
    </>
  );
};

export default CalendarSync;
