import React, { ChangeEvent, FunctionComponent, useContext } from 'react';
import InputField from './InputField';
import { acterioTheme, naturalPallete } from 'theme';
import { Box } from '@mui/system';
import { IconButton, useTheme } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';

interface ICopyField {
  text: string;
  onChange: (s: string) => void;
  isEditable?: boolean;
}

const CopyField: FunctionComponent<ICopyField> = (props) => {
  const { text, onChange, isEditable } = props;

  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const isLinkGenerated = text !== 'Generated link';

  function copyText() {
    if (!isLinkGenerated) {
      addAlert({
        type: ALERT_TYPES.WARNING,
        message: 'No link to copy',
      });
      return;
    }

    navigator.clipboard.writeText(text);
    addAlert({
      type: ALERT_TYPES.SUCCESS,
      message: 'Link copied successfully!',
    });
  }

  return (
    <Box
      sx={{
        width: '100%',
        marginRight: '2rem',
        display: 'flex',
      }}>
      <InputField
        value={text}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange(event.target.value)
        }
        sx={{
          width: '100%',
          paddingRight: '2.5rem',

          '& .MuiOutlinedInput': {
            //root|container component styles
            '&-root': {
              minHeight: '2.5rem',
              backgroundColor: naturalPallete.natural8,
              color: acterioTheme.main,
              textDecoration: 'underline',
              boxShadow: theme.shadows[2],
              borderRadius: '8px 0 0 8px',
              opacity: '90%',
              transition: 'all .3s ease-in-out',
              //styles for border. default styles for boxShadow is needed for smooth animation
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                boxShadow: 'inset 0 0 0 0.063rem transparent',
                transition: 'box-shadow .3s ease',
              },
              //styles for border while hover or focus on input
              '&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  boxShadow: `inset 0 0 0 0.063rem ${theme.palette.primary.main}`,
                },
              //styles for backGround, while focus or hover
              '&.Mui-focused, &:hover': {
                backgroundColor: theme.palette.primary.light,
              },
              '& .MuiInputBase-input': {
                cursor: 'text',
              },
            },
          },
        }}
      />
      <IconButton
        sx={{
          position: 'relative',
          right: '2.5rem',
          top: '50%',
          width: '2.5rem',
          borderRadius: '0 8px 8px 0',
          padding: '0',
          backgroundColor: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        }}
        onClick={copyText}>
        <ContentCopy
          sx={{ color: theme.palette.primary.main, transform: 'scaleY(-1)' }}
        />
      </IconButton>
    </Box>
  );
};

export default CopyField;
