import React, { FunctionComponent, useEffect, useState } from 'react';
import { CometChatAddMembers } from '@cometchat/chat-uikit-react';
import { CometChat } from '@cometchat/chat-sdk-javascript';

interface AddUsersProps {
  group: CometChat.Group;
  setActiveTab: React.Dispatch<
    React.SetStateAction<
      'conversations' | 'groups' | 'users' | 'createGroup' | 'addUsers'
    >
  >;
}

const AddMembers: FunctionComponent<AddUsersProps> = (props) => {
  const { group, setActiveTab } = props;
  const [joinedMembersUIDs, setJoinedMembersUIDs] = useState<string[]>([]);
  const [activeUsersUIDs, setActiveUsersUIDs] = useState<string[]>([]);
  const [notJoinedUsersUIDs, setNotJoinedUsersUIDs] = useState<string[]>([]);

  const limit = 50;

  useEffect(() => {
    const fetchGroupMembers = () => {
      const groupMembersRequest = new CometChat.GroupMembersRequestBuilder(
        group.getGuid(),
      )
        .setLimit(limit)
        .build();

      groupMembersRequest.fetchNext().then(
        (groupMemberList) => {
          setJoinedMembersUIDs(
            groupMemberList.map((member) => member.getUid()),
          );
        },
        (error) => {
          console.error('Error fetching group members:', error);
        },
      );
    };

    fetchGroupMembers();
  }, [group]);

  useEffect(() => {
    const fetchUsers = () => {
      const usersRequest = new CometChat.UsersRequestBuilder()
        .setLimit(limit)
        .build();

      usersRequest.fetchNext().then(
        (usersList) => {
          setActiveUsersUIDs(usersList.map((user) => user.getUid()));
        },
        (error) => {
          console.error('Error fetching users:', error);
        },
      );
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    setNotJoinedUsersUIDs(
      activeUsersUIDs.filter((uid) => !joinedMembersUIDs.includes(uid)),
    );
  }, [activeUsersUIDs, joinedMembersUIDs]);

  return (
    <CometChatAddMembers
      onClose={() => setActiveTab('groups')}
      group={group}
      usersRequestBuilder={new CometChat.UsersRequestBuilder()
        .setLimit(limit)
        .setUIDs(notJoinedUsersUIDs)}
    />
  );
};

export default AddMembers;
