import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';
import { Box, SxProps } from '@mui/system';
import { Typography } from '@mui/material';
import { primary } from '../../theme';

interface IRichTextEditor {
  value?: any;
  onChange?: (value: any) => any;
  readonly?: boolean;
  width?: string;
  height?: number;
  onBlur?: (value: any) => any;
  sx?: SxProps;
  maxLength?: number;
  error?: boolean;
}

const RichTextEditor: FunctionComponent<IRichTextEditor> = (props) => {
  const {
    value,
    onChange,
    readonly,
    width,
    height,
    onBlur,
    sx,
    maxLength,
    error,
  } = props;

  const quillRef = useRef<ReactQuill | null>(null);
  const [charCount, setCharCount] = useState(0);

  function clearRichText(input: string) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = input;
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  // Set initial char count
  useEffect(() => {
    if (value) {
      const initialText = clearRichText(value);
      setCharCount(initialText.length);
    }
  }, []);

  useEffect(() => {
    const quillInstance: Quill | undefined = quillRef.current?.getEditor();

    if (quillInstance) {
      quillInstance.on('text-change', () => {
        const clearedText = clearRichText(quillInstance.root.innerHTML);
        const textLength = clearedText.length;

        setCharCount(textLength);

        if (maxLength && textLength > maxLength - 2) {
          quillInstance.deleteText(
            maxLength,
            quillInstance.getLength(),
            'silent',
          );
        }
      });

      quillInstance.root.addEventListener('paste', (event: ClipboardEvent) => {
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData?.getData('Text') || '';
        const clearedText = clearRichText(quillInstance.root.innerHTML);

        if (maxLength && clearedText.length + pastedText.length > maxLength) {
          event.preventDefault();
        }
      });
    }
  }, [maxLength]);

  return (
    <Box
      sx={{
        '& .ql-container': {
          borderColor: error ? primary.warning : 'grey',
          borderRadius: '0 0 6px 6px',
        },
        '& .ql-toolbar': {
          borderColor: error ? primary.warning : 'grey',
          borderRadius: '6px 6px 0 0',
        },
        width: width || '100%',
        ...sx,
        '&:hover .ql-container, &:hover .ql-toolbar': {
          borderColor: 'black',
        },
        '&:focus-within .ql-container, &:focus-within .ql-toolbar': {
          borderColor: 'black',
        },
      }}>
      <ReactQuill
        ref={quillRef}
        modules={{
          clipboard: {
            matchVisual: false,
          },
        }}
        theme='snow'
        value={value}
        onChange={onChange}
        readOnly={readonly}
        onBlur={onBlur}
        style={{
          height: height ? `${height}px` : 'auto',
        }}
      />
      {maxLength && (
        <Typography
          sx={{
            display: !(charCount < maxLength * 0.75) ? 'block' : 'none',
            position: 'absolute',
            top: '0.8rem',
            right: '7px',
            color: 'rgba(0, 0, 0, 0.5)',
          }}
          variant='body2'>
          {charCount} / {maxLength}
        </Typography>
      )}
    </Box>
  );
};

export default RichTextEditor;
