import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { IApplicationProccess } from 'utils/models';
import { APPLICATIONS_PROCESSES, getData } from 'utils/requests';

interface IFetchProcesses {
  setIsLoading: (b: boolean) => void;
  setProcesses: (p: IApplicationProccess[]) => void;
}

const useFetchAllProcesses = (props: IFetchProcesses) => {
  const { setIsLoading, setProcesses } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  async function getAllProcesses() {
    setIsLoading(true);
    try {
      const data = await getData(APPLICATIONS_PROCESSES, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      setProcesses(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  return getAllProcesses;
};

export default useFetchAllProcesses;
