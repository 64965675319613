import React, { FunctionComponent, useContext, useState } from 'react';
import {
  DeleteRounded,
  Edit,
  ArrowBackRounded,
  ArrowForwardRounded,
  Assignment,
  PauseCircle,
  Error,
  ContentCopy,
  Download,
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';
import MoreOptionsOverlay from 'components/common/MoreOptionsButton/MoreOptionsOverlay';
import { useParams } from 'react-router';
import { acterioTheme, naturalPallete, primary } from 'theme';
import { IApplicant, IApplicationStatus, IListButton } from 'utils/models';
import DeleteColumnModal from './DeleteColumnModal';
import RenameColumnModal from './RenameColumnModal';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import NewColumnModal from './NewColumnModal';
import { APPLICATION_STATUS_ROUTE, postData } from 'utils/requests';
import { useApplicationContext } from 'services/contexts/Applications/ApplicationsContext';
import ApplicantCard from './ApplicantCard';
import { getEcosystemInfo, setListOfApplicantDetailIds } from 'utils/storage';
import StartAssessmentModal from './Assessment/StartAssessmentModal';
import StopAssessmentModal from './Assessment/StopAssessmentModal';

interface ApplicationKanbanColumnProps {
  status: IApplicationStatus;
  handleOpenNewColumnModal: () => void;
  indexOfColumn: number;
}
const ApplicationKanbanColumn: FunctionComponent<
  ApplicationKanbanColumnProps
> = (props) => {
  const { status } = props;
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const [newColumnName, setNewColumnName] = useState<string>('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false);
  const [isLeftColumnModalOpen, setIsLeftColumnModalOpen] =
    useState<boolean>(false);
  const [isRightColumnModalOpen, setIsRightColumnModalOpen] =
    useState<boolean>(false);
  const [isAddLoading, setIsAddLoading] = useState<boolean>(false);
  const [isAssessmentModalOpen, setIsAssessmentModalOpen] =
    useState<boolean>(false);
  const [isStopAssessmentModalOpen, setIsStopAssessmentModalOpen] =
    useState<boolean>(false);

  const { addAlert } = useContext(AlertContext);

  const { setApplicationOverviewData, selectedApplicationProcessId } =
    useApplicationContext();

  const [isAssessmentColumnOn, setIsAssessmentColumnOn] = useState<boolean>(
    !!status.assessmentTemplate,
  );

  const { ecosystemName } = useParams();
  const handleDeleteClick = () => {
    if (status.applicants?.length > 0) {
      addAlert({
        type: ALERT_TYPES.WARNING,
        message: "It's not possible to delete a column with applicants in it.",
      });
      setPopoverAnchorEl(null);
    } else {
      setIsDeleteModalOpen(true);
    }
  };

  function handleCloseRenameModal() {
    setIsRenameModalOpen(false);
    setPopoverAnchorEl(null);
  }

  function handleCloseDeleteModal() {
    setIsDeleteModalOpen(false);
    setPopoverAnchorEl(null);
  }

  let extendedButtonList: IListButton[] = [
    {
      name: 'Rename',
      onClick: () => {
        setIsRenameModalOpen(true);
        setPopoverAnchorEl(null);
      },
      icon: Edit,
    },
    {
      name: 'All as PDF',
      onClick: generateListOfPDFs,
      icon: Download,
    },
    {
      name: 'Add column to left',
      onClick: () => {
        setIsLeftColumnModalOpen(true);
        setPopoverAnchorEl(null);
      },
      icon: ArrowBackRounded,
    },
    {
      name: 'Add column to right',
      onClick: () => {
        setIsRightColumnModalOpen(true);
        setPopoverAnchorEl(null);
      },
      icon: ArrowForwardRounded,
    },
    {
      name: 'Copy link',
      onClick: copyText,
      icon: ContentCopy,
    },
    {
      name: isAssessmentColumnOn ? 'Stop assessment' : 'Start assessment',
      onClick: () => {
        setPopoverAnchorEl(null);
        isAssessmentColumnOn
          ? setIsStopAssessmentModalOpen(true)
          : setIsAssessmentModalOpen(true);
      },
      icon: isAssessmentColumnOn ? PauseCircle : Assignment,
    },
    {
      name: 'Delete column',
      onClick: handleDeleteClick,
      icon: DeleteRounded,
      textColor: acterioTheme.warningRed,
    },
  ];

  let buttonList: IListButton[] = [
    {
      name: 'Rename',
      onClick: () => {
        setIsRenameModalOpen(true);
        setPopoverAnchorEl(null);
      },
      icon: Edit,
    },
    {
      name: 'All as PDF',
      onClick: generateListOfPDFs,
      icon: Download,
    },
    {
      name: 'Copy link',
      onClick: copyText,
      icon: ContentCopy,
    },
    {
      name: isAssessmentColumnOn ? 'Stop assessment' : 'Start assessment',
      onClick: () => {
        setPopoverAnchorEl(null);
        isAssessmentColumnOn
          ? setIsStopAssessmentModalOpen(true)
          : setIsAssessmentModalOpen(true);
      },
      icon: isAssessmentColumnOn ? PauseCircle : Assignment,
    },
  ];

  if (!isAssessmentColumnOn) {
    buttonList = buttonList.filter((button) => button.name !== 'Copy link');
    extendedButtonList = extendedButtonList.filter(
      (button) => button.name !== 'Copy link',
    );
  }

  async function postNewColumn(
    newColumnName: string,
    direction: 'LEFT' | 'RIGHT',
  ) {
    if (newColumnName.trim() === '') return;
    setIsAddLoading(true);
    try {
      const data = await postData(APPLICATION_STATUS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'applicationProcessId', value: selectedApplicationProcessId },
        { name: 'newStatusName', value: newColumnName },
        { name: 'currentColumnId', value: status.id },
        { name: 'direction', value: direction },
      ]);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Column added',
      });
      setApplicationOverviewData(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    if (direction === 'LEFT') {
      setIsLeftColumnModalOpen(false);
    } else {
      setIsRightColumnModalOpen(false);
    }
    setPopoverAnchorEl(null);
    setIsAddLoading(false);
  }

  function copyText() {
    if (!status.url) return;
    navigator.clipboard.writeText(status.url);
    addAlert({
      type: ALERT_TYPES.SUCCESS,
      message: 'Link copied successfully!',
    });
    setPopoverAnchorEl(null);
  }

  function generateListOfPDFs() {
    const idsList = status.applicants.map((applicant) => applicant.id);
    setListOfApplicantDetailIds(idsList);
    window.open(window.location.href + '/pdf', '_blank');
  }

  function renderKanboardCards(
    applicants: IApplicant[],
    statusName?: string,
    statusColor?: string,
  ) {
    if (!applicants) return;
    return (
      <>
        {applicants.map((applicant: IApplicant) => {
          return (
            <ApplicantCard
              applicant={applicant}
              statusName={statusName}
              statusColor={statusColor}
              key={`applicant-${applicant.id}`}
            />
          );
        })}
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          display: 'flex',
          alignItems: 'center',
          paddingY: '0.5rem',
          borderRight: `1px solid ${primary.natural2}`,
          width: '18.875rem',
          height: '3rem',
          backgroundColor: `${primary.pureWhite}`,
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.3rem',
              flexGrow: 1,
            }}>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: '500',
                lineHeight: '1.21rem',
                color: `${naturalPallete.mainBlackFont}`,
              }}>
              {status.name}
            </Typography>
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontStyle: 'normal',
                fontWeight: '300',
                color: `${primary.natural2}`,
              }}>
              ({status.applicants?.length})
            </Typography>
          </Box>
          {isAssessmentColumnOn && (
            <Box sx={{ display: 'flex', alignItems: 'start' }}>
              <Error
                sx={{
                  color: primary.successGreen,
                  fontSize: '1.25rem',
                  mr: '0.25rem',
                }}
              />
              <Typography
                sx={{ color: primary.successGreen, fontWeight: 'bold' }}>
                Assessment in progress
              </Typography>
            </Box>
          )}
        </Box>
        {getEcosystemInfo().accessLevel.canManageApplications && (
          <>
            {status.custom ? (
              <MoreOptionsOverlay
                popoverAnchorEl={popoverAnchorEl}
                setPopoverAnchorEl={setPopoverAnchorEl}
                sx={{ marginLeft: 'auto' }}>
                <MoreOptionsButtons buttonList={extendedButtonList} />
              </MoreOptionsOverlay>
            ) : (
              <MoreOptionsOverlay
                popoverAnchorEl={popoverAnchorEl}
                setPopoverAnchorEl={setPopoverAnchorEl}
                sx={{ marginLeft: 'auto' }}>
                <MoreOptionsButtons buttonList={buttonList} />
              </MoreOptionsOverlay>
            )}
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingTop: '2rem',
        }}>
        <DeleteColumnModal
          isOpen={isDeleteModalOpen}
          handleClose={handleCloseDeleteModal}
          id={status.id}
        />
        <RenameColumnModal
          isOpen={isRenameModalOpen}
          handleClose={handleCloseRenameModal}
          newColumnName={newColumnName}
          setNewColumnName={setNewColumnName}
          id={status.id}
          name={status.name}
        />
        <NewColumnModal
          isOpen={isLeftColumnModalOpen}
          handleClose={() => setIsLeftColumnModalOpen(false)}
          handleAddNewColumn={() => postNewColumn(newColumnName, 'LEFT')}
          newColumnName={newColumnName}
          setNewColumnName={setNewColumnName}
          isAddLoading={isAddLoading}
        />
        <NewColumnModal
          isOpen={isRightColumnModalOpen}
          handleClose={() => setIsRightColumnModalOpen(false)}
          handleAddNewColumn={() => postNewColumn(newColumnName, 'RIGHT')}
          newColumnName={newColumnName}
          setNewColumnName={setNewColumnName}
          isAddLoading={isAddLoading}
        />
        {isAssessmentModalOpen && (
          <StartAssessmentModal
            isOpen={isAssessmentModalOpen}
            handleClose={() => setIsAssessmentModalOpen(false)}
            statusId={status.id}
            applicationProcessId={selectedApplicationProcessId}
            setIsAssessmentOn={setIsAssessmentColumnOn}
          />
        )}
        {isStopAssessmentModalOpen && (
          <StopAssessmentModal
            isOpen={isStopAssessmentModalOpen}
            handleClose={() => setIsStopAssessmentModalOpen(false)}
            statusId={status.id}
            setIsAssessmentOn={setIsAssessmentColumnOn}
          />
        )}
        {renderKanboardCards(status.applicants, status.name, status.color)}
      </Box>
    </>
  );
};

export default ApplicationKanbanColumn;
