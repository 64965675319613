import { Box, SxProps } from '@mui/system';
import React, { FunctionComponent } from 'react';

interface INewIcon {
  src: string;
  alt?: string;
  sx?: SxProps;
}

const IllustrationIcon: FunctionComponent<INewIcon> = (props) => {
  const { src, alt, sx } = props;

  //Max width prop should be removed in time when the illustrations are same size!

  return (
    <Box
      width='100%'
      maxWidth='8rem'
      height='100%'
      overflow='hidden'
      sx={{ ...sx }}>
      <img
        src={src}
        alt={alt}
        style={{
          width: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  );
};

export default IllustrationIcon;
