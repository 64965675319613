import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import CopyField from 'components/common/CopyField';
import Loading from 'components/common/Loading';
import InformationModal from 'components/common/Modal/InformationModal';
import SelectDropdown from 'components/common/SelectDropdown';
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { primary } from 'theme';
import { IApplicationAssessmentTemplateBase } from 'utils/models';
import {
  APPLICATION_ASSESSMENT_TEMPLATES_BASIC,
  APPLICATION_START_ASSESSMENT,
  getData,
  patchData,
} from 'utils/requests';

interface IStartAssessmentModalProps {
  isOpen: boolean;
  handleClose: () => void;
  statusId: number;
  applicationProcessId: string | null;
  setIsAssessmentOn: Dispatch<SetStateAction<boolean>>;
}
const StartAssessmentModal: FunctionComponent<IStartAssessmentModalProps> = (
  props,
) => {
  const {
    isOpen,
    handleClose,
    statusId,
    applicationProcessId,
    setIsAssessmentOn,
  } = props;

  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessfullyStared, setIsSuccessfullyStared] =
    useState<boolean>(false);

  const [assessmentForms, setAssessmentForms] = useState<
    IApplicationAssessmentTemplateBase[]
  >([]);
  const [selectedAssessmentForm, setSelectedAssessmentForm] =
    useState<IApplicationAssessmentTemplateBase | null>();

  const [sharableLink, setSharableLink] = useState('');

  useEffect(() => {
    fetchAssessmentForms();
  }, []);

  async function fetchAssessmentForms() {
    setIsLoading(true);

    try {
      const data = await getData(APPLICATION_ASSESSMENT_TEMPLATES_BASIC, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setAssessmentForms(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  async function startAssessment() {
    if (!applicationProcessId) return;

    setIsLoading(true);

    try {
      const data = await patchData(APPLICATION_START_ASSESSMENT, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'statusId', value: statusId },
        { name: 'applicationProcessId', value: applicationProcessId },
        { name: 'assessmentTemplateId', value: selectedAssessmentForm?.id },
      ]);

      setSharableLink(data);
      setIsAssessmentOn(true);
      setIsSuccessfullyStared(true);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Assessment started',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function goToAssessment() {
    window.open(sharableLink, '_blank');
  }

  function handleDropdownChange(s: string) {
    const selectedFormName = s;
    const selectedForm = assessmentForms.find(
      (form) => form.name === selectedFormName,
    );
    setSelectedAssessmentForm(selectedForm || null);
  }

  if (isSuccessfullyStared)
    return (
      <InformationModal
        isOpen={isOpen}
        handleClose={handleClose}
        headerText='Assessment successfully started'
        primaryText='Go to assessment'
        primaryOnClick={goToAssessment}
        secondaryText='Cancel'
        secondaryOnClick={handleClose}
        headerSize='1.875rem'
        width='36rem'
        buttonSx={{
          '&:hover': {
            color: '#13253E',
            backgroundColor: '#EBF1F9',
          },
        }}>
        {isLoading ? (
          <Loading height={200} />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ mb: '0.75rem' }}>
              You can invite people to evaluate candidates with following link:
            </Typography>
            <CopyField text={sharableLink} onChange={setSharableLink} />
            <Typography sx={{ mt: '0.75rem' }}>
              You can always find this link by clicking the 'More Options' icon
              on your assessment
            </Typography>
          </Box>
        )}
      </InformationModal>
    );

  return (
    <InformationModal
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Start assessment process'}
      primaryText='Start'
      primaryOnClick={startAssessment}
      secondaryText='Cancel'
      secondaryOnClick={handleClose}
      headerSize='1.875rem'
      width='36rem'
      buttonSx={{
        '&:hover': {
          color: '#13253E',
          backgroundColor: '#EBF1F9',
        },
      }}>
      {isLoading ? (
        <Loading height={200} />
      ) : (
        <Box sx={{ width: '30rem', marginY: '2rem' }}>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '0.875rem',
              marginBottom: '0.5rem',
            }}>
            Select assessment form:
          </Typography>
          <SelectDropdown
            value={selectedAssessmentForm?.name}
            onChange={handleDropdownChange}
            options={assessmentForms.map((form) => form.name)}
            height='2.5rem'
            width={'100%'}
          />
          <Typography sx={{ color: primary.natural3, marginTop: '1.75rem' }}>
            If you haven't created assessment template yet, you can do that by
            going to{' '}
            <span style={{ color: primary.additionalDeepBlue }}>
              {' '}
              Assessment settings.{' '}
            </span>
          </Typography>
        </Box>
      )}
    </InformationModal>
  );
};

export default StartAssessmentModal;
