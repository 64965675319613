import React, { FunctionComponent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme } from '@mui/material';
import { Check } from '@mui/icons-material';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import FloatingActionButton from 'components/common/FloatingActionButton';
import { useAssignJourneysContext } from './AssignJourneysContext';
import { formatNumOf } from 'utils/utils';
import {
  ASSIGN_JOURNEY_TEMPLATE_TO_COMPANIES_ROUTE,
  JOURNEY_UNASSIGN_JOURNEYS,
  deleteData,
  postData,
} from 'utils/requests';
import { useParams } from 'react-router';
import { primary } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IAssignJourneysSave {
  reloadCompanies: () => void;
}

const AssignJourneysSave: FunctionComponent<IAssignJourneysSave> = (props) => {
  const { reloadCompanies } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const {
    selectedCompanies,
    selectedJourneyId,
    clearAllCompanies,
    removeJourneyId,
  } = useAssignJourneysContext();
  const numSelectedCompanies = selectedCompanies.length;

  const [loading, setLoading] = useState<boolean>(false);

  async function saveJourneys() {
    setLoading(true);

    try {
      await postData(ASSIGN_JOURNEY_TEMPLATE_TO_COMPANIES_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'journeyTemplateId', value: selectedJourneyId },
        {
          name: 'companyIdList',
          value: selectedCompanies.map(({ companyId }) => companyId),
        },
      ]);

      await reloadCompanies();
      clearAllCompanies();
      removeJourneyId();

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `${formatNumOf(numSelectedCompanies, 'instance')} updated`,
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  async function unassignJourneys() {
    setLoading(true);

    try {
      await deleteData(JOURNEY_UNASSIGN_JOURNEYS, [
        { name: 'ecosystemName', value: ecosystemName },
        {
          name: 'companyIdList',
          value: selectedCompanies.map(({ companyId }) => companyId),
        },
      ]);

      await reloadCompanies();
      clearAllCompanies();
      removeJourneyId();

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `${formatNumOf(numSelectedCompanies, 'companies')} updated`,
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return (
    <>
      <Fade in={numSelectedCompanies > 0 && !!selectedJourneyId} timeout={600}>
        <div>
          <FloatingActionButton
            text={`Assign journey to ${formatNumOf(
              numSelectedCompanies,
              'companies',
            )}`}
            onClick={saveJourneys}
            loading={loading}
            icon={<Check />}
            minWidth={280}
          />
        </div>
      </Fade>
      <Fade in={numSelectedCompanies > 0 && !selectedJourneyId} timeout={600}>
        <div>
          <FloatingActionButton
            text={`Remove journey from ${formatNumOf(
              numSelectedCompanies,
              'companies',
            )}`}
            onClick={unassignJourneys}
            loading={loading}
            icon={<Check />}
            minWidth={280}
            color={primary.warningRed}
          />
        </div>
      </Fade>
    </>
  );
};

export default AssignJourneysSave;
