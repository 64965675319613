import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getMonthName } from 'utils/utils';
import { ICompanyKPIEntry } from 'utils/models';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '10.625rem',
  },
}));

interface ILineChart {
  name: string;
  unit: string;
  showGoals: boolean;
  keyPerformanceIndicatorEntry: ICompanyKPIEntry[];
}

const LineChart: FunctionComponent<ILineChart> = (props) => {
  const { name, unit, showGoals, keyPerformanceIndicatorEntry } = props;
  const classes = useStyles();
  const theme = useTheme();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 10,
          callback: function (value: any, index: any, ticks: any) {
            return `${value}${unit}`;
          },
        },
      },
    },
  };

  const labels = keyPerformanceIndicatorEntry
    .map(({ zonedDateTime }) =>
      zonedDateTime ? getMonthName(new Date(zonedDateTime).getMonth()) : null,
    )
    .filter(Boolean);

  function createDataSet() {
    const datasets: any = [
      {
        data: keyPerformanceIndicatorEntry.map(({ value }) => value),
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        pointRadius: 5,
        pointHoverRadius: 5,
      },
    ];

    if (showGoals) {
      datasets.push({
        data: keyPerformanceIndicatorEntry.map(({ goal }) => goal),
        borderColor: '#E0E0E0',
        backgroundColor: '#E0E0E0',
        pointRadius: 5,
        pointHoverRadius: 5,
      });
    }

    return datasets;
  }

  const data = {
    labels,
    datasets: createDataSet(),
  };

  return (
    <div className={classes.root}>
      <Line options={options} data={data} />
    </div>
  );
};

export default LineChart;
