import React, { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import CommonButton, { ButtonVariant } from 'components/common/CommonButton';
import { primary } from 'theme';
import { FlexBox } from 'utils/styledComponents';
import CommonModal from '../../common/Modal/CommonModal';
import CloseIcon from '@mui/icons-material/Close';
import { ControlButtonStyled } from './ApplicationBuilder';

interface ApplicationFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  isError: boolean;
  onSubmitForm: () => void;
  onBack: () => void;
  message: string;
  email?: string;
}

const ApplicationFormModal: FunctionComponent<ApplicationFormModalProps> = ({
  isOpen,
  onClose,
  isError,
  onSubmitForm,
  onBack,
  message,
  email,
}) => {
  return (
    <CommonModal open={isOpen} onClose={onClose}>
      <FlexBox className={'center'} sx={{ height: '100%' }}>
        <FlexBox
          className={'center'}
          sx={{
            position: 'relative',
            gap: '0.875rem',
            flexDirection: 'column',
            padding: '3.313rem',
            width: isError ? '34.375rem' : '34.375rem',
            height: isError ? '28.625rem' : '33.625rem',
            backgroundColor: primary.pureWhite,
          }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/${
              isError ? 'opps' : 'almost-there'
            }.svg`}
            alt={isError ? 'error' : 'success'}
          />
          <Typography variant={'h4'} align={'center'}>
            {isError ? 'Oops! Something went wrong' : "You're almost there!"}
          </Typography>
          <Typography
            align={'center'}
            sx={{
              color: '#4D4D4D',
              fontStyle: isError ? 'normal' : 'italic',
            }}>
            {message}
          </Typography>
          {!isError && (
            <FlexBox sx={{ flexDirection: 'column' }}>
              <Typography
                sx={{
                  color: '#4D4D4D',
                }}>
                A copy of your application will be sent to your email:
              </Typography>
              <Typography
                fontWeight={'bold'}
                sx={{
                  color: '#4D4D4D',
                }}>
                {email}
              </Typography>
            </FlexBox>
          )}
          <FlexBox
            sx={{
              width: '100%',
              marginTop: '1.625rem',
              gap: '0.5rem',
              justifyContent: 'end',
            }}>
            <>
              <CommonButton
                text={
                  isError ? 'Go back to application' : 'Stay in application'
                }
                variant={ButtonVariant.BLANK}
                onClick={onBack}
                sx={{
                  borderRadius: '0px',
                  width: '13.5rem',
                  height: '3rem',
                  border: '1px solid #D4D4D4',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    boxShadow: '0px 4px 4px 0px #00000040',
                  },
                }}
              />
              <CommonButton
                text={isError ? 'Try again' : 'Submit'}
                onClick={onSubmitForm}
                bgcolor={'black'}
                sx={{
                  borderRadius: '0px',
                  width: '7.5rem',
                  height: '3rem',
                  border: '1px solid black',
                  '&:hover': {
                    backgroundColor: 'black',
                    boxShadow: '0px 4px 4px 0px #00000040',
                  },
                }}
              />
            </>
          </FlexBox>
          <ControlButtonStyled
            sx={{
              position: 'absolute',
              top: '0.3rem',
              right: '0.3rem',
            }}
            onClick={onBack}>
            <CloseIcon />
          </ControlButtonStyled>
        </FlexBox>
      </FlexBox>
    </CommonModal>
  );
};

export default ApplicationFormModal;
