import React, { ChangeEvent, FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { ButtonBase, Theme } from '@mui/material';
import { useJourneyTemplateContext } from './JourneyTemplateContext';
import DeleteIcon from '@mui/icons-material/Delete';
import InputField from 'components/common/InputField';
import { primary } from 'theme';
import { IJourneyStage } from 'utils/models';
import JourneyStageWrapper from 'components/common/JourneyStageWrapper';
import { Draggable } from 'react-beautiful-dnd';
import { DensityMedium } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '10.625rem',
    marginLeft: (props: any) => (props.onlyStage ? '10px' : '0px'),
  },
  delete: {
    padding: '5px !important',
    borderRadius: '50% !important',
    zIndex: 100,
  },
}));

interface IJourneyTemplateStage {
  selected: boolean;
  index: number;
  stage: IJourneyStage;
}

const JourneyTemplateStage: FunctionComponent<IJourneyTemplateStage> = (
  props,
) => {
  const { selected, stage, index } = props;
  const { name } = stage;
  const {
    saveJourneyStage,
    setStage,
    setStageIndex,
    deleteStage,
    updateStageName,
    numStages,
  } = useJourneyTemplateContext();
  const onlyStage = numStages === 1;
  const classes = useStyles({ selected, onlyStage });

  function enterStage() {
    saveJourneyStage();
    setStage(stage);
    setStageIndex(index);
  }

  function renderDeleteBtn() {
    if (onlyStage) return null;

    return (
      <ButtonBase className={classes.delete} onClick={() => deleteStage(index)}>
        <DeleteIcon />
      </ButtonBase>
    );
  }

  return (
    <Draggable draggableId={`stage-${index}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <JourneyStageWrapper
            selected={selected}
            index={index}
            onClick={enterStage}>
            {renderDeleteBtn()}
            <div className={classes.root}>
              <InputField
                value={name}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  updateStageName(event.target.value)
                }
                placeholder={'Stage name...'}
              />
            </div>
            <DensityMedium sx={{ marginX: '1rem', color: primary.natural2 }} />
          </JourneyStageWrapper>
        </div>
      )}
    </Draggable>
  );
};

export default JourneyTemplateStage;
