import React, { FunctionComponent } from 'react';
import { Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router';
import { getAccount, storeSkipProfileCompletion } from 'utils/storage';
import Modal from './Modal';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles({
  dialog: {
    minWidth: '18.75rem',
    borderRadius: '5px',
  },
});

interface ProfileCompletionPopupProps {
  open: boolean;
  onClose: () => void;
}

const ProfileCompletionPopup: FunctionComponent<
  ProfileCompletionPopupProps
> = ({ open, onClose }) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const { userId } = getAccount();
  const { isMobile } = useMobileContext();

  const handleSkip = () => {
    storeSkipProfileCompletion(true);
    onClose();
  };

  const handleCompleteProfile = () => {
    navigate(`members/${userId}`);
    onClose();
  };

  return (
    <Modal
      open={open}
      close={onClose}
      title={'Profile completion'}
      showFooter={true}
      headerColor={theme.palette.primary.light}
      largeFooter={true}
      width={isMobile ? 300 : 600}
      saveButtonText={'Complete profile'}
      saveAction={handleCompleteProfile}
      cancelButtonText={'Remind me later'}
      cancelAction={handleSkip}>
      <Typography variant={'body1'}>
        It seems like you haven't completed your profile. Complete your profile
        for a better experience.
      </Typography>
    </Modal>
  );
};

export default ProfileCompletionPopup;
