import React, { FunctionComponent, useState } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MainButton, { ButtonType } from '../common/MainButton';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyIcon from '@mui/icons-material/Key';
import { acterioTheme, mainFont } from 'theme';
import SendVerifyEmail from './SendVerifyEmail';
import ChangePassword from './ChangePassword';
import { IAccount } from 'utils/models';
import DeleteAccount from './DeleteAccount';
import NotificationsModal from './NotificationsModal';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '0.5rem',
    marginLeft: '-0.938rem',

    '& button': {
      justifyContent: 'flex-start',
      height: '2.5rem !important',
      margin: (props: any) =>
        props.isMobile ? '1rem 0 !important' : '0.469rem 0 !important',
      fontSize: '1.025rem !important',
      fontWeight: 600,
      fontFamily: `${mainFont} !important`,
      color: `${acterioTheme.dark} !important`,
      padding: (props: any) => (props.isMobile ? '1rem !important' : ''),

      '& svg': {
        height: 27.5,
        width: 27.5,
        marginRight: '0.188rem',
        color: `${acterioTheme.dark} !important`,
      },
    },
  },
}));
interface IAccountSettings {
  account: IAccount;
}

const AccountSettings: FunctionComponent<IAccountSettings> = (props) => {
  const { account } = props;
  const { email, notificationSettings } = account;
  const { isMobile } = useMobileContext();
  const classes = useStyles({ isMobile });

  const [showVerifyEmail, setShowVerifyEmail] = useState<boolean>(false);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      {/* disabled as we don't currently handle the user verifying their email*/}
      {/* <MainButton
        onClick={() => setShowVerifyEmail(true)}
        text={'Verify Email'}
        type={ButtonType.BLANK}
        startIcon={<CheckCircleOutlineIcon />}
      /> */}
      <MainButton
        onClick={() => setShowNotifications(true)}
        text={'Notification Settings'}
        type={ButtonType.BLANK}
        startIcon={<NotificationsNoneIcon />}
      />
      <MainButton
        onClick={() => setShowChangePassword(true)}
        text={'Change Password'}
        type={ButtonType.BLANK}
        startIcon={<KeyIcon />}
      />
      <MainButton
        onClick={() => setShowDeleteAccount(true)}
        text={'Delete Account'}
        type={ButtonType.BLANK}
        startIcon={<DeleteForeverIcon />}
      />
      <SendVerifyEmail
        email={email}
        open={showVerifyEmail}
        close={() => setShowVerifyEmail(false)}
      />
      <NotificationsModal
        notificationSettings={notificationSettings}
        open={showNotifications}
        close={() => setShowNotifications(false)}
        showAdminNotification={account.isUserAdmin}
        userId={account.userId}
      />
      <ChangePassword
        open={showChangePassword}
        close={() => setShowChangePassword(false)}
      />
      <DeleteAccount
        open={showDeleteAccount}
        close={() => setShowDeleteAccount(false)}
      />
    </div>
  );
};

export default AccountSettings;
