import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Link } from '@mui/material';
import InputField from '../common/InputField';
import MainButton, { ButtonType } from '../common/MainButton';
import { useNavigate } from 'react-router-dom';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { authenticateEmailAndPassword } from 'utils/utils';
import { acterioTheme } from 'theme';

const useStyles = makeStyles(() => ({
  boldFont: {
    fontSize: '2rem !important',
    marginBottom: '1rem !important',
    color: 'white !important',
    alignItems: 'start',
    width: '100%',
  },
  cursorPointer: {
    cursor: 'pointer',
    fontSize: '1rem',
  },
  label: {
    textAlign: 'left',
    marginBottom: '8px !important',
    fontSize: '1rem !important',
    color: 'white !important',
  },
  forgotPasswordLink: {
    color: acterioTheme.main,
    marginTop: '-20px',
    fontSize: '0.875rem',
    cursor: 'pointer',
    textAlign: 'start',
    width: '100%',
  },
  joinLink: {
    cursor: 'pointer',
    paddingLeft: '7px',
    color: `${acterioTheme.main} !important`,
    fontWeight: '700',
  },
  joinDiv: {
    fontSize: '1rem',
    marginTop: '-20px',
    textAlign: 'center',
    marginBottom: '1rem',
  },
  form: {
    display: 'contents',
  },
}));

interface OwnProps {}

const LoginBox: FunctionComponent<OwnProps> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { addAlert } = useContext(AlertContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  async function authenticate(e: React.FormEvent<HTMLFormElement>) {
    setLoading(true);
    e.preventDefault();

    const userLoggedIn = await authenticateEmailAndPassword(email, password);

    if (userLoggedIn) {
      setLoading(false);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        title: 'Welcome to Acterio',
        message: 'Logged in successfully',
      });
      navigate('/dashboard');
    } else {
      setLoading(false);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: 'Username or password was incorrect',
      });
    }
  }

  return (
    <form onSubmit={(e) => authenticate(e)} className={classes.form}>
      <Typography variant={'h6'} className={classes.boldFont}>
        Log In
      </Typography>
      <InputField
        value={email}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setEmail(event.target.value)
        }
        placeholder={'john@acterio.com'}
        readonly={loading}
        type='email'
        noEffects
        label={'Email address'}
        labelTextColor={'white'}
        formLabelStyles={{
          marginTop: 0,
        }}
      />
      <InputField
        value={password}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setPassword(event.target.value)
        }
        type={'password'}
        placeholder={'Please enter your password'}
        readonly={loading}
        noEffects
        label={'Password'}
        labelTextColor={'white'}
        formLabelStyles={{
          marginTop: 0,
        }}
        helperText={
          'Use 8 or more characters, including a combination of letters, numbers, and uppercase characters.'
        }
      />
      <Link
        className={classes.forgotPasswordLink}
        onClick={function (e) {
          navigate('/reset-password');
        }}>
        Forgot Your Password?
      </Link>
      <MainButton
        text={'Log in'}
        type={ButtonType.FILLED}
        submit={true}
        loading={loading}
        backgroundColor={acterioTheme.main}
        style={{ fontSize: { xs: '1.5rem', lg: '1rem' } }}
      />
      <div className={classes.joinDiv}>
        Not a member yet?
        <Link
          className={classes.joinLink}
          underline={'always'}
          onClick={() => navigate('/signup')}>
          Sign up here!
        </Link>
      </div>
      <Link
        className={classes.cursorPointer}
        href={'/documents/privacy-policy.pdf'}>
        Terms of use, Privacy Policy
      </Link>
    </form>
  );
};

export default LoginBox;
