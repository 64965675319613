import React, { ChangeEvent, FunctionComponent } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import {
  Email,
  LinkedIn,
  Phone,
  //   Facebook,
  //   Instagram,
  //   X,
} from '@mui/icons-material';
import { naturalPallete } from 'theme';
import CloseModalButton from 'components/common/Modal/CloseModalButton';
import InputField from 'components/common/InputField';
import { IProfile, ITag } from 'utils/models';

interface ICompanyEditSocialMedia {
  member: IProfile;
  updateMember: (field: string, value: string | ITag[] | null) => void;
}

const MemberEditSocialMedia: FunctionComponent<ICompanyEditSocialMedia> = (
  props,
) => {
  const { member, updateMember } = props;

  const theme = useTheme();

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item>
          <Email />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Email address*</Typography>
        </Grid>
        <Grid item xs={7}>
          <InputField
            value={member.email ?? ''}
            placeholder={'Company Email'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateMember('email', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={1} sx={{ width: '5rem' }}>
          <CloseModalButton
            handleClose={() => updateMember('email', '')}
            color={naturalPallete.mainBlackFont}
            sx={{ boxShadow: 'none' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item>
          <Phone />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Telephone</Typography>
        </Grid>
        <Grid item xs={7}>
          <InputField
            value={member.phoneNumber ?? ''}
            placeholder={'Company Telephone'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateMember('phoneNumber', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={1} sx={{ width: '5rem' }}>
          <CloseModalButton
            handleClose={() => updateMember('phoneNumber', '')}
            color={naturalPallete.mainBlackFont}
            sx={{ boxShadow: 'none' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item>
          <LinkedIn />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Linkedin</Typography>
        </Grid>
        <Grid item xs={7}>
          <InputField
            value={member.linkedInLink ?? ''}
            placeholder={'Company Linkedin'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateMember('linkedInLink', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={1} sx={{ width: '5rem', justifyContent: 'flex-end' }}>
          <CloseModalButton
            handleClose={() => updateMember('linkedInLink', '')}
            color={naturalPallete.mainBlackFont}
            sx={{ boxShadow: 'none' }}
          />
        </Grid>
      </Grid>
      {/* 
      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item>
          <Facebook />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Facebook</Typography>
        </Grid>
        <Grid item xs={7}>
          <InputField
            value={member.facebook ?? ''}
            placeholder={'Company Facebook'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateMember('facebook', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={1} sx={{ width: '5rem', justifyContent: 'flex-end' }}>
          <CloseModalButton
            handleClose={() => updateMember('facebook', '')}
            color={naturalPallete.mainBlackFont}
            sx={{ boxShadow: 'none' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item>
          <X />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>X</Typography>
        </Grid>
        <Grid item xs={7}>
          <InputField
            value={member.twitter ?? ''}
            placeholder={'Company X'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateMember('twitter', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={1} sx={{ width: '5rem', justifyContent: 'flex-end' }}>
          <CloseModalButton
            handleClose={() => updateMember('twitter', '')}
            color={naturalPallete.mainBlackFont}
            sx={{ boxShadow: 'none' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item>
          <Instagram />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Instagram</Typography>
        </Grid>
        <Grid item xs={7}>
          <InputField
            value={member.instagram ?? ''}
            placeholder={'Company Instagram'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateMember('instagram', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={1} sx={{ width: '5rem', justifyContent: 'flex-end' }}>
          <CloseModalButton
            handleClose={() => updateMember('instagram', '')}
            color={naturalPallete.mainBlackFont}
            sx={{ boxShadow: 'none' }}
          />
        </Grid>
      </Grid> */}
    </Box>
  );
};

export default MemberEditSocialMedia;
