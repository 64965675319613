import {
  AddCircleOutlineOutlined,
  Delete,
  DeleteForever,
  FileCopy,
  FileCopyOutlined,
  NoteAdd,
  RestoreFromTrash,
  Task,
  WarningAmber,
} from '@mui/icons-material';
import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { primary } from 'theme';
import PopoverButtonList from 'components/common/PopoverButtonList';
import { IListButton, IEvent, RecurringOption } from 'utils/models';

interface IAddButtons {
  event: IEvent;
  isAllRequiredFilled: any;
  updateEvent: any;
  isLoading: any;
  duplicateEvent: any;
  deleteEvent: any;
}

const AddFunctionalButtons: FunctionComponent<IAddButtons> = (props) => {
  const {
    event,
    isAllRequiredFilled,
    updateEvent,
    isLoading,
    duplicateEvent,
    deleteEvent,
  } = props;

  const theme = useTheme();

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const [popoverDeleteEl, setPopoverDeleteEl] = useState<HTMLElement | null>(
    null,
  );

  const updateButtons: IListButton[] = [
    {
      name: 'Update only this event',
      onClick: updateEvent(RecurringOption.THIS),
      icon: Task,
    },
    {
      name: 'Update this and following recurring events',
      onClick: updateEvent(RecurringOption.FUTURE),
      icon: NoteAdd,
    },
    {
      name: 'Update all recurring events',
      onClick: updateEvent(RecurringOption.ALL),
      icon: FileCopy,
    },
  ];

  const deleteButtons: IListButton[] = [
    {
      name: 'Delete only this event',
      onClick: deleteEvent(RecurringOption.THIS),
      icon: Delete,
    },
    {
      name: 'Delete this and following recurring events',
      onClick: deleteEvent(RecurringOption.FUTURE),
      icon: RestoreFromTrash,
    },
    {
      name: 'Delete all recurring events',
      onClick: deleteEvent(RecurringOption.ALL),
      icon: DeleteForever,
    },
  ];

  function mountUpdate(e: any) {
    setPopoverAnchorEl(e.currentTarget);
  }

  function mountDelete(e: any) {
    setPopoverDeleteEl(e.currentTarget);
  }

  return (
    <Box>
      {!isAllRequiredFilled && (
        <Box display='flex' sx={{ marginTop: 1 }}>
          <WarningAmber sx={{ marginRight: 1 }} />
          <Typography>Not all required fields are filled!</Typography>
        </Box>
      )}
      <Box
        display='flex'
        justifyContent='space-between'
        sx={{ marginTop: isAllRequiredFilled ? 5 : 1 }}>
        <Button
          onClick={
            event.id && event.id.toString().includes('_')
              ? mountUpdate
              : updateEvent()
          }
          disabled={isLoading || !isAllRequiredFilled}
          sx={{
            bgcolor: !isAllRequiredFilled
              ? primary.natural2
              : theme.palette.primary.dark,
            marginTop: 2,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            '&:hover': {
              backgroundColor: !isAllRequiredFilled
                ? primary.natural2
                : theme.palette.primary.main,
            },
          }}>
          <AddCircleOutlineOutlined sx={{ color: primary.pureWhite }} />
          <Typography
            sx={{
              color: primary.pureWhite,
              paddingLeft: 1,
              paddingRight: 1,
            }}>
            Update the event
          </Typography>
        </Button>
        <Button
          onClick={duplicateEvent}
          disabled={isLoading}
          sx={{
            bgcolor: theme.palette.primary.dark,
            marginTop: 2,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            '&:hover': { backgroundColor: theme.palette.primary.main },
            marginLeft: 2,
          }}>
          <FileCopyOutlined sx={{ color: primary.pureWhite }} />
          <Typography
            sx={{
              color: primary.pureWhite,
              paddingLeft: 1,
              paddingRight: 1,
            }}>
            Duplicate the event
          </Typography>
        </Button>
        <Button
          onClick={
            event.id && event.id.toString().includes('_')
              ? mountDelete
              : deleteEvent()
          }
          disabled={isLoading}
          sx={{
            bgcolor: primary.pureWhite,
            marginTop: 2,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            '&:hover': { backgroundColor: theme.palette.primary.light },
            marginLeft: 2,
          }}>
          <Delete sx={{ color: theme.palette.primary.dark }} />
          <Typography
            sx={{
              color: theme.palette.primary.dark,
              paddingLeft: 1,
              paddingRight: 1,
            }}>
            Delete the event
          </Typography>
        </Button>
        <PopoverButtonList
          popoverAnchorEl={popoverAnchorEl}
          setPopoverAnchorEl={setPopoverAnchorEl}
          buttons={updateButtons}
        />
        <PopoverButtonList
          popoverAnchorEl={popoverDeleteEl}
          setPopoverAnchorEl={setPopoverDeleteEl}
          buttons={deleteButtons}
        />
      </Box>
    </Box>
  );
};

export default AddFunctionalButtons;
