import { useContext, useEffect, useState } from 'react';
import { ICompany } from 'utils/models';
import { getStoredCompanies, storeCompanies } from 'utils/storage';
import { COMPANIES_ROUTE, getData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';

const useCompanies = () => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [companies, setCompanies] = useState<ICompany[] | null>(
    getStoredCompanies(),
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!companies) fetchCompanies();
  }, [ecosystemName]);

  async function fetchCompanies() {
    if (!ecosystemName) return null;
    setLoading(true);

    try {
      const data = await getData(COMPANIES_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setCompanies(data);
      storeCompanies(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return {
    loading,
    companies,
    setCompanies,
  };
};

export default useCompanies;
