import React, { FunctionComponent, useEffect, useState } from 'react';
import PageSwitch from 'components/common/PageNavigation/PageSwitch';
import Members from '../Members/Members';
import Companies from '../Companies/Companies';
import { Add, Apartment, People } from '@mui/icons-material';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import SearchFilter from 'components/common/SearchFilter';
import Loading from 'components/common/Loading';
import MemberCard from 'components/Members/MemberCard';
import CompanyCard from 'components/Companies/CompanyCard';
import SelectMultipleDropdown from 'components/common/SelectMultipleDropdown';
import CommonButton from 'components/common/CommonButton';
import { getEcosystemInfo } from 'utils/storage';
import useCommunity from 'hooks/Community/useCommunity';
import { ICompany, IPeople } from 'utils/models';
import SelectDropdown from 'components/common/SelectDropdown';
import { useMobileContext } from 'services/contexts/MobileContext';
import ListOfCompaniesPopover from 'components/Community/ListOfCompaniesPopover';

interface ICommunity {
  isMembers: boolean;
}

const Community: FunctionComponent<ICommunity> = (props) => {
  const { isMembers } = props;
  const {
    didMount,
    isMembersLoading,
    isCompaniesLoading,
    isLoading,
    filteredCommunity: community,
    availableTypes,
    availableTags,
    selectedTags,
    selectedType,
    setFilteredCommunity: setCommunity,
    selectedCompany,
    availableCompanies,
    setSelectedType,
    setSelectedTags,
    setSelectedCompany,
  } = useCommunity(isMembers);

  const [isTriggerAddNewCompany, setIsTriggerAddNewCompany] =
    useState<boolean>(false);

  const { isMobile } = useMobileContext();

  const theme = useTheme();

  const renderAddCompanyButton =
    !isMembers &&
    !isCompaniesLoading &&
    getEcosystemInfo().accessLevel.createCompanyProfile;

  useEffect(() => {
    if (!isTriggerAddNewCompany) return;

    setIsTriggerAddNewCompany(false);
  }, [isTriggerAddNewCompany]);

  function renderCommunityList() {
    if (isMembersLoading || isCompaniesLoading) return <Loading />;
    if (!community) return;
    if (!didMount) return;

    let hideCount = 0;
    const communitiesGrid = community.map((communityItem: any) => {
      const hide = communityItem.filterOut === false;
      if (hide) hideCount++;
      return (
        <Grid
          key={communityItem.id}
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2.4}
          sx={{
            display: hide ? 'none' : '',
          }}>
          {isMembers ? (
            <MemberCard {...(communityItem as IPeople)} />
          ) : (
            <CompanyCard {...(communityItem as ICompany)} />
          )}
        </Grid>
      );
    });

    if (hideCount === community.length && didMount) {
      return (
        <Typography
          variant={'h4'}
          sx={{ marginLeft: '4rem', marginTop: '2rem' }}>
          No results
        </Typography>
      );
    }
    return communitiesGrid;
  }

  function renderSearchBar() {
    if (!community) return;
    const numberOf =
      community.length -
      (community as IPeople[]).filter(
        (communityItem: any) => communityItem.filterOut === false,
      ).length;

    return (
      <Box
        sx={{
          display: 'flex',
          mt: { xs: '2rem', sm: '0' },
        }}>
        {didMount && (
          <Typography
            sx={{
              whiteSpace: 'noWrap',
              display: { xs: 'none', md: 'flex' },
              alignSelf: 'center',
            }}>
            {isMembers ? `Total members: ${numberOf}` : ''}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            width: { xs: '100%', md: '60%' },
            flexDirection: { xs: 'column-reverse', md: 'row' },
            alignItems: { xs: 'end' },
            gap: { xs: '3.2rem', md: '2.25rem' },
            height: '2.5rem',
            marginRight: '1rem',
          }}>
          {!isLoading && community && (
            <SearchFilter
              collection={community}
              filterOnField={[
                'name',
                'description',
                'templateName',
                'fullName',
                'accessLevelName',
                'headerNames',
              ]}
              onFilter={setCommunity}
              sx={{
                maxWidth: {
                  xs: '100%',
                  sm: '17.688rem',
                  md: '31.688rem',
                },
                ml: '1rem',
              }}
            />
          )}
          {renderAddCompanyButton && (
            <CommonButton
              text='Create company'
              onClick={() => setIsTriggerAddNewCompany(true)}
              bgcolor={theme.palette.primary.dark}
              icon={<Add />}
              sx={{
                minWidth: { sm: '12.5rem' },
                minHeight: { xs: '3rem', md: '100%' },
                display: { xs: 'none', sm: 'flex' },
                marginRight: { md: '6rem' },
              }}
            />
          )}
        </Box>
      </Box>
    );
  }

  function renderFilterDropdown() {
    if (!community) return;

    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: { xs: 'center', sm: 'start' },
        }}>
        <SelectDropdown
          value={selectedType}
          onChange={setSelectedType}
          options={availableTypes}
          getOptionLabel={(option) => option}
          height={40}
          placeholder={isMembers ? 'Member type' : 'Company type'}
          disabled={selectedCompany !== ''}
          sx={{ maxWidth: '12rem', mx: '1rem' }}
        />
        <SelectMultipleDropdown
          value={selectedTags}
          onChange={setSelectedTags}
          options={availableTags}
          placeholder='Tags'
          maxOptionsDisplayed={3}
          height={40}
          sx={{ maxWidth: '12rem' }}
          filterButton
          disabled={selectedCompany !== ''}
        />
        {isMembers && (
          <ListOfCompaniesPopover
            value={selectedCompany}
            onChange={setSelectedCompany}
            options={availableCompanies}
          />
        )}
      </Box>
    );
  }

  return (
    <Container sx={{ minWidth: '94vw', mb: '6rem' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          justifyContent: 'start',
          marginBottom: '2.5rem',
          marginRight: isMembers ? '1rem' : '',
        }}>
        <Box
          sx={{
            marginLeft: '1rem',
          }}>
          <PageSwitch
            firstPage={<Companies isTriggerAddNew={isTriggerAddNewCompany} />}
            firstPageName='Companies'
            secondPage={<Members />}
            secondPageName='Members'
            firstIcon={<Apartment />}
            secondIcon={<People />}
            isFirstInitiallySelected={!isMembers}
            sx={{
              margin: 'auto',
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
            marginTop: { sm: '2rem', md: 0 },
            width: '100%',
          }}>
          <Box
            sx={{
              minWidth: '20%',
              justifyContent: 'start',
            }}>
            {renderFilterDropdown()}
          </Box>
          {renderSearchBar()}
        </Box>
      </Box>
      <Grid container spacing={3} sx={{ padding: '0 1.2rem' }}>
        {renderCommunityList()}
      </Grid>
      {isMobile && (
        <CommonButton
          onClick={() => setIsTriggerAddNewCompany(true)}
          bgcolor={theme.palette.primary.dark}
          icon={<Add />}
          sx={{
            borderRadius: '100%',
            height: '3.125rem',
            minWidth: '3.125rem',
            maxWidth: '3.125rem',
            padding: 0,
            position: 'fixed',
            left: '50%',
            transform: 'translateX(-48%)',
            bottom: '60px',
            span: {
              margin: 0,
            },
          }}
        />
      )}
    </Container>
  );
};

export default Community;
