import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import MainButton, { ButtonType } from 'components/common/MainButton';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import JourneysPreviews from './JourneysPreviews';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '-3rem',
  },
  cont: {
    borderRadius: '6px',
    border: '1px solid #e0e0e0',
    backgroundColor: '#f5f5f5',
    minHeight: '21.875rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  header: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    borderBottom: '1px solid #e0e0e0',
    backgroundColor: 'white',
    padding: '0.4rem',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    width: '100%',

    '& button': {
      maxWidth: '165px !important',
      maxHeight: '45px !important',
      margin: '7.5px !important',
    },
  },
}));

interface IJourneysOverview {}

const JourneysOverview: FunctionComponent<IJourneysOverview> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <Typography variant={'h5'}>Journeys</Typography>
      <div className={classes.cont}>
        <div className={classes.header}>
          <MainButton
            text={'New Journey'}
            type={ButtonType.FILLED}
            startIcon={<Add />}
            onClick={() => navigate('new')}
          />
        </div>
        <JourneysPreviews />
      </div>
    </div>
  );
};

export default JourneysOverview;
