import React from 'react';
import { JourneyProvider } from 'components/Journey/JourneyContext';
import Journey from 'components/Journey/Journey';

const JourneyPage = () => {
  return (
    <JourneyProvider>
      <Journey />
    </JourneyProvider>
  );
};

export default JourneyPage;
