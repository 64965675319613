import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Checkbox, FormControlLabel, Theme, Typography } from '@mui/material';
import { acterioTheme } from 'theme';
import { INotificationSettings } from 'utils/models';
import {
  ACCOUNT_CHANGE_EMAIL_NOTIFICATIONS_ROUTE,
  putData,
} from 'utils/requests';
import { getAccount, updateAccount } from 'utils/storage';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0 1rem',
    paddingBottom: '0.3rem',
    color: `${acterioTheme.pureWhite} !important`,
  },
  cont: {
    padding: '1rem 0',

    '& span': {
      color: `${acterioTheme.pureWhite} !important`,
    },

    '& h6': {
      fontSize: '1.15rem !important',
      marginBottom: '0.3rem !important',
      color: `${acterioTheme.pureWhite} !important`,
    },
  },
}));

interface IChangeNotifications {
  notificationSettings: INotificationSettings;
  showAdminNotification: boolean;
  userId: number | string;
}

interface NotificationCheckboxProps {
  label: string;
  settingKey: keyof INotificationSettings;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    setting: keyof INotificationSettings,
  ) => void;
  checked: boolean;
}

const NotificationCheckbox: FunctionComponent<NotificationCheckboxProps> = ({
  label,
  settingKey,
  onChange,
  checked,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox onChange={(e) => onChange(e, settingKey)} checked={checked} />
      }
      label={label}
    />
  );
};

type NotificationSetting = {
  label: string;
  settingKey: keyof INotificationSettings;
};

const ChangeNotifications: FunctionComponent<IChangeNotifications> = (
  props,
) => {
  const { showAdminNotification, userId } = props;
  const notificationSettings = getAccount().notificationSettings;

  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);

  const [notifcationSettingState, setNotifcationSettingState] =
    useState<INotificationSettings>(notificationSettings);

  const [isInitialRender, setIsInitialRender] = useState(true);

  const notificationSettingsConfig: Record<string, NotificationSetting[]> = {
    ecosystem: [
      {
        label: 'Notify me if my request to join an ecosystem is approved',
        settingKey: 'ecosystemJoinRequestApproved',
      },
      {
        label: 'Notify me if a new announcement is created',
        settingKey: 'ecosystemNewAnnouncement',
      },
    ],
    administrative: [
      {
        label: 'Notify me when a new user joins my ecosystem through an invite',
        settingKey: 'adminUserAddedThroughCompanyInvite',
      },
      {
        label: 'Notify me when a user requests to join an ecosystem I manage',
        settingKey: 'ecosystemJoinRequest',
      },
    ],
    events: [
      {
        label: 'Notify me when a new event is created',
        settingKey: 'eventCreated',
      },
      {
        label: 'Notify me when a user is not attending an event',
        settingKey: 'userNotAttendingEvent',
      },
    ],
    meetings: [
      {
        label: 'Notify me when someone requests a meeting',
        settingKey: 'meetingRequestReceived',
      },
      {
        label: 'Notify me when my meeting request is accepted',
        settingKey: 'meetingRequestAccepted',
      },
      {
        label: 'Notify me when my meeting request is cancelled',
        settingKey: 'meetingRequestCancelled',
      },
    ],
    general: [
      {
        label: 'Unsubscribe from all emails',
        settingKey: 'unsubscribeFromAll',
      },
    ],
  };

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    putNotificationSettings();
  }, [notifcationSettingState]);

  const handleChatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    notifcationSettingState.chat = event.target.value;
    setNotifcationSettingState(notifcationSettingState);
  };

  const handleUnsubscribeAllChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const unsubscribeAll = event.target.checked;
    setNotifcationSettingState((prevState) => ({
      ...prevState,
      unsubscribeFromAll: unsubscribeAll,
      chat: unsubscribeAll ? 'Never' : prevState.chat,
      ecosystemJoinRequest: !unsubscribeAll,
      ecosystemJoinRequestApproved: !unsubscribeAll,
      ecosystemNewAnnouncement: !unsubscribeAll,
      adminUserAddedThroughCompanyInvite: !unsubscribeAll,
      eventCreated: !unsubscribeAll,
      userNotAttendingEvent: !unsubscribeAll,
      meetingRequestReceived: !unsubscribeAll,
      meetingRequestAccepted: !unsubscribeAll,
      meetingRequestCancelled: !unsubscribeAll,
    }));
  };

  async function putNotificationSettings() {
    try {
      //depending on type of user id send diffrent param name
      const params: { name: string; value: string | number }[] = [];

      if (typeof userId === 'number') {
        params.push({ name: 'userId', value: userId });
      } else if (typeof userId === 'string') {
        params.push({ name: 'encryptedUserId', value: userId });
      }
      const body = {
        ...notifcationSettingState,
      };

      const updatedNotificationEntity = await putData(
        ACCOUNT_CHANGE_EMAIL_NOTIFICATIONS_ROUTE,
        params,
        body,
        { 'Content-Type': 'application/json' },
      );

      updateAccount({
        notificationSettings: updatedNotificationEntity,
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  // function renderRadio() {
  //   return (
  //     <Radio
  //       sx={{
  //         color: theme.palette.primary.dark,
  //         '&.Mui-checked': {
  //           color: theme.palette.primary.main,
  //         },
  //       }}
  //     />
  //   );
  // }

  function renderNotificationGroup(
    title: string,
    settings: (typeof notificationSettingsConfig)[keyof typeof notificationSettingsConfig],
  ) {
    return (
      <div className={classes.cont}>
        <Typography variant={'h6'}>{title}</Typography>
        {settings.map((setting) => (
          <NotificationCheckbox
            key={setting.settingKey}
            label={setting.label}
            settingKey={setting.settingKey}
            onChange={handleChange}
            checked={Boolean(notifcationSettingState[setting.settingKey])}
          />
        ))}
      </div>
    );
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    settingKey: keyof INotificationSettings,
  ) => {
    setNotifcationSettingState((prevState) => ({
      ...prevState,
      [settingKey]: event.target.checked,
    }));
  };

  // function renderChatNotifications() {
  //   return (
  //     <div className={classes.cont}>
  //       <Typography variant={'h6'}>Chat Notifications</Typography>
  //       <RadioGroup
  //         defaultValue={notifcationSettingState.chat}
  //         onChange={handleChatChange}>
  //         <FormControlLabel
  //           value='neverMessage'
  //           control={renderRadio()}
  //           label='Never'
  //         />
  //         <FormControlLabel
  //           value='newMessage'
  //           control={renderRadio()}
  //           label='Whenever someone starts a new conversation with me'
  //         />
  //         <FormControlLabel
  //           value='everyMessage'
  //           control={renderRadio()}
  //           label='Whenever someone sends me a message'
  //         />
  //       </RadioGroup>
  //     </div>
  //   );
  // }

  function renderUnsubscribeFromAllSection() {
    return (
      <div className={classes.cont}>
        <Typography variant={'h6'}>General</Typography>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleUnsubscribeAllChange}
              checked={notifcationSettingState.unsubscribeFromAll}
            />
          }
          label='Unsubscribe from all emails'
        />
      </div>
    );
  }

  function renderContent() {
    return (
      <div className={classes.root}>
        {renderNotificationGroup(
          'Ecosystem Notifications',
          notificationSettingsConfig.ecosystem,
        )}
        {renderNotificationGroup(
          'Event Notifications',
          notificationSettingsConfig.events,
        )}
        {renderNotificationGroup(
          'Meeting Request Notifications',
          notificationSettingsConfig.meetings,
        )}
        {!!showAdminNotification &&
          renderNotificationGroup(
            'Administrative Notifications',
            notificationSettingsConfig.administrative,
          )}
        {renderUnsubscribeFromAllSection()}
      </div>
    );
  }

  return <div className={classes.root}>{renderContent()}</div>;
};

export default ChangeNotifications;
