import { useContext } from 'react';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useEventsContext } from 'services/contexts/Events/EventsContext';
import { IEvent, RecurringOption } from 'utils/models';
import { EVENT_ROUTE, postData } from 'utils/requests';
import { getDateWithTime } from 'utils/utils';
import { updateEvents } from 'utils/storage';

interface IUpdate {
  event: IEvent;
  setIsLoading: (b: boolean) => void;
  handleClose: () => void;
  coverImg: any;
  usedTags: any;
  selectedTagGroup: any;
  contactUserId: any;
  recurringOption?: RecurringOption;
}

const useUpdateEvent = (props: IUpdate) => {
  const {
    event,
    setIsLoading,
    handleClose,
    coverImg,
    usedTags,
    selectedTagGroup,
    contactUserId,
    recurringOption,
  } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const { events, setEvents } = useEventsContext();

  function trimEventId(input: any) {
    const index = input.indexOf('_');
    if (index !== -1) {
      return input.substring(0, index);
    } else {
      return input;
    }
  }

  function updateEndEvent(event: IEvent | undefined) {
    if (event === undefined) return;
    const updatedEvent = {
      ...event,
      isEnd: true,
      startDate: event.endDate,
      startDateString: event.endDateString,
    };

    return updatedEvent;
  }

  async function updateEvent() {
    setIsLoading(true);

    try {
      // Create the event data object
      const eventData: any = {
        title: event.title,
        description: event.description,
        id: event.id,
        location: event.location,
        latitude: event.latitude,
        longitude: event.longitude,
        date: event.startDate,
        startTime: event.startTime,
        endTime: event.endTime,
        accessLevelIds: event.accessLevelIds,
        multiDay: event.multiDay,
        startDateString: event.startDate,
        endDateString: event.endDate ?? '',
        url: event.url,
        ecosystemName,
        tags: JSON.stringify(usedTags),
        ...(event.recurring && { recurring: event.recurring }),
        ...(selectedTagGroup && { selectedTagGroup }),
        ...(contactUserId ? { contactUserId } : {}),
        ...(recurringOption
          ? {
              recurringEditType: recurringOption,
            }
          : {}),
      };

      const formData = new FormData();

      const eventDataString = JSON.stringify(eventData);
      formData.append(
        'calendarEventDTO',
        new Blob([eventDataString], { type: 'application/json' }),
      );

      if (coverImg) formData.append('image', coverImg, coverImg.name);

      const response: IEvent[] = await postData(EVENT_ROUTE, [], formData);

      if (event.id === undefined) {
        console.error('Event id is undefined');
      } else {
        let updatedEvents = null;

        if (recurringOption === RecurringOption.ALL) {
          let index = -1;
          updatedEvents = (events as IEvent[]).map((e) =>
            trimEventId(e.id) === trimEventId(event.id) ? response[++index] : e,
          );
        } else if (recurringOption === RecurringOption.FUTURE) {
          updatedEvents = (events as IEvent[]).map((e) => {
            if (e.id === event.id) {
              return response.find(
                (responseEvent) => responseEvent.id === e.id,
              );
            } else if (new Date(e.startDate) >= new Date(event.startDate)) {
              return (
                response.find((responseEvent) => responseEvent.id === e.id) || e
              );
            }
            return e;
          });
        } else {
          updatedEvents = (events as IEvent[]).map((e) =>
            e.id === event.id
              ? e.isEnd
                ? updateEndEvent(
                    response.find((responseEvent) => responseEvent.id === e.id),
                  )
                : response.find((responseEvent) => responseEvent.id === e.id)
              : e,
          );
        }

        const sortedEvents = (updatedEvents as IEvent[]).sort(
          (a, b) => getDateWithTime(a) - getDateWithTime(b),
        );

        setEvents(sortedEvents);
        updateEvents(sortedEvents);
      }

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Event edited',
      });
      handleClose();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }
  return updateEvent;
};

export default useUpdateEvent;
