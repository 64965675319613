import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import AdminSideMenu, {
  AdminMenuKeys,
} from 'components/Admin/AdminSideMenu';
import ManageUsers from 'components/Admin/ManageUsers/ManageUsers';
import ManageJoinRequests from 'components/Admin/ManageUsers/ManageJoinRequests';
import GenerateEmailList from 'components/Admin/ManageUsers/GenerateEmailList';
import TransferCompanyOwnership from 'components/Admin/ManageUsers/TransferCompanyOwnership';
import AddRemoveFromEcosystem from 'components/Admin/ManageUsers/AddRemoveFromEcosystem';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import {
  getData,
  ECOADMIN_ROUTE,
  deleteData,
  ECOADMIN_PENDING_INVITES_ROUTE,
} from 'utils/requests';
import { useParams } from 'react-router';
import Loading from 'components/common/Loading';
import { IEcoAdminData, ISideMenuSection } from 'utils/models';
import InvitationList from 'components/Admin/ManageUsers/InvitationList';
import {
  FormatListBulleted,
  Forum,
  MarkEmailUnread,
  PersonAddDisabled,
  PrivacyTip,
} from '@mui/icons-material';
import { Box } from '@mui/system';
import { getEcosystemInfo } from 'utils/storage';

interface OwnProps {}

const AdminManageUsers: FunctionComponent<OwnProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [ecoAdminData, setEcoAdminData] = useState<IEcoAdminData | null>(null);
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();

  const [selectedOption, setSelectedOption] = useState<AdminMenuKeys>(
    AdminMenuKeys.Manage_Join_Requests,
  );

  const { accessLevel } = getEcosystemInfo();

  useEffect(() => {
    fetchRequests();
    deleteOldPendingInvites();
  }, []);

  function setMenuOption(menuKey: AdminMenuKeys) {
    setSelectedOption(menuKey);
  }

  const sections: ISideMenuSection[] = [
    {
      key: AdminMenuKeys.Manage_Join_Requests,
      text: 'Manage join requests',
      icon: <Forum />,
      onClick: () => setMenuOption(AdminMenuKeys.Manage_Join_Requests),
      hasAccess: accessLevel.canManageUsers,
    },
    {
      key: AdminMenuKeys.Add_Remove_From_Ecosystem,
      text: 'Add/remove from ecosystem',
      icon: <PersonAddDisabled />,
      onClick: () => setMenuOption(AdminMenuKeys.Add_Remove_From_Ecosystem),
      hasAccess: accessLevel.canManageUsers,
    },
    {
      key: AdminMenuKeys.Invited_List,
      text: 'List of active invitations',
      icon: <MarkEmailUnread />,
      onClick: () => setMenuOption(AdminMenuKeys.Invited_List),
      hasAccess: accessLevel.canManageUsers,
    },

    {
      key: AdminMenuKeys.Generate_Email_List,
      text: 'Generate email list',
      icon: <FormatListBulleted />,
      onClick: () => setMenuOption(AdminMenuKeys.Generate_Email_List),
      hasAccess: accessLevel.canManageUsers,
    },
    {
      key: AdminMenuKeys.Transfer_Company_Ownership,
      text: 'Transfer company ownership',
      icon: <PrivacyTip />,
      onClick: () => setMenuOption(AdminMenuKeys.Transfer_Company_Ownership),
      hasAccess: accessLevel.canManageUsers,
    },
  ];

  async function fetchRequests() {
    setLoading(true);

    try {
      const data = await getData(ECOADMIN_ROUTE, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      setEcoAdminData(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  async function deleteOldPendingInvites() {
    try {
      const data = await deleteData(ECOADMIN_PENDING_INVITES_ROUTE, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  function renderSection() {
    if (loading || !ecoAdminData) {
      return <Loading />;
    }

    if (selectedOption === AdminMenuKeys.Manage_Join_Requests) {
      return <ManageJoinRequests joinRequests={ecoAdminData.joinRequests} />;
    }

    if (selectedOption === AdminMenuKeys.Add_Remove_From_Ecosystem) {
      return <AddRemoveFromEcosystem />;
    }

    if (selectedOption === AdminMenuKeys.Invited_List) {
      return <InvitationList />;
    }

    if (selectedOption === AdminMenuKeys.Generate_Email_List) {
      return <GenerateEmailList />;
    }

    if (selectedOption === AdminMenuKeys.Transfer_Company_Ownership) {
      return (
        <TransferCompanyOwnership companyNames={ecoAdminData.companyNames} />
      );
    }

    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'row',
        justifyContent: 'start',
        minHeight: 'calc(100vh - 4.4rem)',
        marginTop: '-3rem',
      }}>
      <Box>
        <AdminSideMenu menuItems={sections} selectedOption={selectedOption} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          marginLeft: '6rem',
        }}>
        <ManageUsers section={renderSection()} />
      </Box>
    </Box>
  );
};

export default AdminManageUsers;
