import React, { ChangeEvent, FunctionComponent, useEffect } from 'react';
import { SxProps } from '@mui/material';
import { filterCollection, filterCollectionAndReturn } from 'utils/utils';
import useFilterSearch from 'hooks/Common/useFilterSearch';
import InputField from './InputField';

interface ISearchFilter {
  collection: any[];
  filterOnField: string | string[];
  onFilter: (filteredCollection: any[]) => void;
  sx?: SxProps;
  returnDirectly?: boolean; //We use 2 types of filtering: One with direct return of filtered items, which requires additional state, and one that does require additional logic to display.
  height?: number | string;
  clearInput?: boolean;
}

const SearchFilter: FunctionComponent<ISearchFilter> = (props) => {
  const {
    collection,
    filterOnField,
    onFilter,
    sx,
    returnDirectly, // If you are using it, make sure you create additional state for the get (to display) and set (to attach on onFilter), and include original state as "collection"
    height,
    clearInput,
  } = props;
  const { searchTerm, setSearchTerm } = useFilterSearch({
    collection,
    filterOnField,
    onFilter,
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const newFilteredCollection = returnDirectly
        ? filterCollectionAndReturn(collection, filterOnField, searchTerm)
        : filterCollection(collection, filterOnField, searchTerm);

      onFilter(newFilteredCollection);
    }, 200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, collection]);

  useEffect(() => {
    setSearchTerm('');
  }, [clearInput]);

  return (
    <InputField
      value={searchTerm}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        setSearchTerm(event.target.value)
      }
      placeholder={'Search'}
      basicIcon={'search'}
      iconPosition={'start'}
      clearBackground
      sx={sx}
    />
  );
};

export default SearchFilter;
