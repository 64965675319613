import React, { FunctionComponent, useEffect } from 'react';
import { Controller, FieldErrors, useFieldArray } from 'react-hook-form';
import { IApplicationAssessmentTemplate } from 'utils/models';
import { FlexBox } from 'utils/styledComponents';
import { Typography } from '@mui/material';
import { primary } from 'theme';
import InputField from '../../../common/InputField';
interface IAssessmentOption {
  control: any;
  errors: FieldErrors<IApplicationAssessmentTemplate>;
  resetField: any;
}

const AssessmentConfigurationOption: FunctionComponent<IAssessmentOption> = (
  props,
) => {
  const { control, resetField } = props;

  const { fields: optionsFields } = useFieldArray({
    name: 'configuration.options',
    control,
  });

  useEffect(() => {
    return () => {
      resetField('configuration.options');
    };
  }, []);

  return (
    <FlexBox
      sx={{
        flexDirection: 'column',
        alignItems: 'start',
        gap: '0.5rem',
        marginTop: '0.5rem',
        ml: '0.25rem',
        width: '95%',
      }}>
      {optionsFields.map((option, optionIndex: number) => {
        return (
          <FlexBox key={option.id} sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: primary.natural4,
                mr: '0.725rem',
                minWidth: '1.25rem',
                maxWidth: '1.25rem',
              }}>
              {optionIndex + 1}
            </Typography>
            <Controller
              name={`configuration.options.${optionIndex}.name`}
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  onChange={(
                    e: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                  ) => {
                    field.onChange(e);
                  }}
                  placeholder={`Label (optional) ${
                    optionIndex === 0
                      ? ' - scored lowest'
                      : optionIndex === optionsFields.length - 1
                      ? ' - scored highest'
                      : ''
                  }`}
                  clearBackground
                  multiline
                  height={'2.5rem'}
                  width={'100%'}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      padding: '5px 0',
                      alignItems: 'center',
                    },
                  }}
                />
              )}
            />
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};

export default AssessmentConfigurationOption;
