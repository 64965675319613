import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Autocomplete, TextField, Theme } from '@mui/material';
import { IPeople } from 'utils/models';
import useMembers from 'hooks/Members/useMembers';

const useStyles = makeStyles((theme: Theme) => ({}));

interface ISearchUsers {
  onSearch: (value: IPeople) => void;
  excludedIds?: number[];
  fullWidth: boolean;
  size?: boolean;
  defaultUser?: any;
  displayEmail?: boolean;
}

const SearchUsers: FunctionComponent<ISearchUsers> = (props) => {
  const { onSearch, excludedIds, fullWidth, size, defaultUser, displayEmail } =
    props;
  const { members } = useMembers();

  const [person, setPerson] = useState<IPeople | null>(defaultUser || null);

  const emptyPerson: IPeople = {
    id: NaN,
    locationString: '',
    emailEcoPreferred: '',
    accessLevelName: '',
    accessLevelColor: '',
    profilePic: '',
    fullName: '',
    subtitle: '',
    primaryCompany: '',
  };

  const options = !members
    ? []
    : !excludedIds
    ? members
    : members.filter(
        ({ id }) => !excludedIds.find((excludedId) => excludedId === id),
      );

  useEffect(() => {
    onSearch(person || emptyPerson);
  }, [person]);

  return (
    <Autocomplete
      defaultValue={defaultUser}
      value={person}
      onChange={(e, value) => setPerson(value)}
      options={options}
      getOptionLabel={(option) =>
        displayEmail
          ? `${option.fullName} (${option.emailEcoPreferred})`
          : option.fullName
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField {...params} placeholder={'Select person'} />
      )}
      sx={{
        width: fullWidth ? '100%' : 'px',
        '& .MuiOutlinedInput-root': {
          borderRadius: '5px',
        },
      }}
      size={!size ? 'small' : 'medium'}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {displayEmail
            ? `${option.fullName} (${option.emailEcoPreferred})`
            : option.fullName}
        </li>
      )}
    />
  );
};

export default SearchUsers;
