import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';
import FormData from 'form-data';
import MainButton, { ButtonType } from '../common/MainButton';
import InputField from '../common/InputField';
import { PASSWORD_REST_EMAIL_ROUTE, postFormData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';

const useStyles = makeStyles(() => ({
  boldFont: {
    fontWeight: 'bold!important',
    color: 'white !important',
  },
  input: {
    border: '#e0e0e0 1.5px solid',
    borderRadius: '6px',
    paddingLeft: '7px',
  },
  form: {
    display: 'contents',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
  },
}));

interface OwnProps {}

const RequestPasswordReset: FunctionComponent<OwnProps> = (props) => {
  const classes = useStyles();

  const theme = useTheme();

  const navigate = useNavigate();
  const { addAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [resetEmail, setResetEmail] = useState('');

  async function requestResetPassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('email', resetEmail);

    try {
      await postFormData(PASSWORD_REST_EMAIL_ROUTE, formData, true);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `Email sent to ${resetEmail}`,
      });
      setResetEmail('');
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return (
    <form onSubmit={(e) => requestResetPassword(e)} className={classes.form}>
      <Typography variant={'h4'} className={classes.boldFont}>
        Reset password
      </Typography>
      <Typography variant={'h6'} color={'white'}>
        Please enter your email address to request a password reset email
      </Typography>

      <InputField
        value={resetEmail}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setResetEmail(event.target.value)
        }
        readonly={loading}
        label={'Email address'}
        labelTextColor={'white'}
        formLabelStyles={{
          fontSize: '1.2rem',
        }}
        noEffects
      />
      <div className={classes.buttonContainer}>
        <MainButton
          text={'Back'}
          type={ButtonType.FILLED}
          onClick={() => navigate('/login')}
          backgroundColor='inherit'
        />
        <MainButton
          text={'Submit'}
          type={ButtonType.FILLED}
          submit={true}
          loading={loading}
          backgroundColor={theme.palette.primary.main}
        />
      </div>
    </form>
  );
};

export default RequestPasswordReset;
