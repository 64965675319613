import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import {
  AccountBox,
  Book,
  Dashboard,
  EditCalendar,
  Event,
  Home,
  People,
} from '@mui/icons-material';
import { Icon, Typography, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { getEcosystemNameFromPath, getPathName } from 'utils/utils';
import { useMobileContext } from 'services/contexts/MobileContext';

interface ILink {
  name: string;
  icon: any;
  path: string;
}

const MOBILE_LINKS: ILink[] = [
  { name: 'Home', icon: <Home />, path: '' },
  { name: 'Community', icon: <People />, path: 'members' },
  { name: 'Booking', icon: <EditCalendar />, path: 'booking' },
  { name: 'Events', icon: <Event />, path: 'events' },
  { name: 'Resources', icon: <Book />, path: 'resources' },
];

const DASHBOARD_LINKS: ILink[] = [
  { name: 'Dashboard', icon: <Dashboard />, path: 'dashboard' },
  { name: 'Account', icon: <AccountBox />, path: 'account' },
];

const MobileNavbar = () => {
  const location = useLocation();
  const fullPathName = location.pathname;
  const ecosystemName = getEcosystemNameFromPath(fullPathName);
  const navigate = useNavigate();
  const theme = useTheme();
  const { setMobilePath } = useMobileContext();

  const [currentPath, setCurrentPath] = useState<string>(
    getPathName(location.pathname),
  );
  const [inActerio, setInActerio] = useState<boolean>(
    DASHBOARD_LINKS.some((link) => link.path === currentPath),
  );

  const currentLinks = inActerio ? DASHBOARD_LINKS : MOBILE_LINKS;

  useEffect(() => {
    const pathName = getPathName(location.pathname);
    setCurrentPath(pathName);
    setInActerio(pathName !== 'ecosystem');

    setMobilePath(fullPathName);
  }, [location]);

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: '#FFFFFF',
        position: 'fixed',
        bottom: 0,
        height: '5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: '2px solid #F0F0F0',
      }}>
      {currentLinks.map((link) => {
        const isCurrentPath =
          link.path === ''
            ? fullPathName === '/ecosystem/' + ecosystemName + '/'
            : fullPathName.includes(link.path);

        return (
          <Box
            key={link.name}
            onClick={() =>
              navigate(
                inActerio
                  ? `/${link.path}`
                  : `/ecosystem/${ecosystemName}/${link.path}`,
              )
            }
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            <Icon
              sx={{
                color: isCurrentPath ? theme.palette.primary.main : '#4D4D4D',
              }}>
              {link.icon}
            </Icon>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: isCurrentPath ? theme.palette.primary.main : '#4D4D4D',
              }}>
              {link.name}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default MobileNavbar;
