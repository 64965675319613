import { Button, Divider, Theme, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import LoginBackground from 'assests/LoginBackground';
import { makeStyles } from '@mui/styles';
import {
  AccessTime,
  CalendarMonth,
  Close,
  Email,
  LocationOn,
  Phone,
} from '@mui/icons-material';
import { primary } from 'theme';
import { IMeeting } from 'utils/models';
import CompanyCard from 'components/Companies/CompanyCard';
import CommonModal from 'components/common/Modal/CommonModal';

const useStyles = makeStyles((theme: Theme) => ({
  wavesBg: {
    backgroundImage: `url('data:image/svg+xml;utf8, ${encodeURIComponent(
      renderToStaticMarkup(<LoginBackground height={1080} width={1920} />),
    )}')`,
    backgroundSize: 'cover',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

interface MeetingDetailsProps {
  isOpen: boolean;
  handleClose: () => void;
  meeting: IMeeting;
  cancelMeeting: () => void;
}

const MeetingDetails: FunctionComponent<MeetingDetailsProps> = (props) => {
  const { isOpen, handleClose, meeting, cancelMeeting } = props;
  const classes = useStyles();
  const theme = useTheme();

  function renderHeader() {
    return (
      <Box
        className={classes.wavesBg}
        sx={{
          width: '200%',
          height: 100,
          marginX: -4,
          marginTop: -4,
          marginBottom: 12,
        }}>
        <Box
          sx={{
            borderRadius: 0.2,
            width: 100,
            height: 100,
            overflow: 'hidden',
            position: 'absolute',
            left: 50,
            top: 50,
          }}>
          <img
            src={meeting.bookedUser.profilePic}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
        <Box
          sx={{
            borderRadius: 0.2,
            bgcolor: meeting.bookedUser.accessLevelColor,
            padding: 0.5,
            marginTop: -1,
            width: 90,
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            left: 55,
            top: 147,
          }}>
          <Typography fontSize={12} fontWeight='bold'>
            {meeting.bookedUser.accessLevelName}
          </Typography>
        </Box>
        <Button
          sx={{
            bgcolor: primary.pureWhite,
            minWidth: 'unset',
            height: 25,
            width: 25,
            borderRadius: 1,
            marginLeft: 'auto',
            position: 'absolute',
            top: 13,
            right: 14,
          }}
          onClick={handleClose}>
          <Close sx={{ color: theme.palette.primary.dark }} />
        </Button>
      </Box>
    );
  }

  function renderTitle() {
    return (
      <Box sx={{ width: '100%' }}>
        <Typography fontSize={24}>
          {`${meeting.topic} with `}
          <span className={classes.bold}>{meeting.bookedUser.fullName}</span>
        </Typography>
        <Box
          sx={{
            position: 'flex',
            flexDirection: 'row',
            marginTop: '1rem',
            marginBottom: '1.5rem',
          }}>
          {/* <Button
            sx={{
              bgcolor: acterioTheme.grey,
              borderRadius: 0.2,
              '&:hover': { bgcolor: acterioTheme.darkHighlight },
              marginRight: '1rem',
            }}
            disabled={true}>
            <Send
              sx={{
                marginRight: 1,
                color: acterioTheme.white,
                transform: 'rotate(-45deg)',
                marginTop: -0.5,
                marginLeft: 0.5,
              }}
            />
            <Typography sx={{ color: acterioTheme.white, marginRight: 1 }}>
              Message
            </Typography>
          </Button> */}
          <Button
            sx={{
              bgcolor: '#FFE7E6',
              borderRadius: 0.2,
              border: '1px solid #DB9F9D',
              '&:hover': { bgcolor: '#DB9F9D' },
            }}
            onClick={cancelMeeting}>
            <Typography sx={{ color: '#D25B58', marginRight: 1 }}>
              Cancel Meeting
            </Typography>
            <Close sx={{ color: '#D25B58' }} />
          </Button>
        </Box>
      </Box>
    );
  }

  function renderDetails() {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          marginY: '1rem',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            marginBottom: '0.5rem',
          }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', marginRight: '2rem' }}>
            <CalendarMonth
              sx={{ color: theme.palette.primary.main, marginRight: 1 }}
            />
            <Typography color={theme.palette.primary.main}>Date</Typography>
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                marginLeft: 2,
                bgcolor: theme.palette.primary.light,
                borderRadius: 0.6,
                paddingY: 0.3,
                paddingX: 1.2,
              }}>
              {meeting.date}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <AccessTime
              sx={{ color: theme.palette.primary.main, marginRight: 1 }}
            />
            <Typography color={theme.palette.primary.main}>Time</Typography>
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                marginLeft: 2,
                bgcolor: theme.palette.primary.light,
                borderRadius: 0.6,
                paddingY: 0.3,
                paddingX: 1.2,
              }}>
              {`${meeting.startTime} - ${meeting.endTime}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            marginBottom: '0.5rem',
          }}>
          {meeting.bookedUser.emailEcoPreferred && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginRight: '2rem',
              }}>
              <Email
                sx={{ color: theme.palette.primary.main, marginRight: 1 }}
              />
              <Typography color={theme.palette.primary.main}>Email</Typography>
              <Typography
                sx={{
                  color: theme.palette.primary.dark,
                  marginLeft: 2,
                  bgcolor: theme.palette.primary.light,
                  borderRadius: 0.6,
                  paddingY: 0.3,
                  paddingX: 1.2,
                  textDecoration: 'underline',
                  maxWidth: 300,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}>
                {meeting.bookedUser.emailEcoPreferred ??
                  'Email was not provided'}
              </Typography>
            </Box>
          )}
          {meeting.bookedUser.phone && (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Phone
                sx={{ color: theme.palette.primary.main, marginRight: 1 }}
              />
              <Typography color={theme.palette.primary.main}>Phone</Typography>
              <Typography
                sx={{
                  color: theme.palette.primary.dark,
                  marginLeft: 2,
                  bgcolor: theme.palette.primary.light,
                  borderRadius: 0.6,
                  paddingY: 0.3,
                  paddingX: 1.2,
                  textDecoration: 'underline',
                  maxWidth: 170,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}>
                {meeting.bookedUser.phone ?? 'No number'}
              </Typography>
            </Box>
          )}
        </Box>
        {meeting.virtualMeetingLink && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <LocationOn
              sx={{ color: theme.palette.primary.main, marginRight: 1 }}
            />
            <Typography color={theme.palette.primary.main}>
              Meeting Link
            </Typography>
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                marginLeft: 2,
                bgcolor: theme.palette.primary.light,
                borderRadius: 0.6,
                paddingY: 0.3,
                paddingX: 1.2,
                textDecoration: 'underline',
              }}>
              <a href={meeting.virtualMeetingLink} target='_blank'>
                {meeting.platform}
              </a>
            </Typography>
          </Box>
        )}
      </Box>
    );
  }

  function renderRequest() {
    return (
      <Box
        sx={{
          width: '100%',
          borderRadius: 0.2,
          bgcolor: '#F5F5F5',
          marginTop: 4,
          marginBottom: '2rem',
        }}>
        <Box
          sx={{
            bgcolor: theme.palette.primary.main,
            position: 'relative',
            width: 145,
            height: 35,
            borderRadius: 0.3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: -17.5,
            left: 25,
          }}>
          <Typography color={primary.pureWhite} fontSize={16} fontWeight='bold'>
            Booked by
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 2,
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box
              sx={{
                borderRadius: 25,
                width: 65,
                height: 65,
                overflow: 'hidden',
                marginRight: '1rem',
              }}>
              <img
                src={meeting.bookingUser.profilePic}
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                fontWeight='bold'
                fontSize={16}
                color={theme.palette.primary.dark}
                marginTop={'0.3rem'}>
                {meeting.bookingUser.fullName}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                <Typography
                  sx={{
                    bgcolor: meeting.bookingUser.accessLevelColor,
                    paddingY: 0.4,
                    paddingX: 1.2,
                    color: theme.palette.primary.dark,
                    minHeight: 20,
                    borderRadius: 0.3,
                    fontSize: 12,
                    marginTop: '0.3rem',
                  }}>
                  {meeting.bookingUser.primaryCompany ?? 'Startup'}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {meeting.bookingUser.emailEcoPreferred && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginY: '0.3rem',
                }}>
                <Email
                  sx={{ color: theme.palette.primary.dark, marginRight: 1 }}
                />
                <Typography
                  color={theme.palette.primary.dark}
                  fontSize={14}
                  fontWeight='bold'>
                  Email
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.dark,
                    marginLeft: 2,
                    bgcolor: '#EAEAEA',
                    borderRadius: 0.6,
                    paddingY: 0.3,
                    paddingX: 1.2,
                    fontSize: 12,
                    textDecoration: 'underline',
                  }}>
                  {meeting.bookingUser.emailEcoPreferred ??
                    'Email was not provided'}
                </Typography>
              </Box>
            )}
            {meeting.bookingUser.phone && (
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Phone
                  sx={{ color: theme.palette.primary.dark, marginRight: 1 }}
                />
                <Typography
                  color={theme.palette.primary.dark}
                  fontSize={14}
                  fontWeight='bold'
                  marginRight={-0.7}>
                  Phone
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.dark,
                    marginLeft: 2,
                    bgcolor: '#EAEAEA',
                    borderRadius: 0.6,
                    paddingY: 0.3,
                    paddingX: 1.2,
                    fontSize: 12,
                    textDecoration: 'underline',
                  }}>
                  {meeting.bookingUser.phone ?? 'Number was not provided'}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: '90%',
            bgcolor: '#FFFFFF',
            minHeight: 100,
            marginLeft: '5%',
            borderRadius: 0.3,
            border: '1px solid #D4D4D4',
            padding: 2,
            display: 'flex',
            marginBottom: '2rem',
          }}>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>
              {meeting.topic}&nbsp;-&nbsp;
            </span>
            {meeting.info}
          </Typography>
        </Box>
        {meeting.bookingUserCompanyDto.name !== null && ( //Used to be companyName
          <Box sx={{ width: '90%', marginLeft: '5%', marginBottom: '2rem' }}>
            <CompanyCard {...meeting.bookingUserCompanyDto} />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <CommonModal
      open={isOpen}
      onClose={handleClose}
      disableScrollLock={true}
      sx={{ overflow: 'scroll', maxHeight: '95vh' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 'calc(84% - min(90vh, 100px))',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 750,
          bgcolor: '#FFFFFF',
          boxShadow: 24,
          p: 4,
          paddingBottom: 0,
          borderRadius: 0.2,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          minHeight: 500,
        }}>
        {renderHeader()}
        {renderTitle()}
        <Divider sx={{ color: '#F5F5F5', width: '120%', marginLeft: -4 }} />
        {renderDetails()}
        <Divider sx={{ color: '#F5F5F5', width: '120%', marginLeft: -4 }} />
        {renderRequest()}
      </Box>
    </CommonModal>
  );
};

export default MeetingDetails;
