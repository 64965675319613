import { useNavigate } from 'react-router-dom';

const useBack = () => {
  const navigate = useNavigate();
  const paths = location.pathname.split('/');
  const numPaths = paths.length;
  const isSubRoute = numPaths > 2;

  function navigateBack() {
    navigate(-1);
  }

  return {
    paths,
    numPaths,
    isSubRoute,
    navigateBack,
  };
};

export default useBack;
