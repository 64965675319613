import React, { FunctionComponent, useState } from 'react';
import InformationModal from 'components/common/Modal/InformationModal';
import { Box } from '@mui/system';
import { Error } from '@mui/icons-material';
import { acterioTheme } from 'theme';
import { Typography } from '@mui/material';
import useDeleteColumn from 'hooks/Applications/useDeleteColumn';
import { useApplicationContext } from 'services/contexts/Applications/ApplicationsContext';
import { useParams } from 'react-router';
import Loading from 'components/common/Loading';

interface IDeleteColumnModalProps {
  isOpen: boolean;
  handleClose: () => void;
  id: number;
}

const DeleteColumnModal: FunctionComponent<IDeleteColumnModalProps> = (
  props,
) => {
  const { isOpen, handleClose, id } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    applicationOverviewData,
    setApplicationOverviewData,
    selectedApplicationProcessId,
  } = useApplicationContext();
  const { ecosystemName } = useParams();

  const deleteColumn = useDeleteColumn({
    selectedApplicationProcessId,
    applicationOverviewData,
    setApplicationOverviewData,
    ecosystemName,
    statusId: id,
    setIsLoading,
  });
  function handleDelete() {
    deleteColumn();
    handleClose();
  }
  if (isLoading) {
    return <Loading height={200} />;
  }

  return (
    <InformationModal
      isOpen={isOpen}
      handleClose={handleClose}
      headerText='Are you sure you want to delete this column?'
      primaryText='Delete'
      primaryOnClick={handleDelete}
      primaryBgColor={acterioTheme.warningRed}
      secondaryText='Cancel'
      secondaryOnClick={handleClose}
      headerSize='1.875rem'
      width='38rem'
      buttonSx={{
        '&:hover': {
          color: '#CC2D2D',
          backgroundColor: '#F6D5D5',
        },
      }}>
      <Box sx={{ width: '30rem', marginY: '2rem', display: 'flex' }}>
        <Error sx={{ color: acterioTheme.warningRed, marginRight: '1rem' }} />
        <Typography>
          The column will be permanently deleted. Please confirm your choice
          carefully.
        </Typography>
      </Box>
    </InformationModal>
  );
};

export default DeleteColumnModal;
