import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ButtonBase, Typography } from '@mui/material';
import { IJourneyStage } from 'utils/models';
import JourneyProgressBar, {
  getProgressKey,
  INITIAL_STAGE_PROGRESS,
  IStageProgress,
} from './JourneyProgressBar';
import { JOURNEY_SET_LOCK_STATUS, postData } from 'utils/requests';
import { AlertContext, ALERT_TYPES } from '../Alert/AlertContext';
import { useParams } from 'react-router';
import { useJourneyContext } from './JourneyContext';
import { getEcosystemInfo } from 'utils/storage';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box } from '@mui/system';

const JourneyStage: FunctionComponent<IJourneyStage> = (props) => {
  const { stages } = useJourneyContext();
  const stage = stages.find((s) => s.id === props.id);

  if (!stage) {
    console.error(`Stage with id ${props.id} not found in the context.`);
    return null;
  }

  const { name, themes, locked, id } = stage;
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName, companyName } = useParams();
  const { updateLockedStatus } = useJourneyContext();

  const [stageProgress, setStageProgress] = useState<IStageProgress>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getStageProgress();
  }, [props]);

  function getStageProgress() {
    const stageProgressCopy = { ...INITIAL_STAGE_PROGRESS };

    themes.forEach(({ milestones }) => {
      milestones.forEach(({ goals }) => {
        if (!goals || goals.length === 0) return;

        goals.forEach(({ progress }) => {
          const key = getProgressKey(progress);
          stageProgressCopy[key] = stageProgressCopy[key] + 1;
        });
      });
    });

    setStageProgress(stageProgressCopy);
  }

  async function updateLockStatus(lockStatus: boolean) {
    setLoading(true);
    if (!companyName) return;
    try {
      await postData(JOURNEY_SET_LOCK_STATUS, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'journeyStageId', value: id },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'lockStatus', value: lockStatus },
      ]);

      updateLockedStatus(props, lockStatus);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Lock Status Updated',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setLoading(false);
  }

  function renderLockStatus() {
    if (locked) {
      return (
        <>
          <Box
            sx={{
              display: 'inline-flex',
              borderRadius: '10px',
              position: 'absolute',
              right: '10px',
              top: '1.875rem',
              width: '67px',
              justifyContent: 'center',
              backgroundColor: '#d4d4d4',
            }}>
            <LockOutlinedIcon sx={{ width: '12px!important' }} />
            <ButtonBase
              sx={{
                fontSize: '12px',
                width: '100%',
                cursor: getEcosystemInfo().accessLevel.canManageJourneys
                  ? 'pointer'
                  : 'default!important',
              }}
              disabled={
                !getEcosystemInfo().accessLevel.canManageJourneys || loading
              }
              onClick={() => updateLockStatus(false)}>
              Locked
            </ButtonBase>
          </Box>
        </>
      );
    }
  }

  return (
    <Box>
      <Box
        sx={{
          root: {
            padding: '0,625rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '3px',
            marginBottom: '3px',

            '& p': {
              fontSize: '16px !important',
            },
          },
        }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.875rem',
            paddingBottom: '0.2rem',
          }}>
          {name}
        </Typography>
        <JourneyProgressBar progress={stageProgress} width={140} />
      </Box>
      {renderLockStatus()}
    </Box>
  );
};

export default JourneyStage;
