import { Check } from '@mui/icons-material';
import { Box, SxProps } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { primary } from 'theme';

interface ICompleteCheckProps {
  size?: number | string;
  iconSize?: number | string;
  sx?: SxProps;
}

const CompleteCheck: FunctionComponent<ICompleteCheckProps> = (props) => {
  const { size, iconSize, sx } = props;

  return (
    <Box
      sx={{
        width: size ?? '1.5rem',
        height: size ?? '1.5rem',
        borderRadius: '100%',
        bgcolor: primary.additionalDarkGreen,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}>
      <Check
        sx={{
          fontSize: iconSize ?? `calc(${size}/1.25)` ?? '0.75rem',
          color: primary.pureWhite,
        }}
      />
    </Box>
  );
};

export default CompleteCheck;
