import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
  TimePicker,
  TimePickerProps,
} from '@mui/lab';
import { cardShadow } from 'theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

type DatePickerView = 'month' | 'year' | 'day';

const useStyles = makeStyles(() => ({
  datePicker: {
    '& .MuiInputBase-root': {
      borderRadius: '4px !important',
      boxShadow: `${cardShadow} !important`,
    },
  },
}));

interface IInputDatePicker {
  onChange: (date: Date | null) => void;
  anchorEl: HTMLElement | null;
  initialValue?: Date;
  withTime?: boolean;
  views?: DatePickerView[];
}

const InputDatePicker: FunctionComponent<IInputDatePicker> = (props) => {
  const { onChange, anchorEl, initialValue, withTime, views } = props;
  const classes = useStyles();

  const [value, setValue] = useState<Date | null>(initialValue ?? null);

  function onClose() {
    if (withTime) onChange(value);
  }

  const dateTimePickerProps: DatePickerProps = {
    ...(withTime ? TimePicker : DatePicker),
    className: classes.datePicker,
    disablePast: true,
    open: true,
    value,
    onClose: () => onClose(),
    onAccept: (date: any) => onChange(date),
    onChange: (_: any) => undefined,
    PopperProps: {
      anchorEl,
      placement: 'bottom-start',
    },
    views,
    renderInput: (params: any) => (
      <TextField {...params} style={{ display: 'none' }} />
    ),
  };

  return (
    <div className={classes.datePicker}>
      <LocalizationProvider dateAdapter={AdapterDateFns as any}>
        {withTime ? (
          <TimePicker
            {...(dateTimePickerProps as TimePickerProps)}
            ampm={false}
          />
        ) : (
          <DatePicker {...(dateTimePickerProps as DatePickerProps)} />
        )}
      </LocalizationProvider>
    </div>
  );
};

export default InputDatePicker;
