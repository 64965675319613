import React, { FunctionComponent, useContext, useState } from 'react';
import { Check } from '@mui/icons-material';
import { useJourneyTemplateContext } from './JourneyTemplateContext';
import { JOURNEY_TEMPLATE_SAVE_ROUTE, putData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { NEW_JOURNEY_KEY } from 'hooks/Journey/useJourneyTemplateSpecific';
import { IJourney } from 'utils/models';
import CommonButton from 'components/common/CommonButton';

interface IJourneyTemplateSave {
  setHasChanged?: (value: boolean) => void;
}

const JourneyTemplateSave: FunctionComponent<IJourneyTemplateSave> = (
  props,
) => {
  const { setHasChanged } = props;

  const { ecosystemName, journeyId } = useParams();
  const { addAlert } = useContext(AlertContext);
  const { journey, setJourney, stageIndex, stage } =
    useJourneyTemplateContext();

  const [loading, setLoading] = useState<boolean>(false);

  async function saveTemplate() {
    if (!journey || !stage) return;
    setLoading(true);

    const journeyToSave = { ...journey };
    journeyToSave.stages[stageIndex] = stage;

    try {
      const data: IJourney = await putData(
        JOURNEY_TEMPLATE_SAVE_ROUTE,
        [{ name: 'ecosystemName', value: ecosystemName }],
        journeyToSave,
      );

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Journey saved',
      });

      setHasChanged && setHasChanged(false);

      if (journeyId === NEW_JOURNEY_KEY) {
        setJourney(data);
      }
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return (
    <CommonButton
      text={'Save'}
      onClick={saveTemplate}
      icon={<Check />}
      sx={{ margin: '1rem' }}
    />
  );
};

export default JourneyTemplateSave;
