import {
  IAvailability,
  IAvailableTimeslots,
  ICompany,
  IEvent,
  IPeople,
  ITag,
  UnreadMessages,
} from './models';
import {
  ACCOUNT_PROFILE_PIC_ROUTE,
  ACCOUNT_ROUTE,
  getData,
  LOGIN_ROUTE,
  postFormData,
} from './requests';
import { setUser, setAccount } from './storage';
import dayjs from 'dayjs';
import { useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment-timezone';
import { CometChat } from '@cometchat/chat-sdk-javascript';

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const dynamicBoxShadow = (hex: string): string => {
  const rgb = hexToRgb(hex);
  return `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px`;
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getMonthName = (monthIndex: number) => monthNames[monthIndex];

export const downloadFromUrl = (url: string, name: string) => {
  const link = document.createElement('a');
  link.target = '_blank';
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getTimeFromDate = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return { hours, minutes };
};

export const addZero = (num: number) => (num < 10 ? `0${num}` : num);

export const filterCollection = (
  collection: any[],
  filterOnFields: string | string[],
  searchTerm: string,
) => {
  // This function is used to filter field of multiple fields with single search term
  if (!collection) return [];
  if (!searchTerm) return resetFilteredCollection(collection);

  const fieldsToFilter: string[] = Array.isArray(filterOnFields)
    ? filterOnFields
    : [filterOnFields];

  const collectionWithTags = changeArrayIntoString(collection, 'tags'); //For array of strings, create a string

  const filteredCollection = collectionWithTags.map((item) => {
    const filterResults = fieldsToFilter.map((field) => {
      const searchOnValue = String(item[field]).toUpperCase();
      return searchOnValue.indexOf(searchTerm.toUpperCase()) > -1;
    });
    item.filterOut = filterResults.some((result) => result);

    return item;
  });

  return filteredCollection;
};

export const filterCollectionAndReturn = (
  collection: any[],
  filterOnFields: string | string[],
  searchTerm: string,
) => {
  //Purpose for having this function is, that when we filter with filterCollection, we return an original collection, BUT modified with additional prop "filteredOut". Then we display or not based on that prop.
  const filteredCollection = filterCollection(
    collection,
    filterOnFields,
    searchTerm,
  );
  return returnFilteredOut(filteredCollection);
};

export const returnFilteredOut = (collection: any[]) => {
  return collection.filter((item) => item.filterOut);
};

export const resetFilteredCollection = (collection: any[]) => {
  return collection.map((item) => {
    item.filterOut = true; //In our app the convention is: FilteredOut => Result of filtering was true (Fulfills condition)
    return item;
  });
};

export const filterOutItemsFromCollection = (
  collection: any[],
  filteredProperty: string,
  searchTerm: string,
) => {
  if (!collection) return [];
  if (!searchTerm) return collection;

  const filteredCollection = collection.filter((item) =>
    item[filteredProperty]?.toUpperCase().includes(searchTerm.toUpperCase()),
  );

  return filteredCollection;
};

export const filterUnionOnTags = (
  collection: any[],
  fieldToCheck: string, //Designed to check tags ITag[]
  searchSet: string[],
) => {
  if (!searchSet) return collection;

  const upperSearchSet = searchSet.map((tag) => tag.toUpperCase());
  //See if at least one tag is present
  return collection.filter((item) => {
    return item[fieldToCheck]?.some((tag: ITag) =>
      upperSearchSet.includes(tag.name.toString().toUpperCase()),
    );
  });
};

export const filterIntersectionOnTags = (
  collection: any[],
  fieldToCheck: string, //Designed to check tags ITag[]
  searchSet: string[],
) => {
  if (!searchSet) return collection;

  const upperSearchSet = searchSet.map((tag) => tag.toUpperCase());
  //See if all tags are present
  return collection.filter((item) => {
    return upperSearchSet.every((tag) =>
      item[fieldToCheck]
        ?.map((itemTag: ITag) => itemTag.name.toString().toUpperCase())
        .includes(tag),
    );
  });
};

export const filterIntersectionOnListField = (
  collection: any[],
  fieldToCheck: string, //Designed to check on any[]
  searchSet: string[],
) => {
  if (!searchSet) return collection;

  const upperSearchSet = searchSet.map((tag) => tag.toUpperCase());
  return collection.filter((item) => {
    return upperSearchSet.every((searchItem) =>
      item[fieldToCheck]
        ?.map((listItem: string) => listItem.toString().toUpperCase())
        .includes(searchItem),
    );
  });
};

export const filterCollectionTwiceWithIntersection = (
  collection: any[],
  filterOnStringField1: string,
  filterOnSetField2: string,
  searchOnString1: string,
  searchOnSet2: string[],
) => {
  if (!collection) return [];

  const filteredCollection1 = filterOutItemsFromCollection(
    collection,
    filterOnStringField1,
    searchOnString1,
  );

  const filteredCollection2 = !searchOnSet2.length
    ? filteredCollection1
    : filterIntersectionOnTags(
        filteredCollection1,
        filterOnSetField2,
        searchOnSet2,
      );

  return filteredCollection2;
};

export const filterCollectionThriceWithIntersection = (
  collection: any[],
  filterOnStringField1: string,
  filterOnSetField2: string,
  filterOnSetField3: string,
  searchOnString1: string,
  searchOnSet2: string[],
  searchOnSet3: string[],
) => {
  if (!collection) return [];

  const filteredCollection1 = filterOutItemsFromCollection(
    collection,
    filterOnStringField1,
    searchOnString1,
  );

  const filteredCollection2 = !searchOnSet2.length
    ? filteredCollection1
    : filterIntersectionOnTags(
        filteredCollection1,
        filterOnSetField2,
        searchOnSet2,
      );

  const filteredCollection3 =
    searchOnSet3[0] === ''
      ? filteredCollection2
      : filterIntersectionOnListField(
          filteredCollection2,
          filterOnSetField3,
          searchOnSet3,
        );

  return filteredCollection3;
};

export const getPathName = (path: string) => {
  return path.split('/')[1];
};

export const getEcosystemNameFromPath = (path: string) => {
  return path.split('/')[2];
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const ONE_MIN = 60000;
export const ONE_DAY = 86400000;

export const formatNumOf = (num: number, of: string) => {
  return `${num} ${of}${num === 1 ? '' : 's'}`;
};

export const previewText = (text: string, maxLength: number) => {
  if (!text) return '';
  if (text.length <= maxLength) return text;
  return `${text.slice(0, maxLength)}...`;
};

export const authenticateEmailAndPassword = async (
  email: string,
  password: string,
) => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('password', password);

  try {
    const userData = await postFormData(LOGIN_ROUTE, formData, true);
    setUser(userData.access_token, userData.refresh_token);

    const accData = await getData(ACCOUNT_ROUTE);
    const accProfilePicData = await getData(ACCOUNT_PROFILE_PIC_ROUTE);
    setAccount({
      ...accData,
      profilePicture: accProfilePicData,
    });

    return true;
  } catch (e: any) {
    console.error('error', e);
    return false;
  }
};

export const getTextFromHTML = (htmlString: string): string => {
  return htmlString.replace(/<[^>]+>/g, '');
};

export const getMonth = (month = dayjs().month()) => {
  month = Math.floor(month);
  const year = dayjs().year();
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;
  const daysMatrix = new Array(6).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
  return daysMatrix;
};

export const getDateWithTime = (event: IEvent) => {
  const { startDate, startTime } = event;
  const time = startTime ? startTime : '00:00';
  const d = new Date(startDate);
  const [hours, mins] = time.split(':');
  d.setHours(parseInt(hours), parseInt(mins), 0);
  return d.getTime();
};

export function formatDateString(dateStr: any) {
  const date = new Date(dateStr);
  const day = date.getDate();
  let suffix = 'th';
  if (day === 1 || day === 21 || day === 31) {
    suffix = 'st';
  } else if (day === 2 || day === 22) {
    suffix = 'nd';
  } else if (day === 3 || day === 23) {
    suffix = 'rd';
  }

  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    year: 'numeric',
  };
  const monthAndYear = date.toLocaleDateString(
    'en-US',
    options as Intl.DateTimeFormatOptions,
  );

  return `${day}${suffix} ${monthAndYear}`;
}

export function formatDateAs_yyyy_MM_dd(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getDayNameFromDate(date: Date) {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return days[date.getDay()];
}

export function formatDateTime(newDate: Date | null) {
  if (!newDate) return '';

  const hours = newDate.getHours();
  const mins = newDate.getMinutes();

  return `${addZero(hours)}:${addZero(mins)}`;
}

export function formatDateYMD(newDate: Date | null) {
  if (!newDate) return '';

  const day = newDate.getDate();
  const year = newDate.getFullYear();
  const month = newDate.getMonth();

  return `${year}-${addZero(month + 1)}-${addZero(day)}`;
}

export function formatDateDMY(newDate: Date | null) {
  if (!newDate) return '';

  const day = newDate.getDate();
  const year = newDate.getFullYear();
  const month = newDate.getMonth();

  return `${addZero(day)}-${addZero(month + 1)}-${year}`;
}

export function formatDateDD(newDate: Date | null) {
  if (!newDate) return '';

  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dayOfWeek = daysOfWeek[newDate.getDay()];
  const month = months[newDate.getMonth()];
  const dayOfMonth = newDate.getDate();

  return `${dayOfWeek}, ${month} ${dayOfMonth}`;
}

export const formatDateEuropean = (
  date: Date,
  format: 'long' | 'short' = 'long',
): string => {
  const longOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };
  const shortOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  const options = format === 'long' ? longOptions : shortOptions;
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

export function isAvailablity(obj: any): obj is IAvailability {
  return 'weeklyHours' in obj;
}

export function isAvailableTimeslots(obj: any): obj is IAvailableTimeslots[] {
  if (!Array.isArray(obj)) {
    return false;
  }
  for (const item of obj) {
    if (!('date' in item && 'dayOfWeek' in item && 'timeSlots' in item)) {
      return false;
    }
  }
  return true;
}

export function adjustResponsiveness(
  paramLG: any,
  paramMD: any,
  paramSM?: any,
  paramXS?: any,
) {
  const theme = useTheme();
  const isLgDn = useMediaQuery(theme.breakpoints.down('lg'));
  const isMdDn = useMediaQuery(theme.breakpoints.down('md'));
  const isSmDn = useMediaQuery(theme.breakpoints.down('sm'));

  if (isSmDn && isMdDn && isLgDn && paramXS) return paramXS;

  if (isMdDn && isLgDn && paramSM) return paramSM;

  if (isLgDn) return paramMD;

  return paramLG;
}

export function createDateFromTime(timeString: string): Date {
  const currentDate = new Date();
  const [hours, minutes] = timeString.split(':').map(Number);
  currentDate.setHours(hours, minutes);
  return currentDate;
}

export function areSetsEqual(arg1: number[], arg2: number[]): boolean {
  const set1 = new Set(arg1);
  const set2 = new Set(arg2);

  if (set1.size !== set2.size) return false;

  const array1 = Array.from(set1);
  const array2 = Array.from(set2);

  array1.sort((a, b) => a - b);
  array2.sort((a, b) => a - b);

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) return false;
  }
  return true;
}

export function findElementsNotInSet1(
  arg1: number[],
  arg2: number[],
): number[] {
  const set1 = new Set(arg1);
  const set2 = new Set(arg2);
  const array1 = Array.from(set1);
  const array2 = Array.from(set2);

  const difference = array2.filter((item) => !array1.includes(item));

  return difference;
}

export const urlToImagePNGFile = async (
  url: string,
  setCoverImg: (a: any) => void,
) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], 'profilePic.png', { type: 'image/png' });
    setCoverImg(file);
  } catch (error) {
    console.error('Error converting URL to image file:', error);
  }
};

export const defaultEventPhoto =
  'https://storage.googleapis.com/enablemagic.appspot.com/testing/event-pics/default-event-pic.png';

export function createNewObject<InputType>(obj: InputType): InputType {
  return JSON.parse(JSON.stringify(obj));
}

export function validateColor(
  color: string | undefined,
  defaultColor: string,
): string {
  if (color && /^#[0-9A-F]{3,6}$/i.test(color)) {
    return color; // If color exists and is valid, return it
  } else if (color && /^[0-9A-F]{3,6}$/i.test(color)) {
    return '#' + color; // If color exists but is missing #, return fixed
  } else {
    return defaultColor; // Default to defaultColor if color doesn't exist or is completely broken
  }
}

export function fixAvailabilityTimeslots(
  availabilityToFix: IAvailability,
): IAvailability {
  const days = Object.keys(availabilityToFix.weeklyHours); // Get all days in weeklyHours

  days.forEach((day) => {
    if (day === 'id') return;
    if (availabilityToFix.weeklyHours[day].times.length === 0) {
      // If times array is empty, add an object with empty startTime and endTime
      availabilityToFix.weeklyHours[day].times.push({
        startTime: '',
        endTime: '',
      });
    }
  });

  return availabilityToFix;
}

export function doesAvailabilityNeedsFixing(
  availability: IAvailability,
): boolean {
  for (const day of Object.keys(availability.weeklyHours)) {
    if (day === 'id') {
      return true;
    }
    if (!availability.weeklyHours[day].times) {
      return true;
    }
    if (availability.weeklyHours[day].times.length === 0) {
      return true;
    }
  }
  return false;
}

export function historyNavigate(inputUrl: string) {
  window.history.pushState(null, '', inputUrl);
}

export function getOffset(timezone: any) {
  const offset = moment.tz(timezone).format('Z');
  return `(UTC${offset})`;
}

export function isPeople(communityItem: any): communityItem is IPeople {
  if (!communityItem) return false;
  return (communityItem as IPeople).accessLevelName !== undefined;
}

export function isCompany(communityItem: any): communityItem is ICompany {
  if (!communityItem) return false;
  return (communityItem as ICompany).label !== undefined;
}

export function changeArrayIntoString<T extends { [key: string]: any }>(
  collection: T[],
  fieldToChange: string,
): T[] {
  if (!collection) return [];
  if (!fieldToChange) return collection;

  return collection.map((item: T) => {
    if (!item[fieldToChange] || !Array.isArray(item[fieldToChange]))
      return item;

    const tagNames = (item[fieldToChange] as any[]).map((tag: any) => tag.name);

    const newItem = {
      ...item,
      [fieldToChange + 'String']: tagNames.join(', '),
    };

    return newItem as T;
  });
}

export const isEmptyRichText = (text: string | undefined) => {
  if (text === undefined) return true;
  return (
    text.trim() === '' ||
    text === '<p><br></p>' ||
    text === '<p><br /></p>' ||
    text === '<p></p>'
  );
};

export function trackUmamiEvent(event: string): void {
  if (window.umami) {
    window.umami.track(event);
  }
}

export function fetchUnreadMessageCount(
  setUnreadMessagesCount: (count: number) => void,
) {
  CometChat.getUnreadMessageCount().then(
    (value) => {
      const unreadMessages = value as UnreadMessages;
      const unreadGroupMessages = Object.values(unreadMessages.groups).reduce(
        (total, count) => total + count,
        0,
      );
      const unreadUserMessages = Object.values(unreadMessages.users).reduce(
        (total, count) => total + count,
        0,
      );
      setUnreadMessagesCount(unreadUserMessages + unreadGroupMessages);
    },
    (error) => {
      console.error('Ошибка получения непрочитанных сообщений:', error);
    },
  );
}
