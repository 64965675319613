import React, {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { COMPANY_ROUTE, getData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useNavigate, useParams } from 'react-router';
import { ICompanyField, ICompanyProfile, ITag } from 'utils/models';
import {
  clearCompanyStatus,
  getCompanyEditStatus,
  setCompanyEditStatus,
} from 'utils/storage';
import Loading from 'components/common/Loading';
import useCompanies from 'hooks/Companies/useCompanies';
import { isEmptyRichText } from 'utils/utils';

const useCompany = () => {
  const editStatus = getCompanyEditStatus();
  const { ecosystemName, companyId } = useParams();
  const { addAlert } = useContext(AlertContext);
  const [companyProfile, setCompanyProfile] = useState<ICompanyProfile | null>(
    null,
  );
  const [editProfile, setEditProfile] = useState<boolean>(editStatus);
  const [loading, setLoading] = useState<boolean>(false);
  const [editedLogo, setEditedLogo] = useState<any | null>(null);

  const [isInfoMissing, setIsInfoMissing] = useState<boolean>(false);

  const { setCompanies } = useCompanies();
  const navigate = useNavigate();

  useEffect(() => {
    if (!ecosystemName || !companyId) return;
    if (!!companyProfile?.name) {
      setIsInfoMissing(false);
    } else {
      setIsInfoMissing(true);
    }
  }, [companyProfile]);

  useEffect(() => {
    if (!ecosystemName || !companyId) return;
    setLoading(true);

    getData(COMPANY_ROUTE, [
      { name: 'ecosystemName', value: ecosystemName },
      { name: 'companyId', value: encodeURIComponent(companyId) },
    ])
      .then((data) => {
        setCompanyProfile(data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error('error', error);
        addAlert({
          type: ALERT_TYPES.ERROR,
          message: error.message,
        });
        setLoading(false);
      });
    clearCompanyStatus();
  }, [ecosystemName, companyId]);

  useEffect(() => {
    if (editProfile) return;
    setCompanyEditStatus(editProfile);
  }, [editProfile]);

  // function updateHeaderAndFirstMainFieldOfCompany(
  //   updatedCompanyOverview: ICompany,
  // ) {
  //   setCompanies((prev: ICompany[] | null) => {
  //     if (!prev) return null;
  //     if (!Array.isArray(prev)) return null;

  //     const companyIndex = prev.findIndex(
  //       (company) => company.name === updatedCompanyOverview.name,
  //     );

  //     if (companyIndex === -1) return prev;

  //     const updatedCompanies = [...prev];
  //     const companyToUpdate = { ...updatedCompanies[companyIndex] };

  //     companyToUpdate.companyHeader = updatedCompanyOverview.companyHeader;
  //     if (updatedCompanyOverview.companyFirstMainField) {
  //       companyToUpdate.companyFirstMainField =
  //         updatedCompanyOverview.companyFirstMainField;
  //     }

  //     updatedCompanies[companyIndex] = companyToUpdate;
  //     storeCompanies(updatedCompanies);
  //     return updatedCompanies;
  //   });
  // }

  // function updateCompanyMembers(
  //   updatedCompanyList: ICompanyProfileEmployeeDTO[],
  // ) {
  //   if (!companyProfile) return;

  //   const updatedCompanyProfile: ICompanyProfile = {
  //     ...companyProfile,
  //     companyProfileEmployeeDto: updatedCompanyList,
  //   };

  //   setCompanyProfile(updatedCompanyProfile);
  // }

  function updateCompanyField(fieldId: number, value: any) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile = { ...prevProfile };
      const allFields = [
        ...updatedProfile.header,
        ...updatedProfile.main,
        ...updatedProfile.sidebar,
      ];

      const fieldToUpdate = allFields.find((field) => field.id === fieldId);

      if (fieldToUpdate) {
        setFieldByType(fieldToUpdate, value);
      }

      return updatedProfile;
    });
  }

  function updateCompanyTagGroup(newTags: ITag[]) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile = { ...prevProfile, usedTags: newTags };

      return updatedProfile;
    });
  }

  function setFieldByType(field: ICompanyField, value: any) {
    switch (field.type) {
      case 'TAGS':
        field.usedTags = value;
        break;
      case 'RICH_TEXT':
        field.richText = value;
        break;
      case 'FILES':
        field.files = value;
        break;
      case 'IMAGES':
        field.files = value;
        break;
      case 'PEOPLE':
        field.people = value;
        break;
      case 'TEXT':
        field.text = value;
        break;
      case 'DATE':
        field.localDate = value;
        break;
      default:
        break;
    }
  }

  function removeSelectedTag(fieldId: number | 'tagGroup', tag: ITag) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile = { ...prevProfile };

      if (fieldId === 'tagGroup') {
        //If null is passed its going to target company tag group instead of a company field
        updatedProfile.usedTags = updatedProfile.usedTags.filter(
          (t) => t.id !== tag.id,
        );
      } else {
        //Target appropriate company field
        const allFields = [
          ...updatedProfile.header,
          ...updatedProfile.main,
          ...updatedProfile.sidebar,
        ];

        const fieldToUpdate = allFields.find((field) => field.id === fieldId);

        if (fieldToUpdate?.usedTags) {
          fieldToUpdate.usedTags = fieldToUpdate.usedTags.filter(
            (t) => t.id !== tag.id,
          );
        }
      }

      return updatedProfile;
    });
  }

  function updateCompanyInfo(fieldName: string, value: string) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile: ICompanyProfile = JSON.parse(
        JSON.stringify(prevProfile),
      ); // Deep copy

      if (fieldName in updatedProfile) {
        (updatedProfile as any)[fieldName] = value; // Use 'as any' to bypass type checking
      }

      return updatedProfile;
    });
  }

  const getFieldByType = (field: ICompanyField) => {
    switch (field.type) {
      case 'TAGS':
        return field.usedTags;
      case 'RICH_TEXT':
        return field.richText;
      case 'FILES':
        return field.files;
      case 'PEOPLE':
        return field.people;
      case 'TEXT':
        return field.text;
      case 'DATE':
        return field.localDate;
      default:
        return null;
    }
  };

  function checkIfInfoIsMissing(
    field: ICompanyField,
    prop: keyof ICompanyField,
  ) {
    if (!companyProfile || !field) return;

    function returnMissing() {
      switch (prop) {
        case 'files':
          return field.required && !field.files;
        case 'richText':
          return (
            field.required && field.richText && isEmptyRichText(field.richText)
          );
        case 'usedTags':
          return (
            (field.required && !field.usedTags) ||
            (field.usedTags && field.usedTags.length < 1)
          );
        default:
          return (
            field.required &&
            (field[prop] === undefined ||
              field[prop] === null ||
              field[prop] === '')
          );
      }
    }

    const isMissing = returnMissing();

    if (!isMissing) return;

    setIsInfoMissing(isMissing);
  }

  return {
    companyProfile,
    editProfile,
    loading,
    editedLogo,
    isInfoMissing,
    setEditedLogo,
    getFieldByType,
    setCompanyProfile,
    setEditProfile,
    removeSelectedTag,
    updateCompanyField,
    updateCompanyInfo,
    checkIfInfoIsMissing,
    setCompanies,
    updateCompanyTagGroup,
  };
};

const CompanyContext = createContext({} as ReturnType<typeof useCompany>);

export const CompanyProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const companyContext = useCompany();
  const { loading, companyProfile } = companyContext;
  if (loading || !companyProfile) {
    return <Loading />;
  }

  return (
    <CompanyContext.Provider value={companyContext}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompanyContext = () => useContext(CompanyContext);
