import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { ButtonBase, Theme, Typography } from '@mui/material';
import { useJourneyTemplateContext } from './JourneyTemplateContext';
import MainButton, { ButtonType } from 'components/common/MainButton';
import { Add } from '@mui/icons-material';
import InputField from 'components/common/InputField';
import DeleteIcon from '@mui/icons-material/Delete';
import JourneyTemplateResources from './JourneyTemplateResources';
import { IJourneyGoal } from 'utils/models';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  goalBody: {
    borderBottom: '1px solid #E0E0E0',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '1.5rem',
    paddingLeft: '0',
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
    padding: '0.4rem 0',

    '& button': {
      width: '170px !important',
      height: '27.5px !important',
      margin: '0 !important',
      fontSize: '14px !important',

      '& svg': {
        height: '15px',
        width: '15px',
      },
    },
  },
  cont: {
    display: 'flex',
    gap: '1rem',
  },
  index: {
    padding: '0 1rem',
    borderRight: '1px solid #eee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteGoal: {
    borderRadius: '3px!important',

    '& svg': {
      color: theme.palette.primary.dark,
    },
  },
  deleteCont: {
    position: 'absolute',
    right: '-37.5px',
    top: '7.5px',
  },
}));

interface IJourneyTemplateGoal {
  index: number;
  themeIndex: number;
  milestoneIndex: number;
  goal: IJourneyGoal;
}

const JourneyTemplateGoal: FunctionComponent<IJourneyTemplateGoal> = (
  props,
) => {
  const { index, themeIndex, milestoneIndex, goal } = props;
  const { name, resources } = goal;
  const { updateStageGoalName, deleteStageGoal } = useJourneyTemplateContext();
  const classes = useStyles();

  const [openResources, setOpenResources] = useState<boolean>(false);
  const numResources = resources.links.length + resources.persons.length;

  return (
    <div className={classes.root}>
      <div className={classes.goalBody}>
        <div>
          <div className={classes.cont}>
            <div className={classes.index}>
              <Typography variant={'h6'}>{index + 1}</Typography>
            </div>
            <div style={{ minWidth: '320px' }}>
              <InputField
                value={name}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  updateStageGoalName(
                    event.target.value,
                    themeIndex,
                    milestoneIndex,
                    index,
                  )
                }
                placeholder={'Goal name...'}
              />
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Typography variant={'h6'}>
            {numResources} related resources
          </Typography>
          <MainButton
            text={'Manage resources'}
            type={ButtonType.FILLED}
            startIcon={<Add />}
            onClick={() => setOpenResources(true)}
          />
        </div>
      </div>

      <div className={classes.deleteCont}>
        <ButtonBase
          className={classes.deleteGoal}
          onClick={() => deleteStageGoal(themeIndex, milestoneIndex, index)}>
          <DeleteIcon fontSize={'medium'} />
        </ButtonBase>
      </div>

      <JourneyTemplateResources
        index={index}
        themeIndex={themeIndex}
        milestoneIndex={milestoneIndex}
        goal={goal}
        open={openResources}
        setOpen={setOpenResources}
      />
    </div>
  );
};

export default JourneyTemplateGoal;
