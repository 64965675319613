import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { Typography } from '@mui/material';
import MainButton, { ButtonType } from '../common/MainButton';
import InputField from '../common/InputField';
import { postFormData, UPDATE_PASSWORD_ROUTE } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';

const useStyles = makeStyles(() => ({
  boldFont: {
    fontWeight: 'bold!important',
    color: 'white !important',
  },
  input: {
    border: '#e0e0e0 1.5px solid',
    borderRadius: '6px',
    paddingLeft: '7px',
  },
  form: {
    display: 'contents',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
  },
}));

interface OwnProps {}

const LoginBox: FunctionComponent<OwnProps> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { addAlert } = useContext(AlertContext);
  const { passwordResetKey } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [resetPassword, setResetPassword] = useState('');
  const [confirmResetPassword, setConfirmResetPassword] = useState('');

  async function requestNewPassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('key', passwordResetKey ? passwordResetKey : '');
    formData.append('newPassword', resetPassword);
    formData.append('confirmPassword', confirmResetPassword);

    try {
      await postFormData(UPDATE_PASSWORD_ROUTE, formData, true);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Password successfully updated',
      });
      navigate('/login');
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setLoading(false);
  }

  return (
    <form onSubmit={(e) => requestNewPassword(e)} className={classes.form}>
      <Typography variant={'h4'} className={classes.boldFont}>
        Create new password
      </Typography>
      <Typography variant={'h6'} color={'white'}>
        Please enter a password with 8 or more characters and a mix of letters,
        numbers & capitals
      </Typography>
      <InputField
        value={resetPassword}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setResetPassword(event.target.value)
        }
        readonly={loading}
        type='password'
        label={'Password'}
        labelTextColor={'white'}
        formLabelStyles={{
          fontSize: '1.25rem',
        }}
        noEffects
      />
      <InputField
        type='password'
        value={confirmResetPassword}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setConfirmResetPassword(event.target.value)
        }
        readonly={loading}
        label={'Confirm Password'}
        labelTextColor={'white'}
        formLabelStyles={{
          fontSize: '1.25rem',
        }}
        noEffects
      />
      <div className={classes.buttonContainer}>
        <MainButton
          text={'Submit'}
          type={ButtonType.FILLED}
          submit={true}
          loading={loading}
        />
      </div>
    </form>
  );
};

export default LoginBox;
