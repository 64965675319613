import { MoreVert } from '@mui/icons-material';
import { Button, Popover, Typography, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { primary } from 'theme';

interface ITrippleOverlay {
  popoverAnchorEl: HTMLElement | null;
  setPopoverAnchorEl: (e: HTMLElement | null) => void;
  children: React.ReactNode;
  size?: string | number;
  sx?: SxProps;
  text?: string;
  boxWidth?: string | number;
}

const MoreOptionsOverlay: FunctionComponent<ITrippleOverlay> = (props) => {
  const {
    popoverAnchorEl,
    setPopoverAnchorEl,
    children,
    sx,
    text,
    size,
    boxWidth,
  } = props;

  const theme = useTheme();

  return (
    <>
      <Button
        onClick={(event) => setPopoverAnchorEl(event.currentTarget)}
        sx={{
          bgcolor: primary.pureWhite,
          margin: '0.5rem',
          padding: '0.375rem',
          color: theme.palette.primary.dark,
          minWidth: size ?? '2rem',
          minHeight: size ?? '2rem',
          borderRadius: '50%',
          '&: hover': {
            backgroundColor: theme.palette.primary.light,
          },
          ...sx,
        }}>
        <MoreVert
          sx={{
            transform: 'rotate(0deg)',
            fontSize: `calc(${size} * 0.9)` ?? '2.125rem',
          }}
        />
      </Button>
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={() => setPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'left',
            width: boxWidth ?? '14rem',
            bgcolor: '#FFFFFF',
            borderRadius: '6px',
            // dropShadow: '0px 0px 4px 0px #000000',
            boxShadow: '0px 20px 27px 0px rgba(0, 0, 0, 0.05)',
            ...sx,
          }}>
          {text && (
            <Typography
              sx={{
                marginLeft: '1rem',
                marginY: '0.5rem',
                fontSize: '1rem',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}>
              {text}
            </Typography>
          )}
          {children}
        </Box>
      </Popover>
    </>
  );
};

export default MoreOptionsOverlay;
