import { Button, Grid, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useState, useEffect } from 'react';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { BOOKING_ROOM_UPCOMING, getData } from 'utils/requests';
import { PermContactCalendar } from '@mui/icons-material';
import StaticCalendar from '../BookingCalendar/StaticCalendar';
import BookedRoomsList from './BookedRoomsList';
import { IAvailableTimeslots, IBookedRoom, ITimeslot } from 'utils/models';
import Loading from 'components/common/Loading';
import { formatDateYMD } from 'utils/utils';

const BookedRooms = () => {
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();

  const theme = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [rooms, setRooms] = useState<IBookedRoom[]>([]);

  const sortedBookings = sortBookingsByDate(rooms);

  const [selectedBookings, setSelectedBookings] =
    useState<IBookedRoom[]>(sortedBookings);
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);

  useEffect(() => {
    setSelectedBookings(sortBookingsByDate(rooms));
  }, [rooms]);

  useEffect(() => {
    if (selectedDay === null) {
      setSelectedBookings(sortedBookings);
    } else {
      setSelectedBookings(
        filterBookingsBySelectedDate(sortedBookings, selectedDay),
      );
    }
  }, [selectedDay]);

  useEffect(() => {
    fetchBookings();
  }, []);

  async function fetchBookings() {
    setIsLoading(true);

    try {
      const data = await getData(BOOKING_ROOM_UPCOMING, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setRooms(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function convertBookingsToTimeslots(
    rooms: IBookedRoom[],
  ): IAvailableTimeslots[] {
    const timeslotsMap = new Map<string, IAvailableTimeslots>();

    rooms.forEach((room) => {
      const date = formatDateYMD(new Date(room.date));
      const dayOfWeek = new Date(date).toLocaleDateString('en-US', {
        weekday: 'long',
      });

      if (!timeslotsMap.has(date)) {
        timeslotsMap.set(date, {
          date: date,
          dayOfWeek: dayOfWeek,
          timeSlots: [],
        });
      }

      const timeslot: ITimeslot = {
        booked: true,
        startTime: room.startTime,
        endTime: room.endTime,
      };

      timeslotsMap.get(date)?.timeSlots.push(timeslot);
    });

    const availableTimeslots: IAvailableTimeslots[] = Array.from(
      timeslotsMap.values(),
    );

    return availableTimeslots;
  }

  function filterBookingsBySelectedDate(
    rooms: IBookedRoom[],
    selectedDate: Date,
  ) {
    return rooms.filter((room) => {
      const meetingDate = new Date(room.date);
      return meetingDate.toDateString() === selectedDate.toDateString();
    });
  }

  function sortBookingsByDate(rooms: IBookedRoom[]) {
    return rooms.slice().sort((meetingA, meetingB) => {
      const dateA = new Date(meetingA.date);
      const dateB = new Date(meetingB.date);

      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return (
    <Box
      sx={{
        maxWidth: 1140,
        width: '100%',
      }}>
      <Box justifyContent='left' marginTop='2rem' marginBottom='2rem'>
        <Typography variant='h5' gutterBottom fontSize={22}>
          Booked Rooms
        </Typography>
      </Box>
      <Box
        sx={{
          minHeight: 700,
          bgcolor: '#F0F0F0',
          border: 1,
          borderColor: '#D4D4D4',
          borderRadius: '6px',
        }}>
        <Box
          sx={{
            bgcolor: '#FFFFFF',
            borderBottom: 1,
            borderColor: '#D4D4D4',
            borderRadius: '6px',
            padding: '2rem',
          }}>
          <Button
            onClick={fetchBookings}
            sx={{
              backgroundColor: theme.palette.primary.light,

              borderRadius: '6px',
              marginTop: 0,
              '&:hover': { bgcolor: theme.palette.primary.contrastText },
            }}>
            <PermContactCalendar sx={{ color: theme.palette.primary.main }} />
            <Typography
              sx={{
                marginLeft: 1,
                marginRight: 1,
                color: theme.palette.primary.main,
              }}>
              Booked rooms
            </Typography>
          </Button>
        </Box>
        <Grid container>
          <Grid item xs={12} lg={7.5}>
            <StaticCalendar
              availability={convertBookingsToTimeslots(rooms)}
              setSelectedDay={setSelectedDay}
            />
          </Grid>
          <Grid item xs={12} lg={4.5}>
            {isLoading ? (
              <Loading />
            ) : (
              <BookedRoomsList
                rooms={selectedBookings}
                setRooms={setSelectedBookings}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BookedRooms;
