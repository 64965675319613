import { useContext, useEffect, useState } from 'react';
import {
  getData,
  JOURNEY_TEMPLATES_SPECIFIC_ROUTE,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { IJourney } from 'utils/models';

export const NEW_JOURNEY_KEY = 'new';

const useJourneyTemplateSpecific = () => {
  const { ecosystemName, journeyId } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [journey, setJourney] = useState<IJourney | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (journeyId === NEW_JOURNEY_KEY) {
      return addNewJourney();
    }

    fetchJourney();
  }, [journeyId]);

  function addNewJourney() {
    setJourney({
      name: '',
      stages: [
        {
          name: 'Stage 1',
          description: '',
          themes: [
            {
              name: 'Theme 1',
              milestones: [
                {
                  name: 'Goal 1',
                  goals: [],
                },
              ],
            },
          ],
        },
      ],
    });

    setLoading(false);
  }

  async function fetchJourney() {
    setLoading(true);

    try {
      const data = await getData(JOURNEY_TEMPLATES_SPECIFIC_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'id', value: journeyId },
      ]);

      setJourney(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return {
    loading,
    journey,
  };
};

export default useJourneyTemplateSpecific;
