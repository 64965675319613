import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  formatDateAs_yyyy_MM_dd,
  getDayNameFromDate,
} from 'utils/utils';
import { IEvent } from 'utils/models';

interface IAddOptions {
  event: IEvent;
  setEvent: (event: IEvent) => void;
}

const AddRecurringOption: FunctionComponent<IAddOptions> = (props) => {
  const { event, setEvent } = props;

  const [recurringOption, setRecurringOption] =
    useState<string>("Doesn't Repeat"); // Initialize with "Doesn't Repeat"
  const [recurringEndDate, setRecurringEndDate] = useState<Date | null>(null);

  const startDate = event.startDate;

  function getDayOrdinal(date: Date) {
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      timeZone: 'Europe/Oslo',
    });
  }

  function getDateOrdinal(date: Date) {
    const weekOfMonth = Math.ceil(date.getDate() / 7);
    const ordinals = ['first', 'second', 'third', 'fourth', 'fifth'];
    return ordinals[weekOfMonth - 1];
  }

  function handleRecurringOptionChange(
    event: SelectChangeEvent<string>,
    child: React.ReactNode,
  ) {
    setRecurringOption(event.target.value);
  }

  useEffect(() => {
    const updatedEvent = {
      ...event,
      recurringEndDate: recurringEndDate
        ? formatDateAs_yyyy_MM_dd(recurringEndDate)
        : '',
      recurring: recurringOption ? recurringOption : "Doesn't Repeat",
    };
    setEvent(updatedEvent);
  }, [recurringEndDate, recurringOption]);

  return (
    <div>
      <Typography
        variant='body1'
        sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
        Recurring Option
      </Typography>
      <FormControl fullWidth>
        <Select
          value={recurringOption}
          onChange={handleRecurringOptionChange}
          sx={{ borderRadius: '5px !important' }}>
          <MenuItem value="Doesn't Repeat">Doesn't Repeat</MenuItem>
          <MenuItem value='Daily'>Daily</MenuItem>
          {startDate && (
            <MenuItem
              value={`Weekly on ${getDayOrdinal(
                new Date(startDate),
              )}`}>{`Weekly on ${getDayOrdinal(
              new Date(startDate),
            )}`}</MenuItem>
          )}
          {startDate && (
            <MenuItem
              value={`Monthly on ${getDateOrdinal(
                new Date(startDate),
              )} ${getDayNameFromDate(new Date(startDate))}`}>
              {`Monthly on ${getDateOrdinal(
                new Date(startDate),
              )} ${getDayNameFromDate(new Date(startDate))}`}
            </MenuItem>
          )}
          {startDate && (
            <MenuItem
              value={`Annually on ${new Date(startDate).toLocaleDateString(
                'en-US',
                {
                  month: 'long',
                  day: 'numeric',
                  timeZone: 'Europe/Oslo',
                },
              )}`}>
              {`Annually on ${new Date(startDate).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                timeZone: 'Europe/Oslo',
              })}`}
            </MenuItem>
          )}
          <MenuItem value='Every Weekday (Monday to Friday)'>
            Every Weekday (Monday to Friday)
          </MenuItem>
        </Select>
      </FormControl>
      {recurringOption !== "Doesn't Repeat" && (
        <div>
          <Typography
            variant='body1'
            sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
            Finish Recurring Event On*
          </Typography>
          <DatePicker
            format='dd/MM/yyyy'
            value={recurringEndDate}
            onChange={setRecurringEndDate}
            disablePast={true}
          />
        </div>
      )}
    </div>
  );
};

export default AddRecurringOption;
