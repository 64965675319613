import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography, useTheme } from '@mui/material';
import Modal from 'components/common/Modal';
import InputField from 'components/common/InputField';
import SelectDropdown from 'components/common/SelectDropdown';
import { IAccessLevelDto } from 'utils/models';
import { ACCESS_ADD_LEVEL_ROUTE, postData } from 'utils/requests';
import { useParams } from 'react-router';
import ColorPicker from 'components/common/ColorPicker';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: '4rem',
  },
  cont: {
    display: 'flex',
    justifyContent: 'space-between',

    '& h6': {
      marginRight: '10px !important',
      marginTop: '5px !important',
      minWidth: '78px !important',
    },
  },
  input: {
    maxWidth: '28.75rem',
    minWidth: '28.75rem',
  },
  dropdown: {
    maxWidth: '225px',
    minWidth: '225px',
    marginRight: '5px',
  },
}));

interface IAddAccessLevel {
  show: boolean;
  onClose: () => void;
  accessLevelDtos: IAccessLevelDto[];
  setAccessLevelDtos: (accessLevelDtos: IAccessLevelDto[]) => void;
  getAccessLevelId: (name: string) => number;
}

const AddAccessLevel: FunctionComponent<IAddAccessLevel> = (props) => {
  const {
    show,
    onClose,
    accessLevelDtos,
    setAccessLevelDtos,
    getAccessLevelId,
  } = props;
  const { ecosystemName } = useParams();
  const classes = useStyles();

  const theme = useTheme();

  const { addAlert } = useContext(AlertContext);

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [color, setColor] = useState<string>(theme.palette.primary.dark);
  const [template, setTemplate] = useState<string>('None');
  const [loading, setLoading] = useState<boolean>(false);

  async function addNewAccessLevel() {
    setLoading(true);

    try {
      const data = await postData(ACCESS_ADD_LEVEL_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        {
          name: 'name',
          value: name,
        },
        { name: 'description', value: description },
        { name: 'color', value: color.replace('#', '').trim() },
        { name: 'similarTo', value: getAccessLevelId(template) },
      ]);

      setColor(theme.palette.primary.dark);
      setTemplate('None');
      setLoading(false);
      setName('');
      setDescription('');
      setAccessLevelDtos([
        ...accessLevelDtos,
        {
          accessLevel: data,
          numberOfUsers: 0,
          usersAndId: [],
          ecosystemName: ecosystemName ? ecosystemName : '',
        },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Access level created',
      });

      onClose();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function getAccessLevelDtoByName(name: string) {
    return accessLevelDtos.find((a) => a.accessLevel.accessLevelName === name);
  }

  function getOptions() {
    const haveAccessLevels = accessLevelDtos.length > 0;
    if (!haveAccessLevels) return [];

    const names = accessLevelDtos.map(
      ({ accessLevel }) => accessLevel.accessLevelName,
    );
    return ['None', ...names];
  }

  function updateSelectedTemplate(value: any) {
    setTemplate(value);

    const accessLevelDto = getAccessLevelDtoByName(value);
    setColor(
      !accessLevelDto
        ? theme.palette.primary.dark
        : accessLevelDto.accessLevel.color,
    );
  }

  function renderContent() {
    return (
      <div className={classes.root}>
        <div className={classes.cont}>
          <Typography variant={'h6'}>Name</Typography>
          <div className={classes.input}>
            <InputField
              value={name}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setName(event.target.value)
              }
              placeholder={'A clear name for the access level'}
              readonly={loading}
            />
          </div>
        </div>
        <div className={classes.cont}>
          <Typography variant={'h6'}>Description</Typography>
          <div className={classes.input}>
            <InputField
              value={description}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setDescription(event.target.value)
              }
              placeholder={'What does this access level control'}
              multiline={true}
              rows={4}
              readonly={loading}
            />
          </div>
        </div>
        <div className={classes.cont}>
          <Typography variant={'h6'}>Similar to:</Typography>
          <div className={classes.dropdown}>
            <SelectDropdown
              value={template}
              onChange={updateSelectedTemplate}
              options={getOptions()}
              disabled={loading}
              height={50}
            />
          </div>
          <ColorPicker
            color={color}
            onSelectedColor={setColor}
            noMargin={true}
          />
        </div>
        <div
          className={classes.cont}
          style={{
            justifyContent: 'flex-end',
          }}></div>
      </div>
    );
  }

  return (
    <Modal
      open={show}
      close={onClose}
      title={'Add access level'}
      largeHeader={true}
      headerColor={theme.palette.primary.light}
      showFooter={true}
      loading={loading}
      disabledSave={!name || !description}
      saveAction={() => addNewAccessLevel()}
      largeFooter={true}>
      {renderContent()}
    </Modal>
  );
};

export default AddAccessLevel;
