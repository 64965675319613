import React, {
  ChangeEvent,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Card,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import CommonButton from 'components/common/CommonButton';
import InputField from 'components/common/InputField';
import { primary } from 'theme';
import {
  IApplicantAssessment,
  IApplicationAssessmentOption,
  IApplicationAssessmentSection,
  IApplicationAssessmentSubSection,
  IApplicationAssessmentTemplate,
  IAssessmentApplicantListItem,
  IAssessmentQuestion,
} from 'utils/models';
import { FlexBox } from 'utils/styledComponents';
import { APPLICATION_ASSESSMENTS_APPLICANT, putData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import CompleteCheck from 'components/common/CompleteCheck';

interface IAssessmentFormProps {
  assessment: IApplicationAssessmentTemplate;
  setApplicantAssessment: Dispatch<SetStateAction<IApplicantAssessment | null>>;
  assessmentNumber: number;
  hashedId: string | undefined;
  applicantId: number;
  email: string;
  assessorName: string;
  setListOfApplicants: Dispatch<SetStateAction<IAssessmentApplicantListItem[]>>;
  openSuccessModal: () => void;
}

const AssessmentForm: FunctionComponent<IAssessmentFormProps> = (props) => {
  const {
    assessment,
    setApplicantAssessment,
    assessmentNumber,
    hashedId,
    applicantId,
    email,
    assessorName,
    setListOfApplicants,
    openSuccessModal,
  } = props;

  const theme = useTheme();
  const { addAlert } = useContext(AlertContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedSection, setSelectedSection] =
    useState<IApplicationAssessmentSection | null>(null);

  const [selectedSubSection, setSelectedSubSection] =
    useState<IApplicationAssessmentSubSection | null>(null);

  const [selectedSectionNumber, setSelectedSectionNumber] = useState<number>(0);

  const [selectedSubSectionNumber, setSelectedSubSectionNumber] =
    useState<number>(0);

  const [assessmentProgress, setAssessmentProgress] = useState<number>(0);

  useEffect(() => {
    setSelectedSection(assessment.sections[0]);
    setSelectedSubSection(assessment.sections[0].subSections[0]);
  }, []);

  useEffect(() => {
    setAssessmentProgress(calculateAssessmentProgress());
  }, [assessment]);

  useEffect(() => {
    updateSelectedSubSectionInfo(
      assessment.sections[selectedSectionNumber],
      assessment.sections[selectedSectionNumber].subSections[
        selectedSubSectionNumber
      ],
    );
  }, [selectedSectionNumber, selectedSubSectionNumber, assessment]);

  async function handleSubmitForm() {
    setIsLoading(true);

    try {
      const data = await putData(
        APPLICATION_ASSESSMENTS_APPLICANT,
        [
          { name: 'hashedId', value: hashedId },
          { name: 'applicantId', value: applicantId },
          { name: 'email', value: email },
          {
            name: 'assessorName',
            value: assessorName,
          },
        ],
        assessment,
        undefined,
        true,
      );

      const indexInTheListToUpdate = assessmentNumber - 1;

      setListOfApplicants((prevList) => {
        let isNextActiveFound = false;
        const newList = prevList.map((applicant, index) => {
          if (index === indexInTheListToUpdate) {
            return {
              ...applicant,
              isSubmitted: true,
              isActive: false,
            };
          }

          if (!isNextActiveFound && !applicant.isSubmitted) {
            isNextActiveFound = true;
            return {
              ...applicant,
              isActive: true,
            };
          }

          return applicant;
        });

        if (!isNextActiveFound) {
          openSuccessModal();
        }
        return newList;
      });

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Assessment submitted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function calculateAssessmentProgress() {
    if (!assessment) return 0;

    let totalQuestions = 0;
    let answeredQuestions = 0;

    assessment.sections.forEach((section) => {
      section.subSections.forEach((subSection) => {
        totalQuestions += subSection.questions.length;
        answeredQuestions += subSection.questions.filter(
          (q) => q.answer,
        ).length;
      });
    });

    const newProgress =
      totalQuestions > 0 ? (answeredQuestions / totalQuestions) * 100 : 0;

    return newProgress;
  }

  function updateSelectedSubSectionInfo(
    section: IApplicationAssessmentSection,
    subSection: IApplicationAssessmentSubSection,
  ) {
    const sectionToSelect = assessment?.sections.find(
      (item) => item.id === section.id,
    );
    const subSectionToSelect = sectionToSelect?.subSections.find(
      (item) => item.id === subSection.id,
    );

    if (!sectionToSelect || !subSectionToSelect) return;
    setSelectedSection(sectionToSelect);
    setSelectedSubSection(subSectionToSelect);
  }

  function handleSubSectionChange(secIndex: number, subSecIndex: number) {
    setSelectedSectionNumber(secIndex);
    setSelectedSubSectionNumber(subSecIndex);
  }

  function handleCommentChange(s: string) {
    if (!selectedSubSection) return;

    setApplicantAssessment((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        assessmentTemplate: {
          ...prev.assessmentTemplate,
          sections: prev.assessmentTemplate.sections.map((section) => {
            if (section.id !== selectedSection?.id) return section;
            return {
              ...section,
              subSections: section.subSections.map((subSection) => {
                if (subSection.id !== selectedSubSection?.id) return subSection;
                return {
                  ...subSection,
                  comment: s,
                };
              }),
            };
          }),
        },
      };
    });
  }

  function handleAnswerChange(
    sectionToChange: IApplicationAssessmentSection,
    subSectionToChange: IApplicationAssessmentSubSection,
    question: IAssessmentQuestion,
    option: IApplicationAssessmentOption,
  ) {
    setApplicantAssessment((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        assessmentTemplate: {
          ...prev.assessmentTemplate,
          sections: prev.assessmentTemplate.sections.map((section) => {
            if (section.id !== sectionToChange.id) return section;
            return {
              ...section,
              subSections: section.subSections.map((subSection) => {
                if (subSection.id !== subSectionToChange.id) return subSection;
                return {
                  ...subSection,
                  questions: subSection.questions.map((q) => {
                    if (q.id !== question.id) return q;
                    return {
                      ...q,
                      answer: option,
                    };
                  }),
                };
              }),
            };
          }),
        },
      };
    });
  }

  function isSubSectionComplete(subSection: IApplicationAssessmentSubSection) {
    return subSection.questions.every(
      (question) => question.answer !== undefined && question.answer !== null,
    );
  }

  function renderTableOfContents() {
    if (!assessment || !assessment.sections) return;
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          maxHeight: '70vh',
        }}>
        {assessment.sections.map((section, sectionIndex) => (
          <Box key={section.id} sx={{ mt: '1.25rem' }}>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '1.25rem',
                textTransform: 'uppercase',
                mb: '1.5rem',
              }}>
              {section.name}
            </Typography>
            {section.subSections.map((subSection, subSectionIndex) => {
              const isSelected = selectedSubSection?.id === subSection.id;
              const isComplete = isSubSectionComplete(subSection);

              return (
                <Box
                  key={subSection.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mb: isSelected ? '0rem' : '1rem',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    handleSubSectionChange(sectionIndex, subSectionIndex)
                  }>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography
                      sx={{
                        fontSize: '1rem',
                        color: primary.natural1,
                        textTransform: 'uppercase',
                        fontWeight: isSelected ? 'bold' : '',
                      }}>
                      {`${subSectionIndex + 1}. ${subSection.name}`}
                    </Typography>

                    {isComplete && (
                      <CompleteCheck
                        size={'1.5rem'}
                        sx={{ minWidth: '1.5rem' }}
                      />
                    )}
                  </Box>

                  {isSelected && (
                    <Box
                      sx={{
                        bgcolor: primary.natural4,
                        width: '100%',
                        borderRadius: '8px',
                        height: '3px',
                        mt: '0.25rem',
                        mb: '0.75rem',
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        ))}
      </Box>
    );
  }

  function renderAssessmentForm() {
    if (!selectedSubSection || !selectedSection) return null;

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: '2rem',
          overflow: 'auto',
          maxHeight: '75vh',
        }}>
        <Typography
          variant='h3'
          sx={{ mb: '2rem', fontWeight: 400, color: primary.natural2 }}>
          {selectedSubSection?.name}
        </Typography>
        {selectedSubSection?.questions.map((question, i) => (
          <Box
            key={question.id}
            sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 'bold', mb: '0.75rem' }}>
              {`${i + 1}. ${question.name}`}
            </Typography>
            <Box sx={{ pl: '1rem' }}>
              {question.options.map((option, questionIndex) => (
                <Box
                  key={option.id}
                  sx={{
                    display: 'flex',
                    mb: '0.5rem',
                    alignItems: 'center',
                  }}>
                  <Typography
                    sx={{
                      mr: '0.5rem',
                      fontWeight: 'bold',
                      fontSize: '0.75rem',
                      width: '1.5rem',
                    }}>
                    {questionIndex + 1}
                  </Typography>
                  <FormControlLabel
                    value={question.answer}
                    control={
                      <Radio
                        checked={question.answer?.id === option.id}
                        onChange={() =>
                          handleAnswerChange(
                            selectedSection,
                            selectedSubSection,
                            question,
                            option,
                          )
                        }
                        sx={{
                          '&.Mui-checked': {
                            color: 'black',
                          },
                        }}
                      />
                    }
                    label={option.name}
                  />
                </Box>
              ))}
            </Box>
            <Divider
              sx={{ color: primary.natural4, mt: '1rem', mb: '1.5rem' }}
            />
          </Box>
        ))}
        <InputField
          value={selectedSubSection.comment ?? ''}
          placeholder={'Write your comment for subsection here...'}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleCommentChange(event.target.value)
          }
          multiline={true}
          rows={3}
          labelTextColor={primary.natural1}
          formLabelStyles={{
            fontSize: '1.1rem',
          }}
        />
      </Box>
    );
  }

  return (
    <Grid container columnSpacing={'1.25rem'}>
      <Grid item xs={4}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}>
          <FlexBox sx={{ justifyContent: 'space-between' }}>
            <Box
              sx={{
                borderRadius: '100%',
                width: '2.5rem',
                aspectRatio: 1,
                border: `1px solid ${primary.additionalDarkGreen}`,
                padding: 0,
                bgcolor: primary.additionalDarkGreen,
                color: primary.pureWhite,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Typography
                sx={{
                  fontSize: '1.25rem',
                  color: 'inherit',
                  ml: '-0.1rem',
                }}>
                {assessmentNumber}
              </Typography>
            </Box>
            <Tooltip
              title={
                assessmentProgress !== 100
                  ? 'Form needs to be filled out to submit'
                  : 'Submit Assessment'
              }>
              <Box>
                <CommonButton
                  text='Submit'
                  onClick={handleSubmitForm}
                  bgcolor={primary.additionalDarkGreen}
                  hoverColor={{ bgcolor: primary.additionalGreen }}
                  isDisabled={assessmentProgress !== 100}
                  isLoading={isLoading}
                />
              </Box>
            </Tooltip>
          </FlexBox>
          <Typography
            sx={{ mt: '1.5rem', fontWeight: 'bold', fontSize: '0.85rem' }}>
            {assessmentProgress === 0
              ? 'Not started'
              : assessmentProgress === 100
              ? 'Finished'
              : 'In progress'}
          </Typography>
          <LinearProgress
            variant='determinate'
            value={assessmentProgress}
            sx={{
              mt: '0.25rem',
              mb: '1rem',
              height: '1rem',
              borderRadius: 2,
              bgcolor: primary.natural7,
              '& .MuiLinearProgress-bar': {
                bgcolor: primary.additionalDarkGreen,
              },
            }}
          />
          {renderTableOfContents()}
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Card
          sx={{
            borderRadius: '8px',
            bgcolor: primary.pureWhite,
            width: '100%',
            boxShadow: theme.shadows[1],
          }}>
          {renderAssessmentForm()}
        </Card>
      </Grid>
    </Grid>
  );
};

export default AssessmentForm;
