import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Box } from '@mui/system';
import Loading from 'components/common/Loading';
import { FlexBox } from 'utils/styledComponents';
import { IApplicationReports } from 'utils/models';
import { APPLICATION_ASSESSMENT_REPORTS, getData } from 'utils/requests';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import AssessmentProcessReport from './AssessmentProcessReport';
import { useAssessmentContext } from 'services/contexts/Applications/AssessmentContext';
import AssessmentReportOverview from './AssessmentReportOverview';
import { Typography } from '@mui/material';
import CommonButton, { ButtonVariant } from 'components/common/CommonButton';
import { ArrowBackIos } from '@mui/icons-material';
import { primary } from 'theme';
import { useApplicationMenuContext } from 'services/contexts/Applications/ApplicationMenuContext';
interface IAssessmentReportsProps {}

const AssessmentReports: FunctionComponent<IAssessmentReportsProps> = () => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const { selectedReportId, setSelectedReportId, assessmentReportDetails } =
    useAssessmentContext();

  const { isComingFromAssessmentPage, setIsComingFromAssessmentPage } =
    useApplicationMenuContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [processesReportsList, setProcessesReportsList] = useState<
    IApplicationReports[]
  >([]);

  useEffect(() => {
    fetchProcessesList();
    if (!isComingFromAssessmentPage) {
      setSelectedReportId(null);
    } else {
      setIsComingFromAssessmentPage(false);
    }
  }, []);

  async function fetchProcessesList() {
    setIsLoading(true);

    try {
      const data = await getData(APPLICATION_ASSESSMENT_REPORTS, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setProcessesReportsList(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderProcessesList() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {processesReportsList.map((process) => (
          <AssessmentProcessReport
            key={process.id}
            process={process}
            setProcessesReportsList={setProcessesReportsList}
          />
        ))}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        margin: '4rem 3rem 0 3rem',
        maxWidth: '100%',
      }}>
      <FlexBox
        sx={{
          gap: '1rem',
          mb: '1.25rem',
          alignItems: 'start',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '2rem',
            lineHeight: '3.75rem',
          }}>
          {!selectedReportId
            ? 'Assessment reports'
            : assessmentReportDetails?.applicationName}
        </Typography>

        {selectedReportId && (
          <CommonButton
            text='Back'
            icon={<ArrowBackIos />}
            variant={ButtonVariant.BLANK}
            onClick={() => setSelectedReportId(null)}
            hoverColor={{ bgcolor: primary.pureWhite }}
            sx={{
              ml: '-3rem',
            }}
          />
        )}
      </FlexBox>
      {!selectedReportId ? (
        isLoading ? (
          <Loading />
        ) : (
          renderProcessesList()
        )
      ) : (
        <AssessmentReportOverview />
      )}
    </Box>
  );
};

export default AssessmentReports;
