import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { SketchPicker } from 'react-color';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import CommonButton from './CommonButton';

interface IColorPicker {
  color: string;
  onSelectedColor: (color: string) => void;
  noMargin?: boolean;
  sxOuterBox?: SxProps;
  sxColorBox?: SxProps;
  sxTypography?: SxProps;
}

const ColorPicker: FunctionComponent<IColorPicker> = (props) => {
  const { color, onSelectedColor, noMargin, sxOuterBox, sxColorBox, sxTypography} = props;
  const [selectedColor, setSelectedColor] = useState<string>(formatColor());
  const [showPicker, setShowPicker] = useState<boolean>(false);
 // const [timer, setTimer] = useState<number | null>(null);
  const pickerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setSelectedColor(formatColor);
  }, [color]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setShowPicker(false);
      }
    }

    if (showPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPicker]);

  function onColorChange(color: any) {
    setSelectedColor(color.hex);
    onSelectedColor(color.hex);
  }

  function formatColor() {
    return color.includes('#') ? color : `#${color}`;
  }

  function handleOpen() {
    if (showPicker) return;
    setShowPicker(true);
  }

  return (
      <Box
      onClick={handleOpen} ref={pickerRef}
        sx={{
          borderRadius: '12px',
          border: '1px solid #e0e0e0',
          padding: '1rem',
          paddingRight: '0.5rem',
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          height: '4.75rem',
          width: '16rem',
          cursor: 'pointer',
          marginTop:
            noMargin ? '0px' : '0.35rem',
          position: 'relative',
          ...sxOuterBox
        }}>
        <Box
          sx={{
            height: '3.75rem',
            width: '6rem',
            borderRadius: '12px',
            backgroundColor: selectedColor,
            ...sxColorBox
          }}
        />
        <Typography sx={{...sxTypography}}>{color}</Typography>
        <ArrowDropDownIcon
          sx={{
            transition: 'all 0.3s ease-in-out',
            transform: showPicker ? 'rotate(0.5turn)' : '',
          }}
        />
        {showPicker && (
          <Box sx={{ position: 'absolute', zIndex: '2' }}>
            <Box sx={{ position: 'absolute', top: '2.4rem' }}>
              <SketchPicker
                color={selectedColor}
                onChange={onColorChange}
                disableAlpha={true}
              />
              <CommonButton
                text='Apply'
                onClick={() => setShowPicker(false)}
                sx={{
                  width: '100%',
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
  );
};

export default ColorPicker;
