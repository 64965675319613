import React from 'react';
import { CompanyTemplateProvider } from './CompanyTemplateContext';
import { Box } from '@mui/system';
import { CompanyTemplateHeaderSection } from './CompanyTemplateHeaderSection';
import { CompanyTemplateMainSection } from './CompanyTemplateMainSection';
import { CompanyTemplateSideBarSection } from './CompanyTemplateSideBarSection';
import { AssignCompanyProfilesProvider } from './AssignCompanyProfileContext';
import { CompanyTemplateNameSection } from './CompanyTemplateNameSection';

const CompanyTemplate = () => {   
  return (
    <CompanyTemplateProvider>
      <AssignCompanyProfilesProvider>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '5rem',
          }}>
          <Box
            sx={{
              width: '85vw',
              marginTop: '2rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '1.25rem',
            }}>
            <CompanyTemplateNameSection />
            <Box
              sx={{
                display: 'flex',
                gap: '3rem',
              }}>
              <Box
                sx={{
                  width: '70%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem ',
                }}>
                <CompanyTemplateHeaderSection />
                <CompanyTemplateMainSection />
              </Box>
              <CompanyTemplateSideBarSection />
            </Box>
          </Box>
        </Box>
      </AssignCompanyProfilesProvider>
    </CompanyTemplateProvider>
  );
};

export default CompanyTemplate;
