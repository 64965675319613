import { Button, Popover, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { IListButton } from 'utils/models';

interface IPopover {
  popoverAnchorEl: HTMLElement | null;
  setPopoverAnchorEl: (a: any) => void;
  buttons: IListButton[];
  header?: string;
  width?: number;
}

const PopoverButtonList: FunctionComponent<IPopover> = (props) => {
  const { popoverAnchorEl, setPopoverAnchorEl, header, width, buttons } = props;

  const theme = useTheme();

  return (
    <Popover
      open={Boolean(popoverAnchorEl)}
      anchorEl={popoverAnchorEl}
      onClose={() => setPopoverAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'left',
          minWidth: width ?? 200,
          bgcolor: 'white',
        }}>
        {header && (
          <Typography
            sx={{
              marginLeft: '1rem',
              marginY: 1,
              fontSize: 16,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              textAlign: 'center',
            }}>
            {header}
          </Typography>
        )}
        {buttons.map((button, i) => (
          <Button
            key={`${button.name}-${i}`}
            onClick={button.onClick}
            sx={{
              display: 'flex',
              bgcolor: button.color ?? 'inherit',
              '&:hover': {
                bgcolor: button.hoverColor ?? theme.palette.primary.light,
              },
              borderRadius: 0.2,
              justifyContent: 'start',
            }}>
            <button.icon
              sx={{
                color: button.textColor ?? theme.palette.primary.dark,
                marginLeft: 1,
                marginRight: 1,
              }}
            />
            <Typography
              sx={{
                color: button.color ?? theme.palette.primary.dark,
                marginRight: 1,
              }}>
              {button.name}
            </Typography>
          </Button>
        ))}
      </Box>
    </Popover>
  );
};

export default PopoverButtonList;
