import { Container, Typography } from '@mui/material';
import Loading from 'components/common/Loading';
import useFetchForm from 'hooks/Applications/useFetchFrom';
import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';

import { FlexBox } from 'utils/styledComponents';
import FormWithHook, { FormForSend } from './FormWithHook';

const ApplicationForm = () => {
  const { formId } = useParams();

  const { isLoading, form } = useFetchForm({ formId });
  const [filledForm, setFilledForm] = useState<FormForSend | null>(null);

  useEffect(() => {
    if (form && !filledForm) {
      setFilledForm({
        ...form,
        files: null,
      });
    }
  }, [form, filledForm]);

  if (isLoading)
    return (
      <FlexBox
        className={'center'}
        sx={{
          flexDirection: 'column',
          height: '90vh',
          gap: '2rem',
        }}>
        <Loading height={40} />
        <Typography>Please wait while we open the form...</Typography>
      </FlexBox>
    );

  if (!form)
    return (
      <FlexBox className={'center'} sx={{ height: '90vh' }}>
        <Typography>This application is not running at the moment.</Typography>
      </FlexBox>
    );

  return (
    <Container
      sx={{
        minWidth: '100%',
        minHeight: '100vh',
        backgroundColor: 'white',
        //lines below helps to get rid of padding from parent component
        marginTop: '-3rem',
        marginLeft: '-0.3rem',
        marginRight: '-0.3rem',
        paddingTop: '3rem',
      }}>
      <Container
        sx={{
          '& .MuiContainer-root': {
            width: '65%',
          },
        }}>
        <FormWithHook
          filledForm={filledForm}
          setFilledForm={setFilledForm}
          form={form}
          formId={formId}
        />
      </Container>
    </Container>
  );
};

export default ApplicationForm;
