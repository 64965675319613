import React, { forwardRef, useState } from 'react';
import {
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
  TextFieldProps,
  useTheme,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { black, primary } from 'theme';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import StarIcon from '@mui/icons-material/Star';
import { SxProps } from '@mui/system';

interface InputFieldProps extends Omit<TextFieldProps, 'color'> {
  accept?: string;
  acterioDefault?: boolean;
  autoCompleteOff?: boolean;
  color?: string;
  fontSize?: string;
  maxLength?: number;
  padding?: string;
  readonly?: boolean;
  bold?: boolean;
  onSubmit?: (value: any) => any;
  helperIcon?: 'warning';
  labelTextColor?: string;
  noEffects?: boolean;
  clearBackground?: boolean;
  icon?: React.ReactElement;
  basicIcon?: 'search';
  iconPosition?: 'start' | 'end';
  formLabelStyles?: SxProps;
  width?: string;
  height?: string;
  helperTextStyles?: SxProps;
}

//using this to simplify customisation for future using
const inputFieldIcons: Record<string, React.ReactElement> = {
  warning: <ErrorOutlineIcon />,
  search: <Search />,
  star: <StarIcon />,
  //specific icons for password field and on that reason I haven't added them to types
  visible: <VisibilityIcon />,
  nonVisible: <VisibilityOffIcon />,
};
//this obj is connected with obj above. main idea is to have basic descriptions
// for different icons if needed, or use custom text if it exists
const helperTextDescription = {
  warning: 'Oops, something went wrong.',
};

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref) => {
    const {
      readonly,
      maxLength,
      onSubmit,
      helperIcon,
      autoCompleteOff,
      value,
      error,
      label,
      basicIcon,
      icon,
      multiline,
      iconPosition = 'start',
      helperText,
      labelTextColor = black,
      noEffects,
      formLabelStyles,
      sx,
      type,
      clearBackground,
      width,
      height,
      acterioDefault = false,
      helperTextStyles,
      ...otherProps
    } = props;
    const [typeStorage, setTypeStorage] = useState(type);
    const theme = useTheme();
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      // Check if the pressed key is the "Enter" key (key code 13)
      if (event.keyCode === 13 && onSubmit) {
        // Prevent the default "Enter" key behavior (e.g., form submission)
        event.preventDefault();
        // Call the onSubmit function when the "Enter" key is pressed
        onSubmit(value);
      }
    };

    //specific function for changing text visibility in password fields
    const passwordToggler = () => {
      if (typeStorage === 'password') {
        setTypeStorage('input');
      }
      if (typeStorage === 'input') {
        setTypeStorage('password');
      }
    };

    return (
      <FormControl
        sx={{
          minWidth: width || '100%',
          maxWidth: '100%',
        }}>
        {/*added FormLabel, so we don't have to add separate headings to input*/}
        {label && (
          <FormLabel
            sx={{
              marginTop: '1.25rem',
              marginBottom: '0.375rem',
              fontWeight: 700,
              color: labelTextColor ? labelTextColor : black,
              ...formLabelStyles,
            }}>
            {label}
          </FormLabel>
        )}
        <TextField
          {...otherProps}
          //pasting otherProps first, so we can rewrite it below.
          //some of the props are unnecessary, but used it A LOT of different components, so for now I live them
          // < section that has to be reworked
          ref={ref}
          value={value}
          multiline={multiline}
          variant={'outlined'}
          color={'primary'}
          autoComplete={autoCompleteOff ? 'nope' : 'on'}
          disabled={readonly}
          inputProps={{ maxLength: maxLength ?? undefined }}
          InputProps={{
            startAdornment: (icon || basicIcon) && iconPosition === 'start' && (
              <InputAdornment position={iconPosition}>
                {basicIcon ? inputFieldIcons[basicIcon] : icon}
              </InputAdornment>
            ),
            endAdornment:
              //special section, for all password, so they have this eye icon
              type === 'password' ? (
                <InputAdornment
                  position={iconPosition}
                  onClick={passwordToggler}
                  sx={{ cursor: 'pointer', marginRight: 0 }}>
                  {typeStorage === 'password'
                    ? inputFieldIcons.nonVisible
                    : inputFieldIcons.visible}
                </InputAdornment>
              ) : (
                (icon || basicIcon) &&
                iconPosition === 'end' && (
                  <InputAdornment position={iconPosition}>
                    {basicIcon ? inputFieldIcons[basicIcon] : icon}
                  </InputAdornment>
                )
              ),
          }}
          //section that has to be reworked >
          helperText={
            //here is implementation of template icons/desrc idea
            <>
              {helperIcon && inputFieldIcons[helperIcon]}
              {helperText || (helperIcon && helperTextDescription[helperIcon])}
            </>
          }
          type={typeStorage ? typeStorage : 'input'}
          onKeyDown={handleKeyDown}
          FormHelperTextProps={{
            sx: {
              display: helperText ? 'flex' : 'none',
              alignItems: 'center',
              gap: '0.25rem',
              marginLeft: 0,
              fontWeight: 500,
              color: error ? primary.warning : labelTextColor,
              ...helperTextStyles,
            },
          }}
          sx={{
            width: '100%',
            '& .MuiOutlinedInput': {
              //root|container component styles
              '&-root': {
                minHeight: height ? height : multiline ? '8rem' : '2.5rem',
                alignItems: multiline ? 'start' : 'center',
                backgroundColor: clearBackground
                  ? primary.pureWhite
                  : primary.whiteHighlight,
                boxShadow: error
                  ? `inset 0 0 0 0.063rem ${primary.warning}`
                  : theme.shadows[2],
                borderRadius: '8px',
                opacity: readonly ? '70%' : '100%',
                transition: 'all .3s ease-in-out',
                //styles for border. default styles for boxShadow is needed for smooth animation
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  boxShadow: 'inset 0 0 0 0.063rem transparent',
                  transition: 'box-shadow .3s ease',
                },
                //styles for border while hover or focus on input
                '&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    ...(!readonly &&
                      !noEffects && {
                        boxShadow: (() => {
                          if (error) {
                            return 'none';
                          }
                          if (acterioDefault) {
                            return `inset 0 0 0 0.063rem ${primary.main}`;
                          }
                          return `inset 0 0 0 0.063rem ${theme.palette.primary.main}`;
                        })(),
                      }),
                  },
                //styles for backGround, while focus or hover
                '&.Mui-focused, &:hover': {
                  ...(!readonly &&
                    !noEffects && {
                      backgroundColor: () => {
                        if (error || clearBackground) {
                          return primary.pureWhite;
                        } else {
                          return primary.whiteHighlight;
                        }
                      },
                    }),
                },
              },
            },

            //styles for inner input component
            '& .MuiInputBase-input': {
              padding: '0 12px',
              marginLeft: '0.25rem',
              '&::placeholder': {
                ...(readonly && { opacity: '200%' }), //added opacity, cause placeholder is too transparent
                color: error ? primary.warningRed : '',
              },
            },
            //cover up default browser styles for autofill
            '& input:-webkit-autofill': {
              width: '90%',
              transition: 'background-color 5000s ease-in-out 0s',
              boxShadow: 'inset 0 0 20px 20px transparent',
            },
            ...sx, //it adds possibility to change styles from outside of component
          }}
        />
      </FormControl>
    );
  },
);

InputField.displayName = 'InputField';

export default InputField;
