import {
  ContentCopyRounded,
  DeleteRounded,
  Error,
  Visibility,
} from '@mui/icons-material';
import { Divider, Grid, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import CommonButton from 'components/common/CommonButton';
import Loading from 'components/common/Loading';
import InformationModal from 'components/common/Modal/InformationModal';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';
import MoreOptionsOverlay from 'components/common/MoreOptionsButton/MoreOptionsOverlay';
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { useAssessmentContext } from 'services/contexts/Applications/AssessmentContext';
import { primary } from 'theme';
import {
  IApplicationReportOverview,
  IApplicationReports,
  IListButton,
} from 'utils/models';
import { APPLICATION_ASSESSMENT_REPORT, deleteData } from 'utils/requests';
import { formatDateEuropean } from 'utils/utils';

interface IAssessmentProcessReportRowProps {
  report: IApplicationReportOverview;
  process: IApplicationReports;
  setProcessesReportsList: Dispatch<SetStateAction<IApplicationReports[]>>;
}

const AssessmentProcessReportRow: FunctionComponent<
  IAssessmentProcessReportRowProps
> = (props) => {
  const { report, process, setProcessesReportsList } = props;

  const theme = useTheme();

  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const { setSelectedReportId } = useAssessmentContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const buttonList: IListButton[] = [
    {
      name: 'Copy link',
      onClick: copyText,
      icon: ContentCopyRounded,
    },
    {
      name: 'Delete',
      onClick: () => {
        setPopoverAnchorEl(null);
        setIsDeleteModalOpen(true);
      },
      icon: DeleteRounded,
      textColor: primary.warningRed,
    },
  ];

  function copyText() {
    navigator.clipboard.writeText(report.assessmentUrl);
    addAlert({
      type: ALERT_TYPES.SUCCESS,
      message: 'Link copied successfully!',
    });
    setPopoverAnchorEl(null);
  }

  function handleOpenReport() {
    if (!report.id) return;
    setSelectedReportId(report.id);
  }

  async function handleDeleteReport() {
    if (!report.id) return;
    setIsLoading(true);
    try {
      const data = await deleteData(APPLICATION_ASSESSMENT_REPORT, [
        { name: 'reportId', value: report.id },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setProcessesReportsList((prevReports) =>
        prevReports
          .map((proc) =>
            proc.id === process.id
              ? {
                  ...proc,
                  reports: proc.reports.filter(
                    (report) => report.id !== report.id,
                  ),
                }
              : proc,
          )
          .filter((proc) => proc.reports.length > 0),
      );

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Report was deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  function renderDeleteModal() {
    if (!isDeleteModalOpen) return;
    return (
      <InformationModal
        isOpen={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        primaryText='Delete'
        primaryOnClick={handleDeleteReport}
        primaryBgColor={primary.warningRed}
        primaryBgHoverColor={primary.warningPlaceholder}
        secondaryText='Cancel'
        secondaryOnClick={() => setIsDeleteModalOpen(false)}
        headerText={'Delete assessment form'}>
        {isLoading ? (
          <Loading height={80} />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: '0.75rem',
              mb: '0.5rem',
            }}>
            <Box sx={{ display: 'flex' }}>
              <Error
                sx={{
                  marginRight: '0.25rem',
                  color: primary.warningRed,
                  width: '2rem',
                }}
              />
              <Typography sx={{ mb: '1.25rem' }}>
                Are you sure you want to delete this assessment report? This
                action cannot be undone.
              </Typography>
            </Box>
            <Typography sx={{ ml: '2.25rem' }}>
              Deleting this form will remove it permanently.
            </Typography>
          </Box>
        )}
      </InformationModal>
    );
  }

  return (
    <Grid item container columnSpacing={'1rem'}>
      <Grid item xs={3}>
        <Typography
          sx={{
            color: primary.mainBlackFont,
            fontWeight: 'bold',
            fontSize: '1.1rem',
          }}>
          {report.assessmentName}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography
          sx={{
            color: report.active ? primary.successGreen : primary.natural2,
            fontWeight: report.active ? 'bold' : '',
          }}>
          {report.active ? 'Active' : 'Inactive'}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography sx={{ color: primary.natural2 }}>
          {formatDateEuropean(new Date(report.createdDate))}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography sx={{ color: primary.natural2 }}>
          {report.numberOfApplicantsWithAssessments}
        </Typography>
      </Grid>

      <Grid
        item
        xs={3}
        sx={{
          mt: '-0.5rem',
          mb: '0.5rem',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
        <CommonButton
          text='View'
          onClick={handleOpenReport}
          icon={<Visibility />}
        />
        <MoreOptionsOverlay
          popoverAnchorEl={popoverAnchorEl}
          setPopoverAnchorEl={setPopoverAnchorEl}
          size={'2rem'}
          sx={{
            borderRadius: 1,
            width: '16rem',
          }}>
          <MoreOptionsButtons buttonList={buttonList} />
        </MoreOptionsOverlay>
        {renderDeleteModal()}
      </Grid>
      <Grid item xs={12} sx={{ ml: '-1rem' }}>
        <Divider sx={{ width: '100%', mt: '0.5rem', mb: '1.5rem' }} />
      </Grid>
    </Grid>
  );
};

export default AssessmentProcessReportRow;
