import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Link } from '@mui/material';
import FormData from 'form-data';
import { useNavigate } from 'react-router-dom';
import InputField from '../common/InputField';
import MainButton, { ButtonType } from '../common/MainButton';
import {
  JOIN_BY_INVITE_ROUTE,
  postFormData,
  REGISTER_USER_ROUTE,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { authenticateEmailAndPassword } from 'utils/utils';
import { acterioTheme } from 'theme';

const useStyles = makeStyles(() => ({
  boldFont: {
    fontWeight: 'bold!important',
    color: 'white !important',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  label: {
    textAlign: 'left',
    fontWeight: '700!important',
    marginBottom: '7px',
    color: 'white !important',
  },
  input: {
    border: '#e0e0e0 1.5px solid',
    borderRadius: '6px',
    paddingLeft: '7px',
  },
  inputContainer: {
    width: '100%',
    marginBottom: '-10px!important',
  },
  joinLink: {
    cursor: 'pointer',
    paddingLeft: '7px',
    color: `${acterioTheme.main} !important`,
    fontWeight: '700',
  },
  joinDiv: {
    fontSize: '1rem',
    marginTop: '-20px',
    textAlign: 'center',
  },
  form: {
    display: 'contents',
  },
  passwordPrompt: {
    fontSize: '0.875rem',
    textAlign: 'center',
  },
}));

interface OwnProps {
  emailToRegisterWith?: string | null;
  secretKey?: string;
}

const RegisterForm: FunctionComponent<OwnProps> = (props) => {
  const classes = useStyles();
  const { emailToRegisterWith, secretKey } = props;
  const { addAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>(emailToRegisterWith || '');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [consent, setConsent] = useState<boolean>(false);
  const [disableEmailInput, setDisableEmailInput] = useState<boolean>(false);
  const [timezone, setTimezone] = useState<string>('');
  const navigate = useNavigate();

  const timezoneCorrections: { [key: string]: string } = {
    'Asia/Calcutta': 'Asia/Kolkata',
  };

  function correctTimezoneIdentifier(timezone: string) {
    return timezoneCorrections[timezone] || timezone;
  }

  useEffect(() => {
    if (newEmail) {
      setDisableEmailInput(true);
    }

    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const correctedTimezone = correctTimezoneIdentifier(detectedTimezone);
    setTimezone(correctedTimezone);
  }, []);

  async function register(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('fullName', fullName.trim());
    formData.append('email', newEmail.trim());
    formData.append('password', newPassword);
    formData.append('confirmPassword', confirmPassword);
    formData.append('consent', consent);
    formData.append('timezone', timezone);

    try {
      const data = await postFormData(REGISTER_USER_ROUTE, formData, true);
      if (secretKey) {
        try {
          const inviteSecretFormData = new FormData();
          inviteSecretFormData.append('invite', secretKey);
          inviteSecretFormData.append('email', data);
          const inviteResponse = await postFormData(
            JOIN_BY_INVITE_ROUTE,
            inviteSecretFormData,
            true,
          );
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: 'Successfully accepted invitation',
          });
        } catch (e: any) {
          console.error('error', e);
          addAlert({
            type: ALERT_TYPES.ERROR,
            message: e.message,
          });
        }
      }

      const userLoggedIn = await authenticateEmailAndPassword(
        newEmail.trim(),
        newPassword,
      );
      if (userLoggedIn) {
        setLoading(false);
        addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: 'Your Acterio account is ready!',
        });
        navigate('/dashboard');
      }
    } catch (e: any) {
      console.error('error', e);
      setLoading(false);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  return (
    <form onSubmit={(e) => register(e)} className={classes.form}>
      <Typography variant={'h4'} className={classes.boldFont}>
        Register new account
      </Typography>
      <InputField
        value={fullName}
        readonly={loading}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setFullName(event.target.value)
        }
        label={'Full Name'}
        labelTextColor={'white'}
        formLabelStyles={{
          marginTop: 0,
        }}
        noEffects
      />

      <InputField
        type='email'
        value={newEmail}
        readonly={loading || disableEmailInput}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setNewEmail(event.target.value)
        }
        label={'Email address'}
        labelTextColor={'white'}
        formLabelStyles={{
          marginTop: 0,
        }}
        noEffects
      />
      <InputField
        value={newPassword}
        type={'password'}
        readonly={loading}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setNewPassword(event.target.value)
        }
        label={'Password'}
        labelTextColor={'white'}
        formLabelStyles={{
          marginTop: 0,
        }}
        noEffects
      />
      <InputField
        value={confirmPassword}
        type='password'
        readonly={loading}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setConfirmPassword(event.target.value)
        }
        label={'Confirm Password'}
        labelTextColor={'white'}
        formLabelStyles={{
          marginTop: 0,
        }}
        noEffects
        helperText={
          'Use 8 or more characters with a mix of letters, numbers & capitals'
        }
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
        }}>
        <input
          style={{ marginRight: '1rem' }}
          id='consent-input'
          type='checkbox'
          onChange={(e) => setConsent(e.target.checked)}
        />
        <Link
          className={classes.cursorPointer}
          href={'/documents/privacy-policy.pdf'}>
          Terms of use, Privacy Policy
        </Link>
      </div>
      <MainButton
        text={'Join Now'}
        type={ButtonType.FILLED}
        style={{
          marginTop: '-15px!important',
          fontSize: { xs: '1.5rem', lg: '1rem' },
        }}
        submit={true}
        loading={loading}
      />
      <div className={classes.joinDiv}>
        Already have an account?
        <Link
          className={classes.joinLink}
          underline={'none'}
          onClick={() => navigate('/login')}>
          Log in here!
        </Link>
      </div>
    </form>
  );
};

export default RegisterForm;
