import { useContext, useEffect, useState } from 'react';
import { IPeople } from 'utils/models';
import { getStoredMembers, storeMembers } from 'utils/storage';
import { getData, PEOPLE_ROUTE } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';

const useMembers = () => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [members, setMembers] = useState<IPeople[] | null>(getStoredMembers());
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!members) fetchMembers();
  }, [ecosystemName]);

  async function fetchMembers() {
    if (!ecosystemName) return null;
    setLoading(true);

    try {
      const data = await getData(PEOPLE_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setMembers(data);
      storeMembers(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return {
    loading,
    members,
    setMembers,
  };
};

export default useMembers;
