import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import InputField from 'components/common/InputField';
import { useParams } from 'react-router';
import {
  ECOADMIN_TRANSFER_COMPANY_OWNERSHIP_ROUTE,
  postData,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import SearchUsers from 'components/common/SearchUsers';
import { primary } from 'theme';
import CommonButton from 'components/common/CommonButton';



interface ITransferCompanyOwnership {
  companyNames: string[];
}

const TransferCompanyOwnership: FunctionComponent<ITransferCompanyOwnership> = (
  props,
) => {
  const { companyNames } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const [email, setEmail] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [removeOldOwner, setRemoveOldOwner] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [newUserEmail, setNewUserEmail] = useState<string>('');

  async function transferOwnership() {
    setLoading(true);

    try {
      const data = await postData(ECOADMIN_TRANSFER_COMPANY_OWNERSHIP_ROUTE, [
        {
          name: 'userEmail',
          value: email || newUserEmail,
        },
        {
          name: 'companyName',
          value: encodeURIComponent(company),
        },
        {
          name: 'role',
          value: role,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
        {
          name: 'removePreviousOwner',
          value: removeOldOwner,
        },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: data,
      });

      setEmail('');
      setCompany('');
      setRole('');
      setNewUserEmail('');
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return (
    <div>
      <Typography
        sx={{
          fontSize: '2.5rem',
          fontWeight: 600,
          lineHeight: '3.75rem',
          marginBottom: '2rem',
        }}>
        Transfer company's ownership
      </Typography>
      <Box
        sx={{
          width: '54.25rem',
          height: '40rem',
          backgroundColor: primary.pureWhite,
          borderRadius: '0.75rem',
          padding: '2.5rem',
          boxShadow: '0px 20px 27px 0px #0000000D',
        }}>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.125rem',
            color: primary.natural3,
          }}>
          Use the fields below to search for a company and a user. The selected
          user will become the company`s owner and be added to the company if
          they are not already a member. The role field is mandatory and should
          be used to assign the user a new role within the company, such as CEO.
        </Typography>
        <div>
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 500,
              lineHeight: '1.313rem',
              color: primary.mainBlackFont,
              marginTop: '1rem',
              marginBottom: '0.5rem',
            }}>
            Select new company owner
          </Typography>
          <SearchUsers
            onSearch={(value) => setEmail(value.emailEcoPreferred)}
            fullWidth={true}
          />
        </div>
        <div>
          <InputField
            value={newUserEmail}
            placeholder={'Enter email you want to send an invite to'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setNewUserEmail(event.target.value)
            }
            readonly={loading}
            label={'Invite new user via email'}
            formLabelStyles={{
              fontSize: '0.875rem',
              fontWeight: 500,
              lineHeight: '1.313rem',
              color: primary.mainBlackFont,
              marginTop: '1rem',
              marginBottom: '0.5rem',
            }}
          />
        </div>
        <div>
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 500,
              lineHeight: '1.313rem',
              color: primary.mainBlackFont,
              marginTop: '1rem',
              marginBottom: '0.5rem',
            }}>
            Company*
          </Typography>
          <Autocomplete
            options={companyNames}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Select a company'
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    '& .MuiInputBase-input': {
                      color: primary.natural2,
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: primary.natural2,
                  },
                }}
              />
            )}
            onChange={(e, value) => setCompany(value || '')}
            size='small'
          />
        </div>
        <div>
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 500,
              lineHeight: '1.313rem',
              color: primary.mainBlackFont,
              marginTop: '1rem',
              marginBottom: '0.5rem',
            }}>
            Role*
          </Typography>
          <InputField
            value={role}
            placeholder={'Role in the company'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setRole(event.target.value)
            }
            readonly={loading}
          />
        </div>
<Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem'}}>
   <FormControlLabel
          control={
            <Checkbox
              checked={removeOldOwner}
              onChange={(e, checked) => setRemoveOldOwner(checked)}
              color='error'
            />
          }
          label='Remove previous owner from the company'
          componentsProps={{
            typography: {
              style: { color: primary.warningRed, fontWeight: 600 },
            },
          }}
          sx={{ marginTop: '1rem' }}
        />
        <CommonButton
          text='Transfer Ownership'
          onClick={() => transferOwnership()}
          isDisabled={Boolean(
            !role ||
              !company ||
              (!email && !newUserEmail) ||
              (email && newUserEmail),
          )}
          isLoading={loading}
          sx={{ width: '12rem', alignSelf: 'flex-end', marginTop: '2rem' }}
        />
</Box>
       
      </Box>
    </div>
  );
};

export default TransferCompanyOwnership;
