import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import AdminSideMenu, { AdminMenuKeys } from 'components/Admin/AdminSideMenu';
import ManageAccess from 'components/Admin/ManageAccess/ManageAccess';
import AccessLevels from 'components/Admin/ManageAccess/AccessLevels';
import AccessMemberList from 'components/Admin/ManageAccess/AccessMemberList';
import AccessSwitchboard from 'components/Admin/ManageAccess/AccessSwitchboard';
import { useParams } from 'react-router';
import { IAccessLevel, IAccessLevelDto, ISideMenuSection } from 'utils/models';
import { ACCESS_CONTROL_ROUTE, getData } from 'utils/requests';
import Loading from 'components/common/Loading';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { Contacts, Security, SwitchAccessShortcut } from '@mui/icons-material';
import { Box } from '@mui/system';
import { getEcosystemInfo } from 'utils/storage';

export interface IAccessLevelsProps {
  accessLevelDtos: IAccessLevelDto[];
  defaultAccessLevel: IAccessLevel;
  setAccessLevelDtos: (accessLevelDtos: IAccessLevelDto[]) => void;
}

interface IAdminManageAccess {}

const AdminManageAccess: FunctionComponent<IAdminManageAccess> = (props) => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [accessLevelDtos, setAccessLevelDtos] = useState<IAccessLevelDto[]>([]);
  const [defaultAccessLevel, setDefaultAccessLevel] = useState<IAccessLevel>();
  const [selectedOption, setSelectedOption] = useState<AdminMenuKeys>(
    AdminMenuKeys.Access_Structure,
  );

  const { accessLevel } = getEcosystemInfo();

  useEffect(() => {
    fetchAccessLevels();
  }, []);

  async function fetchAccessLevels() {
    if (!ecosystemName) return;
    setLoading(true);

    try {
      const data = await getData(ACCESS_CONTROL_ROUTE, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      setAccessLevelDtos(data.accessLevelDtos);
      setDefaultAccessLevel(data.defaultAccessLevel);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function updateAccessLevelDtos(accessLevelDtos: IAccessLevelDto[]) {
    setAccessLevelDtos(accessLevelDtos);
  }

  function updateDefaultAccessLevel(accessLevel: IAccessLevel) {
    setDefaultAccessLevel(accessLevel);
  }

  function setMenuOption(menuKey: AdminMenuKeys) {
    setSelectedOption(menuKey);
  }

  const sections: ISideMenuSection[] = [
    {
      key: AdminMenuKeys.Access_Structure,
      text: 'Access Structure',
      icon: <Contacts />,
      onClick: () => setMenuOption(AdminMenuKeys.Access_Structure),
      hasAccess: accessLevel.canEditAccessLevels,
    },

    {
      key: AdminMenuKeys.Home,
      text: 'Manage Permissions',
      icon: <Security />,
      onClick: () => setMenuOption(AdminMenuKeys.Home),
      hasAccess: accessLevel.canEditAccessLevels,
    },

    {
      key: AdminMenuKeys.Assign_Access_Levels,
      text: 'Assign access levels',
      icon: <SwitchAccessShortcut />,
      onClick: () => setMenuOption(AdminMenuKeys.Assign_Access_Levels),
      hasAccess: accessLevel.canEditAccessLevels,
    },
  ];

  function renderSection() {
    if (!accessLevelDtos || !defaultAccessLevel) return null;

    if (selectedOption === AdminMenuKeys.Access_Structure) {
      return (
        <AccessLevels
          accessLevelDtos={accessLevelDtos}
          defaultAccessLevel={defaultAccessLevel}
          setAccessLevelDtos={updateAccessLevelDtos}
        />
      );
    }

    if (selectedOption === AdminMenuKeys.Assign_Access_Levels) {
      return (
        <AccessMemberList
          accessLevelDtos={accessLevelDtos}
          defaultAccessLevel={defaultAccessLevel}
          setAccessLevelDtos={updateAccessLevelDtos}
          setDefaultAccessLevel={updateDefaultAccessLevel}
        />
      );
    }

    return (
      <AccessSwitchboard
        accessLevelDtos={accessLevelDtos}
        defaultAccessLevel={defaultAccessLevel}
        setAccessLevelDtos={updateAccessLevelDtos}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'row',
        justifyContent: 'start',
        minHeight: 'calc(100vh - 4.4rem)',
        marginTop: '-3rem',
      }}>
      <Box>
        <AdminSideMenu menuItems={sections} selectedOption={selectedOption} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginLeft: loading ? '40rem' : '4rem',
        }}>
        {loading ? (
          <Loading marginTop={90} />
        ) : (
          <ManageAccess section={renderSection()} />
        )}
      </Box>
    </Box>
  );
};

export default AdminManageAccess;
