import React, { FunctionComponent } from 'react';
import { Box, SxProps } from '@mui/system';
import CommonButton, { ButtonVariant, IButton } from '../CommonButton';
import { useTheme } from '@mui/material';

export interface IButtonGroupProps {
  primaryButton: IButton;
  secondaryButton?: IButton;
  tertiaryButton?: IButton;
  minButtonWidth?: string | number;
  sx?: SxProps;
}
const FooterButtonGroup: FunctionComponent<IButtonGroupProps> = (props) => {
  const { primaryButton, secondaryButton, tertiaryButton, minButtonWidth, sx } =
    props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        marginTop: '1rem',
        gap: '1rem',
        ...sx,
      }}>
      <CommonButton
        bgcolor={primaryButton.bgcolor ?? theme.palette.primary.dark}
        sx={{
          marginLeft: '1rem',
          minWidth: minButtonWidth ?? 'unset',
        }}
        {...primaryButton}
      />
      {secondaryButton && (
        <CommonButton
          variant={tertiaryButton ? ButtonVariant.FILLED : ButtonVariant.BLANK}
          sx={{
            marginLeft: '1rem',
            minWidth: minButtonWidth ?? 'unset',
          }}
          {...secondaryButton}
        />
      )}
      {tertiaryButton && (
        <CommonButton
          variant={ButtonVariant.BLANK}
          sx={{ minWidth: minButtonWidth ?? 'unset' }}
          {...tertiaryButton}
        />
      )}
    </Box>
  );
};

export default FooterButtonGroup;
