import React, { FunctionComponent } from 'react';
import { Box } from '@mui/system';
import CopyField from '../CopyField';
import InformationModal, { IInfoModal } from './InformationModal';

interface ModalWithLinkProps extends IInfoModal {
  aboveLinkText: string;
  underLinkText: string;
  link: string;
  setLink: (value: string) => void;
}

const ModalWithLink: FunctionComponent<ModalWithLinkProps> = (props) => {
  const { aboveLinkText, underLinkText, link, setLink, ...otherProps } = props;

  return (
    <InformationModal {...otherProps}>
      <Box sx={{ marginRight: '1.5rem' }}>
        <hr />
        <Box>
          <Box sx={{ marginY: '1rem' }}>{aboveLinkText}</Box>
          <CopyField text={link} onChange={setLink} />
        </Box>
        <Box sx={{ marginTop: '1rem' }}>{underLinkText}</Box>
      </Box>
    </InformationModal>
  );
};

export default ModalWithLink;
