import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Accordion,
  Button,
  ButtonBase,
  Fade,
  Input,
  Theme,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Delete, ArrowDropDown } from '@mui/icons-material';
import { IJourneyMilestone, IJourneyResources } from 'utils/models';
import JourneyProgressBar, {
  getProgressKey,
  INITIAL_STAGE_PROGRESS,
  IStageProgress,
} from './JourneyProgressBar';
import JourneyGoal from './JourneyGoal';
import { Box } from '@mui/system';
import { useJourneyContext } from './JourneyContext';
import TypographyBox from 'components/common/TypographyBox';
import JourneyGoalDeadline from './JourneyGoalDeadline';
import LaunchIcon from '@mui/icons-material/Launch';
import JourneyGoalAssignedPeople from './JourneyGoalAssignedPeople';
import JourneyGoalProgress from './JourneyGoalProgress';
import { StageProgress } from './JourneyProgressBar';

const useStyles = makeStyles((theme: Theme) => ({
  milestoneBody: {
    backgroundColor: (props: any) => (props.isCustom ? '#f7f2de' : 'white'),
    minHeight: '3.125rem',
    minWidth: '100%',
    border: '1px solid #E0E0E0',
    borderTop: (props: any) =>
      (!props.isFirst || props.isLast) && !props.isFirst
        ? 'none'
        : '1px solid #E0E0E0',
    borderTopLeftRadius: (props: any) => (props.isFirst ? '6px' : 'none'),
    borderTopRightRadius: (props: any) => (props.isFirst ? '6px' : 'none'),
    borderBottomLeftRadius: (props: any) => (props.isLast ? '6px' : 'none'),
    borderBottomRightRadius: (props: any) => (props.isLast ? '6px' : 'none'),
  },
  milestoneHeader: {
    padding: '0.5rem 1rem 0.5rem 0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: (props: any) => (props.isCustom ? '#f7f2de' : 'white'),

    '& button': {
      padding: '3px !important',
      borderRadius: '50% !important',
    },

    '& svg': {
      height: '28px',
      width: '28px',
      color: (props: any) =>
        props.haveGoalsOrCustomGoals ||
        (props.isCustom && props.isEditJourneyContext)
          ? theme.palette.primary.dark
          : '#E0E0E0',
    },
  },
  milestone: {
    display: 'flex',
    alignItems: 'start',
    gap: '0.5rem',
  },
  milestoneGoalHeader: {
    padding: '0.5rem 1.5rem',
    paddingBottom: '0.35rem',
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',

    '& h6': {
      fontSize: '14px !important',
    },
  },
  accordion: {
    backgroundColor: 'white !important',
  },
  cont: {
    display: 'flex',
    gap: '1rem',
  },
  milestoneHeaderCont: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',

    '& p': {
      fontSize: '0.75rem !important',
      fontWeight: 600,
      marginRight: '0.5rem',
    },
  },
  mobileBox: {
    padding: '1rem',
  },
}));

interface IJourneyMilestoneProps {
  themeIndex: number;
  milestone: IJourneyMilestone;
  milestoneIndex: number;
  isFirst: boolean;
  isLast: boolean;
  isCustom?: boolean;
}

const JourneyMilestone: FunctionComponent<IJourneyMilestoneProps> = (props) => {
  const { milestone, isFirst, isLast, themeIndex, milestoneIndex, isCustom } =
    props;
  const { name, goals, customGoals } = milestone;
  const haveGoals = goals?.length > 0;
  const haveGoalsOrCustomGoals =
    goals?.length > 0 || (customGoals !== undefined && customGoals?.length > 0);
  const initialHide = isCustom ? false : !haveGoals;
  const [hide, setHide] = useState<boolean>(initialHide);
  const [goalsCompleted, setGoalsCompleted] = useState<number>(0);
  const [stageProgress, setStageProgress] = useState<IStageProgress>();
  const {
    isEditJourneyContext,
    addCustomGoal,
    deleteCustomMilestone,
    editCustomMilestone,
  } = useJourneyContext();

  const classes = useStyles({
    haveGoals,
    haveGoalsOrCustomGoals,
    isFirst,
    isLast,
    isCustom,
    isEditJourneyContext,
  });
  const theme: any = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const formatNumOfWithBrackets = (num: number) => {
    return `(${num})`;
  };

  function getTotalResources(resources: IJourneyResources) {
    const { files, persons, links } = resources;
    return files.length + persons.length + links.length;
  }

  function getBgColor(option: StageProgress | undefined) {
    return !option || option === StageProgress.TODO
      ? '#eee'
      : option === StageProgress.IN_PROGRESS
      ? '#FFDC8D'
      : '#A2F0CF';
  }

  useEffect(() => {
    getStageProgress();
  }, [goals]);

  useEffect(() => {
    if (isEditJourneyContext) {
      setHide(false);
    } else {
      setHide(initialHide);
    }
  }, [isEditJourneyContext]);

  function getStageProgress() {
    if (!haveGoals) return { ...INITIAL_STAGE_PROGRESS };

    const stageProgressCopy = { ...INITIAL_STAGE_PROGRESS };
    let numGoalsCompleted = 0;

    goals.forEach(({ progress }) => {
      const key = getProgressKey(progress);
      stageProgressCopy[key] = stageProgressCopy[key] + 1;

      if (key === 'finished') {
        numGoalsCompleted++;
      }
    });

    setGoalsCompleted(numGoalsCompleted);
    setStageProgress(stageProgressCopy);
  }

  function renderGoals() {
    if (!!goals) {
      return goals.map((goal, i) => (
        <JourneyGoal
          key={`Goal-${i}`}
          index={i}
          goal={goal}
          isLast={isLast}
          themeIndex={themeIndex}
          milestoneIndex={milestoneIndex}
          isCustomMilestone={isCustom}
        />
      ));
    }
  }

  function renderCustomGoals() {
    if (customGoals) {
      return customGoals.map((goal, i) => (
        <JourneyGoal
          key={`Custom-goal-${i}`}
          index={i}
          goal={goal}
          isLast={isLast}
          themeIndex={themeIndex}
          milestoneIndex={milestoneIndex}
          isCustom={true}
          isCustomMilestone={isCustom}
        />
      ));
    }
  }

  function renderAddCustomGoalButton() {
    return (
      <Box sx={{ bgcolor: '#fffcef' }}>
        <Box
          sx={{
            height: 40,
            display: 'flex',
            justifyContent: 'space-between',
            border: '2px dashed rgba(168,168,168,0.6)',
          }}>
          <Button
            onClick={() => addCustomGoal(themeIndex, milestoneIndex, isCustom)}
            sx={{ justifyItems: 'center' }}>
            <Typography
              sx={{
                fontSize: 14,
                color: theme.palette.primary.dark,
                marginLeft: 1,
              }}>
              + Add custom activity
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  }

  function renderDeleteCustomMilestoneButtton() {
    return (
      <Button onClick={() => deleteCustomMilestone(themeIndex, milestoneIndex)}>
        <Delete sx={{ color: theme.palette.primary.dark }} />
      </Button>
    );
  }

  return (
    <div className={classes.milestone}>
      <div className={classes.milestoneBody}>
        <Accordion
          expanded={!hide}
          defaultExpanded={false}
          className={classes.accordion}>
          <div className={classes.milestoneHeader}>
            <div className={classes.milestoneHeaderCont}>
              <div className={classes.cont}>
                {isCustom && isEditJourneyContext ? (
                  <Input
                    value={name}
                    placeholder='New custom goal'
                    onChange={(e) =>
                      editCustomMilestone(
                        themeIndex,
                        milestoneIndex,
                        e.target.value,
                      )
                    }
                  />
                ) : (
                  <Typography variant={'h6'}>{name}</Typography>
                )}
              </div>
            </div>
            <Fade in={true} timeout={1000}>
              <div className={classes.milestoneHeaderCont}>
                {isCustom && isEditJourneyContext ? (
                  renderDeleteCustomMilestoneButtton()
                ) : (
                  <></>
                )}
                {isEditJourneyContext && isCustom ? (
                  <></>
                ) : (
                  <Typography variant={'body1'}>
                    {haveGoals
                      ? `${goalsCompleted}/${goals.length} Activities`
                      : 'No Activities'}
                  </Typography>
                )}
                {isEditJourneyContext && isCustom ? (
                  <></>
                ) : (
                  <JourneyProgressBar
                    progress={stageProgress}
                    width={150}
                    backgroundColor={'#f5f5f5'}
                    borderColor={'#1C7D53'}
                    sx={{ justifySelf: 'flex-end' }}
                  />
                )}
              </div>
            </Fade>
            <ButtonBase
              onClick={() => setHide(!hide)}
              disabled={
                isEditJourneyContext && isCustom
                  ? false
                  : !haveGoalsOrCustomGoals
              }>
              <ArrowDropDown
                style={{
                  transition: '0.2s ease-in-out all',
                  transform: hide ? 'rotate(180deg)' : 'none',
                }}
              />
            </ButtonBase>
          </div>
          {isMobileScreen ? (
            <>
              {goals.map((goal, i) => (
                <Box key={i}>
                  <Box sx={{ borderBottom: '1px solid #E0E0E0' }}>
                    <Box className={classes.mobileBox}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '1rem',
                          lineHeight: '1.5rem',
                        }}>
                        Activity
                      </Typography>
                      <TypographyBox
                        text={goal.name}
                        sx={{ paddingTop: '0.5rem' }}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.mobileBox}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '1rem',
                        lineHeight: '1.5rem',
                      }}>
                      Deadline
                    </Typography>
                  </Box>
                  <JourneyGoalDeadline
                    progress={goal.progress}
                    deadline={goal.deadline ? new Date(goal.deadline) : null}
                    goalId={goal.id}
                    isCustom={isCustom}
                    boxWidth={'100%'}
                  />
                  <Box className={classes.mobileBox}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '1rem',
                        lineHeight: '1.5rem',
                      }}>
                      Resources
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '1rem',
                    }}>
                    <ButtonBase>
                      <LaunchIcon />
                    </ButtonBase>
                    <Typography
                      sx={{
                        fontSize: '0.8rem',
                        fontWeight: 500,
                        marginLeft: '0.5rem',
                      }}>
                      {formatNumOfWithBrackets(
                        getTotalResources(goal.resources),
                      )}
                    </Typography>
                  </Box>
                  <Box className={classes.mobileBox}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '1rem',
                        lineHeight: '1.5rem',
                      }}>
                      People
                    </Typography>
                  </Box>
                  <Box sx={{ margin: '0 0 1rem 1rem' }}>
                      <JourneyGoalAssignedPeople
                        goal={goal}
                        isCustom={isCustom}
                      />
                  </Box>
                  <Box className={classes.mobileBox}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '1rem',
                        lineHeight: '1.5rem',
                      }}>
                      Status
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      paddingLeft: '1rem',
                      display: 'flex',
                      backgroundColor: getBgColor(goal.progress),
                    }}>
                    <JourneyGoalProgress
                      themeIndex={0}
                      milestoneIndex={0}
                      progress={goal.progress}
                      isLast={false}
                      goalId={undefined}
                      isCustom={undefined}
                      isCustomMilestone={undefined}
                    />
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <>
              <Box className={classes.milestoneGoalHeader}>
                <Box sx={{ width: { md: '25%', lg: '30%' } }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                    }}>
                    Activity
                  </Typography>
                </Box>
                <Box sx={{ width: { md: '15%', lg: '10%' } }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                    }}>
                    Deadline
                  </Typography>
                </Box>
                <Box sx={{ width: { md: '15%', lg: '10%' } }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                    }}>
                    Resources
                  </Typography>
                </Box>
                <Box sx={{ width: { md: '15%', lg: '10%' } }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                    }}>
                    People
                  </Typography>
                </Box>
                <Box sx={{ width: { md: '15%', lg: '10%' } }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                    }}>
                    Status
                  </Typography>
                </Box>
              </Box>
              <div>{renderGoals()}</div>
              <div>{renderCustomGoals()}</div>
              {isEditJourneyContext && renderAddCustomGoalButton()}
            </>
          )}
        </Accordion>
      </div>
    </div>
  );
};

export default JourneyMilestone;
