import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import clsx from 'clsx';
import MainButton, { ButtonType } from './MainButton';
import { acterioTheme } from 'theme';
import { Box } from '@mui/system';
import { WarningAmber } from '@mui/icons-material';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: '10px 20px',
    paddingRight: '7.5px',
    paddingLeft: (props: any) => (props.withAside ? '0' : '20px'),
    height: (props: any) => (props.largeHeader ? '55px' : '45px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: (props: any) =>
      props.isActerioTheme
        ? `${acterioTheme.light} !important`
        : `${props.headerColor ?? '#eee'}`,
    borderBottom: (props: any) =>
      props.headerBorder ? '1px solid #e0e0e0' : 'none',

    '& button': {
      minWidth: (props: any) => (props.largeHeader ? '2.5rem' : '35px'),
      width: (props: any) => (props.largeHeader ? '2.5rem' : '35px'),
      height: (props: any) => (props.largeHeader ? '2.5rem' : '35px'),

      '& svg': {
        color: (props: any) =>
          props.isActerioTheme
            ? `${acterioTheme.dark} !important`
            : `${theme.palette.primary.dark} !important`,
        width: (props: any) => (props.largeHeader ? '1.875rem' : '24px'),
        height: (props: any) => (props.largeHeader ? '1.875rem' : '24px'),
      },
    },

    '& h6': {
      textTransform: 'capitalize',
      fontSize: (props: any) =>
        props.largeHeader ? '1.2rem !important' : '1rem !important',
      lineHeight: '1rem !important',
      marginTop: '2px',
      color: (props: any) =>
        props.color ?? props.isActerioTheme
          ? `${acterioTheme.dark} !important`
          : theme.palette.primary.dark,
    },
  },
  main: {
    padding: (props: any) => (props.noPadding ? '0' : '20px'),
    paddingBottom: (props: any) => (props.noPadding ? '0' : '10px'),
    borderTop: 'none',
    minHeight: '6.25rem',

    '& div, textarea': {
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 12px #E0E0E0',
        webkitBoxShadow: 'inset 0 0 12px #E0E0E0',
        borderRadius: '0px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#BDBDBD',
        borderRadius: '0px',
      },
    },
  },
  footer: {
    padding: '5px 20px',
    paddingLeft: (props: any) => (props.withAside ? '0' : '20px'),
    paddingTop: (props: any) => (props.withAside ? '0' : '5px'),
    paddingBottom: (props: any) => (props.withAside ? '0' : '5px'),
    height: (props: any) => (props.isMobile ? '60px' : '55px'),
    maxHeight: (props: any) => (props.isMobile ? '60px' : '55px'),
    display: 'flex',
    alignItems: 'center',
    borderTop: (props: any) =>
      props.hideFooterBorder || props.largeFooter ? 'none' : '1px solid #eee',
    backgroundColor: (props: any) =>
      props.footerColor ? props.footerColor : 'transparent',

    '& button': {
      fontSize: '0.8rem',
      borderRadius: '4px',
      marginLeft: '7.5px !important',
      textTransform: 'capitalize',
      height: '35px !important',
      minWidth: '5.313rem !important',
    },
  },
  cancel: {
    backgroundColor: 'transparent !important',
    color: (props: any) =>
      props.isActerioTheme
        ? `${acterioTheme.dark} !important`
        : `${theme.palette.primary.dark} !important`,
  },
  save: {
    backgroundColor: (props: any) =>
      props.isActerioTheme
        ? `${acterioTheme.dark} !important`
        : `${theme.palette.primary.dark} !important`,
    color: 'white !important',
  },
  remove: {
    marginLeft: '-10px !important',
    color: (props: any) =>
      props.acterioTheme
        ? `${acterioTheme.dark} !important`
        : `${theme.palette.primary.dark} !important`,
    textDecoration: 'underline !important',

    '& button': {
      width: '10rem !important',
      minWidth: '10rem !important',
      maxWidth: '10rem !important',
    },

    '& svg': {
      marginRight: '5px',
      height: '18px',
      width: '18px',
    },
  },
  largeFooter: {
    padding: '25px 1.75rem',
    paddingTop: '0',
    display: 'flex',
    alignItems: 'center',
    borderTop: 'none',
    minHeight: '4.375rem',
    maxHeight: '4.375rem',

    '& button': {
      fontSize: '0.95rem',
      borderRadius: '4px',
      marginLeft: '5px',
      padding: '7.5px 15px !important',
      minWidth: '7.188rem !important',
      textTransform: 'capitalize',
      fontWeight: 600,
      minHeight: '41.5px !important',
      maxHeight: '41.5px !important',
    },
  },
  saveLarge: {
    backgroundColor: (props: any) =>
      props.isActerioTheme
        ? `${acterioTheme.dark} !important`
        : `${theme.palette.primary.dark} !important`,
  },
  closeLarge: {
    backgroundColor: 'transparent !important',
  },
  asideOffset: {
    display: 'flex',
    minWidth: '17.188rem',
    minHeight: '100%',
    backgroundColor: '#FAFAFA',
    padding: '1rem 1.5rem',
  },
  btnCont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export interface IBaseModal {
  open: boolean;
  close: () => void;
}

interface IModal extends IBaseModal {
  title?: string;
  color?: string;
  isActerioTheme?: boolean;
  children?: JSX.Element | null;
  showFooter?: boolean;
  saveAction?: () => any;
  saveButtonText?: string;
  cancelButtonText?: string;
  cancelAction?: () => any;
  footerRemoveAction?: () => any;
  footerRemoveText?: string;
  noPadding?: boolean;
  width?: number;
  headerColor?: string;
  backdropStrength?: number;
  largeHeader?: boolean;
  backgroundColor?: string;
  noHeader?: boolean;
  largeFooter?: boolean;
  footerButtonsFlexStart?: boolean;
  headerBorder?: boolean;
  footerColor?: string;
  hideFooterBorder?: boolean;
  withAside?: boolean;
  loading?: boolean;
  disabledSave?: boolean;
  loadOnRemove?: boolean;
  saveButtonColor?: string;
  hideBlur?: boolean;
  hideSave?: boolean;
  isAllRequiredFilled?: boolean;
  reverseRow?: boolean;
}

const Modal: FunctionComponent<IModal> = (props) => {
  const {
    open,
    isActerioTheme,
    close,
    title,
    color,
    children,
    showFooter,
    saveAction,
    saveButtonText,
    cancelButtonText,
    cancelAction,
    noPadding,
    footerRemoveAction,
    footerRemoveText,
    width,
    headerColor,
    backdropStrength,
    largeHeader,
    backgroundColor,
    noHeader,
    largeFooter,
    footerButtonsFlexStart,
    headerBorder,
    footerColor,
    hideFooterBorder,
    withAside,
    loading,
    disabledSave,
    loadOnRemove,
    saveButtonColor,
    hideBlur,
    hideSave,
    isAllRequiredFilled,
    reverseRow,
  } = props;

  const { isMobile } = useMobileContext();
  const theme = useTheme();

  const classes = useStyles({
    color,
    isActerioTheme,
    noPadding,
    headerColor,
    largeHeader,
    headerBorder,
    footerColor,
    largeFooter,
    hideFooterBorder,
    withAside,
    loading,
    isMobile,
  });

  function renderFooterButtons() {
    const cancelBtn = (
      <Button
        disabled={loading}
        key={'modal-cancel-btn'}
        className={classes.cancel}
        onClick={() => (!!cancelAction ? cancelAction() : close())}>
        {cancelButtonText ?? 'Cancel'}
      </Button>
    );

    const saveBtn = (
      <MainButton
        key={'modal-save-btn'}
        type={ButtonType.FILLED}
        text={saveButtonText ?? 'Save'}
        loading={!loadOnRemove && loading}
        disabled={disabledSave || loading}
        onClick={saveAction}
        backgroundColor={
          isActerioTheme
            ? acterioTheme.dark
            : !!saveButtonColor
            ? saveButtonColor
            : theme.palette.primary.dark
        }
        style={{ display: !hideSave ? 'block' : 'none' }}
      />
    );

    const buttons = [cancelBtn, saveBtn];
    return (
      <Box
        className={classes.btnCont}
        sx={{
          flexDirection: reverseRow ? 'row-reverse' : '',
          marginTop: isMobile ? '0.5rem' : 0,
        }}>
        {footerButtonsFlexStart ? buttons.reverse() : buttons}
      </Box>
    );
  }

  function onClose() {
    return !loading && close();
  }

  function disableBackdropClick(event: any, reason: string) {
    if (reason === 'backdropClick') return;
    onClose;
  }

  return (
    <Dialog
      onClose={disableBackdropClick}
      transitionDuration={500}
      open={open}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(4px) !important',
        },
      }}
      PaperProps={{
        style: {
          borderRadius: '5px',
          backgroundColor: backgroundColor ?? '#fafafa',
          boxShadow:
            'rgba(0, 0, 0, 0.04) 0px 3px 6px, rgba(0, 0, 0, 0.08) 0px 3px 6px',
          minWidth: `${width ?? 600}px`,
        },
      }}>
      <div>
        {!noHeader && (
          <div className={classes.header}>
            {withAside && (
              <div className={classes.asideOffset}>
                <Typography variant={'h6'}>{title}</Typography>
              </div>
            )}
            {!withAside && <Typography variant={'h6'}>{title}</Typography>}
            <Button onClick={onClose} disabled={loading}>
              <HighlightOffIcon />
            </Button>
          </div>
        )}
        <div className={classes.main}>{children}</div>
        {isAllRequiredFilled !== undefined && !isAllRequiredFilled && (
          <Box display='flex' sx={{ marginLeft: 5 }}>
            <WarningAmber sx={{ marginRight: 1 }} />
            <Typography>Not all required fields are filled!</Typography>
          </Box>
        )}
        {showFooter && (
          <div
            className={clsx(classes.footer, {
              [classes.largeFooter]: largeFooter,
            })}
            style={{
              justifyContent:
                footerRemoveAction || withAside
                  ? 'space-between'
                  : footerButtonsFlexStart
                  ? 'flex-start'
                  : 'flex-end',
            }}>
            {withAside && <div className={classes.asideOffset}></div>}
            {footerRemoveAction && (
              <div className={classes.remove}>
                <MainButton
                  text={footerRemoveText ?? 'Delete'}
                  type={ButtonType.BLANK}
                  loading={loadOnRemove}
                  disabled={loading}
                  startIcon={<DeleteForeverIcon />}
                  onClick={footerRemoveAction}
                />
              </div>
            )}
            {renderFooterButtons()}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default Modal;
