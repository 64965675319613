import { Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { primary } from 'theme';

interface ITypoBox {
  text: string;
  sx?: SxProps;
  numberOfLines?: number;
}

const TypographyBox: FunctionComponent<ITypoBox> = (props) => {
  const { text, sx, numberOfLines = 1 } = props;

  const textRef = useRef<HTMLDivElement>(null);

  const [linesNeeded, setLinesNeeded] = useState<number>(1);

  function calculateNumberOfRows() {
    const textContainer = textRef.current;
    if (!textContainer) return;

    const tempParagraph = document.createElement('p');

    const computedStyle = window.getComputedStyle(textContainer);

    // Copy and insert styling of the Typography into temporaryParaghraph element to ensure accurate calculations

    for (let i = 0; i < computedStyle.length; i++) {
      const propName = computedStyle[i];
      const value = computedStyle.getPropertyValue(propName);
      (tempParagraph.style as any)[propName] = value;
    }

    // Assign additional styling attributes to ensure that calculations are possible

    Object.assign(tempParagraph.style, {
      position: 'absolute',
      visibility: 'hidden',
      whiteSpace: 'nowrap',
      width: 'auto',
      height: 'auto',
    });

    tempParagraph.innerText = text;

    document.body.appendChild(tempParagraph);

    // Calculate number of lines needed to display text

    const numberOfLinesNeeded = Math.ceil(
      tempParagraph.offsetWidth / textContainer.offsetWidth,
    );

    setLinesNeeded(numberOfLinesNeeded);

    document.body.removeChild(tempParagraph);
  }

  useEffect(() => {
    calculateNumberOfRows();
  }, [text]);

  return (
    <Box
      width='100%'
      height='100%'
      sx={{
        ...sx,
      }}>
      <Typography
        ref={textRef}
        sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: numberOfLines,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&:hover':
            linesNeeded > numberOfLines
              ? {
                  WebkitLineClamp: 'unset',
                  position: 'relative',
                  backgroundColor: primary.pureWhite,
                  padding: '0.5rem',
                  marginTop: '-0.5rem',
                  marginLeft: '-0.5rem',
                  borderRadius: '5px',
                  border: `1px solid ${primary.natural2}`,
                  zIndex: 2,
                }
              : {},
        }}>
        {text}
      </Typography>
    </Box>
  );
};

export default TypographyBox;
