import React, { useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import {
  CompanyProfileFieldType,
  CompanyTemplateSectionType,
} from 'utils/models';
import { Box } from '@mui/system';
import { useCompanyTemplateContext } from '../CompanyTemplateContext';
import InputField from 'components/common/InputField';
import { IconPicker } from '../../../common/IconPicker';
import InformationModal from 'components/common/Modal/InformationModal';
import SelectDropdown from 'components/common/SelectDropdown';

interface IAddField {
  sectionType: CompanyTemplateSectionType;
  isOpen: boolean;
  onClose: () => void;
}

export const CompanyTemplateAddSectionModal = (props: IAddField) => {
  const { sectionType, isOpen, onClose } = props;
  const theme = useTheme();
  const [hasError, setHasError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const { sectionField, setSectionField, addSectionField, companyTemplate } =
    useCompanyTemplateContext();
  const fieldNames = companyTemplate?.[sectionType]
    ? companyTemplate[sectionType].map((field) => field.name)
    : [];

  async function handleAddSectionField() {
    if (!companyTemplate) return;

    if (companyTemplate && companyTemplate.header) {
      if (fieldNames.includes(sectionField.name)) {
        setHasError(true);
        setHelperText('Field name already exists');
        return;
      }
    }
    await addSectionField(sectionType, sectionField);
    onClose();
  }

  function handleChange(name: string) {
    setHasError(false);
    setHelperText('');

    setSectionField((prevField) => ({
      ...prevField,
      name: name,
    }));
  }

  function renderInputField() {
    return (
      <Box sx={{ width: '90%' }}>
        <InputField
          placeholder='Name of a section'
          value={sectionField.name}
          onChange={(e) => handleChange(e.target.value)}
          helperText={helperText}
          error={hasError}
        />
      </Box>
    );
  }

  function renderRequiredToggler() {
    return (
      <Box
        onClick={() =>
          setSectionField((prevField) => ({
            ...prevField,
            required: !prevField.required,
          }))
        }
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        {sectionField.required ? (
          <IconPicker name='Error' sx={{ color: theme.palette.primary.main }} />
        ) : (
          <IconPicker
            name='ErrorOutline'
            sx={{ color: theme.palette.primary.main }}
          />
        )}
      </Box>
    );
  }

  function filterOptions() {
    if (sectionType === CompanyTemplateSectionType.MAIN) {
      return [
        CompanyProfileFieldType.RICH_TEXT,
        CompanyProfileFieldType.IMAGES,
      ];
    }
    if (sectionType === CompanyTemplateSectionType.SIDEBAR) {
      return [
        CompanyProfileFieldType.PEOPLE,
        CompanyProfileFieldType.TAGS,
        CompanyProfileFieldType.FILES,
        CompanyProfileFieldType.DATE,
      ];
    }
    return [];
  }

  const fieldOptionLabels = {
    [CompanyProfileFieldType.TEXT]: 'Single-line input',
    [CompanyProfileFieldType.RICH_TEXT]: 'Text area',
    [CompanyProfileFieldType.TAGS]: 'Tags',
    [CompanyProfileFieldType.DATE]: 'Date picker',
    [CompanyProfileFieldType.FILES]: 'File uploader',
    [CompanyProfileFieldType.PEOPLE]: 'People',
    [CompanyProfileFieldType.IMAGES]: 'Image uploader',
  };

  function renderFieldTypeSelector() {
    return (
      <SelectDropdown
        value={sectionField.type}
        options={filterOptions()}
        getOptionLabel={(option: CompanyProfileFieldType) =>
          fieldOptionLabels[option]
        }
        onChange={(value) =>
          setSectionField((prevField) => ({
            ...prevField,
            type: value,
          }))
        }
        height={40}
        sx={{ width: '90%' }}
      />
    );
  }

  return (
    <InformationModal
      isOpen={isOpen || false}
      handleClose={onClose}
      headerText='Add section'
      headerSize='1.875rem'
      width='36rem'
      primaryText='Add'
      primaryOnClick={handleAddSectionField}
      secondaryText='Cancel'
      secondaryOnClick={onClose}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', marginRight: '2rem' }}>
        <Typography
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            gap: '0.5rem',
            fontWeight: 400,
            fontSize: '1rem',
            paddingBottom: '1.25rem',
          }}>
          Mark this field with
          <IconPicker name='Error' sx={{ color: theme.palette.primary.main }} />
          icon if you want it to be mandatory.
        </Typography>
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: 600,
            paddingBottom: '0.5rem',
          }}>
          Section name
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.25rem',
            paddingBottom: '6.25rem',
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '1.25rem',
            }}>
            {renderInputField()}
            {renderRequiredToggler()}
          </Box>
          {renderFieldTypeSelector()}
        </Box>
      </Box>
    </InformationModal>
  );
};
