import { Typography, Theme, Divider, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router';
import { makeStyles } from '@mui/styles';
import CommonModal from '../common/Modal/CommonModal';
import CloseModalButton from '../common/Modal/CloseModalButton';
import AccessLevelTagCard from '../common/AccessLevelTagCard';

const useStyles = makeStyles((theme: Theme) => ({
  createdBy: {
    display: 'flex',
    cursor: 'pointer',

    '& p': {
      marginTop: '-6px !important',
      color: '#9e9e9e !important',
    },

    '& img': {
      borderRadius: '50%',
      height: '37.5px',
      width: '37.5px',
      marginRight: '15px',
    },
  },
}));

interface EventAttendeesProps {
  isOpen: boolean;
  handleClose: () => void;
  attendees: any;
}

const EventAttendees: FunctionComponent<EventAttendeesProps> = (props) => {
  const { isOpen, handleClose, attendees } = props;
  const navigate = useNavigate();
  const { ecosystemName } = useParams();
  const classes = useStyles();

  const theme = useTheme();

  function renderEventAttendees() {
    if (!attendees || !attendees.length)
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>No attendees yet!</Typography>
        </div>
      );
    return (
      <>
        {attendees.map((attendee: any, i: number) => (
          <Box key={`${attendee.id}-${i}`} marginBottom={2}>
            <div
              className={classes.createdBy}
              onClick={() =>
                navigate(`/ecosystem/${ecosystemName}/members/${attendee.id}`)
              }>
              <img src={attendee.profilePic} />
              <div>
                <Typography variant={'h6'}>{attendee.fullName}</Typography>
                <Typography variant={'body1'}>
                  {attendee.accessLevelName}
                </Typography>
              </div>
              <AccessLevelTagCard
                accessLevelName={attendee.accessLevelName}
                accessLevelColor={attendee.accessLevelColor}
                left={'1rem'}
                height={'1.75rem'}
              />
            </div>
          </Box>
        ))}
      </>
    );
  }

  return (
    <CommonModal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: 'calc(84% - min(90vh, 18.75rem))',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '36rem',
          bgcolor: '#FFFFFF',
          boxShadow: 24,
          p: '2rem',
          pl: '3rem',
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}>
        <Typography fontSize={'1.25rem'} fontWeight='bold'>
          People who are going:
        </Typography>
        <Divider sx={{ my: '1rem', width: 'calc(100% - 1rem)' }} />
        <Box sx={{ position: 'absolute', top: '1rem', right: '1rem' }}>
          <CloseModalButton
            handleClose={handleClose}
            color={theme.palette.primary.dark}
          />
        </Box>
        <Box sx={{ maxHeight: '25rem', overflow: 'auto', mt: '1rem' }}>
          {renderEventAttendees()}
        </Box>
      </Box>
    </CommonModal>
  );
};

export default EventAttendees;
