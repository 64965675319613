import React, { FunctionComponent } from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { primary } from 'theme';
import { useJourneyContext } from './JourneyContext';
import CommonModal from '../common/Modal/CommonModal';

interface EditWarningWindowProps {
  open: boolean;
  handleClose: () => void;
}

const EditWarningWindow: FunctionComponent<EditWarningWindowProps> = ({
  open,
  handleClose,
}) => {
  const { isEditJourneyContext, setIsEditJourneyContext, putUpdatedStage } =
    useJourneyContext();

  const theme = useTheme();

  const handleAccept = () => {
    if (isEditJourneyContext) {
      putUpdatedStage();
      setIsEditJourneyContext(false);
    } else {
      setIsEditJourneyContext(true);
    }
    handleClose();
  };

  return (
    <CommonModal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 0.5,
        }}>
        <Typography variant='h4' gutterBottom>
          {isEditJourneyContext ? 'Save Changes' : 'Custom goals'}
        </Typography>
        <Typography variant='body1' gutterBottom>
          {isEditJourneyContext
            ? 'Are you sure you want to add these goals and activities to this journey? Changes will not count towards the overall progress.'
            : 'Adding custom goals will allow you to add aditional goals and activities, but you will not be able to change the underlying template, and custom activities will not count towards the overall progress.'}
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            marginTop: '1rem',
          }}>
          <Button
            variant='contained'
            onClick={handleAccept}
            sx={{
              bgcolor: theme.palette.primary.dark,
              marginRight: '1rem',
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
            }}>
            <Typography color='white'>I understand</Typography>
          </Button>
          <Button
            variant='contained'
            onClick={handleClose}
            sx={{
              bgcolor: 'inherit',
              marginRight: '1rem',
              '&:hover': {
                bgcolor: primary.natural6,
              },
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
            }}>
            <Typography sx={{ color: theme.palette.primary.dark }}>
              Cancel
            </Typography>
          </Button>
        </div>
      </Box>
    </CommonModal>
  );
};

export default EditWarningWindow;
