import {
  BreakpointOverrides,
  Checkbox,
  MenuItem,
  Select,
  SxProps,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { forwardRef, useState } from 'react';
import { primary } from 'theme';
import CommonButton, { ButtonSize } from './CommonButton';

interface ISelectMultiple {
  isEmpty?: boolean;
  isClearButtonOff?: boolean;
  value: string[];
  placeholder?: string;
  onChange: (value: any) => any;
  options: string[];
  backgroundColor?: string;
  disabled?: boolean;
  height?: number | string | BreakpointOverrides;
  width?: number | string | BreakpointOverrides;
  maxOptionsDisplayed?: number;
  filterButton?: boolean;
  sx?: SxProps;
  menuStyles?: SxProps;
}
const SelectMultipleDropdown = forwardRef<HTMLDivElement, ISelectMultiple>(
  (props, ref) => {
    const {
      value,
      placeholder,
      onChange,
      options,
      backgroundColor,
      disabled,
      height,
      width,
      maxOptionsDisplayed,
      filterButton,
      isEmpty,
      isClearButtonOff,
      sx,
      menuStyles,
    } = props;
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    function renderOptions() {
      return options.map((option, i) => {
        return (
          <MenuItem key={`select-option-${option}-index-${i}`} value={option}>
            <Checkbox
              checked={value.includes(option)}
              sx={{ color: theme.palette.primary.main }}
            />
            {option}
          </MenuItem>
        );
      });
    }

    function renderValueSelected(selected: string[]) {
      if (selected.length === 0 || isEmpty)
        return placeholder ?? 'Select value...';

      if (maxOptionsDisplayed)
        return (
          selected.slice(0, maxOptionsDisplayed).join(', ') +
          (selected.length > maxOptionsDisplayed ? '...' : '')
        );

      return selected.join(', ');
    }

    return (
      <Select
        ref={ref}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disabled={disabled}
        displayEmpty
        value={value}
        multiple
        onChange={(event) => onChange(event.target.value as string)}
        renderValue={renderValueSelected}
        MenuProps={{
          sx: {
            maxHeight: '70vh',
            ...menuStyles,
          },
        }}
        sx={{
          width: width ?? '50%',
          minWidth: width ? 'unset' : '12rem',
          height: height ?? '2rem',
          minHeight: height ? 'unset' : '32px',
          bgcolor: backgroundColor ?? primary.pureWhite,
          borderRadius: '8px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              border: '1px solid rgba(0, 0, 0, 0.23)',
            },
          ...sx,
        }}>
        {renderOptions()}
        {!!value.length && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              justifyItems: 'center',
              my: '0.5rem',
            }}>
            {!isClearButtonOff && (
              <CommonButton
                text='Clear selected'
                onClick={() => onChange([])}
                bgcolor={theme.palette.primary.light}
                color={primary.mainBlackFont}
                size={ButtonSize.SMALL}
                sx={{ width: '96%', margin: 'auto' }}
              />
            )}
          </Box>
        )}
        {filterButton && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              justifyItems: 'center',
              mb: '0.5rem',
            }}>
            <CommonButton
              text={!!value.length ? 'Filter' : 'Close'}
              onClick={() => setOpen(false)}
              bgcolor={theme.palette.primary.dark}
              size={ButtonSize.SMALL}
              sx={{ width: '96%', margin: 'auto' }}
            />
          </Box>
        )}
      </Select>
    );
  },
);

SelectMultipleDropdown.displayName = 'SelectMultipleDropdown';

export default SelectMultipleDropdown;
