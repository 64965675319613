import { REDIRECT_URI } from 'utils/requests';

export const authenticateWithMicrosoft = (
  ecosystemName: string,
  roomId: number,
): void => {
  const tenant = 'organizations';
  const clientId = '74623c56-740e-4556-8a86-f7d71da71941';
  const redirectUri = encodeURIComponent(REDIRECT_URI);
  const scope = encodeURIComponent('Calendars.ReadWrite.Shared offline_access');
  const responseType = 'code';
  const responseMode = 'query';

  const stateObj = {
    ecosystemName: encodeURIComponent(ecosystemName),
    isRoom: true,
    roomId: roomId,
  };
  const stateString = JSON.stringify(stateObj);
  const encodedState = btoa(stateString);

  const authUrl = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&response_mode=${responseMode}&scope=${scope}&state=${encodedState}`;
  window.location.href = authUrl;
};
